import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { moveIngredient } from "../../../../actions/createrecipe";
import { DragDropContext } from "@hello-pangea/dnd";

import { Typography } from "@mui/material";

//Components
import RecipeSection from "./RecipeSection";
import { preventWidows } from "../../../../utils/text";

const RecipeSectionManager = ({ recipe: { create }, setShowAddSection, setInitialAddType, moveIngredient }) => {
  const handleAddSectionClick = () => {
    setInitialAddType("add");
    setShowAddSection(true);
  };

  const onDragEnd = (result) => {
    console.log("Drag ended:", result);
    const { source, destination, type } = result;
    if (!destination) {
      console.log("No destination, drag cancelled");
      return;
    }

    const sourceSectionIndex = parseInt(source.droppableId.split("-")[1]);
    const destSectionIndex = parseInt(destination.droppableId.split("-")[1]);

    moveIngredient(sourceSectionIndex, destSectionIndex, source.index, destination.index);
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="mb-4">
        {create && create.sections && create.sections.length === 0 ? (
          <div className="p-8 border-y md:border border-separator md:rounded-md shadow-sm cursor-pointer bg-background">
            <div onClick={() => handleAddSectionClick()}>
              <Typography variant="h6" className="font-normal leading-none mb-2">
                <span className="font-semibold text-mainGreen">+ </span>
                {preventWidows("Add a section to get started", 3)}
              </Typography>
              <Typography
                variant="subtitle2"
                className="text-secondaryText font-normal mr-4 md:mr-0 md:max-w-[50%] leading-tight text-xs"
              >
                Sections are used to group ingredients and steps, such as the icing on a cake or the side of a meal.
              </Typography>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            {create.sections.map((section, sectionIndex) => (
              <RecipeSection section={section} sectionIndex={sectionIndex} key={sectionIndex} />
            ))}
          </div>
        )}
      </div>
    </DragDropContext>
  );
};

RecipeSectionManager.propTypes = {
  recipe: PropTypes.object.isRequired,
  moveIngredient: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  recipe: state.recipe,
});

export default connect(mapStateToProps, { moveIngredient })(RecipeSectionManager);
