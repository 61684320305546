import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toggleRecipeMacrosModal, pushSectionMacro, removeSectionMacro } from "../../../../actions/createrecipe";
import { setAlert } from "../../../../actions/alert";
import CustomModal from "../../../modal/CustomModal";
import Searchable from "../../../layout/searchable/Searchable";
import { TextField, Button, Typography } from "@mui/material";

const RecipeAddMacroModal = ({ open, handleClose, sections, sectionIndex, pushSectionMacro, setAlert }) => {
  const [amount, setAmount] = useState("");
  const [macronutrient, setMacronutrient] = useState([]);

  const handleAmountChange = (e) => {
    const value = e.target.value;
    // Only allow numbers and decimal point, and ensure length is less than 6
    if ((/^\d*\.?\d*$/.test(value) || value === "") && value.length < 6) {
      setAmount(value);
    }
  };

  const handleAddMacro = () => {
    // Validate amount
    const numAmount = parseFloat(amount);
    if (isNaN(numAmount) || numAmount.toString().length > 5) {
      setAlert("Amount must be a number with at most 5 digits", "error");
      return;
    }

    // Validate macronutrient
    if (!macronutrient || !macronutrient.name) {
      setAlert("Please select a macronutrient", "error");
      return;
    }

    if (macronutrient.name.length > 32) {
      setAlert("Macronutrient name cannot be longer than 32 characters", "error");
      return;
    }

    //Push macro to section
    pushSectionMacro(sectionIndex, { amount: numAmount, macronutrient });
    //Set alert
    setAlert(`Added ${numAmount} ${macronutrient.name} to ${sections[sectionIndex].title}`);
    //Reset values
    setAmount("");
    setMacronutrient([]);
  };

  const handleSelectMacro = (item) => {
    setMacronutrient(item);
  };

  return (
    <CustomModal open={open} handleClose={handleClose} type="bottom" parentClassNameOverride="md:max-w-[600px]">
      <div className="flex-col gap-2 px-4 w-full">
        <div className="mb-4">
          <Typography variant="h6" className="font-normal leading-none">
            Add Macronutrient {sections[sectionIndex]?.title && `to ${sections[sectionIndex].title}`}
          </Typography>
        </div>
        <div className="w-full flex flex-col gap-4 mb-2">
          <div className="w-full">
            <TextField label="Amount" value={amount} onChange={handleAmountChange} fullWidth />
          </div>

          <div className="w-full">
            <Searchable
              apiUrl="/macros/searchable"
              label="Macronutrient"
              multiple={false}
              onItemChange={(item) => handleSelectMacro(item)}
              selectedItemsFromParent={macronutrient}
              maxInputLength={32}
            />
          </div>
        </div>
        <div className="flex justify-end mb-4">
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleAddMacro()}
            disableElevation
            fullWidth
          >
            + Add Macro
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

RecipeAddMacroModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  pushSectionMacro: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  sections: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sections: state.recipe.create.sections,
});

export default connect(mapStateToProps, { toggleRecipeMacrosModal, pushSectionMacro, setAlert })(RecipeAddMacroModal);
