import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Button, Typography } from "@mui/material";

import viper from "../../../../api/viper";

const PREFIX = "AudioRecorder";

const classes = {
  container: `${PREFIX}-container`,
  button: `${PREFIX}-button`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.container}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },
}));

// Function to upload audio blob using axios
const uploadAudio = async (audioBlob) => {
  const formData = new FormData();
  formData.append("file", audioBlob, "v2p.webm");

  try {
    const response = await viper.post("/v2p/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    console.log(response.data); // Handle response
  } catch (error) {
    console.error("Error uploading audio:", error);
  }
};

//TO DO:
// - Display something if microphone is disabled
// - Add recording time limit
// - Add recording time display
// - Add recording progress bar
// - Add recording cancel button
// - Add recording retry button
// - Add sample item addition text floating behind to give user feedback on what to say

//Prop -  //onRecordingComplete
const AudioRecorder = ({}) => {
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [isRecording, setIsRecording] = useState(false);

  const onRecordingComplete = async (audioBlob) => {
    await uploadAudio(audioBlob);
  };

  useEffect(() => {
    let stream = null;
    let recorder = null;

    // Request permissions and set up MediaRecorder
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((mediaStream) => {
        stream = mediaStream;
        recorder = new MediaRecorder(stream);
        setMediaRecorder(recorder);

        let audioChunks = [];
        recorder.ondataavailable = (e) => {
          audioChunks.push(e.data);
          if (recorder.state === "inactive") {
            const audioBlob = new Blob(audioChunks);
            onRecordingComplete(audioBlob);
          }
        };
      })
      .catch((error) => {
        console.error("Failed to get user media:", error);
      });

    // Cleanup function
    return () => {
      if (recorder && recorder.state === "recording") {
        recorder.stop();
      }
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  const startRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.start();
      setIsRecording(true);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setIsRecording(false);
    }
  };

  const ingredientStrings = [
    "9 eggs",
    "3/4 of a 1 kg bag of flour",
    "2 liters of whole milk",
    "1/2 pound of unsalted butter",
    "3 cans of chickpeas",
    "1 jar of peanut butter",
    "250 grams of cheddar cheese",
    "1 bottle of olive oil",
    "4 loaves of whole wheat bread",
    "5 ripe bananas",
    "2 kg of jasmine rice",
    "1 packet of spaghetti",
    "800 grams of chicken breast",
    "1 container of Greek yogurt",
    "6 sweet potatoes",
    "1 bag of mixed frozen vegetables",
    "2 onions",
    "3 heads of garlic",
    "1 bunch of fresh cilantro",
    "1 piece of ginger root",
    "500 grams of lean ground beef",
    "2 cans of diced tomatoes",
    "1 box of cereal",
    "1 jar of honey",
    "2 lemons",
    "1 kg of apples",
    "1 packet of taco seasoning",
    "1 bottle of soy sauce",
    "1 jar of marinara sauce",
    "1 block of tofu",
    "1 container of oatmeal",
    "1 bottle of hot sauce",
    "1 can of coconut milk",
    "1 bag of coffee beans",
    "1 packet of green tea",
    "500 grams of almonds",
    "1 jar of pickles",
    "2 cucumbers",
    "1 kg of carrots",
    "1 bar of dark chocolate",
    "1 bottle of red wine vinegar",
    "1 carton of orange juice",
    "1 bottle of maple syrup",
    "1 can of corn",
    "1 packet of yeast",
    "1 box of baking soda",
    "1 jar of mustard",
    "1 container of heavy cream",
    "1 bag of granulated sugar",
    "1 box of salt",
  ];

  return (
    <Root className={classes.container}>
      <div className={classes.background}></div>
      <div className={classes.foreground}>
        <div className={classes.nav}></div>
        <Button
          variant="contained"
          color="primary"
          onClick={startRecording}
          disabled={isRecording}
          className={classes.button}
        >
          Start Recording
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={stopRecording}
          disabled={!isRecording}
          className={classes.button}
        >
          Stop Recording
        </Button>
      </div>
    </Root>
  );
};

export default AudioRecorder;
