import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import api from "../../../api/api";

import { Button, Typography } from "@mui/material";

import { ReactComponent as DefaultPFP } from "../../../assets/default/profile_picture.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/svg/leaf-solid.svg";

//Actions
import { setAlert } from "../../../actions/alert";

const BlockedProfile = ({ profile, setAlert, onUnblock }) => {
  const [loading, setLoading] = useState(false);

  const handleUnblock = async () => {
    try {
      console.log(profile);
      setLoading(true);
      const res = await api.post("/users/unblock", { userid: profile._id });

      //Remove profile from blocked list
      onUnblock(profile._id);
      setAlert(`Unblocked @${profile.username}`, "success");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setAlert("An error occurred while unblocking the profile. Please refresh the page and try again.", "warning");
      setLoading(false);
    }
  };

  return (
    <div className="w-full px-4 py-4 flex justify-between border-t border-separator">
      <div className="flex items-center">
        <div className="flex items-center">
          {profile && profile.picture && profile.picture !== "" ? (
            <div className="h-10 aspect-square">
              <img
                src={profile.picture}
                alt={`@${profile.username}}`}
                className="w-full aspect-square rounded-[50%] object-cover border border-separator"
              />
            </div>
          ) : (
            <DefaultPFP className="h-10 aspect-square rounded-[50%] object-cover border border-separator" />
          )}
        </div>
        <div className="w-full ml-4">
          <Typography variant="body1" className="leading-none mb-1">
            {profile.name}
          </Typography>
          <div className="flex flex-row items-center">
            <Typography
              vatiant="body2"
              className="text-mainGreen font-medium leading-snug"
            >{`@${profile.username}`}</Typography>
            {profile.isPlus && <PlusIcon className="h-[1rem] ml-1 fill-mainGreen" />}
          </div>
        </div>
      </div>

      <div className="flex items-center">
        <Button variant="contained" color="error" disableElevation onClick={handleUnblock} disabled={loading}>
          Unblock
        </Button>
      </div>
    </div>
  );
};

BlockedProfile.propTypes = {
  profile: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { setAlert })(BlockedProfile);
