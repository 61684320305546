import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { showLoginModal, showRegisterModal } from "../../actions/auth";

import { IconButton, useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { ReactComponent as EditProfileIcon } from "../../assets/icons/svg/edit-profile.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/svg/settings.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus, faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const PREFIX = "ProfileNav";

const classes = {
  root: `${PREFIX}-root`,
  nav: `${PREFIX}-nav`,
  username_root: `${PREFIX}-username_root`,
  username: `${PREFIX}-username`,
  back: `${PREFIX}-back`,
  icons_root: `${PREFIX}-icons_root`,
  nav_svg_icon: `${PREFIX}-nav_svg_icon`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
  },

  [`& .${classes.nav}`]: {
    width: "100vw",
    height: "5rem",
    backgroundColor: theme.palette.mainGreen,
    transition: "background-color 0.3s linear",
    position: "fixed",
    borderBottom: "1px solid white",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2, 4),
    zIndex: 999,
    color: theme.palette.background,
    [theme.breakpoints.down("md")]: {
      height: "4rem",
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(1),
    },
  },

  [`& .${classes.username_root}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },

  [`& .${classes.username}`]: {
    fontWeight: 500,
    lineHeight: 1,
  },

  [`& .${classes.back}`]: {
    color: theme.palette.background,
  },

  [`& .${classes.icons_root}`]: {
    height: "100%",
    padding: theme.spacing(0.375, 0),
  },

  [`& .${classes.nav_svg_icon}`]: {
    height: "100%",
    width: "auto",
    fill: theme.palette.background,
    marginLeft: theme.spacing(2),
  },
}));

const ProfileNav = ({ showLoginModal, showRegisterModal }) => {
  /* TO DO:
        Different options for your profile or viewing one
        - My Profile
            Create?
            Edit Profile
            Settings
        - Other Profile
            Back Button (on left)
            username in middle
            Right icons:
                Notifications Handling?
                Options -> Report/block/share profile/copy profile link
        
        Skeleton when loading/Show standard navigation?
    */

  const navigate = useNavigate();
  //Breakpoints
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Root className={classes.root}>
      <div className={classes.nav}>
        <div className={classes.username_root}>
          <IconButton className={classes.back} onClick={() => navigate(-1)} size="large">
            <FontAwesomeIcon icon={faChevronLeft} />
          </IconButton>

          <div className={classes.username_container}>
            <Typography variant="h6" className={classes.username}>
              @sofiawillam
            </Typography>
          </div>
        </div>
        <div className={classes.icons_root}>
          <EditProfileIcon className={classes.nav_svg_icon} />
          <SettingsIcon className={classes.nav_svg_icon} />
        </div>
      </div>
    </Root>
  );
};

ProfileNav.propTypes = {
  showLoginModal: PropTypes.func.isRequired,
  showRegisterModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { showLoginModal, showRegisterModal })(ProfileNav);
