import React, { useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//Actions
import { showRegisterModal } from "../../../actions/auth";

import { motion, useInView } from "framer-motion";
import { Typography, Button } from "@mui/material";

import FramerAnimationManager from "./animations/FramerAnimationManager";

const FramerSplitShowcase = ({
  animation,
  title,
  text,
  cta,
  imagesOn = "left",
  fadeSrc = null,
  showRegisterModal,
  padding = true,
}) => {
  const textRef = useRef(null);
  const textInView = useInView(textRef, { once: false });

  const textVariants = {
    titleHidden: { opacity: 0, y: -20 },
    bodyHidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.3, delay: 0.3, ease: "easeOut" } },
  };

  return (
    <div
      className={`w-full h-full flex flex-col items-center ${
        imagesOn === "left" ? "lg:flex-row" : "lg:flex-row-reverse"
      }`}
    >
      <div
        className={`w-full max-w-[100vh] lg:max-w-[50vw] lg:w-1/2 h-full bg-stone-100 aspect-square flex items-center overflow-visible ${
          padding ? "p-8 md:p-12 lg:p-24" : "p-0"
        }`}
      >
        {animation === "full" ? (
          <img className="w-full h-full object-cover" src={fadeSrc}></img>
        ) : (
          <div className="h-full w-full">
            <FramerAnimationManager animation={animation} fadeSrc={fadeSrc} visible={textInView} />
          </div>
        )}
      </div>
      <div className="w-full lg:w-1/2 h-full bg-background flex flex-col items-center">
        <div className="w-full h-full px-8 py-16 flex flex-col justify-center md:p-16 lg:p-24" ref={textRef}>
          <motion.div initial="titleHidden" animate={textInView ? "visible" : "titleHidden"} variants={textVariants}>
            <Typography variant="h5" className="font-normal">
              {title}
            </Typography>
          </motion.div>
          <motion.div
            className="mt-2 w-full"
            initial="bodyHidden"
            animate={textInView ? "visible" : "bodyHidden"}
            variants={textVariants}
          >
            <Typography variant="body1" component="p" className="text-secondaryText-600">
              {text}
            </Typography>
            <div className="mt-8">
              <Button variant="contained" color="primary" disableElevation onClick={() => showRegisterModal()}>
                {cta}
              </Button>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

FramerSplitShowcase.propTypes = {
  showRegisterModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { showRegisterModal })(FramerSplitShowcase);
