import React, { useState } from "react";

import { styled } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";

//Components
import Ingredient from "../components/Ingredient";

const PREFIX = 'FinishRecipeSection';

const classes = {
  root: `${PREFIX}-root`,
  cookSection: `${PREFIX}-cookSection`,
  checkbox: `${PREFIX}-checkbox`,
  titleRoot: `${PREFIX}-titleRoot`,
  title: `${PREFIX}-title`,
  ingredientsButton: `${PREFIX}-ingredientsButton`,
  ingredientsText: `${PREFIX}-ingredientsText`,
  ingredientToggleText: `${PREFIX}-ingredientToggleText`,
  ingredientToggle: `${PREFIX}-ingredientToggle`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    // Add your styles here
  },

  [`&.${classes.cookSection}`]: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.checkbox}`]: {
    padding: 0,
  },

  [`& .${classes.titleRoot}`]: {
    marginLeft: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.title}`]: {
    fontWeight: 400,
    lineHeight: 1,
  },

  [`& .${classes.ingredientsButton}`]: {
    padding: theme.spacing(1, 0),
    textTransform: "none",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  [`& .${classes.ingredientsText}`]: {
    color: theme.palette.secondaryTextOnWhite,
  },

  [`& .${classes.ingredientToggleText}`]: {
    fontWeight: 400,
    color: theme.palette.secondaryTextOnWhite,
  },

  [`& .${classes.ingredientToggle}`]: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  }
}));

const FinishRecipeSection = ({ section, sectionIndex, checked, setChecked }) => {


  const [showIngredients, setShowIngredients] = useState(false);

  return (
    <Root className={classes.cookSection}>
      <div>
        <Checkbox
          className={classes.checkbox}
          checked={checked}
          color="primary"
          onChange={(e) => {
            console.log(sectionIndex);
            console.log(section);
            setChecked(sectionIndex, e.target.checked);
          }}
        />
      </div>
      <div className={classes.titleRoot}>
        <Typography variant="h6" className={classes.title}>
          {section.title}
        </Typography>
        <div>
          {showIngredients ? (
            <>
              <Button
                disableElevation
                disableRipple
                className={classes.ingredientToggle}
                onClick={() => setShowIngredients(false)}
              >
                <Typography variant="subtitle1" className={classes.ingredientToggleText}>
                  -&nbsp;Hide Ingredients
                </Typography>
              </Button>
              <ul>
                {section.ingredients.map((ingredient, index) => (
                  <Ingredient
                    key={index}
                    ingredient={ingredient}
                    section={section}
                    sectionIndex={sectionIndex}
                    index={index}
                  />
                ))}
              </ul>
            </>
          ) : (
            <Button
              disableElevation
              disableRipple
              className={classes.ingredientToggle}
              onClick={() => setShowIngredients(true)}
            >
              <Typography variant="subtitle1" className={classes.ingredientToggleText}>
                +&nbsp;Show Ingredients
              </Typography>
            </Button>
          )}
        </div>
      </div>
    </Root>
  );
};

export default FinishRecipeSection;
