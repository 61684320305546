import React, { useState } from "react";
import { Typography } from "@mui/material";

import StandardPostGrid from "../layout/standardgrid/StandardPostGrid";

const ProfilePinned = ({ posts, username }) => {
  const determineEmpty = (type) => {
    const phrases = [
      [`No ${type} yet.`, `Maybe @${username} is just marinating on some ideas!`],
      [`No ${type} here yet.`, `Maybe @${username} is just marinating on some ideas!`],
      [`No ${type} yet.`, `Maybe @${username} is still cooking up something special!`],
      [`No ${type} here yet.`, `Maybe @${username} is still cooking up something special!`],
      [`No ${type} yet.`, `Maybe @${username} is still gathering the ingredients!`],
      [`No ${type} here yet.`, `Maybe @${username} is still gathering the ingredients!`],
      [`No ${type} yet.`, `Maybe @${username} is still prepping the ingredients!`],
      [`No ${type} here yet.`, `Maybe @${username} is still prepping the ingredients!`],
    ];
    return phrases[Math.floor(Math.random() * phrases.length)];
  };
  const [emptyRecipesText, setEmptyRecipesText] = useState(determineEmpty("pinned posts"));

  return (
    <div className="flex-grow flex-shrink basis-1/2 min-w-[50%] flex flex-col px-4 md:px-0">
      <div>
        {posts.pinned.length === 0 ? (
          <div className="mt-6 md:mt-4 px-8 py-4 border border-separator rounded-lg shadow-sm text-center bg-background">
            <Typography variant="h6" className="text-primaryText font-normal">
              {emptyRecipesText[0]}
            </Typography>
            {emptyRecipesText[1] && (
              <Typography variant="subtitle1" className="text-secondaryText font-normal mt-2">
                {emptyRecipesText[1]}
              </Typography>
            )}
          </div>
        ) : (
          <StandardPostGrid posts={posts.pinned} />
        )}
      </div>
    </div>
  );
};

export default ProfilePinned;
