import React from "react";
import { styled } from '@mui/material/styles';
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const PREFIX = 'ComingSoon';

const classes = {
  root: `${PREFIX}-root`,
  onelinelink: `${PREFIX}-onelinelink`,
  uhoh: `${PREFIX}-uhoh`,
  linktext: `${PREFIX}-linktext`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 5rem)" /* Optionally, set the height of the container */,
    margin: theme.spacing(0, 4),
    textAlign: "center",
  },

  [`& .${classes.onelinelink}`]: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(4),
  },

  [`& .${classes.uhoh}`]: {
    fontWeight: 600,
    color: theme.palette.mainGreen,
  },

  [`& .${classes.linktext}`]: {
    color: theme.palette.mainGreen,
  }
}));

const ComingSoon = () => {


  return (
    <Root className={classes.root}>
      <Typography variant="h2" className={classes.uhoh}>
        Woah there!
      </Typography>
      <Typography variant="h6">The page you're on is coming soon. Check back later, or find a time machine!</Typography>
      <Link to="/" className={classes.onelinelink}>
        <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: "5px" }} />
        <Typography variant="h6" className={classes.linktext}>
          Go back to Home
        </Typography>
      </Link>
    </Root>
  );
};

export default ComingSoon;
