import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//API
import api from "../../../api/api";

//Actions
import { setAlert } from "../../../actions/alert";
import { setPostInteractions } from "../../../actions/feed";

//Icons
import { ReactComponent as ReportIcon } from "../../../assets/icons/svg/report.svg";
import { ReactComponent as LinkIcon } from "../../../assets/icons/svg/link.svg";
import { ReactComponent as CopyIcon } from "../../../assets/icons/svg/copy.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/svg/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/svg/trash.svg";

import { Button, Typography } from "@mui/material";

const PostOptionsModal = ({ post, auth: { user, isAuthenticated }, setAlert, handleClose }) => {
  const isMyPost = isAuthenticated ? post.user.id === user._id : false;

  const [deleteAYS, setDeleteAYS] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);

  //Options to add
  //Follow/unfollow user (if not the user's post)
  //View poster's profile
  //QR Code for post
  //Subscribe to user (if paid profile)
  //If my post, delete post/pin post to profile (if not already pinned) (not delete on recipe, must delete from recipe page)

  const handleCopyRecipeLink = () => {
    const recipeLink = `/${post.user.username}/${post.recipe && post.recipe.url ? post.recipe.url : "unknown-recipe"}`;
    navigator.clipboard.writeText(window.location.origin + recipeLink);
    setAlert("Recipe link copied to clipboard", "success");
    handleClose();
  };

  const handleCopyPostLink = () => {
    const postLink = `/${post.user.username}/post/${post.id}`;
    navigator.clipboard.writeText(window.location.origin + postLink);
    setAlert("Post link copied to clipboard", "success");
    handleClose();
  };

  const handleCopyLink = () => {
    if (post.type === "recipe") {
      handleCopyRecipeLink();
    } else {
      handleCopyPostLink();
    }
  };

  const handleReport = async () => {
    setReportLoading(true);
    if (post.type === "recipe") {
      try {
        await api.post(`/report/recipe`, { recipeId: post.recipe.id, reason: "" });
        setReportLoading(false);
        setAlert("Recipe reported. Thank you for helping us keep the community safe.", "success");
        handleClose();
      } catch (error) {
        setReportLoading(false);
        if (error.response && error.response.status === 400) {
          setAlert(error.response.data.msg, "warning");
        } else {
          setAlert("Error reporting post, please refresh and try again.", "error");
        }
      }
    } else {
      try {
        await api.post(`/report/post`, { postId: post.id, reason: "" });
        setReportLoading(false);
        setAlert("Recipe reported. Thank you for helping us keep the community safe.", "success");
        handleClose();
      } catch (error) {
        setReportLoading(false);
        if (error.response && error.response.status === 400) {
          setAlert(error.response.data.msg, "warning");
        } else {
          setAlert("Error reporting post, please refresh and try again.", "error");
        }
      }
    }
  };

  const handleDelete = async () => {
    setDeleteLoading(true);
    //TO DO: Potentially handle state, refresh, and redirect
    if (post.type === "recipe") {
      try {
        await api.delete(`/recipes/${post.recipe.id}`);
        setAlert("Recipe deleted", "success");
        setDeleteLoading(false);
        handleClose();
      } catch (error) {
        setDeleteLoading(false);
        setDeleteAYS(false);
        setAlert("Error deleting post, please refresh and try again.", "error");
      }
    } else {
      try {
        await api.delete(`/post/${post.id}`);
        setAlert("Post deleted", "success");
        setDeleteLoading(false);
        handleClose();
      } catch (error) {
        setDeleteLoading(false);
        setDeleteAYS(false);
        setAlert("Error deleting post, please refresh and try again.", "error");
      }
    }
  };

  const myPost = (
    <>
      <Link to={`/${post.user.username}/post/${post.id}`} className="w-full">
        <div className="w-full border-t border-separator">
          <Button
            className="w-full h-[3rem] rounded-none justify-start pl-4"
            startIcon={
              <div className="w-[2rem] flex justify-start">
                <LinkIcon className="fill-textPrimary h-[1rem]" />
              </div>
            }
          >
            <Typography variant="subtitle1" className="font-normal">
              View Post
            </Typography>
          </Button>
        </div>
      </Link>
      <div className="w-full border-t border-separator">
        <Button
          className="w-full h-[3rem] rounded-none justify-start pl-4"
          onClick={handleCopyLink}
          startIcon={
            <div className="w-[2rem] flex justify-start">
              <CopyIcon className="fill-textPrimary h-[1rem]" />
            </div>
          }
        >
          <Typography variant="subtitle1" className="font-normal">
            Copy Link
          </Typography>
        </Button>
      </div>

      <div className="w-full border-t border-separator">
        {deleteAYS ? (
          <Button
            className="w-full h-[3rem] rounded-none justify-start pl-4"
            startIcon={
              <div className="w-[2rem] flex justify-start">
                <DeleteIcon className="fill-red h-[1rem]" />
              </div>
            }
            onClick={handleDelete}
            disabled={deleteLoading}
          >
            <Typography variant="subtitle1" className="font-normal ">
              Yes, Delete Post
            </Typography>
          </Button>
        ) : (
          <Button
            className="w-full h-[3rem] rounded-none justify-start pl-4"
            startIcon={
              <div className="w-[2rem] flex justify-start">
                <DeleteIcon className="fill-red h-[1rem]" />
              </div>
            }
            onClick={() => setDeleteAYS(true)}
          >
            <Typography variant="subtitle1" className="font-normal ">
              Delete Post
            </Typography>
          </Button>
        )}
      </div>
    </>
  );

  const notMyPost = (
    <>
      <Link to={`/${post.user.username}/post/${post.id}`} className="w-full">
        <div className="w-full border-t border-separator">
          <Button
            className="w-full h-[3rem] rounded-none justify-start pl-4"
            startIcon={
              <div className="w-[2rem] flex justify-start">
                <LinkIcon className="fill-textPrimary h-[1rem]" />
              </div>
            }
          >
            <Typography variant="subtitle1" className="font-normal">
              View Post
            </Typography>
          </Button>
        </div>
      </Link>
      <div className="w-full border-t border-separator">
        <Button
          className="w-full h-[3rem] rounded-none justify-start pl-4"
          onClick={handleCopyLink}
          startIcon={
            <div className="w-[2rem] flex justify-start">
              <CopyIcon className="fill-textPrimary h-[1rem]" />
            </div>
          }
        >
          <Typography variant="subtitle1" className="font-normal">
            Copy Link
          </Typography>
        </Button>
      </div>
      <div className="w-full border-t border-separator">
        {isAuthenticated && (
          <Button
            className="w-full h-[3rem] rounded-none justify-start pl-4"
            startIcon={
              <div className="w-[2rem] flex justify-start">
                <ReportIcon className="fill-red h-[1rem]" />
              </div>
            }
            onClick={handleReport}
            disabled={reportLoading}
          >
            <Typography variant="subtitle1" className="font-normal ">
              Report
            </Typography>
          </Button>
        )}
      </div>
    </>
  );

  const myRecipe = (
    <>
      <Link
        to={`/${post.user.username}/${post.recipe && post.recipe.url ? post.recipe.url : "unknown-recipe"}`}
        className="w-full"
      >
        <div className="w-full border-t border-separator">
          <Button
            className="w-full h-[3rem] rounded-none justify-start pl-4"
            startIcon={
              <div className="w-[2rem] flex justify-start">
                <LinkIcon className="fill-textPrimary h-[1rem]" />
              </div>
            }
          >
            <Typography variant="subtitle1" className="font-normal">
              View Recipe
            </Typography>
          </Button>
        </div>
      </Link>

      <div className="w-full border-t border-separator">
        <Button
          className="w-full h-[3rem] rounded-none justify-start pl-4"
          onClick={handleCopyLink}
          startIcon={
            <div className="w-[2rem] flex justify-start">
              <CopyIcon className="fill-textPrimary h-[1rem]" />
            </div>
          }
        >
          <Typography variant="subtitle1" className="font-normal">
            Copy Link
          </Typography>
        </Button>
      </div>
      <Link to={`/create/recipe/${post.recipe && post.recipe.id ? post.recipe.id : "unknown"}`} className="w-full">
        <div className="w-full border-t border-separator">
          <Button
            className="w-full h-[3rem] rounded-none justify-start pl-4"
            startIcon={
              <div className="w-[2rem] flex justify-start">
                <EditIcon className="fill-textPrimary h-[1rem]" />
              </div>
            }
          >
            <Typography variant="subtitle1" className="font-normal">
              Edit Recipe
            </Typography>
          </Button>
        </div>
      </Link>
      <div className="w-full border-t border-separator">
        {deleteAYS ? (
          <Button
            className="w-full h-[3rem] rounded-none justify-start pl-4"
            startIcon={
              <div className="w-[2rem] flex justify-start">
                <DeleteIcon className="fill-red h-[1rem]" />
              </div>
            }
            onClick={handleDelete}
            disabled={deleteLoading}
          >
            <Typography variant="subtitle1" className="font-normal ">
              Yes, Delete Recipe
            </Typography>
          </Button>
        ) : (
          <Button
            className="w-full h-[3rem] rounded-none justify-start pl-4"
            startIcon={
              <div className="w-[2rem] flex justify-start">
                <DeleteIcon className="fill-red h-[1rem]" />
              </div>
            }
            onClick={() => setDeleteAYS(true)}
          >
            <Typography variant="subtitle1" className="font-normal ">
              Delete Recipe
            </Typography>
          </Button>
        )}
      </div>
    </>
  );

  const notMyRecipe = (
    <>
      <Link
        to={`/${post.user.username}/${post.recipe && post.recipe.url ? post.recipe.url : "unknown-recipe"}`}
        className="w-full"
      >
        <div className="w-full border-t border-separator">
          <Button
            className="w-full h-[3rem] rounded-none justify-start pl-4"
            startIcon={
              <div className="w-[2rem] flex justify-start">
                <LinkIcon className="fill-textPrimary h-[1rem]" />
              </div>
            }
          >
            <Typography variant="subtitle1" className="font-normal">
              View Recipe
            </Typography>
          </Button>
        </div>
      </Link>
      <div className="w-full border-t border-separator">
        <Button
          className="w-full h-[3rem] rounded-none justify-start pl-4"
          onClick={handleCopyLink}
          startIcon={
            <div className="w-[2rem] flex justify-start">
              <CopyIcon className="fill-textPrimary h-[1rem]" />
            </div>
          }
        >
          <Typography variant="subtitle1" className="font-normal">
            Copy Link
          </Typography>
        </Button>
      </div>
      <div className="w-full border-t border-separator">
        {isAuthenticated && (
          <Button
            className="w-full h-[3rem] rounded-none justify-start pl-4"
            startIcon={
              <div className="w-[2rem] flex justify-start">
                <ReportIcon className="fill-red h-[1rem]" />
              </div>
            }
            onClick={handleReport}
            disabled={reportLoading}
          >
            <Typography variant="subtitle1" className="font-normal ">
              Report
            </Typography>
          </Button>
        )}
      </div>
    </>
  );
  const determineOptions = () => {
    if (post.type === "recipe") {
      return isMyPost ? myRecipe : notMyRecipe;
    } else {
      return isMyPost ? myPost : notMyPost;
    }
  };

  return <div className="flex flex-col items-center justify-center">{determineOptions()}</div>;
};
//post.user.id !== user._id
PostOptionsModal.propTypes = {
  post: PropTypes.object.isRequired,
  postid: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(PostOptionsModal);
