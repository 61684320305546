import React, { useState, useEffect } from "react";
import { Typography, Button, Divider } from "@mui/material";
import api from "../../../api/api";

import MealSection from "../components/MealSection";
import { Flame } from "lucide-react";
import Spinner from "../../layout/Spinner";

export const RecipeHistory = () => {
  //IDEAS
  //Top chunk, show favourites? Planned meals? etc, in horizontal scroll
  //Top chunk show total cost?
  //Each meal section is a card, can add or plan meals in each
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [recipeHistory, setRecipeHistory] = useState(null);
  const fetchMacros = async () => {
    try {
      const response = await api.get("/macros/demo");

      console.log(response.data);
      setRecipeHistory(response.data);
      setLoading(false);
      setError(false);
    } catch (error) {
      setError(true);
      setLoading(false);
      setRecipeHistory(null);
    }
  };

  useEffect(() => {
    fetchMacros();
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row justify-between items-center p-4 border border-separator rounded-md shadow-sm bg-background">
        <div>
          <Typography variant="h6" className="font-normal leading-none">
            Meal Planning
          </Typography>
        </div>
        <div className="flex flex-row gap-2">
          <Button variant="contained" color="secondary" size="small" disableElevation className="text-primaryText">
            + Plan or Track
          </Button>
        </div>
      </div>

      <div className="flex flex-col gap-4">
        {error ? (
          <div className="w-full p-8 border border-separator rounded-lg bg-background text-center">
            <Typography variant="subtitle1">
              Error fetching your meal history.<br></br>Please refresh the page and try again.
            </Typography>{" "}
          </div>
        ) : loading ? (
          <div className="w-full p-4 border border-separator rounded-lg bg-background">
            <Spinner />
          </div>
        ) : (
          recipeHistory.courses.map((course) => {
            return <MealSection course={course.name} data={course.items} />;
          })
        )}
      </div>
    </div>
  );
};

export default RecipeHistory;
