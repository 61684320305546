import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//API
import api from "../../../api/api";

//Actions
import { setAlert } from "../../../actions/alert";
import { setPostInteractions } from "../../../actions/feed";

//Icons
import { ReactComponent as ShareIcon } from "../../../assets/icons/svg/share.svg";
import { ReactComponent as RepostIcon } from "../../../assets/icons/svg/repost.svg";
import { ReactComponent as CopyIcon } from "../../../assets/icons/svg/copy.svg";
import { ReactComponent as PinIcon } from "../../../assets/icons/svg/pin.svg";

import { Button, Typography } from "@mui/material";

const PostShareModal = ({
  post,
  auth: { user, isAuthenticated },
  setAlert,
  handleClose,
  handleRepost,
  hasReposted,
}) => {
  const [pinLoading, setPinLoading] = useState(false);
  const [hasPinned, setHasPinned] = useState(post && post.interactions && post.interactions.hasPinned ? true : false);
  const [canShare, setCanShare] = useState(false);

  console.log(post);

  const handleCopyRecipeLink = () => {
    const recipeLink = `/${post.user.username}/${post.recipe && post.recipe.url ? post.recipe.url : "unknown-recipe"}`;
    navigator.clipboard.writeText(window.location.origin + recipeLink);
    setAlert("Recipe link copied to clipboard", "success");
    handleClose();
  };

  const handleCopyPostLink = () => {
    const postLink = `/${post.user.username}/post/${post.id}`;
    navigator.clipboard.writeText(window.location.origin + postLink);
    setAlert("Post link copied to clipboard", "success");
    handleClose();
  };

  const handleCopyLink = () => {
    if (post.type === "recipe") {
      handleCopyRecipeLink();
    } else {
      handleCopyPostLink();
    }
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        //Format share data
        if (post.type === "recipe") {
          await navigator.share({
            title: `${post.recipe.name} by @${post.user.username}`,
            text: `${post.recipe.name} by @${post.user.username}`,
            url: `${window.location.origin}/${post.user.username}/${post.recipe.url}`,
          });
        } else {
          if (post.text && post.text !== "") {
            const truncatedText = post.text.length > 50 ? post.text.substring(0, 50) + "..." : post.text;
            await navigator.share({
              title: `Post by @${post.user.username}`,
              text: truncatedText,
              url: `${window.location.origin}/${post.user.username}/post/${post.id}`,
            });
          } else {
            await navigator.share({
              title: `Post by @${post.user.username}`,
              text: `Post by @${post.user.username}`,
              url: `${window.location.origin}/${post.user.username}/post/${post.id}`,
            });
          }
        }
      } catch (error) {
        handleCopyLink();
        setAlert("Link copied to clipboard", "success");
      }
    } else {
      handleCopyLink();
      setAlert("Link copied to clipboard", "success");
      setAlert("Web Share API is not supported in your browser", "warning");
    }
  };

  const handlePin = async () => {
    setPinLoading(true);
    if (hasPinned) {
      try {
        //Unpin
        const res = await api.post(`/profile/unpin/${post.id}`);
        //Close modal
        //Set alert
        setPostInteractions(post.id, { hasPinned: false });
        setAlert("Post unpinned", "success");
        setHasPinned(false);
        setPinLoading(false);
        handleClose();
      } catch (error) {
        setAlert("Error pinning post", "error");
        setPinLoading(false);
        handleClose();
      }
    } else {
      try {
        //Add to pinned
        const res = await api.post(`/profile/pin/${post.id}`);
        //Close modal
        //Set alert
        setPostInteractions(post.id, { hasPinned: true });
        setAlert("Post pinned", "success");
        setHasPinned(true);
        setPinLoading(false);
        handleClose();
      } catch (error) {
        setAlert("Error pinning post", "error");
        setPinLoading(false);
        handleClose();
      }
    }
  };

  //Check if can share
  useEffect(() => {
    if (navigator.share) {
      setCanShare(true);
    }
  }, []);

  return (
    <div className="flex flex-col items-center justify-center">
      {hasReposted ? (
        <div className="w-full border-t border-separator">
          <Button
            className="w-full h-[3rem] rounded-none justify-start pl-4"
            onClick={handleRepost}
            startIcon={
              <div className="w-[2rem] flex justify-start">
                <RepostIcon className="fill-primaryText h-[1rem]" />
              </div>
            }
          >
            <Typography variant="subtitle1" className="font-normal">
              Unrepost
            </Typography>
          </Button>
        </div>
      ) : (
        <div className="w-full border-t border-separator">
          <Button
            className="w-full h-[3rem] rounded-none justify-start pl-4"
            onClick={handleRepost}
            startIcon={
              <div className="w-[2rem] flex justify-start">
                <RepostIcon className="fill-primaryText h-[1rem]" />
              </div>
            }
          >
            <Typography variant="subtitle1" className="font-normal">
              Repost
            </Typography>
          </Button>
        </div>
      )}

      {isAuthenticated && (
        <>
          {hasPinned ? (
            <div className="w-full border-t border-separator">
              <Button
                className="w-full h-[3rem] rounded-none justify-start pl-4"
                onClick={handlePin}
                startIcon={
                  <div className="w-[2rem] flex justify-start">
                    <PinIcon className="fill-primaryText h-[1rem]" />
                  </div>
                }
                disabled={pinLoading}
              >
                <Typography variant="subtitle1" className="font-normal">
                  Unpin from Profile
                </Typography>
              </Button>
            </div>
          ) : (
            <div className="w-full border-t border-separator">
              <Button
                className="w-full h-[3rem] rounded-none justify-start pl-4"
                onClick={handlePin}
                startIcon={
                  <div className="w-[2rem] flex justify-start">
                    <PinIcon className="fill-primaryText h-[1rem]" />
                  </div>
                }
                disabled={pinLoading}
              >
                <Typography variant="subtitle1" className="font-normal">
                  Pin to Profile
                </Typography>
              </Button>
            </div>
          )}
        </>
      )}

      {canShare && (
        <div className="w-full border-t border-separator">
          <Button
            className="w-full h-[3rem] rounded-none justify-start pl-4"
            onClick={handleShare}
            startIcon={
              <div className="w-[2rem] flex justify-start">
                <ShareIcon className="fill-primaryText h-[1rem]" />
              </div>
            }
          >
            <Typography variant="subtitle1" className="font-normal">
              Share
            </Typography>
          </Button>
        </div>
      )}

      <div className="w-full border-t border-separator">
        <Button
          className="w-full h-[3rem] rounded-none justify-start pl-4"
          onClick={handleCopyLink}
          startIcon={
            <div className="w-[2rem] flex justify-start">
              <CopyIcon className="fill-primaryText h-[1rem]" />
            </div>
          }
        >
          <Typography variant="subtitle1" className="font-normal">
            Copy Link
          </Typography>
        </Button>
      </div>
    </div>
  );
};
PostShareModal.propTypes = {
  post: PropTypes.object.isRequired,
  postid: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  setPostInteractions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert, setPostInteractions })(PostShareModal);
