//Import redux types
import { WALKTHROUGH_GET_RECIPE, WALKTHROUGH_RECIPE_ERROR, WALKTHROUGH_SET_SECTION } from "../actions/types";

//Initial state object
const initialState = {
  recipe: null,
  loading: true,
  error: false,
  timer: {
    value: 0,
  },
  sections: null,
  current: {
    section: null,
  },
  user: null,
};

export default function (state = initialState, action) {
  //Destructure action
  const { type, payload } = action;

  switch (type) {
    case WALKTHROUGH_GET_RECIPE: {
      return {
        ...state,
        recipe: payload.recipe,
        user: payload.user,
        sections: payload.sections,
        loading: false,
        error: false,
        current: {
          section: {
            ...payload.recipe.sections[0],
            index: 0,
          },
        },
      };
    }
    case WALKTHROUGH_RECIPE_ERROR: {
      return {
        ...state,
        recipe: null,
        user: null,
        loading: false,
        error: true,
      };
    }
    case WALKTHROUGH_SET_SECTION: {
      return {
        ...state,
        current: {
          section: {
            ...state.recipe.sections[payload],
            index: payload,
          },
        },
      };
    }
    default:
      return state;
  }
}
