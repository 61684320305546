import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Typography } from "@mui/material";

//Actions
import { getAllNotifications, resetAllNotifications } from "../../actions/notifications";

//Components
import NotificationManager from "./NotificationManager";
import Spinner from "../layout/Spinner";
import Button from "@mui/material/Button";

//Icons
import { ReactComponent as SearchIcon } from "../../assets/icons/svg/search.svg";
import { ReactComponent as InviteIcon } from "../../assets/icons/svg/user-plus-solid.svg";

const NotificationsPage = ({
  notifications: { unread, notifications, error, loading },
  getAllNotifications,
  resetAllNotifications,
}) => {
  useEffect(() => {
    getAllNotifications();

    return () => {
      resetAllNotifications();
    };
  }, []);

  //Get notifications
  return (
    <div className="w-full mt-[4rem] mb-[4rem] md:w-[calc(100%-5rem)] md:ml-[5rem] md:my-0 bg-neutral-50">
      <div className="m-auto md:max-w-[750px] md:px-8 min-h-screen pt-8">
        <div className="mx-4 md:mx-0">
          <Typography variant="h5" className="font-normal">
            Notifications
          </Typography>
          {unread && unread > 0 ? (
            <Typography
              variant="subtitle1"
              className="text-secondaryText-600 italic leading-none font-normal mt-1"
            >{`${unread.toLocaleString()} unread`}</Typography>
          ) : null}
        </div>
        <div className="border border-separator md:rounded-[16px] mt-8 bg-background">
          {loading ? (
            <Spinner />
          ) : error ? (
            <div className="h-full flex justify-center items-center text-center py-8 px-4">
              <Typography variant="subtitle1">An error has occurred. Please refresh the page and try again.</Typography>
            </div>
          ) : (
            <>
              {notifications.length > 0 ? (
                notifications.map((notification, i) => (
                  <NotificationManager
                    key={notification._id}
                    notification={notification}
                    index={i}
                    isLast={i === notifications.length - 1 ? true : false}
                  />
                ))
              ) : (
                <div className="text-center py-8 px-4">
                  <Typography variant="subtitle1">No notifications yet!</Typography>
                  <div className="flex flex-col sm:flex-row gap-2 mt-4 justify-center">
                    <Link to="/search?type=profiles">
                      <Button
                        variant="outlined"
                        disableRipple
                        className="border border-secondaryText-200 hover:border-secondaryText"
                        size="small"
                      >
                        <SearchIcon className="h-3 mr-2 fill-secondaryText" />
                        <Typography variant="caption" component="p">
                          Find users to follow
                        </Typography>
                      </Button>
                    </Link>

                    {navigator.share && (
                      <Button
                        className="hover:bg-transparent"
                        size="small"
                        disableRipple
                        onClick={() => {
                          navigator
                            .share({
                              title: "Join me on thisPantry",
                              text: "Bring all your recipes together with thisPantry. Generate new recipes, manage your pantry, and gain valuable insights on expenses and nutrition. Simplify your cooking and make every ingredient count with thisPantry.",
                              url: "https://www.thispantry.com",
                            })
                            .catch((error) => console.log("Error sharing", error));
                        }}
                      >
                        <InviteIcon className="h-3 mr-2 fill-secondaryText" />
                        <Typography variant="caption" component="p">
                          Invite&nbsp;Friends
                        </Typography>
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

NotificationsPage.propTypes = {
  notifications: PropTypes.object.isRequired,
  getAllNotifications: PropTypes.func.isRequired,
  resetAllNotifications: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  notifications: state.notifications,
});

export default connect(mapStateToProps, { getAllNotifications, resetAllNotifications })(NotificationsPage);
