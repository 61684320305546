import React, { useState, useEffect } from "react";

import { styled } from '@mui/material/styles';

//Components
import ModularManager from "./ModularManager";

const PREFIX = 'ModularPostGrid';

const classes = {
  grid: `${PREFIX}-grid`,
  gridItem: `${PREFIX}-gridItem`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.grid}`]: {
    display: "grid",
    gridTemplateColumns: (props) => `repeat(${props.gridWidth}, 1fr)`,
    gridGap: "16px",
    marginTop: theme.spacing(2),
  },

  [`& .${classes.gridItem}`]: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }
}));

//Takes in a static grid width and posts array, and generates a modular grid.

/* Minimum data for each post type to display in grid

ALL - 
- Post ID
- Post Type
- postData: {

},
user: {
  _id
  username
  avatar

}


*/

//TO DO: MOVE THIS TO A FETCHING INFINITE SCROLL, WITH REF TO PARENT TO GET MORE POSTS
// - Determine if we need to fetch more posts based on scroll position and position of last post in grid
//Do the post fetching via chunks?
//Handle errors on fetching, recalculate grid without offending post -> Only in offending chunk?

const ModularPostGrid = ({ posts, loading, error, gridWidth = 2 }) => {

  const [gridGenerating, setGridGenerating] = useState(false);
  const [lastChunkGenerating, setLastChunkGenerating] = useState(false);

  const generateObjects = () => {
    const objects = [];
    const types = ["text", "media", "recipe"];
    for (let i = 0; i < 50; i++) {
      const randomType = types[Math.floor(Math.random() * types.length)];
      objects.push({ type: randomType });
    }
    return objects;
  };

  const [testPosts, setTestPosts] = useState(generateObjects());
  const [gridPosts, setGridPosts] = useState([]);
  //Carry chunk is 1 more than the max height of an element in the grid. Will allow a post to carry over to the next grid chunk
  const [lastCarryChunk, setLastCarryChunk] = useState([
    Array(gridWidth).fill(false),
    Array(gridWidth).fill(false),
    Array(gridWidth).fill(false),
  ]);
  const [postsStartIndex, setPostsStartIndex] = useState(0);

  //Generate grid chunks
  const generateGridChunk = async (gridWidth, posts) => {
    const xMax = gridWidth;
    const yMax = 2;
    let carryChunk = lastCarryChunk;
    let newGridPosts = [];
    let lastItemHeight = 1;

    for (let y = 0; y < yMax; y++) {
      for (let x = 0; x < xMax; x++) {
        let postIndex = postsStartIndex + y * xMax + x;
        //Make sure current space is not filled, if it is, skip
        if (carryChunk[y][x] === true) {
          continue;
        }

        //Check space to right and below to see what sizes to fill
        let xSpace = gridWidth - x;
        let maxWidth = 2;

        if (xSpace < maxWidth) {
          maxWidth = xSpace;
        }

        let maxHeight = 2;
        let ySpace = carryChunk.length - y;
        if (ySpace < maxHeight) {
          maxHeight = ySpace;
        }

        //Check spaces to right of current index to edit max width
        for (let i = 1; i < maxWidth; i++) {
          if (carryChunk[y][x + i] === true) {
            maxWidth = i;
            break;
          }
        }

        //Check spaces below current index to edit max height
        for (let i = 1; i < maxHeight; i++) {
          //Check all spaces along
          if (carryChunk[y + i][x] === true) {
            maxHeight = i;
            break;
          }
        }

        //Determine post size depending on our maximums
        let postSize = "1x1";

        const setCarryChunk = (size) => {
          switch (size) {
            case "1x1":
              carryChunk[y][x] = true;
              lastItemHeight = 1;
              break;
            case "1x2":
              carryChunk[y][x] = true;
              carryChunk[y + 1][x] = true;
              lastItemHeight = 2;
              break;
            case "2x1":
              carryChunk[y][x] = true;
              carryChunk[y][x + 1] = true;
              lastItemHeight = 1;
              break;
            case "2x2":
              carryChunk[y][x] = true;
              carryChunk[y + 1][x] = true;
              carryChunk[y][x + 1] = true;
              carryChunk[y + 1][x + 1] = true;
              lastItemHeight = 2;
              break;
            default:
              carryChunk[y][x] = true;
          }
        };
        //If last item was 2 height, force this to be 1x1
        if (lastItemHeight > 1) {
          postSize = "1x1";
          setCarryChunk(postSize);
        } else {
          if (maxHeight === 1) {
            postSize = "1x1";
            setCarryChunk(postSize);
            //No 2x1 posts
          } else if (maxHeight === 2) {
            //TO DO: Make it not auto fill max space, have some randomness

            console.log(posts, postIndex, posts[postIndex]);
            switch (
              posts[postIndex].type //posts[postIndex].postData.type) {
            ) {
              case "media": {
                if (maxWidth === 1) {
                  postSize = "1x1";
                  setCarryChunk(postSize);
                  break;
                } else if (maxWidth === 2) {
                  postSize = Math.random() < 0.5 ? "1x1" : "2x2";
                  setCarryChunk(postSize);
                  break;
                }
              }
              case "recipe": {
                if (maxWidth === 1) {
                  postSize = "1x1";
                  setCarryChunk(postSize);
                  break;
                } else if (maxWidth === 2) {
                  postSize = Math.random() < 0.5 ? "1x1" : "2x2";
                  setCarryChunk(postSize);
                  break;
                }
              }
              case "text": {
                if (maxHeight === 1) {
                  postSize = "1x1";
                  setCarryChunk(postSize);
                  break;
                } else {
                  postSize = Math.random() < 0.5 ? "1x1" : "1x2";
                  setCarryChunk(postSize);
                  break;
                }
              }
              default: {
                postSize = "1x1";
                setCarryChunk(postSize);
                break;
              }
            }
          }
        }
        //console.log(postSize, x, y, maxWidth, maxHeight, posts[postIndex].postData.type, carryChunk);
        //Push post data to newGridPosts
        newGridPosts.push({ ...posts[postIndex], size: postSize });
      }
    }
    //Adjust carry chunk
    let newCarryChunk = [carryChunk[2], Array(gridWidth).fill(false), Array(gridWidth).fill(false)];
    //Set carry chunk
    setLastCarryChunk(newCarryChunk);
    //Set grid posts
    setGridPosts([...gridPosts, ...newGridPosts]);
  };

  useEffect(() => {
    //If posts changes, generate new grid chunk for new posts to fill
    //Generate h
    //Compile a list of valid posts to generate to grid chunks from new posts
    //Run a recalculation when posts and gridwidth changes
    /*If gridWidth changes, recalculate all grid chunks
    if (!loading && !error) {
      const newPosts = posts.map((post) => (!post.loading && post.error ? post : null));
      console.log(newPosts);
      generateGridChunk(gridWidth, newPosts, 0, lastCarryChunk);
    }*/

    if (!loading && !error) {
      generateGridChunk(gridWidth, testPosts);
    }
  }, [posts, gridWidth, loading, error]);

  return (
    <Root className={classes.grid}>
      {gridPosts.map((post, index) => (
        <ModularManager key={index} post={post} size={post.size} />
      ))}
    </Root>
  );
};

export default ModularPostGrid;
