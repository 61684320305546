import React from "react";

import { styled } from '@mui/material/styles';

import { Typography } from "@mui/material";

const PREFIX = 'Update';

const classes = {
  root: `${PREFIX}-root`,
  titleContainer: `${PREFIX}-titleContainer`,
  titleText: `${PREFIX}-titleText`,
  titleDate: `${PREFIX}-titleDate`,
  imgRoot: `${PREFIX}-imgRoot`,
  image: `${PREFIX}-image`,
  bodyTextRoot: `${PREFIX}-bodyTextRoot`,
  updatesList: `${PREFIX}-updatesList`,
  update: `${PREFIX}-update`,
  updateTitle: `${PREFIX}-updateTitle`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    margin: theme.spacing(4),
    marginTop: 0,
  },

  [`& .${classes.titleContainer}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.titleText}`]: {
    fontWeight: 600,
    color: theme.palette.mainGreen,
  },

  [`& .${classes.titleDate}`]: {
    color: theme.palette.secondaryTextOnWhite,
  },

  [`& .${classes.imgRoot}`]: {
    width: "100%",
    height: "auto",
  },

  [`& .${classes.image}`]: {
    width: "100%",
  },

  [`& .${classes.bodyTextRoot}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.updatesList}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.update}`]: {
    marginTop: theme.spacing(1.5),
  },

  [`& .${classes.updateTitle}`]: {
    fontWeight: 500,
  }
}));

//Utility Functions
const formatDateForDisplay = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  const seconds = Math.floor((now - date) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (seconds < 60) {
    return "Just now";
  } else if (minutes < 60) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else if (hours < 24) {
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (days < 7) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (weeks < 4) {
    return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
  } else if (months < 12) {
    return `${months} month${months > 1 ? "s" : ""} ago`;
  } else {
    return `${years} year${years > 1 ? "s" : ""} ago`;
  }
};

const Update = ({ update }) => {
  const { date, title, image, text, updates } = update;


  return (
    <Root className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography variant="h5" className={classes.titleText}>
          {title}
        </Typography>
        <Typography variant="subtitle1" className={classes.titleDate}>
          {formatDateForDisplay(date)}
        </Typography>
      </div>

      <div className={classes.imgRoot}>
        <img src={image} className={classes.image}></img>
      </div>

      <div className={classes.bodyTextRoot}>
        <Typography variant="subtitle1">{text}</Typography>
      </div>

      <div className={classes.updatesList}>
        <div className={classes.listTitleRoot}>
          <Typography variant="subtitle1" className={classes.updateTitle}>
            Updates:
          </Typography>
        </div>
        <ul>
          {updates.map((u, i) => {
            return (
              <li className={classes.update}>
                <Typography variant="subtitle1" className={classes.updateTitle}>
                  {u.title}
                </Typography>
                <Typography variant="subtitle1" className={classes.updateBody}>
                  {u.text}
                </Typography>
              </li>
            );
          })}
        </ul>
      </div>
    </Root>
  );
};

export default Update;
