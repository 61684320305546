import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Typography from "@mui/material/Typography";

import Footer from "../layout/Footer";

import { Helmet } from "react-helmet";

const Terms = ({ auth: { isAuthenticated } }) => {
  return (
    <div className="w-full">
      <Helmet>
        <title>thisPantry - Terms of Service</title>
      </Helmet>
      <div className="h-full overflow-y-scroll">
        <div
          className={`px-8 lg:px-32 xl:px-64 min-h-[100vh] ${
            isAuthenticated ? "my-[5rem] md:ml-[5rem] md:mt-8" : "my-8"
          }`}
        >
          <div>
            <Typography variant="h5">Terms of Service</Typography>
          </div>
          <div className="my-4">
            <Typography variant="h6">Welcome to thisPantry!</Typography>
            <div className="my-2">
              <Typography variant="body1">
                These Terms of Service govern your use of thisPantry and provide information about the thisPantry
                Service, outlined below. When you create a thisPantry account or use thisPantry, you agree to these
                terms.{" "}
              </Typography>
              <br></br>
              <Typography variant="body1">
                The thisPantry Service is a thisPantry product. These Terms of Service, therefore, constitute an
                agreement between you and thisPantry Incorporated.{" "}
              </Typography>
            </div>
          </div>
          <div className="my-4">
            <Typography variant="h6">The thisPantry Service</Typography>
            <div className="my-2">
              <Typography variant="body1">
                We agree to provide you with the thisPantry Service. The Service includes all of the thisPantry
                products, features, applications, services, technologies, and software that we provide. The thisPantry
                service is made up of the following aspects:
              </Typography>
              <ul className="my-4 mx-4 marker:text-secondaryText">
                <li className="mb-2">
                  <Typography variant="body1">
                    Offering personalized opportunities to create, connect, communicate, discover, and share. People are
                    different. We want to strengthen your relationships through shared experiences you care about. So we
                    build systems that try to understand who and what you and others care about and use that information
                    to help you create, find, join, and share in experiences that matter to you. Part of that is
                    highlighting content, features, offers, and accounts you might be interested in and offering ways
                    for you to experience thisPantry, based on things you and others do on and off thisPantry.
                  </Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1">
                    Fostering a positive, inclusive, and safe environment. We develop and use tools and offer resources
                    to our community members that help to make their experiences positive and inclusive, including when
                    we think they might need help. We also have teams and systems that work to combat abuse and
                    violations of our Terms and policies, as well as harmful and deceptive behaviour. We use all the
                    information we have-including your information-to try to keep our platform secure.
                  </Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1">
                    Developing and using technologies that help us consistently serve our growing community. Organizing
                    and analyzing information for our growing community is central to our Service. A big part of our
                    Service is creating and using cutting-edge technologies that help us personalize, protect, and
                    improve our Service on an incredibly large scale for a broad global community. Technologies like
                    artificial intelligence and machine learning give us the power to apply complex processes across our
                    Service. Automated technologies also help us ensure the functionality and integrity of our Service.
                  </Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1">
                    Ensuring a stable global infrastructure for our Service. To provide our global Service, we must
                    store and transfer data across our systems around the world, including outside of your country of
                    residence. This infrastructure may be owned or operated by thisPantry Inc., Alphabet Inc.,
                    Salesforce Inc., MongoDB Inc., Mixpanel Inc., or their affiliates and/or subsidiaries.
                  </Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1">
                    Connecting you with brands, products, and services in ways you care about. We use data from
                    thisPantry, as well as from third-party partners, to show you ads, offers, and other sponsored
                    content that we believe will be meaningful to you. And we try to make that content as relevant as
                    all your other experiences on thisPantry.
                  </Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1">
                    Research and innovation. We use the information we have to study our Service and collaborate with
                    others on research to make our Service better and contribute to the well-being of our community.
                  </Typography>
                </li>
              </ul>
            </div>
          </div>
          <div className="my-4">
            <Typography variant="h6">The Data Policy</Typography>
            <div className="my-2">
              <Typography variant="body1">
                Providing our Service requires collecting and using your information. The{" "}
                <Link to="/privacy">Data Policy</Link> explains how we collect, use, and share information across
                thisPantry. It also explains the many ways you can control your information, including in the thisPantry{" "}
                <Link to="/settings">Settings</Link>. You must agree to the <Link to="/privacy">Data Policy</Link> to
                use thisPantry.
              </Typography>
            </div>
          </div>
          <div className="my-4">
            <Typography variant="h6">Your Commitments</Typography>
            <div className="my-2">
              <Typography variant="body1">
                In return for our commitment to provide the thisPantry Service, we require you to make the below
                commitments to us:
              </Typography>
            </div>
            <div className="m-4 md:mx-8">
              <Typography variant="subtitle1" className="font-normal mb-2 italic">
                Who can use thisPantry
              </Typography>
              <Typography variant="body1">
                We want our Service to be as open and inclusive as possible, but we also want it to be safe and secure.
                So, we need you to commit to a few restrictions to be part of the thisPantry community.
              </Typography>
              <ul className="my-4 mx-4 marker:text-secondaryText">
                <li className="mb-2">
                  <Typography variant="body1">
                    You must be at least 13 years old or the minimum legal age in your country to use thisPantry.
                  </Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1">
                    You must not be prohibited from receiving any aspect of our Service under applicable laws or
                    engaging in payments-related Services if you are on an applicable denied party listing.
                  </Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1">
                    We must not have previously disabled your account for violation of law or any of our policies.
                  </Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1">You must not be a convicted sex offender.</Typography>
                </li>
              </ul>
            </div>
            <div className="m-4 md:mx-8">
              <Typography variant="subtitle1" className="font-normal mb-2 italic">
                How You Can't Use thisPantry
              </Typography>
              <Typography variant="body1">
                Providing a safe and open Service for a broad community requires that we all do our part.
              </Typography>
              <ul className="my-4 mx-4 marker:text-secondaryText">
                <li className="mb-2">
                  <Typography variant="body1">
                    You can't impersonate others or provide inaccurate information. You don't have to disclose your
                    identity on thisPantry, but you must provide us with accurate and up-to-date information (including
                    registration information). Also, you may not impersonate someone you aren't, and you can't create an
                    account for someone else unless you have their express permission.
                  </Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1">
                    You can't do anything unlawful, misleading, or fraudulent or for an illegal or unauthorized purpose.
                  </Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1">
                    You can't violate (or help or encourage others to violate) these Terms or our policies.
                  </Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1">
                    You can't do anything to interfere with or impair the intended operation of the Service.
                  </Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1">
                    You can't attempt to create accounts or access or collect information in unauthorized ways. This
                    includes creating accounts or collecting information in an automated way without our express
                    permission.
                  </Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1">
                    You can't attempt to buy, sell, or transfer any aspect of your account (including your username) or
                    solicit, collect, or use login credentials or badges of other users.
                  </Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1">
                    You can't post private or confidential information or do anything that violates someone else's
                    rights, including intellectual property. To report content, send us an email to{" "}
                    <a href="mailto:hello@thispantry.com" target="_blank" rel="noreferrer">
                      hello@thispantry.com
                    </a>{" "}
                    or complete a contact form <Link to="/contact">here</Link>.
                  </Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1">
                    You can't use a domain name or URL in your username without our prior written consent.
                  </Typography>
                </li>
              </ul>
            </div>
            <div className="m-4 md:mx-8">
              <Typography variant="subtitle1" className="font-normal mb-2 italic">
                Permissions You Give To Us
              </Typography>
              <Typography variant="body1">
                As part of our agreement, you also give us permissions that we need to provide the Service.
              </Typography>
              <ul className="my-4 mx-4 marker:text-secondaryText">
                <li className="mb-2">
                  <Typography variant="body1">
                    We do not claim ownership of your content, but you grant us a license to use it. Nothing is changing
                    about your rights in your content. We do not claim ownership of the content that you post on or
                    through the Service. Instead, when you share, post, or upload content that is covered by
                    intellectual property rights (like photos or videos) on or in connection with our Service, you
                    hereby grant to us a non-exclusive, royalty-free, transferable, sub-licensable, worldwide license to
                    host, use, distribute, modify, run, copy, publicly perform or display, translate, and create
                    derivative works of your content (consistent with your privacy and application settings). You can
                    end this license anytime by deleting your content or account. However, content will continue to
                    appear if you shared it with others and they have not deleted it. To learn more about how we use
                    information, and how to control or delete your content, review the{" "}
                    <Link to="/privacy">Data Policy</Link> or your thisPantry <Link to="/settings">Settings</Link>
                  </Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1">
                    Permission to use your username, profile picture, and information about your relationships and
                    actions with accounts, ads, and sponsored content. You give us permission to show your username,
                    profile picture, and information about your actions (such as likes) or relationships (such as
                    follows) next to or in connection with accounts, ads, offers, and other sponsored content that you
                    follow or engage with that are displayed on thisPantry, without any compensation to you. For
                    example, we may show that you liked a sponsored post created by a brand that has paid us to display
                    its ads on thisPantry.
                  </Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1">
                    You agree that we can download and install updates to the Service on your device.
                  </Typography>
                </li>
              </ul>
            </div>
            <div className="m-4 md:mx-8">
              <Typography variant="subtitle1" className="font-normal mb-2 italic">
                Additional Rights We Retain
              </Typography>
              <ul className="my-4 mx-4 marker:text-secondaryText">
                <li className="mb-2">
                  <Typography variant="body1">
                    If you select a username or similar identifier for your account, we may change it if we believe it
                    is appropriate or necessary (for example, if it infringes someone's intellectual property or
                    impersonates another user).
                  </Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1">
                    If you use content covered by intellectual property rights that we have and make available in our
                    Service (for example, images, designs, videos, or sounds we provide that you add to content you
                    create or share), we retain all rights to our content (but not yours).
                  </Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1">
                    You can only use our intellectual property and trademarks or similar marks with our prior written
                    permission.
                  </Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1">
                    You must obtain written permission from us or under an open-source license to modify, create
                    derivative works of, decompile, or otherwise attempt to extract source code from us.
                  </Typography>
                </li>
              </ul>
            </div>
          </div>
          <div className="my-4">
            <Typography variant="h6">Content Removal and Disabling or Terminating Your Account</Typography>
            <ul className="my-4 mx-4 marker:text-secondaryText">
              <li className="mb-2">
                <Typography variant="body1">
                  {" "}
                  We can remove any content or information you share on the Service if we believe that it violates these
                  Terms of Service, our policies, or we are permitted or required to do so by law. We can refuse to
                  provide or stop providing all or part of the Service to you (including terminating or disabling your
                  account) immediately to protect our community or services, or if you create risk or legal exposure for
                  us, violate these Terms of Service or our policies if you repeatedly infringe other people's
                  intellectual property rights, or where we are permitted or required to do so by law. If we take action
                  to disable or terminate your account, we will notify you where appropriate. If you believe your
                  account has been terminated in error, or you want to disable or permanently delete your account,
                  contact us via email at{" "}
                  <a href="mailto:hello@thispantry.com" target="_blank" rel="noreferrer">
                    hello@thispantry.com
                  </a>
                  , or by completing the contact form <Link to="/contact">here</Link>, or in your thisPantry
                  <Link to="/settings"> Settings</Link>.
                </Typography>
              </li>
              <li className="mb-2">
                <Typography variant="body1">
                  Content you delete may persist for a limited period of time in backup copies and will still be visible
                  where others have shared it. This paragraph, and the section below called "Our Agreement and What
                  Happens if We Disagree," will still apply even after your account is terminated or deleted.
                </Typography>
              </li>
            </ul>
          </div>
          <div className="my-4">
            <Typography variant="h6">Our Agreement and What Happens if We Disagree</Typography>
            <div className="my-2">
              <Typography variant="body1">Our Agreement</Typography>
              <ul className="my-4 mx-4 marker:text-secondaryText">
                <li className="mb-2">
                  <Typography variant="body1">
                    If any aspect of this agreement is unenforceable, the rest will remain in effect.
                  </Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1">
                    Any amendment or waiver to our agreement must be in writing and signed by us. If we fail to enforce
                    any aspect of this agreement, it will not be a waiver.
                  </Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1">We reserve all rights not expressly granted to you.</Typography>
                </li>
              </ul>
            </div>
            <div className="my-2">
              <Typography variant="body1">Who Has Rights Under this Agreement</Typography>
              <ul className="my-4 mx-4 marker:text-secondaryText">
                <li className="mb-2">
                  <Typography variant="body1">This agreement does not give rights to any third parties.</Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1">
                    You cannot transfer your rights or obligations under this agreement without our consent.
                  </Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1">
                    Our rights and obligations can be assigned to others. For example, this could occur if our ownership
                    changes (as in a merger, acquisition, or sale of assets) or by law.
                  </Typography>
                </li>
              </ul>
            </div>
            <div className="my-2">
              <Typography variant="body1">Who Is Responsible if Something Happens</Typography>
              <ul className="my-4 mx-4 marker:text-secondaryText">
                <li className="mb-2">
                  <Typography variant="body1">
                    Our Service is provided "as is," and we can't guarantee it will be safe and secure or will work
                    perfectly all the time. TO THE EXTENT PERMITTED BY LAW, WE ALSO DISCLAIM ALL WARRANTIES, WHETHER
                    EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                    PURPOSE, TITLE, AND NON-INFRINGEMENT.
                  </Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1">
                    We also don’t control what people and others do or say, and we aren’t responsible for their (or
                    your) actions or conduct (whether online or offline) or content (including unlawful or objectionable
                    content). We also aren’t responsible for services and features offered by other people or companies,
                    even if you access them through our Service.
                  </Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1">
                    Our responsibility for anything that happens on the Service (also called "liability") is limited as
                    much as the law will allow. If there is an issue with our Service, we can't know what all the
                    possible impacts might be. You agree that we won't be responsible ("liable") for any lost profits,
                    revenues, information, or data, or consequential, special, indirect, exemplary, punitive, or
                    incidental damages arising out of or related to these Terms, even if we know they are possible. This
                    includes when we delete your content, information, or account.
                  </Typography>
                </li>
              </ul>
            </div>
          </div>
          <div className="my-4">
            <Typography variant="h6">Unsolicited Material</Typography>
            <div className="my-2">
              <Typography variant="body1">
                We always appreciate feedback or other suggestions, but may use them without any restrictions or
                obligation to compensate you for them, and are under no obligation to keep them confidential.
              </Typography>
            </div>
          </div>
          <div className="my-4">
            <Typography variant="h6">Medical Disclaimer</Typography>
            <div className="my-2">
              <Typography variant="body1">
                thisPantry is designed to offer general information on recipes and nutrition and is provided for
                informational purposes only.
                <br></br>
                <br></br>
                The content within the app is not medical advice and should not be used to diagnose, treat, cure, or
                prevent any disease or health condition. Before making any changes to your diet or nutrition, you should
                consult with a healthcare professional. This is especially important if you have health concerns or
                conditions that may be affected by dietary changes.
                <br></br>
                <br></br>Users are responsible for ensuring that any recipes or ingredients selected from thisPantry are
                safe for their personal consumption and do not conflict with any health restrictions they may have. We
                endeavor to provide accurate and up-to-date information but do not guarantee that such information is
                always current or complete.
                <br></br>
                <br></br>thisPantry shall not be liable for any reliance on the information provided in this app, and
                all such reliance is solely at your own risk.
                <br></br>
                <br></br>The advice and content provided by thisPantry are not a substitute for professional medical
                advice, diagnosis, or treatment. Always seek the guidance of your physician or another qualified health
                provider with any questions regarding a medical condition or dietary restrictions.
                <br></br>
                <br></br>
                By using thisPantry, you acknowledge that you have read and understood this medical disclaimer and agree
                to its terms. This disclaimer does not cover any third-party websites or content that may be linked to
                from within the app, and we are not responsible for the content of or any damages that may result from
                your access to or reliance on these third-party sources.
              </Typography>
            </div>
          </div>
          <div className="my-4">
            <Typography variant="h6">Updating These Terms</Typography>
            <div className="my-2">
              <Typography variant="body1">
                We may change our Service and policies, and we may need to make changes to these Terms so that they
                accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for
                example, through our Service) before we make changes to these Terms and give you an opportunity to
                review them before they go into effect. Then, if you continue to use the Service, you will be bound by
                the updated Terms. If you do not want to agree to these or any updated Terms, you can delete your
                account, <Link to="/settings">here</Link>.
              </Typography>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  );
};

Terms.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Terms);
