import React, { useState } from "react";
import { Typography, Button, IconButton } from "@mui/material";
import { Progress } from "../../ui/progress";
import { ToggleGroup, ToggleGroupItem } from "../../ui/toggle-group";

import RadialChart from "../../charts/RadialChart";
import { ScrollArea, ScrollBar } from "../../ui/scroll-area";

import { ReactComponent as ChevronLeft } from "../../../assets/icons/svg/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../../assets/icons/svg/chevron-right.svg";

//import { Button } from "../../ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../ui/dropdown-menu";
//Icons
import { Beef, Pizza, Flame, Wheat } from "lucide-react";

//Sample data
const protein = {
  name: "Protein",
  goal: 120,
  value: 90,
  unit: "g",
};

const carbs = {
  name: "Carbohydrates",
  goal: 250,
  value: 200,
  unit: "g",
};

const fat = {
  name: "Fat",
  goal: 65,
  value: 70,
  unit: "g",
};

const water = {
  name: "Water",
  goal: 2000,
  value: 1700,
  unit: "ml",
};

const MacroItem = ({ macro: { name, value, goal, unit } }) => {
  console.log(name.toLowerCase() === "water");
  return (
    <div className="auto-fill-minmax-300 p-4 border border-separator flex-col gap- md:rounded-md shadow-sm bg-background">
      <div className="w-full flex flex-row justify-between items-center">
        <Typography variant="subtitle1">{name}</Typography>
      </div>
      <div className="mt-8">
        <div className="flex flex-col gap-2">
          <Typography variant="subtitle1" className="font-light">
            {value}/{goal}
            {` ${unit}`}
          </Typography>
          <Progress
            value={(value / goal) * 100}
            indicatorColor={name.toLowerCase() === "water" ? "bg-mainBlue-400" : ""}
          />
        </div>

        <div className="mt-2">
          <Typography variant="caption">{`${Math.round((value / goal) * 100)}% of your goal`}</Typography>
        </div>
      </div>
    </div>
  );
};

//TODO: Make calories a bigger card at the top with circular chart, combine others into progress bars in one card for "other" macros
export const Nutrition = ({ currentDate }) => {
  const formatDate = (date) => {
    const day = date.getDate();
    const suffix = ["th", "st", "nd", "rd"][day % 10 > 3 ? 0 : (((day % 100) - (day % 10) !== 10) * day) % 10];
    return date.toLocaleDateString("en-US", { month: "long", day: "numeric" }).replace(/\d+/, day + suffix);
  };

  const formatDay = (date) => {
    return date.toLocaleDateString("en-US", { weekday: "long" });
  };

  const chartData = { macro: "Calories", current: 1260, target: 2500 };

  return (
    <div className="flex flex-col gap-2">
      <div className="p-4 border border-separator md:rounded-md shadow-sm bg-background">
        <div className="flex flex-row justify-between items-center">
          <Typography variant="h6" className="leading-none font-normal">
            Nutrition
          </Typography>
          <Button variant="contained" color="secondary" size="small" disableElevation className="text-primaryText">
            + Track
          </Button>
        </div>
      </div>
      <div className="w-full h-full flex flex-col md:flex-row justify-between items-center gap-4 md:gap-16 p-4 md:px-8 border border-separator md:rounded-md shadow-sm bg-background">
        <div className="flex flex-row gap-2 w-full px-4 md:px-0 md:w-1/2">
          <IconButton className="p-0" disableRipple>
            <ChevronLeft className="h-4 w-auto text-secondaryText" />
          </IconButton>
          <div className="flex-1">
            <RadialChart data={chartData} />
          </div>
          <IconButton className="p-0" disableRipple>
            <ChevronRight className="h-4 w-auto text-secondaryText-700" />
          </IconButton>
        </div>
        <div className="w-full md:w-1/2">
          <div className="w-full flex flex-col items-center">
            <div className="w-full">
              <Typography variant="subtitle1" className="text-secondaryText leading-none">
                {formatDay(currentDate)}
              </Typography>
              <Typography variant="h5">{formatDate(currentDate)}</Typography>
            </div>
            <div className="w-full my-2 flex flex-col gap-2">
              <Typography variant="subtitle2" className="leading-none font-normal text-secondaryText-700">
                6 Logged Events
              </Typography>
              <Typography variant="subtitle2" className="leading-none font-normal text-secondaryText text-xs">
                2000 kcal target
              </Typography>
              <div className="flex flex-col gap-1 mt-2">
                <Typography variant="subtitle2" className="text-secondaryText-600 leading-none text-xs font-normal">
                  Streak:
                </Typography>
                <div className="flex flex-row gap-1 items-center bg-complementaryRed rounded-md w-fit px-2 py-1 mt-1">
                  <Typography variant="subtitle2" className="text-background leading-none text-sm font-medium">
                    <span className=" font-semibold text-background text-lg">37</span> days
                  </Typography>
                  <Flame className="h-4 w-auto fill-background text-background" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <div className="grid gap-2 grid-cols-1 md:grid-cols-2">
          <MacroItem macro={protein} />
          <MacroItem macro={carbs} />
          <MacroItem macro={fat} />
          <MacroItem macro={water} />
        </div>
        <div className="w-full border border-seprator md:rounded-md shadow-sm flex justify-center">
          <Button fullWidth className="bg-background">
            <Typography variant="subtitle2" className="font-normal">
              View Nutrition
            </Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Nutrition;
