import React, { useState } from "react";

import { Button, Typography, ListItem } from "@mui/material";

import { ReactComponent as EditIcon } from "../../../../assets/icons/svg/edit.svg";
import { ReactComponent as GrabbableIcon } from "../../../../assets/icons/svg/bars.svg";
//Modal
import CustomModal from "../../../modal/CustomModal";
import EditIngredientModal from "./EditIngredientModal";

//Utils
import { decimalToFraction } from "../../../../utils/recipe";

//DnD
import { Draggable } from "@hello-pangea/dnd";

//TO DO:
//Format data
//Display if missing key data (eg. ingredient name)
//Display if mapped ingredient
//Click to open edit ingredient modal (to edit or remove an ingredient)

String.prototype.toTitleCase = function () {
  var i, j, str, lowers, uppers;
  str = this.replace(/([^\W_]+[^\s-]*) */g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

  // Certain minor words should be left lowercase unless
  // they are the first or last words in the string
  lowers = [
    "A",
    "An",
    "The",
    "And",
    "But",
    "Or",
    "For",
    "Nor",
    "As",
    "At",
    "By",
    "For",
    "From",
    "In",
    "Into",
    "Near",
    "Of",
    "On",
    "Onto",
    "To",
    "With",
  ];
  for (i = 0, j = lowers.length; i < j; i++)
    str = str.replace(new RegExp("\\s" + lowers[i] + "\\s", "g"), function (txt) {
      return txt.toLowerCase();
    });

  // Certain words such as initialisms or acronyms should be left uppercase
  uppers = ["Id", "Tv"];
  for (i = 0, j = uppers.length; i < j; i++)
    str = str.replace(new RegExp("\\b" + uppers[i] + "\\b", "g"), uppers[i].toUpperCase());

  return str;
};

const RecipeSectionIngredient = ({ ingredient, sectionIndex, ingredientIndex }) => {
  //Hover state
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  const handleClick = () => setShowEditIngredientModal(true);
  const handleModalClose = () => setShowEditIngredientModal(false);

  //Modal State
  const [showEditIngredientModal, setShowEditIngredientModal] = useState(false);

  //String formatting

  const formatAmountUnit = () => {
    let formattedAmount = "";
    let formattedUnit = "";

    if (ingredient.quantity && ingredient.quantity !== "") {
      formattedAmount = decimalToFraction(ingredient.quantity);
    }

    if (ingredient.unit && ingredient.unit !== "") {
      if (
        ingredient.unit !== "self" &&
        ingredient.unit !== "count" &&
        ingredient.unit !== "not_exact" &&
        ingredient.unit !== "to_taste"
      ) {
        formattedUnit = ingredient.unit;
      }
    }

    let showSpace = false;

    if (formattedAmount !== "" && formattedUnit !== "") {
      showSpace = true;
    }

    return `${formattedAmount} ${formattedUnit}${showSpace ? " " : ""}`;
  };

  const formatIngredient = () => {
    //Convert ingredient data to a string for the top line
    if (ingredient.name && typeof ingredient.name === "string") {
      let formattedName =
        ingredient.name && ingredient.name !== "" ? ingredient.name.toTitleCase() : "Unknown Ingredient";

      return `${formattedName}`;
    } else {
      return "Unknown Ingredient";
    }
  };

  const formatState = () => {
    let formattedState = "";

    if (ingredient.state && typeof ingredient.state === "string" && ingredient.state !== "") {
      formattedState = ingredient.state.toTitleCase();
    }

    if (formattedState !== "") {
      return `, ${formattedState}`;
    }
  };

  //Top line -> amount unit name, state
  //Bottom line -> note
  //Verification/Error status
  return (
    <Draggable draggableId={`ingredient-${sectionIndex}-${ingredientIndex}`} index={ingredientIndex}>
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={`select-none list-item marker:text-mainGreen py-1 px-2 ${
            snapshot.isDragging ? "bg-gray-100" : ""
          }`}
        >
          <div className="flex items-stretch w-full h-full">
            <div className="flex flex-grow items-center">
              <div
                className="cursor-pointer"
                onClick={handleClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <div className="flex flex-row items-center">
                  <Typography variant="subtitle1" className="font-medium">
                    <span className="font-normal">{formatAmountUnit()}</span>
                    <span className={`${isHovered ? "text-mainGreen" : "text-primaryText"}`}>{formatIngredient()}</span>
                    {ingredient.state !== "" && <span className="font-normal italic">{formatState()}</span>}
                  </Typography>
                  {isHovered && <EditIcon className="h-3 fill-secondaryText ml-2" />}
                </div>
                {ingredient.note && ingredient.note !== "" && (
                  <Typography variant="body2" className="text-secondaryText">
                    ({ingredient.note})
                  </Typography>
                )}
              </div>
              <div {...provided.dragHandleProps} className="flex-grow h-full cursor-move ml-2" />
              <div {...provided.dragHandleProps} className=" cursor-move flex items-center text-secondaryText-600">
                <GrabbableIcon className="h-3 fill-secondaryText" />
              </div>
            </div>
          </div>
          <CustomModal open={showEditIngredientModal} handleClose={handleModalClose}>
            <EditIngredientModal
              ingredient={ingredient}
              sectionIndex={sectionIndex}
              ingredientIndex={ingredientIndex}
              handleModalClose={handleModalClose}
            />
          </CustomModal>
        </ListItem>
      )}
    </Draggable>
  );
};

export default RecipeSectionIngredient;
