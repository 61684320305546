import React from "react";

import PostTextContent from "./components/PostTextContent";
const TextContent = ({ post }) => {
  return (
    <>
      <PostTextContent text={post.text} />
    </>
  );
};

export default TextContent;
