import React, { useState, useEffect, Fragment } from "react";
import { styled } from "@mui/material/styles";
import {
  Slider,
  Typography,
  Select,
  Button,
  useMediaQuery,
  Modal,
  Fade,
  Backdrop,
  IconButton,
  Paper,
} from "@mui/material";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";

import SpendSourcesModal from "./SpendSourcesModal";

const PREFIX = "GrocerySpendSlider";

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  title_bold: `${PREFIX}-title_bold`,
  slider: `${PREFIX}-slider`,
  results: `${PREFIX}-results`,
  quotes: `${PREFIX}-quotes`,
  quotes_container: `${PREFIX}-quotes_container`,
  card: `${PREFIX}-card`,
  slider_section: `${PREFIX}-slider_section`,
  amount_container: `${PREFIX}-amount_container`,
  amount: `${PREFIX}-amount`,
  amount_bold: `${PREFIX}-amount_bold`,
  cta: `${PREFIX}-cta`,
  cta_icon: `${PREFIX}-cta_icon`,
  groceries: `${PREFIX}-groceries`,
  sources_container: `${PREFIX}-sources_container`,
  sources: `${PREFIX}-sources`,
  amount_value: `${PREFIX}-amount_value`,
  modal_root: `${PREFIX}-modal_root`,
  modal_background: `${PREFIX}-modal_background`,
  modalContent: `${PREFIX}-modalContent`,
  modal_nav: `${PREFIX}-modal_nav`,
  nav_button: `${PREFIX}-nav_button`,
  nav_text: `${PREFIX}-nav_text`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    padding: "5rem 10rem",
    backgroundColor: theme.palette.background,
    [theme.breakpoints.down("xl")]: {
      padding: `${theme.spacing(12)} 5%`,
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(4),
    },
  },

  [`& .${classes.title}`]: {
    width: "100%",
    paddingRight: theme.spacing(10),
    fontWeight: "300",
    [theme.breakpoints.down("md")]: {
      paddingRight: 0,
      margin: theme.spacing(2, 0),
    },
    //w-full pr-0 my-2 font-light md:pr-32
  },

  [`& .${classes.title_bold}`]: {
    fontWeight: "500",
    color: "#e2725b",
    fontSize: "2rem",
    margin: theme.spacing(0, 0.2),
  },

  [`& .${classes.slider}`]: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(3),
    zIndex: 2,
    "& .MuiSlider-thumb": {
      height: 32,
      width: 32,
      marginTop: "-8px",
    },
    "& .MuiSlider-track": {
      height: 16,
    },
    "& .MuiSlider-rail": {
      height: 16,
    },
    [theme.breakpoints.down("md")]: {
      width: "calc(100% - 16px)",
    },
  },

  [`& .${classes.results}`]: {
    marginTop: 0,
  },

  [`& .${classes.quotes}`]: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxHeight: "60%",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      gap: theme.spacing(4),
    },
  },

  [`& .${classes.quotes_container}`]: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative",
    paddingLeft: "2.5rem",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      paddingBottom: theme.spacing(4),
      paddingLeft: 0,
    },
  },

  [`& .${classes.card}`]: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },

  [`& .${classes.slider_section}`]: {
    width: "50%",
    borderRight: "1px solid #00000066",
    paddingRight: "2.5rem",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      borderTop: "1px solid #00000066",
      borderRight: 0,
      paddingTop: theme.spacing(4),
      marginTop: theme.spacing(2),
      paddingRight: 0,
    },
  },

  [`& .${classes.amount_container}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "start",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "start",
    },
  },

  [`& .${classes.amount}`]: {
    width: "60%",
  },

  [`& .${classes.amount_bold}`]: {
    fontWeight: "500",
    color: "#e2725b",
    fontSize: "1.5rem",
    margin: theme.spacing(0, 0.2),
    //font-bold color-mainGreen[900] text-xl mx-1
  },

  [`& .${classes.cta}`]: {
    textTransform: "none",
    marginTop: theme.spacing(4),
    borderWidth: 2,
    color: theme.palette.textOnWhite,
    borderColor: theme.palette.textOnWhite,
    fontWeight: "600",
  },

  [`& .${classes.cta_icon}`]: {
    fontSize: "0.75rem",
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.groceries}`]: {
    paddingBottom: "1.5rem",
  },

  [`& .${classes.sources_container}`]: {
    display: "flex",
    justifyContent: "end",
    marginRight: theme.spacing(10),
    [theme.breakpoints.down("md")]: {
      marginRight: 0,
    },
  },

  [`& .${classes.sources}`]: {
    textTransform: "none",
    color: theme.palette.secondaryTextOnWhite + "99",
    marginTop: theme.spacing(2),
    position: "absolute",
    bottom: 0,
  },

  [`& .${classes.amount_value}`]: {
    [theme.breakpoints.down("md")]: {
      fontSize: "5rem",
    },
  },

  [`& .${classes.modal_root}`]: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },

  [`& .${classes.modal_background}`]: {
    backgroundColor: theme.palette.background,
    borderRadius: 16,
    border: "1px solid #fff",
    minHeight: "10vh",
    maxHeight: "85vh",
    width: "30vw",
    overflowY: "auto",
    [theme.breakpoints.down("md")]: {
      width: "90vw",
    },
  },

  [`& .${classes.modalContent}`]: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    marginLeft: "auto",
    marginRight: "auto",
  },

  [`& .${classes.modal_nav}`]: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    top: 0,
    padding: theme.spacing(1, 2),
    position: "fixed",
  },

  [`& .${classes.nav_button}`]: {
    textTransform: "none",
    color: theme.palette.textOnWhite,
  },

  [`& .${classes.nav_text}`]: {
    fontWeight: 500,
  },
}));

/*Slider subclasses BROKEN WITH REACT 18 TO DO: FIX
const sliderStyles = makeStyles((theme) => ({
  mark: {
    backgroundColor: theme.palette.secondaryTextOnWhite + "44",
    height: 16,
    width: 1,
  },
  markActive: {
    backgroundColor: "green",
  },
  markLabel: {
    transform: "translate(-50%, -40px) !important",
  },
}));*/

const GrocerySpendSlider = ({ scrollToPantry }) => {
  //const sliderClasses = sliderStyles();
  //Breakpoints
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const [spend, setSpend] = useState(8065 / 12);
  const [period, setPeriod] = useState("monthly");
  const [sliderMax, setSliderMax] = useState(2500);
  const [sliderStep, setSliderStep] = useState(10);

  const [showSources, setShowSources] = useState(false);
  useEffect(() => {
    switch (period) {
      case "weekly":
        setSliderMax(1000);
        setSliderStep(1);
        if (spend > 1000) setSpend(1000);
        break;
      case "monthly":
        setSliderMax(2500);
        setSliderStep(10);
        break;
      default:
        setSliderMax(30000);
        setSliderStep(25);
        if (spend > 30000) setSpend(30000);
        break;
    }
  }, [period, spend]);

  const handleSpendChange = (event, newValue) => {
    setSpend(newValue);
  };

  const handlePeriodChange = (event) => {
    //Annual ->
    if (period === "yearly") {
      //To Monthly
      if (event.target.value === "monthly") {
        setSpend(spend / 12);
        setPeriod(event.target.value);
        return;
      }
      //To Monthly
      if (event.target.value === "weekly") {
        setSpend(spend / 52);
        setPeriod(event.target.value);
        return;
      }
    }
    //Monthly ->
    if (period === "monthly") {
      //To Yearly
      if (event.target.value === "yearly") {
        setSpend(spend * 12);
        setPeriod(event.target.value);
        return;
      }
      //To Weekly
      if (event.target.value === "weekly") {
        setSpend(spend / 4.345);
        setPeriod(event.target.value);
        return;
      }
    }

    //Weekly ->
    if (period === "weekly") {
      //To Yearly
      if (event.target.value === "yearly") {
        setSpend(spend * 52);
        setPeriod(event.target.value);
        return;
      }
      //To Monthly
      if (event.target.value === "monthly") {
        setSpend(spend * 4.345);
        setPeriod(event.target.value);
        return;
      }
    }

    console.log("Error: Spend change not handled, unknown period");
    setPeriod(event.target.value);
  };

  const calculateAnnualSpend = () => {
    switch (period) {
      case "weekly":
        return spend * 52;
      case "monthly":
        return spend * 12;
      default:
        return spend;
    }
  };

  const annualSpend = calculateAnnualSpend();
  const estimatedWaste = annualSpend * 0.319;
  const dailyWaste = estimatedWaste / 365.25;

  return (
    <Root>
      <Modal
        open={showSources}
        onClose={() => setShowSources(false)}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showSources}>
          <div className={classes.modal_root}>
            <Paper className={classes.modalContent} elevation={4}>
              <div className={classes.modal_background}>
                <div className={classes.modal_nav}>
                  <div></div>

                  <IconButton
                    aria-label="exit"
                    onClick={() => setShowSources(false)}
                    className={classes.nav_button}
                    size="large"
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </IconButton>
                </div>

                <SpendSourcesModal />
              </div>
            </Paper>
          </div>
        </Fade>
      </Modal>
      <div className=" xl:p-[5rem] md:p-8 bg-stone-300">
        <div className={classes.card}>
          <div className={classes.slider_section}>
            <div>
              <Typography variant="h6" className="text-gray-700">
                I spend:
              </Typography>
              <div className={classes.amount_container}>
                <div className={classes.amount}>
                  <Typography
                    variant="h1"
                    component="p"
                    style={{
                      marginRight: "2rem",
                      color: "#456b42",
                      fontWeight: "600",
                    }}
                    className={classes.amount_value}
                  >
                    ${spend.toFixed(0)}
                  </Typography>
                </div>
                <div>
                  <Typography variant="h6" className="pb-8 text-gray-700">
                    on groceries
                  </Typography>
                </div>
              </div>
              <Select
                native
                value={period}
                onChange={handlePeriodChange}
                className="text-mainGreen-700"
                variant="standard"
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              >
                <option value={"weekly"}>per week</option>
                <option value={"monthly"}>per month</option>
                <option value={"yearly"}>per year</option>
              </Select>
            </div>

            <Slider
              className={classes.slider}
              //classes={{ ...sliderClasses }}
              value={spend}
              onChange={handleSpendChange}
              step={sliderStep}
              min={0}
              max={sliderMax}
              valueLabelDisplay="off"
              valueLabelFormat={(value) => `$${value.toFixed(2)}`}
            />
            <Typography variant="subtitle1" className="mt-0 text-gray-700">
              Annual spend:<br></br>
              <span className="font-medium text-current text-xl mx-1">${annualSpend.toFixed(2)}</span>
            </Typography>
            <Typography variant="subtitle1" className="mt-0 text-gray-700">
              Annual waste:<br></br>
              <span className="font-medium text-current text-xl mx-1">${estimatedWaste.toFixed(2)}</span>
            </Typography>
            <Typography variant="subtitle1" className="mt-0 text-gray-700">
              That's <span className="font-medium text-current text-xl mx-1">${dailyWaste.toFixed(2)}</span>
              wasted daily.
            </Typography>
            <Button
              className={classes.cta}
              variant="outlined"
              size="medium"
              endIcon={<FontAwesomeIcon icon={faArrowDown} />}
              onClick={scrollToPantry}
            >
              Easily Prevent This
            </Button>
          </div>
          <div className={classes.quotes_container}>
            <div className={classes.quotes}>
              <Typography variant="h6" className="w-full pr-0 my-2 font-light md:pr-32 text-gray-700">
                The average household spends{" "}
                <span className="font-medium text-mainGreen-600 text-4xl mx-1">$8,065</span> on groceries every year.
              </Typography>
              <Typography variant="h6" className="w-full pr-0 my-2 font-light md:pr-32 text-gray-700">
                A staggering <span className="font-medium text-mainGreen-600 text-4xl mx-1"> $2,572.74 </span> (31.9%)
                of that typically ends up in the garbage.
              </Typography>
              <Typography variant="h6" className="w-full pr-0 my-2 font-light md:pr-32 text-gray-700">
                That's <span className="font-medium text-mainGreen-600 text-4xl mx-1">$7.04</span> a day tossed away.
              </Typography>
            </div>
            <div className={classes.sources_container}>
              <Button
                className={classes.sources}
                size="small"
                endIcon={<FontAwesomeIcon icon={faCircleQuestion} />}
                onClick={() => setShowSources(true)}
              >
                Sources
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Root>
  );
};

export default GrocerySpendSlider;
