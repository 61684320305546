import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

//MUI Styling
import { Button } from "@mui/material";

//Actions
import { showLoginModal, showRegisterModal } from "../../../actions/auth";

//Modals
import LoginModal from "../../auth/login/LoginModal";
import RegisterModal from "../../auth/RegisterModal";

//Assets
import { ReactComponent as Logo } from "../../../assets/logos/full_logo.svg";

const NoAuth = ({ showLoginModal, showRegisterModal }) => {
  const onNavigation = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <nav className="w-full h-[calc(4rem+env(safe-area-inset-top))] bg-white sticky top-[calc(0+env(safe-area-inset-top))] border-b border-separator flex items-center justify-between px-8 pb-4 pt-[calc(1rem+env(safe-area-inset-top))] z-[999]">
        <div className="h-full flex items-center">
          <Link to="/" className="h-full" onClick={() => onNavigation()}>
            <Logo className="fill-mainGreen h-full"></Logo>
          </Link>
        </div>
        <div className="flex flex-row align-center justify-center">
          <Button
            variant="outlined"
            disableElevation
            className="border-2 border-primaryText hover:border-2 hover:border-mainGreen mr-2 md:mr-4"
            onClick={() => showLoginModal()}
          >
            Log In
          </Button>
          <Button
            variant="contained"
            disableElevation
            className="bg-mainGreen text-white"
            onClick={() => showRegisterModal()}
          >
            + Sign Up
          </Button>
        </div>
      </nav>
      <LoginModal />
      <RegisterModal />
    </div>
  );
};

NoAuth.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { showLoginModal, showRegisterModal })(NoAuth);
