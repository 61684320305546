import React from "react";

import RecipeStepsSection from "./RecipeStepsSection";

const RecipeSteps = ({ recipe }) => {
  return (
    <div className="pt-6 pb-2">
      {recipe.sections.map((section, i) => {
        if (section.steps.length === 0) return null;
        return <RecipeStepsSection key={i} section={section} />;
      })}
    </div>
  );
};

export default RecipeSteps;
