import React, { useState, useCallback } from "react";
import { styled } from '@mui/material/styles';
import Cropper from "react-easy-crop";
import { connect } from "react-redux";
import { cropImageInUpload, removeImageFromUpload } from "../../../../actions/media";
import { setAlert } from "../../../../actions/alert";
import { getCroppedImg } from "../../../../utils/image"; // This is a utility function you need to implement

import { Button, Typography } from "@mui/material";

const PREFIX = 'ImageCropModal';

const classes = {
  buttonContainer: `${PREFIX}-buttonContainer`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.buttonContainer}`]: {
    display: "flex",
    justifyContent: "space-between",
    margin: theme.spacing(2, 4),
  }
}));

//TO DO: Fix initial crop and zoom values to actually show the cropped image
const ImageCropper = ({ media: { images, modals }, cropImageInUpload, removeImageFromUpload, setAlert }) => {


  const image = images[modals.cropIndex];

  const [crop, setCrop] = useState(image && image.cropped && image.cropped.crop ? image.cropped.crop : { x: 0, y: 0 });
  const [zoom, setZoom] = useState(image && image.cropped && image.cropped.zoom ? image.cropped.zoom : 1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  //Removal state
  const [areYouSure, setAreYouSure] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onCropConfirm = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(image.previewUrl, croppedAreaPixels); //Do this again on all images in the fire to backend, append to formData
      cropImageInUpload(modals.cropIndex, { croppedImage, crop, zoom, croppedAreaPixels });
      setAlert("Image cropped", "success");
    } catch (e) {
      console.error(e);
    }
  }, [image, croppedAreaPixels, cropImageInUpload, modals.cropIndex]);

  return (
    <Root>
      <div style={{ width: "100%", position: "relative" }}>
        <div style={{ position: "relative", height: "100%", width: "100%", minHeight: "600px" }}>
          {image && (
            <Cropper
              image={image.previewUrl}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
              initialCroppedAreaPixels={image && image.cropped && image.cropped.croppedAreaPixels}
            />
          )}
        </div>
      </div>

      <div className={classes.buttonContainer}>
        {areYouSure ? (
          <Button
            size="small"
            variant="outlined"
            color="danger"
            onClick={() => removeImageFromUpload(modals.cropIndex)}
          >
            <Typography>Yes, Remove</Typography>
          </Button>
        ) : (
          <Button variant="outlined" onClick={() => setAreYouSure(true)}>
            <Typography>Remove</Typography>
          </Button>
        )}

        <Button onClick={onCropConfirm} variant="contained" color="primary" disableElevation>
          <Typography>Crop</Typography>
        </Button>
      </div>
    </Root>
  );
};

const mapStateToProps = (state) => ({
  media: state.media,
});
export default connect(mapStateToProps, { cropImageInUpload, removeImageFromUpload, setAlert })(ImageCropper);
