import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Typography, Divider, Button } from "@mui/material";
import { ReactComponent as ChevronRight } from "../../../assets/icons/svg/chevron-right.svg";
import { ReactComponent as TitleIcon } from "../../../assets/icons/svg/leaf-solid.svg";
import api from "../../../api/api";

const SubscriptionSettings = () => {
  const handleManageSubscription = async () => {
    try {
      const response = await api.post("/stripe/create-portal-session");
      window.location.href = response.data.url;
    } catch (error) {
      console.error("Error creating portal session:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  return (
    <>
      <Helmet>
        <title>thisPantry - Subscription Settings</title>
      </Helmet>

      <div className="my-[4rem] md:my-0 md:pt-6 bg-neutral-50 min-h-screen">
        <div className="w-full md:ml-[5rem]">
          <div className="px-4 py-3 md:px-8 md:py-6 md:mx-auto w-full md:max-w-[600px] border-b md:border border-separator md:rounded-[16px] bg-background">
            <div className="flex flex-col">
              <div className="my-4 md:mt-0">
                <Link className="hover:bg-transparent text-secondaryText p-0 justify-start" to="/settings">
                  <Typography variant="body2" className="body1 leading-none">{`< Go Back`}</Typography>
                </Link>
              </div>
              <div className="flex flex-row mt-">
                <div className="mr-4">
                  <TitleIcon className="h-[1.25rem] fill-mainGreen"></TitleIcon>
                </div>
                <div className="mb-4">
                  <Typography variant="h6" className="font-normal leading-none mb-1">
                    Subscription Settings
                  </Typography>
                  <Typography variant="subtitle2" className="text-secondaryText leading-tight font-normal">
                    Manage your thisPantry+ subscription
                  </Typography>
                </div>
              </div>
            </div>
            <Divider />
            <div className="flex flex-row justify-between items-center py-3">
              <div>
                <Typography variant="subtitle2" className="font-normal">
                  Manage Subscription
                </Typography>
                <Typography variant="body2" className="text-secondaryText">
                  Update payment method, cancel, or change plan
                </Typography>
              </div>
              <Button onClick={handleManageSubscription} variant="outlined" color="primary">
                Manage
              </Button>
            </div>
            <Divider />
            <Link to="/plus/">
              <div className="flex flex-row justify-between items-center py-3">
                <div>
                  <Typography variant="subtitle2" className="font-normal">
                    thisPantry+ Features
                  </Typography>
                  <Typography variant="body2" className="text-secondaryText">
                    View all thisPantry+ features
                  </Typography>
                </div>
                <div>
                  <ChevronRight className="h-[1rem] text-primaryText " />
                </div>
              </div>
            </Link>
            <Divider />
            <Link to="/plus/terms">
              <div className="flex flex-row justify-between items-center py-3">
                <div>
                  <Typography variant="subtitle2" className="font-normal">
                    Subscription Terms
                  </Typography>
                  <Typography variant="body2" className="text-secondaryText">
                    View subscription terms and conditions
                  </Typography>
                </div>
                <div>
                  <ChevronRight className="h-[1rem] text-primaryText " />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionSettings;
