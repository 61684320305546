import {
  SEARCH_GET_RESULTS,
  SEARCH_RESULTS_ERROR,
  SEARCH_SET_QUERY,
  SEARCH_SET_TYPE,
  SEARCH_FETCH_PROFILES,
  SEARCH_PROFILES_ERROR,
  SEARCH_RESET_RESPONSE,
} from "../actions/types";

const initialState = {
  query: {
    value: "",
    type: "trending",
  },
  response: {
    loading: true,
    error: false,
    data: null,
    endOfItems: false,
    offset: 0,
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SEARCH_RESET_RESPONSE:
      return {
        ...state,
        response: {
          loading: true,
          error: false,
          data: null,
          endOfItems: false,
          offset: 0,
        },
      };
    case SEARCH_SET_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          value: payload,
        },
      };
    case SEARCH_SET_TYPE:
      return {
        ...state,
        query: {
          ...state.query,
          type: payload,
        },
        response: {
          loading: true,
          error: false,
          data: null,
          endOfItems: false,
          offset: 0,
        },
      };
    case SEARCH_FETCH_PROFILES:
      return {
        ...state,
        response: {
          loading: false,
          error: false,
          data: [...(state.response.data?.profiles || []), ...payload.profiles],
          endOfItems: payload.endOfItems,
          offset: payload.offset,
        },
      };
    case SEARCH_PROFILES_ERROR:
    case SEARCH_RESULTS_ERROR:
      return {
        ...state,
        response: {
          loading: true,
          error: false,
          data: null,
          endOfItems: false,
          offset: 0,
        },
      };
    default:
      return state;
  }
}
