import React, { useEffect, useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import clsx from "clsx";

import { useMediaQuery } from "@mui/material";

//Components
import TChart from "./TChart";

//Resources
import background from "../img/savings/background.jpeg";
import dashboard from "../img/savings/dashboard.png";
import highlight from "../img/savings/highlight.png";

//MUI
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const PREFIX = "Savings";

const classes = {
  root: `${PREFIX}-root`,
  content_container: `${PREFIX}-content_container`,
  image_root: `${PREFIX}-image_root`,
  image_container: `${PREFIX}-image_container`,
  dark: `${PREFIX}-dark`,
  image: `${PREFIX}-image`,
  section_container: `${PREFIX}-section_container`,
  section_header: `${PREFIX}-section_header`,
  section_title: `${PREFIX}-section_title`,
  expandable: `${PREFIX}-expandable`,
  border: `${PREFIX}-border`,
  cta: `${PREFIX}-cta`,
  iconEdgeButton: `${PREFIX}-iconEdgeButton`,
  startIconCustom: `${PREFIX}-startIconCustom`,
  toggle_button: `${PREFIX}-toggle_button`,
  info: `${PREFIX}-info`,
  title: `${PREFIX}-title`,
  savings_imgs: `${PREFIX}-savings_imgs`,
  savings_img: `${PREFIX}-savings_img`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: "relative",
    width: "100%",
    height: "100%",
  },

  [`& .${classes.content_container}`]: {
    display: "flex",
    flexDirection: "row",
    color: theme.palette.textOnWhite,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      color: theme.palette.background,
    },
  },

  [`& .${classes.image_root}`]: {
    width: "50%",
    height: "auto",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      margin: theme.spacing(2, 0),
    },
  },

  [`& .${classes.image_container}`]: {
    width: "100%",
    height: "auto",
    flex: 1,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflowX: "hidden",
  },

  [`& .${classes.dark}`]: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.1)", // Semi-transparent black
    top: 0,
    left: 0,
    zIndex: 1,
  },

  [`& .${classes.image}`]: {
    position: "absolute",
    height: "100%",
    width: "100%",
    left: "50%",
    transform: "translateX(-50%)",
    objectFit: "cover",
    [theme.breakpoints.down("md")]: {},
  },

  [`& .${classes.section_container}`]: {
    width: "50%",
    backgroundColor: "#faf3e0", //Test a cool orange? #a7c2cc -> ashe blue, #e6e6fa -> lavender, #008080 -> teal, #a7ceeb -> sky blue
    //5b7c99 -> slate blue, #afa79f -> warm grey. #e1ad01 -> mustard yellow *** / #ffd700 -> gold / #edb458 -> orange? #f7e7ce _> champ
    padding: theme.spacing(20),
    paddingRight: "10%",
    paddingLeft: "5%",
    [theme.breakpoints.down("xl")]: {
      padding: theme.spacing(20, 8),
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: theme.spacing(2, 0),
      backgroundColor: "#363030",
    },
  },

  [`& .${classes.section_header}`]: {
    marginBottom: theme.spacing(4),
  },

  [`& .${classes.section_title}`]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textTransform: "none",
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0, 4),
    },
  },

  [`& .${classes.expandable}`]: {
    display: "block",
  },

  [`& .${classes.border}`]: {
    borderTop: `1px solid ${theme.palette.textOnWhite + "66"}`, //40% = 66
    borderBottom: `1px solid ${theme.palette.textOnWhite + "66"}`, //40% = 66
  },

  [`& .${classes.cta}`]: {
    margin: theme.spacing(2, 0),
    position: "relative",
    left: "-11px",
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(2, 4),
    },
  },

  [`& .${classes.iconEdgeButton}`]: {
    position: "relative",
    textTransform: "none",
    paddingRight: theme.spacing(2), // Adjust this to control spacing between the icon and the text
    color: theme.palette.mainGreen,
  },

  [`& .${classes.startIconCustom}`]: {
    position: "absolute",
    left: "1rem", // This determines the gap between the left edge and the icon
  },

  [`& .${classes.toggle_button}`]: {
    color: theme.palette.textOnWhite,
    padding: theme.spacing(2, 0),
    borderRadius: 0,
    [theme.breakpoints.down("md")]: {
      color: theme.palette.backgroundColor,
    },
  },

  [`& .$"mx-8 my-4 mt-8 text-background font-normal md:m-0 md:text-primaryText"`]: {
    fontWeight: 400,
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(2, 4),
      marginTop: theme.spacing(4),
      color: theme.palette.backgroundColor,
    },
    //mx-8 my-4 mt-8 text-background font-normal md:m-0 md:text-primaryText
  },

  [`& .$"text-background font-medium md:text-primaryText"`]: {
    color: theme.palette.textOnWhite,
    [theme.breakpoints.down("md")]: {
      color: theme.palette.background,
    },
    //text-background font-medium md:text-primaryText
  },

  [`& .${classes.savings_imgs}`]: {
    bottom: "0",
    display: "flex",
    flex: 1,
    width: "100%",
    position: "relative",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(8, 0),
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(4, 0),
    },
  },

  [`& .${classes.savings_img}`]: {
    position: "relative",
    width: "auto",
    height: "auto",
    "&.dashboard": {
      width: "20%",
      left: "2%",
      zIndex: 3,
      filter: `drop-shadow(0 8px 10px rgba(0,0,0,0.4)) blur(0.3px)`,
      [theme.breakpoints.down("xl")]: {
        width: "26%",
      },
      [theme.breakpoints.down("lg")]: {
        width: "33%",
      },
      [theme.breakpoints.down("md")]: {
        filter: `drop-shadow(0 8px 10px rgba(0,0,0,0.4))`,
      },
      [theme.breakpoints.down("sm")]: {},
    },
    "&.highlight": {
      width: "22%",
      right: "2%",
      zIndex: 4,
      filter: `drop-shadow(0 8px 10px rgba(0,0,0,0.4))`,
      [theme.breakpoints.down("xl")]: {
        width: "28%",
      },
      [theme.breakpoints.down("lg")]: {
        width: "36%",
      },
      [theme.breakpoints.down("md")]: {},
      [theme.breakpoints.down("sm")]: {},
    },
  },
}));

//Just have one section in the AI Style?
const Savings = ({ expanded = true }) => {
  //Breakpoints
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Root className={classes.root}>
      <div className={classes.content_container}>
        {!isSmallScreen && (
          <div className={classes.image_root}>
            <div className={classes.image_container}>
              <div className={classes.dark}></div>
              <img src={background} className={classes.image}></img>
              <div className={classes.savings_imgs}>
                <img src={dashboard} className={`${classes.savings_img} dashboard`}></img>
                <img src={highlight} className={`${classes.savings_img} highlight`}></img>
              </div>
            </div>
          </div>
        )}

        <div className={classes.section_container}>
          <div className={classes.section}>
            <div className={clsx(classes.section_title, classes.toggle_button)}>
              <Typography variant="h5" className="text-background font-normal md:text-primaryText">
                Affordable Appetites
              </Typography>
            </div>
            {isSmallScreen && (
              <div className={classes.image_root}>
                <div className={classes.image_container}>
                  <div className={classes.dark}></div>
                  <img src={background} className={classes.image}></img>
                  <div className={classes.savings_imgs}>
                    <img src={dashboard} className={`${classes.savings_img} dashboard`}></img>
                    <img src={highlight} className={`${classes.savings_img} highlight`}></img>
                  </div>
                </div>
              </div>
            )}
            <div className={classes.expandable}>
              <Typography
                variant="body1"
                component="p"
                className="mx-8 my-4 mt-8 text-background font-normal md:m-0 md:text-secondaryText-600"
              >
                With our Saving Dashboard, track your expenses and see how much you save over time. And for those keen
                on reducing waste, we've got you covered. Take that food out of the trash. (Before it goes in there, of
                course!)
              </Typography>
              <div className={classes.cta}>
                <Button
                  endIcon={<FontAwesomeIcon icon={faArrowRight} />}
                  classes={{
                    root: classes.iconEdgeButton,
                    startIcon: classes.startIconCustom,
                  }}
                  size="large"
                >
                  Learn More
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Root>
  );
};

export default Savings;
