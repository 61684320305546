import React, {useState} from 'react';
import axios from 'axios';

export const ScanReceipt = () => {
    const [file, setFile] = useState();

    const onFileChange = event => {
        setFile(event.target.files[0]);
    };

    const onFileUpload = async (e) => {
        
    e.preventDefault();
        const formData = new FormData(); 
        // Send formData object 
    formData.append("image", file)
    await axios.post("/api/pantry/receipt", formData, { headers: {'Content-Type': 'multipart/form-data'}})


    };

    return (
        <div>
            <form onSubmit={(e) => onFileUpload(e)}>
            <input type="file" onChange={onFileChange} />
            <button type='submit'>
                Upload!
            </button>
</form>
            
        </div>
    );
}

export default ScanReceipt;