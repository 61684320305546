import React from "react";

//Default PFP
import FollowedYou from "./types/FollowedYou";
import DefaultNotification from "./types/DefaultNotification";

const NotificationManager = ({ notification, index, isLast }) => {
  const determineNotification = () => {
    if (notification.type === "follow") {
      return <FollowedYou notification={notification} index={index} isLast={isLast} />;
    } else {
      return <DefaultNotification notification={notification} index={index} isLast={isLast} />;
    }
  };

  return determineNotification();
};

export default NotificationManager;
