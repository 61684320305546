import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
//MUI Styling
import { Typography, Button, IconButton, TextField, InputAdornment } from "@mui/material";

//Actions
import { setType, setQuery } from "../../../actions/search";

//Icons
import { ReactComponent as ChevronLeft } from "../../../assets/icons/svg/chevron-left.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/svg/search.svg";

const Search = ({ search: { query }, setType, setQuery }) => {
  const navigate = useNavigate();

  const handleRecipesClick = () => {
    navigate("/cookbook");
  };

  const handleQueryChange = (value) => {
    if (query.type === "trending") {
      setType("profiles");
    }

    setQuery(value);
  };

  const handleBackClick = () => {
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    return () => {
      //Reset search variables
      setType("trending");
      setQuery("");
    };
  }, []);

  return (
    <nav className="w-full min-h-[5.5rem] bg-background fixed top-0 border-b border-separator flex items-center justify-between z-[999] px-4 pb-2 pt-[calc(1rem+env(safe-area-inset-top))] md:px-8">
      <div className="h-full w-full flex items-center flex-col">
        <div className="flex flex-row items-start w-full">
          <div className="flex items-start h-full pt-[0.125rem]">
            <IconButton
              className="p-0 text-primaryText mr-4 hover:bg-transparent"
              onClick={handleBackClick}
              size="large"
            >
              <ChevronLeft className="h-[1.25rem] w-[1.25rem]" />
            </IconButton>
          </div>
          <div className="w-full pb-1">
            <TextField
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: "pb-0",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon className="h-[1.25rem] w-[1.25rem] fill-secondaryText mr-2" />
                  </InputAdornment>
                ),
              }}
              value={query.value}
              onChange={(e) => handleQueryChange(e.target.value)}
              placeholder="Search"
              fullWidth
              size="small"
              variant="standard"
            />
          </div>
        </div>
        <div className="flex flex-row items-start w-full pl-[2.25rem]">
          <div>
            <Button
              onClick={() => setType("trending")}
              className={
                query.type === "trending"
                  ? "text-primaryText pl-0 hover:bg-transparent"
                  : "text-secondaryText pl-0 hover:bg-transparent"
              }
              disableRipple
            >
              <Typography className={query.type === "trending" && "font-medium"}>Trending</Typography>
            </Button>
          </div>
          <div className="ml-2">
            <Button
              onClick={() => setType("profiles")}
              className={
                query.type === "profiles"
                  ? "text-primaryText pl-0 hover:bg-transparent"
                  : "text-secondaryText pl-0 hover:bg-transparent"
              }
              disableRipple
            >
              <Typography className={query.type === "profiles" && "font-medium"}>Profiles</Typography>
            </Button>
          </div>
          <div className="ml-2">
            <Button
              onClick={() => handleRecipesClick()}
              className={
                query.type === "recipes"
                  ? "text-primaryText pl-0 hover:bg-transparent"
                  : "text-secondaryText pl-0 hover:bg-transparent"
              }
              disableRipple
            >
              <Typography className={query.type === "recipes" && "font-medium"}>Recipes</Typography>
            </Button>
          </div>
        </div>
      </div>
    </nav>
  );
};

Search.propTypes = {
  search: PropTypes.object.isRequired,
  setType: PropTypes.func.isRequired,
  setQuery: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  search: state.search,
});

export default connect(mapStateToProps, { setType, setQuery })(Search);
