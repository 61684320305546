import api from "../api/api";

import store, { history } from "../store";

//Redux types
import {
  GET_MY_INGREDIENTS,
  MY_INGREDIENTS_ERROR,
  SEARCH_SINGLE_INGREDIENT,
  SEARCH_SINGLE_INGREDIENT_ERROR,
  RESET_SINGLE_INGREDIENT_SEARCH,
  START_SINGLE_INGREDIENT_SEARCH,
  SELECT_SINGLE_INGREDIENT_TO_ADD,
  ADD_SINGLE_INGREDIENT_TO_PANTRY,
  ADD_SINGLE_INGREDIENT_TO_PANTRY_ERROR,
  PANTRY_SELECT_ITEM_BY_ID,
  RESET_ADD_PANTRY,
} from "./types";

export const getMyIngredients = () => async (dispatch) => {
  try {
    const res = await api.get("/pantry/myitems");

    dispatch({
      type: GET_MY_INGREDIENTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: MY_INGREDIENTS_ERROR,
    });
  }
};

export const searchIngredientsByName = (name) => async (dispatch) => {
  try {
    if (name === "") {
      dispatch({
        type: RESET_SINGLE_INGREDIENT_SEARCH,
      });
      return;
    }
    dispatch({
      type: START_SINGLE_INGREDIENT_SEARCH,
    });
    const res = await api.get(`/ingredients/search/${name}`);

    dispatch({
      type: SEARCH_SINGLE_INGREDIENT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SEARCH_SINGLE_INGREDIENT_ERROR,
    });
  }
};

export const resetSingleIngredientSearch = () => (dispatch) => {
  dispatch({
    type: RESET_SINGLE_INGREDIENT_SEARCH,
  });
};

export const selectSingleItemToAdd = (item) => (dispatch) => {
  dispatch({
    type: SELECT_SINGLE_INGREDIENT_TO_ADD,
    payload: item,
  });
};

export const addSingleItemToPantry = (item) => async (dispatch) => {
  try {
    const res = await api.post("/pantry/add", item);

    dispatch({
      type: ADD_SINGLE_INGREDIENT_TO_PANTRY,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ADD_SINGLE_INGREDIENT_TO_PANTRY_ERROR,
    });
  }
};

export const pantrySelectItemById = (id, location) => (dispatch) => {
  const state = store.getState();
  const items = JSON.parse(JSON.stringify(state.pantry.items));

  const locationItems = items[location].items;
  const itemIndex = locationItems.findIndex((item) => item.pantryId === id);

  if (itemIndex !== -1) {
    locationItems[itemIndex] = {
      ...locationItems[itemIndex],
      selected: !locationItems[itemIndex].selected,
    };

    items.numSelected = items.numSelected + (locationItems[itemIndex].selected ? 1 : -1);
  }

  dispatch({
    type: PANTRY_SELECT_ITEM_BY_ID,
    payload: items,
  });
};

export const resetAddPantry = () => (dispatch) => {
  dispatch({
    type: RESET_ADD_PANTRY,
  });
};
