import React from "react";

import { Typography } from "@mui/material";

const PostTextContent = ({ text, username, hasImg = false }) => {
  return (
    <div className={`w-full break-words break-all px-4${hasImg ? " pt-4" : ""}`}>
      {username && <Typography variant="subtitle1" className="font-medium">{`@${username}`}</Typography>}
      <Typography variant="body1">{text}</Typography>
    </div>
  );
};

export default PostTextContent;
