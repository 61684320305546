import React, { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Spinner from "../layout/Spinner";

import Post from "../feed/Post";
import PostSkeleton from "../feed/PostSkeleton";

//Actions
import { getProfilePostData } from "../../actions/profile";

const ProfilePostManager = ({
  index,
  getProfilePostData,
  viewedProfile: { posts },
  viewedLoading,
  fullWidth = false,
}) => {
  useEffect(() => {
    if (!viewedLoading) {
      getProfilePostData(
        posts.posts[index].postid,
        index,
        posts.posts[index]?.repostuser ? posts.posts[index].repostuser : null
      );
    }
  }, []);

  return (
    <div className="md:mt-4">
      {posts.posts[index].loading ? (
        <div>
          <PostSkeleton />
        </div>
      ) : posts.posts[index].error ? null : (
        <Post post={posts.posts[index]} fullWidth={fullWidth} />
      )}
    </div>
  );
};

ProfilePostManager.propTypes = {
  getProfilePostData: PropTypes.func.isRequired,
  feed: PropTypes.object.isRequired,
  viewedProfile: PropTypes.object.isRequired,
  viewedLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  feed: state.feed,
  viewedProfile: state.profile.viewedProfile,
  viewedLoading: state.profile.viewedLoading,
});
export default connect(mapStateToProps, { getProfilePostData })(ProfilePostManager);
