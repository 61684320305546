import React, { useState, useEffect, Fragment } from "react";
import { styled } from "@mui/material/styles";
import { Fade, Zoom, Slide, Collapse } from "@mui/material";
import clsx from "clsx";

//Animation images
//Pantry 1
import pantry_1_1 from "../../img/pantry/1/receipt_scan.png";
import pantry_1_2 from "../../img/pantry/1/pantry.png";
import pantry_1_3 from "../../img/pantry/1/item.png";
//Pantry 2
import pantry_2_1 from "../../img/pantry/2/notification.png";
import pantry_2_2 from "../../img/pantry/2/ui.png";
import pantry_2_3 from "../../img/pantry/2/ui_2.png";
//Pantry 3
import pantry_3_1 from "../../img/pantry/3/pantry.png";
import pantry_3_2 from "../../img/pantry/3/recipe.png";
//Social
import social_1_1 from "../../img/social/post.png";
import social_1_2 from "../../img/social/messages.png";
import social_1_3 from "../../img/social/comment.png";

const PREFIX = "AnimationManager";

const classes = {
  container: `${PREFIX}-container`,
  root: `${PREFIX}-root`,
  img: `${PREFIX}-img`,
  img_small: `${PREFIX}-img_small`,
  img_large: `${PREFIX}-img_large`,
  shadow: `${PREFIX}-shadow`,
  pantry_1: `${PREFIX}-pantry_1`,
  pantry_2: `${PREFIX}-pantry_2`,
  pantry_3: `${PREFIX}-pantry_3`,
  social_1: `${PREFIX}-social_1`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.container}`]: {
    width: "100%",
    position: "relative",
    "&:before": {
      content: '""',
      display: "block",
      paddingTop: "100%",
    },
  },

  [`&.${classes.root}`]: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  [`& .${classes.img}`]: {
    transition: "opacity 0.3s",
    maxHeight: "60%",
    [theme.breakpoints.down("md")]: {
      maxHeight: "80%",
    },
  },

  [`& .${classes.img_small}`]: {
    maxHeight: "50%",
  },

  [`& .${classes.img_large}`]: {
    maxHeight: "80%",
    [theme.breakpoints.down("md")]: {
      maxHeight: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "100%",
    },
  },

  [`& .${classes.shadow}`]: {
    filter: `drop-shadow(${theme.palette.textOnWhite + "66"} 0.5rem 0.5rem 1rem)`,
  },

  [`& .${classes.pantry_1}`]: {
    position: "relative",
    width: "auto",
    height: "auto",
    borderRadius: 16,
    [theme.breakpoints.down("md")]: {
      borderRadius: 8,
    },
    "&.img0": {
      width: "32%",
      zIndex: 3,
      filter: `drop-shadow(${
        theme.palette.textOnWhite + "26" //26 = 15% Opactity
      } 0.25rem 0.25rem 0.5rem)`,
      [theme.breakpoints.down("md")]: {
        width: "20%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "32%",
      },
    },
    "&.img1": {
      width: "25%",
      zIndex: 2,
      left: "20px",
      filter: `drop-shadow(${
        theme.palette.textOnWhite + "26" //26 = 15% Opactity
      } 0.25rem 0.25rem 0.5rem)`,
      [theme.breakpoints.down("md")]: {
        width: "18%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "25%",
      },
    },
    "&.img2": {
      width: "25%",
      right: "20px",
      zIndex: 1,
      filter: `drop-shadow(${
        theme.palette.textOnWhite + "26" //26 = 15% Opactity
      } 0.25rem 0.25rem 0.5rem)`,
      [theme.breakpoints.down("md")]: {
        width: "18%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "25%",
      },
    },
  },

  [`& .${classes.pantry_2}`]: {
    position: "relative",
    width: "auto",
    height: "auto",
    "&.img0": {
      position: "absolute",
      top: "12%",
      left: "13%",
      width: "45%",
      zIndex: 4,
      filter: `drop-shadow(${
        theme.palette.textOnWhite + "26" //26 = 15% Opactity
      } 0.25rem 0.25rem 0.5rem)`,
      [theme.breakpoints.down("md")]: {
        width: "30%",
        top: "30%",
        left: "20%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "45%",
        top: "12%",
        left: "13%",
      },
    },
    "&.img1": {
      borderRadius: 16,
      width: "32%",
      left: "10px",
      zIndex: 3,
      filter: `drop-shadow(${
        theme.palette.textOnWhite + "26" //26 = 15% Opactity
      } 0.25rem 0.25rem 0.5rem)`,
      [theme.breakpoints.down("md")]: {
        width: "20%",
        borderRadius: 8,
      },
      [theme.breakpoints.down("sm")]: {
        width: "32%",
      },
    },
    "&.img2": {
      borderRadius: 16,
      width: "30%",
      right: "10px",
      zIndex: 2,
      filter: `drop-shadow(${
        theme.palette.textOnWhite + "26" //26 = 15% Opactity
      } 0.25rem 0.25rem 0.5rem)`,
      [theme.breakpoints.down("md")]: {
        width: "18%",
        borderRadius: 8,
      },
      [theme.breakpoints.down("sm")]: {
        width: "30%",
      },
    },
  },

  [`& .${classes.pantry_3}`]: {
    position: "relative",
    width: "auto",
    height: "auto",
    borderRadius: 16,
    "&.img0": {
      width: "32%",
      zIndex: 3,
      left: "10px",
      filter: `drop-shadow(${
        theme.palette.textOnWhite + "26" //26 = 15% Opactity
      } 0.25rem 0.25rem 0.5rem)`,
      [theme.breakpoints.down("md")]: {
        width: "20%",
        borderRadius: 8,
      },
      [theme.breakpoints.down("sm")]: {
        width: "32%",
      },
    },
    "&.img1": {
      width: "30%",
      zIndex: 2,
      right: "10px",
      filter: `drop-shadow(${
        theme.palette.textOnWhite + "26" //26 = 15% Opactity
      } 0.25rem 0.25rem 0.5rem)`,
      [theme.breakpoints.down("md")]: {
        width: "18%",
        borderRadius: 8,
      },
      [theme.breakpoints.down("sm")]: {
        width: "30%",
      },
    },
  },

  [`& .${classes.social_1}`]: {
    position: "relative",
    width: "auto",
    height: "auto",
    borderRadius: 16,
    [theme.breakpoints.down("md")]: {
      borderRadius: 8,
    },
    "&.img0": {
      width: "50%",
      zIndex: 2,
      filter: `drop-shadow(${
        theme.palette.textOnWhite + "26" //26 = 15% Opactity
      } 0.25rem 0.25rem 0.5rem)`,
      [theme.breakpoints.down("md")]: {
        width: "25%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "40%",
      },
    },
    //Messages
    "&.img1": {
      position: "absolute",
      bottom: "7%",
      transform: "translateX(75%)",
      width: "35%",
      zIndex: 3,
      filter: `drop-shadow(${
        theme.palette.textOnWhite + "26" //26 = 15% Opactity
      } 0.25rem 0.25rem 0.5rem)`,
      [theme.breakpoints.down("md")]: {
        width: "18%",
        bottom: "27%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "30%",
        bottom: "15%",
      },
    },
    //Comment
    "&.img2": {
      position: "absolute",
      transform: "translateX(-50%)",
      top: "15%",
      width: "40%",
      zIndex: 4,
      filter: `drop-shadow(${
        theme.palette.textOnWhite + "26" //26 = 15% Opactity
      } 0.25rem 0.25rem 0.5rem)`,
      [theme.breakpoints.down("md")]: {
        width: "25%",
        top: "32%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "40%",
        top: "20%",
      },
    },
  },
}));

const AnimationManager = ({ srcs, alts = [], animation = "fade", shadow, large = false }) => {
  const [currentSrc, setCurrentSrc] = useState(srcs);
  const [show, setShow] = useState(false);

  //Pantry 1
  const pantry1Default = [false, false, false];
  const [showPantry1, setShowPantry1] = useState(pantry1Default);
  const [hasShownP1, setHasShownP1] = useState(false);
  //Pantry 2
  const pantry2Default = [false, false, false];
  const [showPantry2, setShowPantry2] = useState(pantry2Default);
  const [hasShownP2, setHasShownP2] = useState(false);
  //Pantry 3
  const pantry3Default = [false, false];
  const [showPantry3, setShowPantry3] = useState(pantry3Default);
  const [hasShownP3, setHasShownP3] = useState(false);

  //Social 1
  const socialDefault = [false, false, false];
  const [showSocial, setShowSocial] = useState(socialDefault);
  const [hasShownSocial, setHasShownSocial] = useState(false);

  const clearShow = () => {
    setShow(false);
    setShowPantry1(pantry1Default);
    setShowPantry2(pantry2Default);
    setShowPantry3(pantry3Default);
    setShowSocial(socialDefault);
  };

  //Source change handler
  //If one image, just use simple fade.
  //If multiple images, do something dependent on animation

  //BUGS:
  //Image 1 showing when scrolling back?
  useEffect(() => {
    clearShow();

    if (!animation || animation === "fade") {
      // After the fade-out duration (300ms), change the src and fade in
      const fadeTimeout = setTimeout(() => {
        setCurrentSrc(srcs);
        setShow(true);
      }, 300);

      return () => clearTimeout(fadeTimeout);
    }
    if (animation === "pantry1") {
      setCurrentSrc(srcs);

      // After the fade-out duration (300ms), change the src and fade in
      const pantry1Timeout1 = setTimeout(() => {
        setShowPantry1((prev) => [true, prev[1], prev[2]]);
      }, 300);
      const pantry1Timeout2 = setTimeout(() => {
        setShowPantry1((prev) => [prev[0], true, prev[2]]);
      }, 1000);
      const pantry1Timeout3 = setTimeout(() => {
        setShowPantry1((prev) => [prev[0], prev[1], true]);
        setHasShownP1(true);
      }, 1700);

      return () => {
        clearTimeout(pantry1Timeout1);
        clearTimeout(pantry1Timeout2);
        clearTimeout(pantry1Timeout3);
      };
    }
    if (animation === "pantry2") {
      setCurrentSrc(srcs);

      // After the fade-out duration (300ms), change the src and fade in
      const pantry2Timeout1 = setTimeout(() => {
        setShowPantry2((prev) => [true, prev[1], prev[2]]);
      }, 300);
      const pantry2Timeout2 = setTimeout(() => {
        setShowPantry2((prev) => [prev[0], true, prev[2]]);
      }, 1000);
      const pantry2Timeout3 = setTimeout(() => {
        setShowPantry2((prev) => [prev[0], prev[1], true]);
        setHasShownP2(true);
      }, 1700);

      return () => {
        clearTimeout(pantry2Timeout1);
        clearTimeout(pantry2Timeout2);
        clearTimeout(pantry2Timeout3);
      };
    }
    if (animation === "pantry3") {
      setCurrentSrc(srcs);

      // After the fade-out duration (300ms), change the src and fade in
      const pantry3Timeout1 = setTimeout(() => {
        setShowPantry3((prev) => [true, prev[1]]);
      }, 300);
      const pantry3Timeout2 = setTimeout(() => {
        setShowPantry3((prev) => [prev[0], true]);
        setHasShownP3(true);
      }, 1000);

      return () => {
        clearTimeout(pantry3Timeout1);
        clearTimeout(pantry3Timeout2);
      };
    }
    if (animation === "social1") {
      setCurrentSrc(srcs);

      // After the fade-out duration (300ms), change the src and fade in
      const social1Timeout1 = setTimeout(() => {
        setShowSocial((prev) => [true, prev[1], prev[2]]);
      }, 300);
      const social1Timeout2 = setTimeout(() => {
        setShowSocial((prev) => [prev[0], true, prev[2]]);
      }, 2300);
      const social1Timeout3 = setTimeout(() => {
        setShowSocial((prev) => [prev[0], prev[1], true]);
        setHasShownSocial(true);
      }, 1200);

      return () => {
        clearTimeout(social1Timeout1);
        clearTimeout(social1Timeout2);
        clearTimeout(social1Timeout3);
      };
    }
  }, [srcs, animation]);

  const pantry1HTML = (
    <div className={classes.root}>
      <Fade in={showPantry1[1]} timeout={300}>
        <img className={`${classes.pantry_1} img1`} src={pantry_1_2} alt={""} />
      </Fade>
      <Fade in={showPantry1[0]} timeout={300}>
        <img className={`${classes.pantry_1} img0`} src={pantry_1_1} alt={""} />
      </Fade>
      <Fade in={showPantry1[2]} timeout={300}>
        <img className={`${classes.pantry_1} img2`} src={pantry_1_3} alt={""} />
      </Fade>
    </div>
  );

  const pantry2HTML = (
    <div className={classes.root}>
      <Zoom in={showPantry2[0]} timeout={300}>
        <img className={`${classes.pantry_2} img0`} src={pantry_2_1} alt={""} />
      </Zoom>
      <Slide in={showPantry2[1]} timeout={{ enter: 500, exit: 300 }} direction="left">
        <img className={`${classes.pantry_2} img1`} src={pantry_2_2} alt={""} direction="right" />
      </Slide>
      <Slide in={showPantry2[2]} timeout={{ enter: 500, exit: 300 }} direction="left">
        <img className={`${classes.pantry_2} img2`} src={pantry_2_3} alt={""} direction="right" />
      </Slide>
    </div>
  );

  const pantry3HTML = (
    <div className={classes.root}>
      <Fade in={showPantry3[0]} timeout={300}>
        <img className={`${classes.pantry_3} img0`} src={pantry_3_1} alt={""} />
      </Fade>
      <Fade in={showPantry3[1]} timeout={300}>
        <img className={`${classes.pantry_3} img1`} src={pantry_3_2} alt={""} />
      </Fade>
    </div>
  );

  const social1HTML = (
    <div className={classes.root}>
      <Fade in={showSocial[0]} timeout={300}>
        <img className={`${classes.social_1} img0`} src={social_1_1} alt={""} />
      </Fade>
      <Fade in={showSocial[1]} timeout={300}>
        <img className={`${classes.social_1} img1`} src={social_1_2} alt={""} />
      </Fade>
      <Fade in={showSocial[2]} timeout={300}>
        <img className={`${classes.social_1} img2`} src={social_1_3} alt={""} />
      </Fade>
    </div>
  );

  return (
    <div className={classes.container}>
      {!animation || animation === "fade" ? (
        <div className={classes.root}>
          {srcs.map((src, index) => (
            <Fade in={show} timeout={300}>
              <img
                key={index}
                className={
                  shadow
                    ? clsx(classes.img, classes.shadow)
                    : large
                    ? clsx(classes.img, classes.img_large)
                    : clsx(classes.img, classes.img_small)
                }
                src={currentSrc[index]}
                alt={alts[index]}
              />
            </Fade>
          ))}
        </div>
      ) : (
        <Fragment>
          {animation === "pantry1" && pantry1HTML}
          {animation === "pantry2" && pantry2HTML}
          {animation === "pantry3" && pantry3HTML}
          {animation === "social1" && social1HTML}
        </Fragment>
      )}
    </div>
  );
};

export default AnimationManager;
