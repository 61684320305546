//Utility functions for recipes

//Convert ingredient quantity (decimal) to fractional string
export const decimalToFraction = (decimal) => {
  if (typeof decimal === "string" && /^\d{1,3}\/\d{1,3}$/.test(decimal)) {
    return decimal;
  }

  decimal = Number(decimal);
  if (isNaN(decimal)) {
    return "1";
  }

  // Handle whole numbers with fractional parts
  const wholeNumber = Math.floor(decimal);
  const fractionalPart = decimal - wholeNumber;

  // If it's a whole number, return it as is
  if (fractionalPart === 0) {
    return wholeNumber.toString();
  }

  const tolerance = 1.0e-6;
  let bestNumerator = 1;
  let bestDenominator = 1;
  let minError = Math.abs(fractionalPart - bestNumerator / bestDenominator);

  // Rest of fraction calculation now works with just the fractional part
  for (let denominator = 1; denominator <= 100; ++denominator) {
    for (let numerator = 1; numerator <= denominator; ++numerator) {
      let error = Math.abs(fractionalPart - numerator / denominator);
      if (error < minError) {
        minError = error;
        bestNumerator = numerator;
        bestDenominator = denominator;
      }
    }
  }

  if (minError < tolerance) {
    const fractionPart = bestNumerator + "/" + bestDenominator;
    return wholeNumber ? `${wholeNumber} ${fractionPart}` : fractionPart;
  } else {
    // For decimals that are common repeating fractions in recipes
    const commonRepeatingFractions = {
      // Halves
      0.5: "1/2",
      // Thirds
      0.333: "1/3",
      0.666: "2/3",
      // Quarters
      0.25: "1/4",
      0.75: "3/4",
      // Sixths
      0.166: "1/6",
      0.833: "5/6",
      // Eighths
      0.125: "1/8",
      0.375: "3/8",
      0.625: "5/8",
      0.875: "7/8",
      // Sixteenths
      0.062: "1/16",
      0.187: "3/16",
      0.312: "5/16",
      0.437: "7/16",
      0.562: "9/16",
      0.687: "11/16",
      0.812: "13/16",
      0.937: "15/16",
    };
    const fractionPart = commonRepeatingFractions[fractionalPart.toFixed(3)];
    if (fractionPart) {
      return wholeNumber ? `${wholeNumber} ${fractionPart}` : fractionPart;
    }
    return decimal.toString();
  }
};
export const convertToTitleCase = (str) => {
  if (typeof str !== "string") {
    return str;
  }

  var i, j, lowers, uppers;
  str = str.replace(/([^\W_]+[^\s-]*) */g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

  // Certain minor words should be left lowercase unless
  // they are the first or last words in the string
  lowers = [
    "A",
    "An",
    "The",
    "And",
    "But",
    "Or",
    "For",
    "Nor",
    "As",
    "At",
    "By",
    "For",
    "From",
    "In",
    "Into",
    "Near",
    "Of",
    "On",
    "Onto",
    "To",
    "With",
  ];
  for (i = 0, j = lowers.length; i < j; i++)
    str = str.replace(new RegExp("\\s" + lowers[i] + "\\s", "g"), function (txt) {
      return txt.toLowerCase();
    });

  // Certain words such as initialisms or acronyms should be left uppercase
  uppers = ["Id", "Tv", "Bbq"];
  for (i = 0, j = uppers.length; i < j; i++)
    str = str.replace(new RegExp("\\b" + uppers[i] + "\\b", "g"), uppers[i].toUpperCase());

  return str;
};
