import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

//Components
import Spinner from "../layout/Spinner";
import PantryItem from "./PantryItem";

import { Checkbox, TextField, Button, IconButton, Typography } from "@mui/material";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";

function GroceryList({ pantry, location }) {
  const [searchTerm, setSearchTerm] = useState("");

  // Add sorting functions and search filter logic here

  return (
    <div className="w-full mt-4 h-full">
      <TextField
        id="search"
        label="Search..."
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        size="small"
        className="bg-backround rounded-lg"
      />
      <div className="flex flex-row justify-between">
        <Button>Name</Button>
        <Button>Use Within</Button>
      </div>
      <div className="custom-scrollbar h-[20rem] flex-1 overflow-y-auto w-full border border-separator rounded-lg bg-background">
        {pantry ? (
          pantry.items ? (
            pantry.items.loading ? (
              <Spinner />
            ) : pantry.items.error ? (
              <Typography>Error</Typography>
            ) : pantry.items[location].items.length > 0 ? (
              pantry.items[location].items.map((item, index) => (
                <PantryItem key={item._id} item={item} index={index} location={location} />
              ))
            ) : (
              <div className="flex flex-col items-center justify-center text-center px-4 py-8">
                <Typography variant="subtitle1">
                  No items in the {location}. Add items to get recipes, track your items
                </Typography>
              </div>
            )
          ) : (
            <Spinner />
          )
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
}

GroceryList.propTypes = {
  pantry: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  pantry: state.pantry,
});

export default connect(mapStateToProps)(GroceryList);
