import React, { useState, useRef } from "react";
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Typography } from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

//Actions
import { updateSectionIngredient } from "../../../../../actions/walkthrough";

const PREFIX = 'IngredientModal';

const classes = {
  root: `${PREFIX}-root`,
  amountRoot: `${PREFIX}-amountRoot`,
  ingredientRoot: `${PREFIX}-ingredientRoot`,
  amountRoot2: `${PREFIX}-amountRoot2`,
  unitText: `${PREFIX}-unitText`,
  confirmButton: `${PREFIX}-confirmButton`,
  verified: `${PREFIX}-verified`,
  verifiedIcon: `${PREFIX}-verifiedIcon`,
  editButtons: `${PREFIX}-editButtons`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    margin: theme.spacing(4),
    marginTop: theme.spacing(6),
  },

  [`& .${classes.amountRoot}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  [`& .${classes.ingredientRoot}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.amountRoot2}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.unitText}`]: {
    marginLeft: theme.spacing(2),
  },

  [`& .${classes.confirmButton}`]: {
    textTransform: "none",
  },

  [`& .${classes.verified}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: theme.spacing(2, 0),
    color: theme.palette.secondaryTextOnWhite,
  },

  [`& .${classes.verifiedIcon}`]: {
    color: theme.palette.mainGreen,
    height: "1.25rem",
    marginRight: theme.spacing(2),
  },

  [`& .${classes.editButtons}`]: {
    marginBottom: theme.spacing(2),
  }
}));

const IngredientModal = ({ section, sectionIndex, ingredient, index, updateSectionIngredient }) => {


  console.log(section, sectionIndex, ingredient, index);

  const [amount, setAmount] = useState(ingredient.unit.display.magnitude);

  const handleAmountChange = (event) => {
    updateSectionIngredient(sectionIndex, index, {
      ...ingredient,
      unit: { ...ingredient.unit, display: { ...ingredient.unit.display, magnitude: event.target.value } },
    });
  };

  const updateAmount = (value) => {
    updateSectionIngredient(sectionIndex, index, {
      ...ingredient,
      unit: { ...ingredient.unit, display: { ...ingredient.unit.display, magnitude: value } },
    });
  };

  //TO DO:
  //Only whole value buttons if self/count unit
  //Update value in redux
  return (
    <Root className={classes.root}>
      <div className={classes.ingredientRoot}>
        <Typography variant="h5" className={classes.ingredientName}>
          {ingredient.name.toTitleCase()}
        </Typography>
      </div>
      {ingredient.id && ingredient.unit.id && (
        <div className={classes.verified}>
          <FontAwesomeIcon icon={faCheckCircle} className={classes.verifiedIcon} />
          <Typography variant="body2">
            This ingredient will be subtracted from your on-hand ingredients. Edit the amount used below.
          </Typography>
        </div>
      )}
      <div className={classes.amountRoot}>
        <TextField value={amount} onChange={handleAmountChange} type="number" variant="outlined" size="small" />
        <Typography className={classes.unitText}>{ingredient.unit.text}</Typography>
      </div>
      <div className={classes.editButtons}>
        <ButtonGroup fullWidth aria-label="Amount toggle group" variant="outlined" disableElevation>
          <Button onClick={() => updateAmount(amount - 1)}>- 1</Button>
          <Button onClick={() => updateAmount(amount - 0.5)}>- 1/2</Button>
          <Button onClick={() => updateAmount(amount - 0.25)}>- 1/4</Button>
          <Button onClick={() => updateAmount(amount + 0.25)}>+ 1/4</Button>
          <Button onClick={() => updateAmount(amount + 0.5)}>+ 1/2</Button>
          <Button onClick={() => updateAmount(amount + 1)}>+ 1</Button>
        </ButtonGroup>
      </div>
      <div className={classes.confirmRoot}>
        <Button color="primary" variant="contained" disableElevation className={classes.confirmButton}>
          Update
        </Button>
      </div>
    </Root>
  );
};

IngredientModal.propTypes = {
  ingredient: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  walkthrough: state.walkthrough,
});

export default connect(mapStateToProps, { updateSectionIngredient })(IngredientModal);
