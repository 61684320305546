import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Link } from "react-router-dom";

import { motion } from "framer-motion";

//MUI
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Modal, Paper, IconButton, Typography, Button } from "@mui/material";

//Icons
import { ReactComponent as ExitIcon } from "../../../assets/icons/svg/exit.svg";

//Actions
import { toggleKitchenDashboardModal } from "../../../actions/navigation";

const KitchenDashboardModal = ({
  modals: { showKitchenDashboard },
  onClose,
  toggleKitchenDashboardModal,
  disableScrollLock = false,
}) => {
  const theme = useTheme();
  const isMediumOrLarger = useMediaQuery(theme.breakpoints.up("md"));

  const handleClose = () => {
    onClose();
  };

  const onNavigation = () => {
    toggleKitchenDashboardModal(false);
  };

  const animVariants = {
    hidden: { opacity: 0, y: "100%", x: "-50%" },
    visible: { opacity: 1, y: "0%", x: "-50%" },
    exit: { opacity: 0, y: "100%", x: "-50%" },
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showKitchenDashboard}
        onClose={handleClose}
        closeAfterTransition
        disableScrollLock={disableScrollLock}
      >
        <motion.div
          initial="hidden"
          animate={showKitchenDashboard ? "visible" : "hidden"}
          exit="exit"
          variants={animVariants}
          transition={{ duration: 0.175 }}
          className="fixed bottom-0 left-1/2 transform -translate-x-1/2 w-full max-w-[100vw] md:max-w-[600px] md:ml-[2.5rem]"
        >
          <Paper className="outline-none mx-auto w-full " elevation={0}>
            <div className="w-full bg-background md:rounded-t-[16px] border border-solid border-separator">
              <div className="w-full h-[2rem] flex items-center justify-between flex-row top-0 py-1 fixed px-3 md:px-4 mb-2 ">
                <div></div>
                <IconButton
                  aria-label="exit"
                  onClick={() => handleClose()}
                  className="text-none hover:bg-transparent px-0"
                  size="large"
                  disableRipple
                >
                  <ExitIcon className="h-[1rem] fill-primaryText" />
                </IconButton>
              </div>
              <div className="w-full min-w-0 min-h-[10vh] max-h-[95vh] overflow-y-auto mt-8 mb-8 md:mb-0">
                <Link to="/cookbook" onClick={() => onNavigation()}>
                  <Button className="w-full p-0">
                    <div className="w-full px-4 py-3 border-t border-separator">
                      <div className="flex items-start text-start">
                        <Typography variant="subtitle1" className="font-normal leading-tight">
                          Cookbook
                        </Typography>
                      </div>
                      <div className="flex items-start text-start">
                        <Typography variant="subtitle2" className="text-secondaryText leading-tight font-normal">
                          Find and manage your recipes
                        </Typography>
                      </div>
                    </div>
                  </Button>
                </Link>
                <Link to="/pantry" onClick={() => onNavigation()}>
                  <Button className="w-full p-0">
                    <div className="w-full px-4 py-3 border-t  border-separator">
                      <div className="flex items-start text-start">
                        <Typography variant="subtitle1" className="font-normal leading-tight">
                          Pantry<span className="italic text-secondaryText text-xs">&nbsp;- Coming Soon</span>
                        </Typography>
                      </div>
                      <div className="flex items-start text-start">
                        <Typography variant="subtitle2" className="text-secondaryText leading-tight font-normal">
                          View and manage your on-hand ingredients
                        </Typography>
                      </div>
                    </div>
                  </Button>
                </Link>
                <Link to="/insights" onClick={() => onNavigation()}>
                  <Button className="w-full p-0">
                    <div className="w-full px-4 py-3 border-t  border-separator">
                      <div className="flex items-start text-start">
                        <Typography variant="subtitle1" className="font-normal leading-tight">
                          Insights
                          <span className="italic text-secondaryText text-xs">&nbsp;- Coming Soon</span>
                        </Typography>
                      </div>
                      <div className="flex items-start text-start">
                        <Typography variant="subtitle2" className="text-secondaryText leading-tight font-normal">
                          View grocery expenses and nutritional insights
                        </Typography>
                      </div>
                    </div>
                  </Button>
                </Link>
              </div>
            </div>
          </Paper>
        </motion.div>
      </Modal>
    </div>
  );
};

KitchenDashboardModal.propTypes = {
  modals: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  modals: state.navigation.modals,
  toggleKitchenDashboardModal: PropTypes.func.isRequired,
});

export default connect(mapStateToProps, { toggleKitchenDashboardModal })(KitchenDashboardModal);
