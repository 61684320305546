import React, { useEffect, useState, useRef } from "react";

import { useMediaQuery } from "@mui/material";
import Collapse from "@mui/material/Collapse";

//Components
import TChart from "./TChart";

//Resources
import ai_large_1 from "../img/ai_large_1.jpg";
import ai_large_2 from "../img/ai_large_2.jpg";
import ai_large_3 from "../img/ai_3.jpeg";

//MUI
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const AI = ({ expanded = true, showRegisterModal }) => {
  const sectionRef = useRef(null);
  //Breakpoints
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const scrollToTop = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  //Expand all sections by default to ensure it is as readable as possible by SEO without SSR. Monitor the SEO for this page
  const [section1Open, setSection1Open] = useState(true);
  const [section2Open, setSection2Open] = useState(expanded);
  const [section3Open, setSection3Open] = useState(expanded);

  const [activeImage, setActiveImage] = useState(0);

  const images = [ai_large_1, ai_large_2, ai_large_3];

  const handleToggle1 = () => {
    if (!section1Open) {
      setSection2Open(false);
      setSection3Open(false);
      setActiveImage(0);
    }

    setSection1Open(!section1Open);
    scrollToTop();
  };

  const handleToggle2 = () => {
    if (!section2Open) {
      setSection1Open(false);
      setSection3Open(false);
      setActiveImage(1);
    }

    setSection2Open(!section2Open);
    scrollToTop();
  };

  const handleToggle3 = () => {
    if (!section3Open) {
      setSection2Open(false);
      setSection1Open(false);
      setActiveImage(2);
    }

    setSection3Open(!section3Open);
    scrollToTop();
  };

  useEffect(() => {
    if (!expanded) {
      setSection1Open(true);
      setSection2Open(false);
      setSection3Open(false);
      setActiveImage(0);
    }
  }, [expanded]);

  return (
    <div className="w-full" ref={sectionRef}>
      <div className="flex flex-col text-primaryText md:flex-row">
        <div className="w-full bg-[#faf3e0] p-0 xl:pr-[5%] xl:pl-[10%] md:px-8 md:py-20 md:w-1/2 ">
          <div>
            <Button
              className="w-full flex flex-row justify-between items-center text-left text-primaryText p-8 sm:p-8 rounded-none md:py-4 md:px-0"
              onClick={handleToggle1}
              aria-expanded={section1Open}
              disableRipple
            >
              <Typography variant="h5" className="font-normal">
                Where AI meets 'Al Dente'
              </Typography>
              {section1Open ? (
                <div>
                  <FontAwesomeIcon icon={faChevronUp} />
                </div>
              ) : (
                <div>
                  <FontAwesomeIcon icon={faChevronDown} />
                </div>
              )}
            </Button>
            <Collapse in={section1Open}>
              {isSmallScreen && (
                <div className="w-full flex flex-row md:flex-col md:w-1/2">
                  <div className="flex-1 relative overflow-hidden">
                    <img
                      src={images[0]}
                      className="relative h-full w-full left-0 transform-none object-cover md:absolute md:left-1/2 md:transform md:-translate-x-1/2"
                    ></img>
                  </div>
                </div>
              )}
              <div className="block">
                <Typography variant="body1" component="p" className="font-normal m-8 md:m-0 text-secondaryText-600">
                  Fear not the rise of machines, especially when they're here to serve you dinner. Our AI Integration
                  tailors recipes to your taste, ingredients, and skill level. And for those who want more, our premium
                  version even factors in nutrition.
                </Typography>
                <div>
                  <TChart />
                </div>
                <div className="relative left-[-11px] mx-8 my-4 md:mx-0 md:my-4">
                  <Button
                    endIcon={<FontAwesomeIcon icon={faArrowRight} />}
                    classes={{
                      root: "relative pr-4 text-mainGreen",
                      startIcon: "absolute left-[1rem]",
                    }}
                    size="large"
                    onClick={() => showRegisterModal()}
                  >
                    Get Started
                  </Button>
                </div>
              </div>
            </Collapse>
          </div>
          <div className="border-solid border-t border-b border-primaryText/40">
            <Button
              className="w-full flex flex-row justify-between items-center text-left text-primaryText p-8 sm:p-8 rounded-none md:py-4 md:px-0"
              onClick={handleToggle2}
              aria-expanded={section2Open}
              disableRipple
            >
              <Typography variant="h5" className="font-normal">
                Custom Culinary Insights
              </Typography>
              {section2Open ? (
                <div>
                  <FontAwesomeIcon icon={faChevronUp} />
                </div>
              ) : (
                <div>
                  <FontAwesomeIcon icon={faChevronDown} />
                </div>
              )}
            </Button>
            <Collapse in={section2Open}>
              {isSmallScreen && (
                <div className="w-full flex flex-row md:flex-col md:w-1/2">
                  <div className="flex-1 relative overflow-hidden">
                    <img
                      src={images[1]}
                      className="relative h-full w-full left-0 transform-none object-cover md:absolute md:left-1/2 md:transform md:-translate-x-1/2"
                    ></img>
                  </div>
                </div>
              )}
              <div className="block">
                <Typography variant="body1" component="p" className="font-normal m-8 md:m-0 text-secondaryText-600">
                  Ever wondered what pairs best with that grilled chicken? Or which drink complements your pasta? Dive
                  into our ingredient and recipe pairing feature. It's like having a sommelier and a chef, all in one.
                </Typography>

                <div className="relative left-[-11px] mx-8 my-4 md:mx-0 md:my-4">
                  <Button
                    endIcon={<FontAwesomeIcon icon={faArrowRight} />}
                    classes={{
                      root: "relative pr-4 text-mainGreen",
                      startIcon: "absolute left-[1rem]",
                    }}
                    size="large"
                    onClick={() => showRegisterModal()}
                  >
                    Discover Now
                  </Button>
                </div>
              </div>
            </Collapse>
          </div>
          <div>
            <Button
              className="w-full flex flex-row justify-between items-center text-left text-primaryText p-8 sm:p-8 rounded-none md:py-4 md:px-0"
              onClick={handleToggle3}
              aria-expanded={section3Open}
              disableRipple
            >
              <Typography variant="h5" className="font-normal">
                A Guide in Gourmet
              </Typography>
              {section3Open ? (
                <div>
                  <FontAwesomeIcon icon={faChevronUp} />
                </div>
              ) : (
                <div>
                  <FontAwesomeIcon icon={faChevronDown} />
                </div>
              )}
            </Button>
            <Collapse in={section3Open}>
              {isSmallScreen && (
                <div className="w-full flex flex-row md:flex-col md:w-1/2">
                  <div className="flex-1 relative overflow-hidden">
                    <img
                      src={images[2]}
                      className="relative h-full w-full left-0 transform-none object-cover md:absolute md:left-1/2 md:transform md:-translate-x-1/2"
                    ></img>
                  </div>
                </div>
              )}
              <div className="block">
                <Typography variant="body1" component="p" className="font-normal m-8 md:m-0 text-secondaryText-600">
                  For our premium users, we introduce Recipe Generation. Watch as recipes get crafted before your eyes
                  and are tailored to your preferences. A pixelated masterpiece, just for you.
                </Typography>
                <div className="relative left-[-11px] mx-8 my-4 md:mx-0 md:my-4">
                  <Button
                    endIcon={<FontAwesomeIcon icon={faArrowRight} />}
                    classes={{
                      root: "relative pr-4 text-mainGreen",
                      startIcon: "absolute left-[1rem]",
                    }}
                    size="large"
                    onClick={() => showRegisterModal()}
                  >
                    Explore
                  </Button>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
        {!isSmallScreen && (
          <div className="w-full flex flex-row md:flex-col md:w-1/2">
            <div className="flex-1 relative overflow-hidden">
              <img
                src={images[activeImage]}
                className="relative h-full w-full left-0 transform-none object-cover md:absolute md:left-1/2 md:transform md:-translate-x-1/2"
              ></img>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AI;
