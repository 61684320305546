import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";

import Footer from "../layout/Footer";

import { Helmet } from "react-helmet";

const Privacy = ({ auth: { isAuthenticated } }) => {
  return (
    <div className="w-full">
      <Helmet>
        <title>thisPantry - Privacy Policy</title>
      </Helmet>
      <div className=" h-full  overflow-y-scroll">
        <div
          className={`px-8 lg:px-32 xl:px-64 min-h-[100vh] ${
            isAuthenticated ? "my-[5rem] md:ml-[5rem] md:mt-8" : "my-8"
          }`}
        >
          <div>
            <Typography variant="h5">Privacy and Data Policy</Typography>
          </div>
          <div className="my-4">
            <Typography variant="body1">
              thisPantry is compliant with the Canadian federal Personal Information and Protection of Privacy Act.
              thisPantry collects personal information ("Personal Information", which is defined as name, email address,
              username) in order to provide its services and products to you, to improve its services and products, and
              to communicate with you.
            </Typography>
          </div>
          <div>
            <Typography variant="body1">
              thisPantry does not directly sell any Personal Information to third parties, but thisPantry does use
              Google Analytics and Google AdSense. You can learn more about the Google Privacy Policy{" "}
              <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
                here
              </a>
              .
            </Typography>
          </div>
          <div className="my-4">
            <Typography variant="body1">
              On thisPantry, all posts, profiles, and recipes are public information. Information identified within
              thisPantry as private, such as date of birth, will not be made public unless shared by you.
            </Typography>
          </div>
          <div className="my-4">
            <Typography variant="body1">
              thisPantry will store and maintain your data as long as you have a thisPantry account. thisPantry reserves
              the right to unilaterally change its Data Policy, and will notify you by email of any changes to its Data
              Policy.
            </Typography>
          </div>
        </div>

        <Footer></Footer>
      </div>
    </div>
  );
};

Privacy.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Privacy);
