export const getCroppedImg = async (imageSrc, pixelCrop) => {
  const image = new Image();

  image.src = imageSrc;
  await new Promise((resolve) => {
    image.onload = resolve;
  });

  const canvas = document.createElement("canvas");
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext("2d");

  // Draw the image on the canvas at the negative left/top positions
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  // Convert the canvas to a Blob
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        // reject the Promise if the canvas couldn't be converted to a Blob
        reject(new Error("Canvas is empty"));
        return;
      }
      blob.name = "newFile.jpeg";
      // Create a URL for the blob
      const croppedImageUrl = URL.createObjectURL(blob);
      resolve(croppedImageUrl);
    }, "image/jpeg");
  });
};
