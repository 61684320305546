import React from "react";

import { Link } from "react-router-dom";

//Utils
import { shortInteractionCount } from "../../../../utils/post";

//Icons
import { ReactComponent as HeartIcon } from "../../../../assets/icons/svg/heart.svg";
import { ReactComponent as CommentIcon } from "../../../../assets/icons/svg/comment.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/svg/leaf-solid.svg";

import { Typography } from "@mui/material";
const StandardGridText = ({ post }) => {
  return (
    <div className="relative w-full h-0 pb-[100%] border border-separator rounded-lg shadow-sm bg-background">
      <Link to={`/${post.user.username}/post/${post.id}`}>
        <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-col">
          <div className="p-4 flex flex-col justify-between flex-1 h-full box-border">
            <div className="flex-grow break-words overflow-hidden overflow-ellipsis mb-4">
              <Typography>{post.text}</Typography>
            </div>
            <div>
              <div className="flex flex-row items-center">
                <Typography variant="subtitle1" className="text-mainGreen">{`@${post.user.username}`}</Typography>
                {post && post?.user?.isPlus && <PlusIcon className="h-[1rem] ml-1 fill-mainGreen" />}
              </div>

              <div className="flex flex-row items-center">
                <div className="flex flex-row items-center">
                  <HeartIcon className="h-[1rem] mr-2" />
                  <Typography>{shortInteractionCount(post.interactions.likesCount)}</Typography>
                </div>
                <div className="flex flex-row items-center ml-4">
                  <CommentIcon className="h-[1rem] mr-2" />
                  <Typography>{shortInteractionCount(post.interactions.commentsCount)}</Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default StandardGridText;
