import { v4 } from "uuid";
import { SET_ALERT, REMOVE_ALERT } from "./types";

export const setAlert =
  (msg, alertType, timeout = 5000, action = false, onClose = "self", refresh = false) =>
  (dispatch) => {
    //Get random unique id
    const id = v4();

    if (onClose) {
      if (onClose === "self") {
        onClose = () => dispatch({ type: REMOVE_ALERT, payload: id });
      }
    }
    //Call SetAlert reducer with type and payload object
    dispatch({
      type: SET_ALERT,
      payload: { msg, alertType, id, action, onClose },
    });

    // After a set timeout, remove the alert (after timeout in ms, do arrow function)
    setTimeout(() => {
      dispatch({
        type: REMOVE_ALERT,
        payload: id,
      });
      if (refresh) {
        window.location.reload();
      }
    }, timeout);
  };

export const removeAlert = (id) => (dispatch) => {
  dispatch({
    type: REMOVE_ALERT,
    payload: id,
  });
};
