import React from "react";
import { Typography } from "@mui/material";
import { Badge } from "../../../../ui/badge";

const RecipeAllergens = ({ allergens }) => {
  //To DO:
  //- allergen disclaimer, always check product labels and verify ingredients for allergens
  //- if list empty, say "none listed"

  return (
    <div className="mt-4">
      <div>
        <Typography variant="subtitle1" className="text-primaryText font-sans font-normal leading-tight">
          Allergens:
        </Typography>
      </div>
      <div className="my-2">
        {allergens.contains.length > 0 && (
          <div className="mb-2">
            <Typography variant="subtitle2" className=" mb-1 leading-tight font-normal text-secondaryText-600 text-xs">
              Contains:
            </Typography>
            <div>
              {allergens.contains.map((allergen, i) => {
                return (
                  <Badge
                    variant="secondary"
                    className="font-normal mr-[0.25rem] mb-[0.25rem] py-1 px-3 shadow-sm cursor-pointer border-secondaryText-200"
                  >
                    <Typography variant="body2" className="text-xs">
                      {allergen.text}
                    </Typography>
                  </Badge>
                );
              })}
            </div>
          </div>
        )}
        {allergens.mayContain.length > 0 && (
          <div className="mb-2">
            <Typography variant="subtitle2" className=" mb-1 leading-tight font-normal text-secondaryText-600 text-xs">
              May Contain:
            </Typography>
            <div>
              {allergens.mayContain.map((allergen, i) => {
                return (
                  <Badge
                    variant="secondary"
                    className="font-normal mr-[0.25rem] mb-[0.25rem] py-1 px-3 shadow-sm cursor-pointer border-secondaryText-200"
                  >
                    <Typography variant="body2" className="text-xs">
                      {allergen.text}
                    </Typography>
                  </Badge>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div className="mb-2">
        <Typography variant="subtitle2" className="font-normal text-secondaryText text-xs leading-snug">
          Always check product labels and verify ingredients for allergens.
        </Typography>
      </div>
    </div>
  );
};

export default RecipeAllergens;
