import React, { useState, useEffect } from "react";
import RecipeIngredientsSection from "./RecipeIngredientsSection";

const RecipeIngredients = ({ recipe }) => {
  return (
    <div>
      <div className="pt-6 pb-2">
        {recipe.sections.map((section, i) => {
          if (section.ingredients.length === 0) return null;
          console.log(section);
          return <RecipeIngredientsSection key={i} section={section} />;
        })}
      </div>
    </div>
  );
};

export default RecipeIngredients;
