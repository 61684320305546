import React from "react";

//Components
import StandardPostGridManager from "./StandardPostGridManager";

const StandardPostGrid = ({ posts }) => {
  return (
    <div className="grid gap-2 md:gap-4 mt-2 md:mt-4 [grid-template-columns:repeat(auto-fit,minmax(266px,1fr))] ">
      {posts.map((post) => (
        <StandardPostGridManager key={post.id} post={post} />
      ))}
    </div>
  );
};

export default StandardPostGrid;
