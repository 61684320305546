import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { TextField, Button, Chip } from "@mui/material";

import { setAlert } from "../../../../actions/alert";

const RecipeSectionTags = ({ setAlert, tags, setTags }) => {
  const [tagInput, setTagInput] = useState("");

  const handleTagInputChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.endsWith(",")) {
      const newTag = inputValue.slice(0, -1).trim();
      if (newTag !== "" && newTag.length <= 30) {
        // Check if the new tag already exists
        if (!tags.some((tag) => tag.toLowerCase() === newTag.toLowerCase())) {
          // Check the length of the new tag
          if (tags.length < 20) {
            setTags([...tags, newTag]);
          } else {
            setAlert("You can only add up to 20 tags.", "warning");
          }
        } else {
          setAlert("Tag already added!", "warning");
        }
        setTagInput("");
      } else if (newTag.length > 30) {
        // If the tag is too long, show an alert
        setAlert("Tags must be 30 characters or less.", "warning");
      }
    } else {
      setTagInput(inputValue);
    }
  };

  const handleTagSubmit = () => {
    if (tagInput.trim() !== "") {
      const newTags = tagInput
        .split(",")
        .map((tag) => tag.trim())
        .filter((tag) => {
          if (tag.length > 30) {
            // If the tag is too long, show an alert and filter it out
            setAlert("Tags must be 30 characters or less.", "warning");
            return false;
          }
          if (tag.length === 0) {
            setAlert("A tag cannot be empty", "warning");
            return false;
          }
          // Check if the new tag already exists
          if (tags.some((existingTag) => existingTag.toLowerCase() === tag.toLowerCase())) {
            setAlert("Tag already added!", "warning");
            return false;
          }
          return true;
        });
      if (tags.length + newTags.length > 20) {
        setAlert("You can only add up to 20 tags.", "warning");
      } else {
        setTags([...tags, ...newTags]);
      }
      setTagInput("");
    }
  };
  const handleTagDelete = (index) => {
    const newTags = tags.filter((tag, i) => i !== index);
    setTags(newTags);
  };

  return (
    <div
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          handleTagSubmit();
        }
      }}
    >
      <div className="w-full flex flex-row">
        <TextField
          label="Tags"
          variant="outlined"
          value={tagInput}
          onChange={handleTagInputChange}
          helperText="Add up to 20 tags to help users find your recipe, such as the course or recipe type. For example, breakfast, drink, or vegetarian."
        />
        <Button variant="outlined" onClick={handleTagSubmit} className="h-[56px] ml-1 font-normal">
          Add&nbsp;Tag
        </Button>
      </div>

      <div className="mt-2">
        {tags.map((tag, index) => (
          <Chip key={index} label={tag} className="mb-1 mr-1" color="primary" onDelete={() => handleTagDelete(index)} />
        ))}
      </div>
    </div>
  );
};

RecipeSectionTags.propTypes = {
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { setAlert })(RecipeSectionTags);
