import React from "react";
import { Typography } from "@mui/material";
import ItemsList from "./ItemsList";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const PantrySection = ({ location, title, pantry }) => {
  const getExpiryText = () => {
    //Get the most recent non-zero expiry period
    const expiry = pantry?.items[location]?.expiry;

    if (expiry) {
      if (expiry.today > 0) {
        return `${expiry.today} item${expiry.today > 1 ? "s" : ""} expiring today`;
      }
      if (expiry.tomorrow > 0) {
        return `${expiry.tomorrow} item${expiry.tomorrow > 1 ? "s" : ""} expiring tomorrow`;
      }
      if (expiry.week > 0) {
        return `${expiry.week} item${expiry.week > 1 ? "s" : ""} expiring this week`;
      }
      if (expiry.month > 0) {
        return `${expiry.month} item${expiry.month > 1 ? "s" : ""} expiring this month`;
      }
      return "0 items expiring soon";
    } else {
      return "0 items expiring soon";
    }
  };

  return (
    <div className="border-separator border-y md:border md:rounded-lg md:shadow-sm bg-background">
      <div className="bg-background p-4 pb-0 md:p-6 md:pt-4 md:pb-0 md:rounded-t-lg">
        <Typography variant="h6" className="font-normal mb-2">
          {title}
        </Typography>

        {pantry && pantry?.items[location] && (
          <div className="flex flex-col">
            <Typography variant="subtitle2" className="text-secondaryText-700 font-normal text-sm">
              {getExpiryText()}
            </Typography>
            <Typography variant="subtitle1" className="text-secondaryText-600 leading-none text-sm">
              <span className="text-mainGreen font-semibold text-lg">${pantry.items[location].value}</span> in items
            </Typography>
          </div>
        )}
      </div>

      <div className="p-4 md:p-6 pt-0 md:pt-0">
        <ItemsList location={location} />
      </div>
    </div>
  );
};

PantrySection.propTypes = {
  location: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  pantry: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  pantry: state.pantry,
});

export default connect(mapStateToProps)(PantrySection);
