import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { pluralize } from "inflection";

//Actions
import { pantrySelectItemById } from "../../actions/pantry";

import { Typography, Button, IconButton, Checkbox } from "@mui/material";
import { Checkbox as sCheckbox } from "../ui/checkbox";

//Components
import PantryItemModal from "./PantryItemModal";
import CustomModal from "../modal/CustomModal";

//Utility Functions
import { convertToTitleCase } from "../../utils/recipe";

const PantryItem = ({ item, index, location, pantrySelectItemById }) => {
  const [showModal, setShowModal] = useState(false);

  const formatItemName = () => {
    let name = item.name;
    let quantity = "";

    if (item.amount && item.amount.unit) {
      if (item.amount.unit.type === "self" || item.amount.unit.type === "count") {
        // Show quantity and unit
        quantity = item.amount.quantity + " ";
      }
    }

    // Pluralize name if quantity is greater than 1
    if (quantity > 1) {
      name = pluralize(name);
    }

    return `${quantity}${convertToTitleCase(name)}`.trim();
  };

  const formatDate = (expiry) => {
    if (expiry && expiry.date) {
      const now = new Date();
      const expiryDate = new Date(expiry.date);
      const diffTime = expiryDate - now;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      let expiryText = "-";
      let textColor = "text-secondaryText-700";

      if (diffDays <= 0) {
        expiryText = "0d";
        textColor = "text-rose-600";
      } else if (diffDays === 1) {
        expiryText = "1d";
        textColor = "text-rose-600";
      } else if (diffDays <= 3) {
        expiryText = `${diffDays}d`;
        textColor = "text-amber-600";
      } else if (diffDays <= 7) {
        expiryText = `${diffDays}d`;
      } else if (diffDays <= 30) {
        const weeks = Math.floor(diffDays / 7);
        expiryText = `${weeks}w`;
      } else if (diffDays <= 365) {
        const months = Math.floor(diffDays / 30);
        expiryText = `${months}m`;
      } else {
        const years = Math.floor(diffDays / 365);
        expiryText = `${years}y`;
      }
      return (
        <div className="h-full flex items-center">
          <Typography variant="subtitle1" className={`leading-snug text-sm ${textColor}`}>
            {expiryText}
          </Typography>
        </div>
      );
    } else {
      return (
        <div className="h-full flex items-center">
          <Typography variant="subtitle1" className="leading-snug text-secondaryText-600 mr-2 text-sm">
            -
          </Typography>
        </div>
      );
    }
  };

  return (
    <div className={`${index % 2 === 0 ? "bg-background" : "bg-neutral-100"} py-[0.0625rem]`}>
      <CustomModal open={showModal} handleClose={() => setShowModal(false)} type="bottom">
        <PantryItemModal item={item} location={location} />
      </CustomModal>
      <div className="pr-4 pl-2 my-2 h-[2.45rem]">
        <div className="h-full flex flex-row items-center">
          <div className="flex-shrink-0 mr-2">
            <Checkbox
              value={item.selected}
              onClick={() => pantrySelectItemById(item.pantryId, location)}
              disableRipple
              className="py-0"
            />
          </div>
          <div className="flex-grow min-w-0 flex flex-col">
            <div className="truncate">
              <Typography variant="subtitle1" className="leading-snug font-medium truncate">
                {formatItemName()}
              </Typography>
            </div>
            {item && item.amount && item.amount.unit && item.amount.unit.type !== "count" && (
              <Typography variant="subtitle2" className="leading-snug font-normal text-xs text-mainGreen-600 truncate">
                {`${item.amount.unitMagnitude} ${
                  item.amount.unit.shortName ? item.amount.unit.shortName : item.amount.unit.displayName
                } (${item.amount.percentage}%)`}
              </Typography>
            )}
          </div>
          <div className="flex-shrink-0 ml-2">{formatDate(item.expiry)}</div>
        </div>
      </div>
    </div>
  );
};

PantryItem.propTypes = {
  item: PropTypes.object.isRequired,
  location: PropTypes.string.isRequired,
  pantrySelectItemById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { pantrySelectItemById })(PantryItem);
