import React, { useState } from "react";
import { Button, Typography, Divider } from "@mui/material";
import RecipeCard from "../../recipes/card/RecipeCard";

import { ReactComponent as PantryIcon } from "../../../assets/icons/svg/carrot-filled.svg";

//Utils
import { decimalToFraction, convertToTitleCase } from "../../../utils/recipe";
const formatAmountUnit = (ingredient) => {
  const unit = ingredient.unit.display;
  let formattedAmount = "";
  let formattedUnit = "";

  if (unit && unit.magnitude && unit.magnitude !== "") {
    formattedAmount = decimalToFraction(unit.magnitude);
  }

  if (unit && unit.text !== "" && unit.text !== "not_exact" && unit.text !== "self" && unit.type !== "unknown") {
    formattedUnit = unit.text;
  }

  let showSpace = false;

  if (formattedAmount !== "" && formattedUnit !== "") {
    showSpace = true;
  }

  return `${formattedAmount} ${formattedUnit}${showSpace ? " " : ""}`;
};

const formatIngredient = (ingredient) => {
  //Convert ingredient data to a string for the top line
  let formattedName =
    ingredient.name && ingredient.name !== "" ? convertToTitleCase(ingredient.name) : "Unknown Ingredient";

  return `${formattedName}`;
};

const formatState = (ingredient) => {
  let formattedState = "";

  if (ingredient.state && ingredient.state !== "") {
    formattedState = ingredient.state.toTitleCase();
  }

  if (formattedState !== "") {
    return `, ${formattedState}`;
  }
};

const MacroEvent = ({ data }) => {
  return (
    <div className="w-full border border-separator rounded-md shadow-sm bg-background flex flex-col md:flex-row">
      <div className="w-full md:w-[250px] flex flex-col justify-end md:items-end bg-neutral-100 px-4 md:px-8 py-4 rounded-md">
        <Typography variant="h6" className="leading-snug font-normal">
          {data.name}
        </Typography>
        <Typography variant="caption" className="font-normal text-secondaryText-600 leading-none mt-1">
          Macro Event
        </Typography>
      </div>
      <div className="flex flex-1 flex-col">
        <div>
          {data.macros && (
            <div className="py-4 px-4 md:px-8">
              <div>
                <Typography variant="subtitle1" className="font-normal leading-snug">
                  Nutrition Information:
                </Typography>
              </div>
              <div className="flex flex-row justify-between mb-2">
                <Typography variant="caption" className="text-secondaryText-600">
                  Servings: {data.servings}
                </Typography>
                <Typography variant="caption" className="text-secondaryText-600">
                  Serving Size: {data.servingSize}
                </Typography>
              </div>
              {data.macros.map((macro, i) => (
                <div className="flex flex-row justify-between items-center" key={`${data.name}-${macro.name}-${i}`}>
                  <Typography variant="subtitle2" className="font-normal">
                    {macro.name}
                  </Typography>
                  <div className="flex-1 border-b border-separator mx-2"></div>
                  <div className="flex flex-row">
                    <Typography variant="body2">{macro.amount}</Typography>
                    {macro.unit && (
                      <Typography variant="body2" className="ml-2">
                        {macro.unit}
                      </Typography>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const RecipeEvent = ({ data }) => {
  return (
    <div className="w-full border border-separator rounded-md shadow-sm bg-background flex flex-col md:flex-row">
      <div className="bg-neutral-100 rounded-md hidden md:block">
        <div className="w-[250px] ">
          <RecipeCard recipeid={data.recipe.id} rounded="rounded-md" />
        </div>
      </div>
      <div className="bg-neutral-100 rounded-md md:hidden p-4">
        <Typography variant="h6" className="leading-snug font-normal">
          {data.recipe.name}
        </Typography>
        <Typography variant="caption" className="font-normal text-secondaryText-600 leading-none">
          {data.recipe.username ? `@${data.recipe.username}` : ""}
        </Typography>
      </div>
      <div className="flex flex-1 flex-col px-4 md:px-8 pt-4">
        <div></div>
        <div>
          {data.macrosTotal && (
            <div>
              <div>
                <Typography variant="subtitle1" className="font-normal leading-snug">
                  Nutrition Information:
                </Typography>
              </div>
              <div className="flex flex-row justify-between mb-2">
                <Typography variant="caption" className="text-secondaryText-600">
                  Servings: {data.servings}
                </Typography>
                <Typography variant="caption" className="text-secondaryText-600">
                  Serving Size: {data.servingSize}
                </Typography>
              </div>
              {data.macrosTotal.map((macro, i) => (
                <div className="flex flex-row justify-between items-center" key={`${data.name}-${macro.name}-${i}`}>
                  <Typography variant="subtitle2" className="font-normal">
                    {macro.name}
                  </Typography>
                  <div className="flex-1 border-b border-separator mx-2"></div>
                  <div className="flex flex-row">
                    <Typography variant="body2">{macro.amount}</Typography>
                    {macro.unit && (
                      <Typography variant="body2" className="ml-2">
                        {macro.unit}
                      </Typography>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
          {data.ingredients.length > 0 && (
            <div className="flex flex-row items-center gap-2 mt-4">
              <PantryIcon className="w-3 fill-mainGreen" />
              <Typography variant="caption" className="font-normal text-secondaryText-600">
                {data.ingredients.length} ingredients used
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const MealSectionItem = ({ course, data }) => {
  return <div>{data.recipe ? <RecipeEvent data={data} /> : <MacroEvent data={data} />}</div>;
};

export default MealSectionItem;
