//Import redux types
import { TOGGLE_CREATE_MODAL, TOGGLE_KITCHEN_DASHBOARD_MODAL } from "../actions/types";

//Initial state object
const initialState = {
  modals: {
    showCreate: false,
    showKitchenDashboard: false,
  },
};

export default function (state = initialState, action) {
  //Destructure action
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_CREATE_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          showCreate: payload,
        },
      };
    case TOGGLE_KITCHEN_DASHBOARD_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          showKitchenDashboard: payload,
        },
      };
    default:
      return state;
  }
}
