import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { ReactComponent as EditIcon } from "../../../../assets/icons/svg/edit.svg";

import { ListItem, Typography } from "@mui/material";

//Modals
import CustomModal from "../../../modal/CustomModal";
import EditDirectionModal from "./EditDirectionModal";

const RecipeSectionStep = ({ step, sectionIndex, stepIndex }) => {
  //TO DO:
  //Hover to show edit icon
  //Click to open edit modal
  //Display tip under step

  //Hover state
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  //Modal state
  const [showEditStepModal, setShowEditStepModal] = useState(false);

  const handleClick = () => {
    setShowEditStepModal(true);
  };

  const handleModalClose = () => {
    setShowEditStepModal(false);
  };

  return (
    <ListItem className="list-item cursor-pointer hover:text-secondaryText-600 marker:text-mainGreen py-1 px-2">
      <CustomModal open={showEditStepModal} handleClose={handleModalClose}>
        <EditDirectionModal
          sectionIndex={sectionIndex}
          stepIndex={stepIndex}
          step={step}
          handleClose={handleModalClose}
        />
      </CustomModal>
      <div onClick={handleClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div className="inline-flex items-center">
          <Typography variant="body1" component="p">
            {step.text}
          </Typography>
        </div>
        {step.tips && step.tips.length > 0 && (
          <div className="mt-1 text-secondaryText">
            <Typography>Tip:</Typography>
            <Typography>{step.tips[0]}</Typography>
          </div>
        )}
      </div>
    </ListItem>
  );
};

export default RecipeSectionStep;
