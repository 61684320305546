import api from "../api/api";

import store, { history } from "../store";
import { setAlert } from "./alert";

//Redux types
import {
  IMAGE_UPLOAD_ADD,
  IMAGE_UPLOAD_REMOVE,
  IMAGE_UPLOAD_ERROR,
  IMAGE_UPLOAD_RESET,
  IMAGE_UPLOAD_CROP,
  IMAGE_UPLOAD_OPEN_CROP,
  IMAGE_UPLOAD_CLOSE_CROP,
  IMAGE_UPLOAD_CROP_ERROR,
  IMAGE_UPLOAD_REORDER,
  SET_ORIGINAL_IMAGES,
} from "./types";

export const setOriginalImages = (images) => (dispatch) => {
  console.log(images);
  dispatch({
    type: SET_ORIGINAL_IMAGES,
    payload: images,
  });
};

export const addImageToUpload = (image) => (dispatch) => {
  dispatch({
    type: IMAGE_UPLOAD_ADD,
    payload: image,
  });
};

export const removeImageFromUpload = (index) => (dispatch) => {
  dispatch({
    type: IMAGE_UPLOAD_REMOVE,
    payload: index,
  });
  dispatch({
    type: IMAGE_UPLOAD_CLOSE_CROP,
  });
  dispatch(setAlert("Image removed", "success"));
};

export const cropImageInUpload = (index, croppedImageData) => (dispatch) => {
  dispatch({
    type: IMAGE_UPLOAD_CROP,
    payload: { index, croppedImageData },
  });
};

export const reorderImagesInUpload = (images) => (dispatch) => {
  dispatch({
    type: IMAGE_UPLOAD_REORDER,
    payload: images,
  });
};

export const imageUploadReset = () => (dispatch) => {
  console.log("IMAGE RESET");
  dispatch({
    type: IMAGE_UPLOAD_RESET,
  });
};

export const openImageCropModal = (index) => (dispatch) => {
  dispatch({
    type: IMAGE_UPLOAD_OPEN_CROP,
    payload: index,
  });
};

export const closeImageCropModal = () => (dispatch) => {
  dispatch({
    type: IMAGE_UPLOAD_CLOSE_CROP,
  });
};
