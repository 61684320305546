import React from "react";

const ReceiptScan = () => {
  return (
    <div>
      <h1>Welcome to ReceiptScan!</h1>
      <p>This is the ReceiptScan component.</p>
    </div>
  );
};

export default ReceiptScan;
