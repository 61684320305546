import React, { useEffect, useState, useRef, Fragment } from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import { ReactComponent as Icon } from "../../assets/logos/full_logo.svg";

//Material UI
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
//Components
import VideoComponent from "./sections/VideoComponent";
import RegisterModal from "../auth/RegisterModal";
import LoginModal from "../auth/login/LoginModal";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";

//Sections
import SplitShowcase from "./sections/SplitShowcase";
import InteractiveSpend from "./sections/InteractiveSpend";
import Footer from "../layout/Footer";
import AI from "./sections/AI";
import Savings from "./sections/Savings";
import Hero from "./sections/Hero";
import LandingNav from "../navigation/sections/Landing";
import FramerSplitShowcase from "./sections/FramerSplitShowcase";

import { motion } from "framer-motion";

//Resources
//---------
//Background Image
import kitchen_bg from "./img/0_2.png";
//Pantry - 1
import Pantry1_ReceiptScan from "./img/pantry/1/receipt_scan.png";
//Pantry - 2
import Pantry2_Notification from "./img/pantry/2/notification.png";
import Pantry2_UI from "./img/pantry/2/ui.png";
import Pantry2_UI_2 from "./img/pantry/2/ui_2.png";
//Pantry - 3
import P_3_pantry from "./img/pantry/3/pantry.png";
import P_3_recipe from "./img/pantry/3/recipe.png";
//AI - 1
//AI - 2
//AI - 3
import videoSrc from "./pan.mp4";
import ai_1 from "./img/ai_1.png";
import ai_3 from "./img/ai_3.jpeg";

//Setup
//import setupImg from "./img/setup.png";
import setupImg from "./img/ai_large_1.jpg";

//Social
import Social_1 from "./img/social/post.png";
import Social_2 from "./img/social/comment.png";
import Social_3 from "./img/social/messages.png";

//Charity
import Charity_Img from "./img/pledge.jpg";
//Redux Actions
import { showLoginModal, showRegisterModal, hideRegisterModal, hideLoginModal, logout } from "../../actions/auth";

const Landing = ({ isAuthenticated, modals, showLoginModal, showRegisterModal, logout }) => {
  //Breakpoints
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  //Carousel
  const slideData = [
    {
      key: 1,
      content: <img src={ai_1} alt="1" />,
    },
    {
      key: 2,
      content: <img src="https://i.imgur.com/xA6yXMh.png" alt="2" />,
    },
    {
      key: 3,
      content: <img src={ai_1} alt="3" />,
    },
    {
      key: 4,
      content: <img src="https://i.imgur.com/xA6yXMh.png" alt="4" />,
    },
    {
      key: 5,
      content: <img src={ai_1} alt="5" />,
    },
  ];

  //Section data
  const sections = {
    pantry: [
      {
        images: [Pantry1_ReceiptScan, Pantry1_ReceiptScan],
        title: "The Tastebud Tech",
        text: "In a world where technology often complicates, we introduce a solution that simplifies. Presenting the tastebud tech where your grocery receipts transform into a digital pantry. No more guessing what's left in your kitchen; scan and know.",
        cta: "Get Started",
        animation: "pantry1",
      },
      {
        images: [Pantry2_Notification, Pantry2_UI, Pantry2_UI_2],
        alts: ["", "", ""],
        title: "Reduce Waste, Not Flavor",
        text: "Ever found an expired ingredient just when you needed it? Get notified with useful recipes that use ingredients that are about to expire. A delightful way to ensure nothing goes to waste.",
        cta: "Dive In",
        animation: "pantry2",
      },
      {
        images: [P_3_pantry, P_3_recipe],
        title: "Sync, Spice, Sizzle",
        text: "Why search for recipes when they can come to you? With our on-hand ingredients feature, get recipes tailored to what you already have. It's like having a personal chef who knows your pantry inside out.",
        cta: "Spice It Up",
        animation: "pantry3",
      },
    ],
    ai: [
      {
        images: [ai_1],
        title: 'Where AI meets "Al Dente"',
        text: "Fear not the rise of machines, especially when they're here to serve you dinner. Our AI Integration tailors recipes to your taste, ingredients, and skill level. And for those who want more, our premium version even factors in nutrition.",
        cta: "Learn More",
      },
      {
        images: ["https://creazilla-store.fra1.digitaloceanspaces.com/cliparts/3868181/food-pantry-clipart-sm.png"],
        title: "Custom Culinary Insights",
        text: "Ever wondered what pairs best with that grilled chicken? Or which drink complements your pasta? Dive into our ingredient and recipe pairing feature. It's like having a sommelier and a chef, all in one.",
        cta: "Learn More",
      },
      {
        images: [ai_3],
        title: "A Guide in Gourmet",
        text: "For our premium users, we introduce Recipe Generation. Watch as recipes get crafted before your eyes, tailored to your preferences. A pixelated masterpiece, just for you.",
        cta: "Learn More",
      },
    ],
    savings: [
      {
        images: ["https://cdn-icons-png.flaticon.com/512/8131/8131880.png"],
        title: "Don't Let Inflation Cook/Outpace Inflation",
        text: "With our Saving Dashboard, track your expenses and see how much you save over time. And for those keen on reducing waste, we've got you covered. Slice down that moldy bread and watch your waste reduce.",
        cta: "Learn More",
      },
    ],
    social: [
      {
        images: [Social_1, Social_2, Social_3],
        title: "The Ultimate Foodie Forum",
        text: "Share your favorite recipes, get tips from renowned chefs, and even support them directly. It's not just a platform; it's a community. Sharpen your blade, fix your whisk, and dive into a world of culinary delights.",
        cta: "Join Now",
        alts: ["A recipe post of classic chicken tacos", "5 star review of the tacos", "comment on the tacos"],
        animation: "social1",
      },
    ],
    setup: [
      {
        images: [setupImg],
        title: "A Private Chef, In Your Pocket",
        text: "Setting up is a breeze. Define your dietary preferences, scan your receipts, and let us do the rest. And for those on the go, scan barcodes in partnered stores for automatic updates and personalized savings.",
        cta: "Explore",
      },
    ],
    charity: [
      {
        images: [Charity_Img],
        title: "Reducing Waste, On and Off the Screens",
        text: "At thisPantry, we believe in giving back. That's why we pledge to give 1% of our profits back to the community. It's our way of ensuring that while we reduce waste on screens, we also make a tangible difference in the real world.",
        cta: "Learn More About Pledge 1%",
      },
    ],
  };

  const onRegisterModalClose = () => {
    hideRegisterModal();
  };

  const onLoginModalClose = () => {
    hideLoginModal();
  };
  //Scroll into position
  const scrollRef = useRef(null);

  //Detect scroll position for:
  //Nav bar -> Change color to white when scrolling past hero section
  //Auto close AI sections for SEO
  const navbarRef = useRef(null);
  const heroRef = useRef(null);

  const [navIsGreen, setNavIsGreen] = useState(true);
  const [expandAI, setExpandAI] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (!navbarRef.current || !heroRef.current) return;

      const navbarBottom = navbarRef.current.getBoundingClientRect().bottom + window.scrollY;
      const contentBottom = heroRef.current.getBoundingClientRect().bottom + window.scrollY;

      if (navbarBottom > contentBottom) {
        setNavIsGreen(false);
      } else {
        setNavIsGreen(true);
      }

      //AI section auto contract
      if (expandAI) {
        if (window.scrollY > 0) {
          setExpandAI(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  /*


        <div className={classes.carousel_container}>
          <Carousel
            slides={slideData}
            className={classes.carousel}
            offsetRadius={1}
          />
        </div>

        */

  /*
 <InteractiveSpend scrollToPantry={scrollToPantry} />
        */
  return (
    <div className="w-full h-fit max-w-[100vw] overflow-x-hidden">
      <Helmet>
        <title>thisPantry - Use What You Choose</title>
      </Helmet>
      <RegisterModal />
      <LoginModal />
      <div className="relative">
        <Hero scrollRef={scrollRef} />
      </div>
      <div className="bg-white z-20 h-fit ">
        <div ref={scrollRef}>
          <FramerSplitShowcase
            animation="pantry1"
            title={sections.pantry[0].title}
            text={sections.pantry[0].text}
            cta={sections.pantry[0].cta}
          />
        </div>

        <FramerSplitShowcase
          animation="pantry2"
          title={sections.pantry[1].title}
          text={sections.pantry[1].text}
          cta={sections.pantry[1].cta}
        />
        <FramerSplitShowcase
          animation="pantry3"
          title={sections.pantry[2].title}
          text={sections.pantry[2].text}
          cta={sections.pantry[2].cta}
        />
        <AI expanded={expandAI} showRegisterModal={showRegisterModal} />
        <Savings expanded={expandAI} showRegisterModal={showRegisterModal} />

        <FramerSplitShowcase
          animation="social1"
          title={sections.social[0].title}
          text={sections.social[0].text}
          cta={sections.social[0].cta}
          imagesOn="right"
        />
        <FramerSplitShowcase
          animation="full"
          imagesOn="left"
          padding={false}
          fadeSrc={sections.setup[0].images[0]}
          title={sections.setup[0].title}
          text={sections.setup[0].text}
          cta={sections.setup[0].cta}
        />
        <FramerSplitShowcase
          animation="charity"
          imagesOn="right"
          padding={false}
          fadeSrc={sections.charity[0].images[0]}
          title={sections.charity[0].title}
          text={sections.charity[0].text}
          cta={sections.charity[0].cta}
        />
        <VideoComponent videoSrc={videoSrc} quote="Simply. Savour. Savings." showRegisterModal={showRegisterModal} />
        <Footer />
      </div>
    </div>
  );
};

Landing.propTypes = {
  isAuthenticated: PropTypes.bool,
  modals: PropTypes.object.isRequired,
  showLoginModal: PropTypes.func.isRequired,
  showRegisterModal: PropTypes.func.isRequired,
  hideLoginModal: PropTypes.func.isRequired,
  hideRegisterModal: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

//Bring in state (Map to props)
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  modals: state.auth.modals,
});

export default connect(mapStateToProps, {
  showLoginModal,
  showRegisterModal,
  hideLoginModal,
  hideRegisterModal,
  logout,
})(Landing);
