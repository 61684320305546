import React from "react";

//UI Components
import { motion, AnimatePresence } from "framer-motion";
import lettuce from "../img/lettuce2.png";

const visible = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.5,
  },
};

const Hero = ({ scrollRef }) => {
  const handleViewMoreClick = () => {
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="h-[100vh] w-full bg-radial-gradient">
      <div>
        <AnimatePresence>
          <motion.div
            initial="hidden"
            animate="visible"
            exit={{ opacity: 0, transition: { duration: 1 } }}
            id="hero-content"
            className="w-full h-full overflow-hidden font-bold"
          >
            <div className="w-full h-full rotate-[-45deg]">
              <div className="w-full h-full bg-gradient-shadow"></div>
            </div>
            <div className="absolute bottom-0 left-0 z-10 h-full pt-[7rem] md:h-fit md:pt-[5rem] flex flex-col justify-between w-full">
              <motion.div
                variants={{
                  hidden: { opacity: 0, y: -30 },
                  visible,
                }}
                className="pr-8 md:pr-16 pl-10 md:pl-[4.5rem] w-full"
              >
                <h1 className="text-7xl text-background font-serif font-normal md:font-bold italic">Use what</h1>
                <h1 className="text-8xl text-background font-serif font-normal md:font-bold italic">
                  you <span className="pr-4">choose.</span>
                </h1>
              </motion.div>
              <motion.div
                variants={{
                  hidden: { opacity: 0, y: 30 },
                  visible,
                }}
                className="px-8 pb-8 md:px-16 md:pb-16 w-full"
              >
                <h4 className="text-xl text-stone-200 font-sans max-w-[400px] md:mt-12 font-normal">
                  From forgotten groceries to front-row flavors. Unearth the treasures hidden in your own kitchen.
                </h4>
                <button onClick={() => handleViewMoreClick()}>
                  <h6 className="text-stone-200 font-normal font-sans italic mt-2 text-xl hover:underline">
                    Find Out How
                  </h6>
                </button>
              </motion.div>
            </div>
            <div className=" absolute bottom-0 right-0">
              <motion.div
                variants={{
                  hidden: { opacity: 0, y: 50 },
                  visible,
                }}
              >
                <div>
                  <img src={lettuce} className="z-0 relative max-h-[50vh] px-4 md:px-8" />
                </div>
              </motion.div>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Hero;
