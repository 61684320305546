import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Typography, Button, IconButton, TextField, Divider } from "@mui/material";

//Actions
import { deleteSection, editSectionInfo } from "../../../../actions/createrecipe";

//Icons
import { ReactComponent as PlusIcon } from "../../../../assets/icons/svg/leaf-solid.svg";
import { ReactComponent as ChevronDown } from "../../../../assets/icons/svg/chevron-down.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/icons/svg/trash.svg";
import { ReactComponent as TrashCheckIcon } from "../../../../assets/icons/svg/trash-check.svg";

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../../ui/dropdown-menu";
import { Button as ShadcnButton } from "../../../ui/button";

const RecipeEditSectionModal = ({ section, sectionIndex, deleteSection, editSectionInfo, handleClose }) => {
  const [title, setTitle] = useState(section.title);
  const [source, setSource] = useState(section.source);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const [deleteAreYouSure, setDeleteAreYouSure] = useState(false);

  const handleDelete = () => {
    deleteSection(sectionIndex);
    handleClose();
  };

  const handleSave = (e) => {
    //Stop higher forms from firing
    e.stopPropagation();
    e.preventDefault();
    let success = editSectionInfo(sectionIndex, title.trim(), source);

    if (success) {
      handleClose();
    }
  };

  const handleAddSource = () => {
    setSource({
      text: "",
      url: "",
      type: "Add a source",
    });
  };

  const handleRemoveSource = () => {
    setSource(null);
  };

  const handleSourceTypeChange = (value) => {
    if (value === "Add a source") {
      handleRemoveSource();
    } else {
      setSource({
        ...source,
        type: value,
      });
    }
  };

  const handleSourceTextChange = (e) => {
    setSource({
      ...source,
      text: e.target.value,
    });
  };

  const handleSourceUrlChange = (e) => {
    setSource({
      ...source,
      url: e.target.value,
    });
  };

  return (
    <div className="mx-4 mb-4">
      <form onSubmit={(e) => handleSave(e)}>
        <div className="flex flex-row gap-4 items-center">
          <Typography variant="h6" className="font-normal leading-none">
            Edit Section
          </Typography>
          {deleteAreYouSure ? (
            <IconButton onClick={() => handleDelete()} className="p-0 hover:bg-transparent" disableRipple>
              <TrashCheckIcon className="h-4 fill-red" />
            </IconButton>
          ) : (
            <IconButton onClick={() => setDeleteAreYouSure(true)} className="p-0 hover:bg-transparent" disableRipple>
              <TrashIcon className="h-4 fill-red" />
            </IconButton>
          )}
        </div>
        <div className="my-4">
          <TextField value={title} onChange={(e) => handleTitleChange(e)} variant="outlined" fullWidth label="Title" />
        </div>
        <div>
          {source ? (
            <div>
              <div className="flex flex-col gap-3">
                <Divider className="my-2" />
                <div className="mb-2">
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <ShadcnButton
                        variant="outline"
                        className={`w-full justify-between font-normal rounded-lg border-secondaryText-200 hover:border-primaryText font-sans text-md`}
                        disabled={source.ref}
                      >
                        {source && source.type}
                        <ChevronDown className="w-3 opacity-50" />
                      </ShadcnButton>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-[var(--radix-dropdown-menu-trigger-width)] rounded-[8px] z-[1301]">
                      <DropdownMenuItem
                        onSelect={() => handleSourceTypeChange("Add a source")}
                        className="text-secondaryText-600/90"
                      >
                        Remove Source
                      </DropdownMenuItem>
                      <DropdownMenuItem onSelect={() => handleSourceTypeChange("Inspired by")}>
                        Inspired by
                      </DropdownMenuItem>
                      <DropdownMenuItem onSelect={() => handleSourceTypeChange("Adapted from")}>
                        Adapted from
                      </DropdownMenuItem>
                      <DropdownMenuItem onSelect={() => handleSourceTypeChange("Based on")}>Based on</DropdownMenuItem>
                      <DropdownMenuItem onSelect={() => handleSourceTypeChange("A take on")}>
                        A take on
                      </DropdownMenuItem>
                      <DropdownMenuItem onSelect={() => handleSourceTypeChange("Found in")}>Found in</DropdownMenuItem>
                      <DropdownMenuItem onSelect={() => handleSourceTypeChange("Handed down from")}>
                        Handed down from
                      </DropdownMenuItem>
                      <DropdownMenuItem onSelect={() => handleSourceTypeChange("Influenced by")}>
                        Influenced by
                      </DropdownMenuItem>
                      <DropdownMenuItem onSelect={() => handleSourceTypeChange("Generated with")}>
                        Generated with
                      </DropdownMenuItem>
                      <DropdownMenuItem onSelect={() => handleSourceTypeChange("Imported from")}>
                        Imported from
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
                <div>
                  <TextField
                    value={source.text}
                    onChange={(e) => handleSourceTextChange(e)}
                    variant="outlined"
                    fullWidth
                    label="Source Text"
                    size="small"
                    helperText="Name of the recipe source"
                    disabled={source.ref}
                  />
                </div>
                <div>
                  <TextField
                    value={source.url}
                    onChange={(e) => handleSourceUrlChange(e)}
                    variant="outlined"
                    fullWidth
                    label="Source URL"
                    size="small"
                    helperText="Add a link to the recipe source"
                    disabled={source.ref}
                  />
                </div>
                {source.ref && (
                  <Typography
                    variant="subtitle2"
                    className="text-secondaryText-600 italic text-xs leading-snug font-normal"
                  >
                    This source was automatically generated when importing or generating a recipe and cannot be edited
                  </Typography>
                )}
              </div>
            </div>
          ) : (
            <Button
              onClick={() => handleAddSource()}
              className="px-0 hover:bg-transparent text-secondaryText font-normal"
              disableRipple
            >
              <Typography variant="subtitle2" className="font-normal">
                + Add a Source
              </Typography>
            </Button>
          )}
        </div>
        <div className="flex flex-row justify-between mt-2">
          <Button variant="contained" color="primary" disableElevation type="submit" fullWidth>
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

RecipeEditSectionModal.propTypes = {
  section: PropTypes.object.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  deleteSection: PropTypes.func.isRequired,
  editSectionInfo: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { deleteSection, editSectionInfo })(RecipeEditSectionModal);
