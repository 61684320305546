import api from "../api/api";

//Redux types
import {
  SET_SIGNUP_STAGE,
  SET_REGISTER_ERRORS,
  CLEAR_SIGNUP,
  SIGNUP_PREFERENCES_ERROR,
  SIGNUP_PREFERENCES_NEXT,
  SIGNUP_RESTRICTIONS_ERROR,
  SIGNUP_RESTRICTIONS_NEXT,
  SIGNUP_ABILITY_ERROR,
  SIGNUP_ABILITY_NEXT,
} from "./types";

//Set Signup Stage
export const setSignupStage = (value) => (dispatch) => {
  if (value === -1) {
    dispatch({
      type: CLEAR_SIGNUP,
    });
  } else {
    dispatch({
      type: SET_SIGNUP_STAGE,
      payload: value,
    });
  }
};

//Set Register Errors
export const setRegisterErrors = (stage, errors) => (dispatch) => {
  dispatch({
    type: SET_REGISTER_ERRORS,
    payload: {
      stage,
      errors,
    },
  });
};

//Dietary Restrictions
export const restrictionsNext = (data) => async (dispatch) => {
  try {
    //Verify data
    let { allergens, diets, dislikes } = data;

    console.log(allergens, diets);
    if (allergens.length > 30) {
      dispatch(setRegisterErrors("restrictions", { allergens: { isValid: false, error: "Limit of 30 allergens" } }));
    }

    if (diets.length > 30) {
      dispatch(setRegisterErrors("restrictions", { diets: { isValid: false, error: "Limit of 30 special diets" } }));
    }

    let reqData = { allergens: [], diets: [], dislikes: [], stage: 1 };

    console.log(allergens, diets, dislikes);

    for (let i = 0; i < allergens.length; i++) {
      if (allergens[i].name.length > 128) {
        allergens[i].name = allergens[i].name.substring(0, 128);
      }

      reqData.allergens[i] = allergens[i].name;
    }

    for (let i = 0; i < diets.length; i++) {
      if (diets[i].name.length > 128) {
        diets[i].name = diets[i].name.substring(0, 128);
      }

      reqData.diets[i] = diets[i].name;
    }

    for (let i = 0; i < dislikes.length; i++) {
      if (!dislikes[i].name) {
        console.log(dislikes);
      }
      if (dislikes[i].name.length > 128) {
        dislikes[i].name = dislikes[i].name.substring(0, 128);
      }

      reqData.dislikes[i] = dislikes[i].name;
    }

    const res = await api.post("/users/signup", reqData);

    dispatch({
      type: SIGNUP_RESTRICTIONS_NEXT,
      payload: {
        allergens: reqData.allergens,
        diets: reqData.diets,
        dislikes: reqData.dislikes,
      },
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: SIGNUP_RESTRICTIONS_ERROR,
    });
    dispatch(
      setRegisterErrors("restrictions", {
        general: { isValid: false, error: "An error occurred. Please refresh the page and try again." },
      })
    );
  }
};
//Cooking Ability
export const abilityNext = (data) => async (dispatch) => {
  try {
    //Verify data
    let { ability } = data;

    if (ability === -1) {
      dispatch(setRegisterErrors("ability", { general: { isValid: false, error: "Must select one" } }));
    }

    let reqData = { ability, stage: 3 };

    const res = await api.post("/users/signup", reqData);

    dispatch({
      type: SIGNUP_ABILITY_NEXT,
      payload: {
        ability: reqData.ability,
      },
    });
  } catch (error) {
    console.log(error);

    dispatch({
      type: SIGNUP_ABILITY_ERROR,
    });
    dispatch(
      setRegisterErrors("ability", {
        general: { isValid: false, error: "An error occurred. Please refresh the page and try again." },
      })
    );
  }
};

//Dietary Preferences
export const preferencesNext = (data) => async (dispatch) => {
  try {
    //Verify data
    let { notifications } = data;

    const res = await api.post("/users/signup", { notifications, stage: 2 });

    dispatch({
      type: SIGNUP_PREFERENCES_NEXT,
      payload: {
        notifications,
      },
    });
  } catch (error) {
    console.log(error);

    dispatch({
      type: SIGNUP_PREFERENCES_ERROR,
    });
    dispatch(
      setRegisterErrors("preferences", {
        general: { isValid: false, error: "An error occurred. Please refresh the page and try again." },
      })
    );
  }
};
