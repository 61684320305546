import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

//UI
import { Typography, Grid, Paper, Button, IconButton } from "@mui/material";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../ui/card";
import { ReactComponent as LinkIcon } from "../../assets/icons/svg/link.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/svg/search.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/svg/trash.svg";
//Components
import ItemsList from "./ItemsList";
import PantrySection from "./PantrySection";

//Actions
import { getMyIngredients, resetAddPantry } from "../../actions/pantry";

//Modals
import CustomModal from "../modal/CustomModal";
import Initial from "./modals/add_items/Initial";
import ComingSoonModal from "../comingsoon/ComingSoonModal";

const PantryHome = ({ pantry, getMyIngredients, resetAddPantry }) => {
  //Get pantry items
  useEffect(() => {
    getMyIngredients();
  }, []);

  //Modal State
  const [showAddItem, setShowAddItem] = useState(false);
  const [showSelectedOptions, setShowSelectedOptions] = useState(false);
  const [numSelected, setNumSelected] = useState(0);

  const handleExit = () => {
    setShowAddItem(false);
    resetAddPantry();
  };

  const navigate = useNavigate();
  const handleCloseComingSoon = () => {
    navigate("/");
  };

  const checkShowSelectedOptions = () => {
    let selectedCount = 0;

    const countSelectedItems = (section) => {
      for (let i = 0; i < pantry.items[section].items.length; i++) {
        if (pantry.items[section].items[i].selected) {
          selectedCount++;
        }
      }
    };

    // Count selected items in each section
    if (pantry && pantry?.items) {
      countSelectedItems("fridge");
      countSelectedItems("pantry");
      countSelectedItems("freezer");
      countSelectedItems("spicerack");

      if (selectedCount > 0) {
        setShowSelectedOptions(true);
        setNumSelected(selectedCount);
        return { show: true, value: selectedCount };
      } else {
        setShowSelectedOptions(false);
        setNumSelected(0);
        return { show: false, value: 0 };
      }
    }
    setShowSelectedOptions(false);
    setNumSelected(0);
    return { show: false, value: 0 };
  };

  useEffect(() => {
    checkShowSelectedOptions();
  }, [pantry.items]);

  return (
    <div className="min-h-[100vh] bg-neutral-50">
      <CustomModal open={showAddItem} handleClose={() => handleExit()}>
        <Initial />
      </CustomModal>
      <CustomModal
        open={true}
        showExit={false}
        handleClose={handleCloseComingSoon}
        rounded="rounded-2xl"
        border={false}
        type="bottom"
        parentClassNameOverride="md:min-w-[600px]"
      >
        <ComingSoonModal />
      </CustomModal>
      <div className="py-[4rem] md:py-0 md:pl-[5rem]">
        <div className="md:p-4">
          <div className="px-4 py-4 md:px-6 border-y border-separator md:border md:rounded-lg w-full bg-background -translate-y-[1px] flex flex-col gap-2 md:flex-row justify-between md:items-center">
            <Typography variant="h5">My Ingredients</Typography>
            <div className="flex flex-row gap-2">
              <Button
                variant="contained"
                color="secondary"
                size="small"
                disableElevation
                className="text-primaryText"
                onClick={() => setShowAddItem(true)}
              >
                +&nbsp;Add&nbsp;Items
              </Button>
              <Link to="/cookbook">
                <Button
                  variant="outlined"
                  disableElevation
                  size="small"
                  color="secondary"
                  className="bg-background border-secondaryText hover:border-secondaryText-600"
                  startIcon={<LinkIcon className="h-2 w-auto fill-primaryText" />}
                >
                  Recipes
                </Button>
              </Link>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-y-4 xl:grid-cols-2 mt-4 lg:gap-4 mb-[3rem]">
            <PantrySection location={"fridge"} title={"Refrigerator"} />
            <PantrySection location={"pantry"} title={"Pantry"} />
            <PantrySection location={"freezer"} title={"Freezer"} />
            <PantrySection location={"spicerack"} title={"Spice Rack"} />
          </div>
        </div>
      </div>
      {showSelectedOptions && (
        <div className="fixed w-screen bottom-0 py-2 bg-neutral-500/20 backdrop-blur-[2px] mb-[4rem] md:mb-0 z-20">
          <div className="flex flex-row gap-2 justify-center md:pl-[5rem]">
            <Button
              variant="contained"
              color="primary"
              startIcon={<SearchIcon className="h-4 w-auto fill-background" />}
            >
              Search for Recipes
            </Button>

            <Button
              variant="contained"
              color="secondary"
              className="bg-background hover:bg-neutral-100 border border-primaryText-300 hover:border-primaryText-600 min-w-fit"
            >
              <TrashIcon className="h-4 w-auto fill-primaryText" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

PantryHome.propTypes = {
  pantry: PropTypes.object.isRequired,
  getMyIngredients: PropTypes.func.isRequired,
  resetAddPantry: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  pantry: state.pantry,
});

export default connect(mapStateToProps, { getMyIngredients, resetAddPantry })(PantryHome);
