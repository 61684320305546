import React, { useRef, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link, Redirect, useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

//Components
import Helmet from "react-helmet";

import Navigation from "../navigation/Navigation";
import FeedManager from "../feed/FeedManager";
import Landing from "../landing/Landing";

//MUI
import Typography from "@mui/material/Typography";

import { setTokensFromCallback } from "../../actions/auth";

//DEV
import Post from "../feed/Post";

const PREFIX = "Home";

const classes = {
  container: `${PREFIX}-container`,
  nav_wrapper: `${PREFIX}-nav_wrapper`,
  feed: `${PREFIX}-feed`,
  stickySection: `${PREFIX}-stickySection`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.container}`]: {
    height: "100vh",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: 0,
      marginTop: "4rem",
    },
    //h-screen w-full md:w-full md:ml-0 md:mt-4
  },

  [`& .${classes.nav_wrapper}`]: {
    position: "sticky",
    top: 0,
    left: 0,
    zIndex: 1000,
  },

  [`& .${classes.feed}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "auto",
  },

  [`& .${classes.stickySection}`]: {
    width: "25vw",
    position: "sticky",
    top: 0,
    right: 0,
    height: "100vh",
    overflowY: "auto",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

const Home = ({ auth: { isAuthenticated, user } }) => {
  //Get query parameters, if any (social login callback)
  //MUI Styling

  const theme = useTheme();

  const isAuth = (
    <Root className="h-full w-full md:w-full md:ml-0 md:pt-4 bg-neutral-50 min-h-screen">
      <Helmet>
        <title>thisPantry</title>
      </Helmet>
      <FeedManager />
    </Root>
  );
  return <div>{isAuthenticated && user ? isAuth : <Landing />}</div>;
};

Home.propTypes = {
  auth: PropTypes.object.isRequired,
  setTokensFromCallback: PropTypes.func.isRequired,
};

//Bring in state (Map to props)
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Home);
