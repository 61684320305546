import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis, Tooltip, ReferenceLine, Legend } from "recharts";

export const CompoundBarChart = ({ data, referenceLine = false, referenceLineLabel = "" }) => {
  // Create a custom legend payload that includes the reference line
  const legendPayload = [
    { value: "Groceries", type: "rect", color: "hsl(var(--chart-1))" },
    { value: "Restaurants", type: "rect", color: "hsl(var(--chart-3))" },
    { value: "Other", type: "rect", color: "hsl(var(--chart-5))" },
    // Add the reference line to the legend if it exists
    ...(referenceLine ? [{ value: referenceLineLabel, type: "line", color: "#333333" }] : []),
  ];
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data} margin={{ top: 20, right: 0, left: -20, bottom: 0 }}>
        <XAxis
          dataKey="date"
          tick={{ fontSize: 12 }}
          tickFormatter={(value) => {
            const date = new Date(value);
            const options = { month: "short", day: "numeric" };
            return date.toLocaleDateString("en-US", options);
          }}
        />
        <YAxis tick={{ fontSize: 12 }} tickFormatter={(value) => `$${Math.round(value)}`} />
        <Tooltip
          contentStyle={{
            backgroundColor: "rgba(255, 255, 255, 0.95)",
            border: "none",
            borderRadius: "4px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          }}
          formatter={(value, name) => {
            return [`$${value.toFixed(2)}`, `${name}`];
          }}
          labelFormatter={(value) => {
            const date = new Date(value);
            const options = { month: "short", day: "numeric", year: "numeric" };
            return date.toLocaleDateString("en-US", options);
          }}
        />
        <Legend align="left" wrapperStyle={{ paddingLeft: "2rem" }} payload={legendPayload} />
        {referenceLine && <ReferenceLine y={referenceLine} stroke="#333333" strokeDasharray="6 2" />}
        <Bar dataKey="groceries" stackId="a" fill="hsl(var(--chart-1))" name="Groceries" />
        <Bar dataKey="restaurants" stackId="a" fill="hsl(var(--chart-3))" name="Restaurants" />
        <Bar dataKey="other" stackId="a" fill="hsl(var(--chart-5))" name="Other" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default CompoundBarChart;
