import React from "react";
import Typography from "@mui/material/Typography";

const RecipeMacrosItem = ({ data }) => {
  return (
    <div>
      <div className="flex flex-row justify-between items-center">
        <Typography variant="subtitle1" className="font-normal leading-snug">
          {data.name}
        </Typography>
        <div className="flex-1 border-b border-separator mx-2"></div>
        <div className="flex flex-row">
          <Typography variant="body2">{data.amount}</Typography>
          {data.unit && (
            <Typography variant="body2" className="ml-2">
              {data.unit.name}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecipeMacrosItem;
