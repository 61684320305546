import React from "react";
import { useDropzone } from "react-dropzone";
import { connect } from "react-redux";
import { addImageToUpload, cropImageInUpload } from "../../../../actions/media";
import { setAlert } from "../../../../actions/alert";
import { ObjectId } from "bson";

import { Typography } from "@mui/material";

//Icons
import { ReactComponent as ImagesIcon } from "../../../../assets/icons/svg/images-duotone.svg";

//Utils
import { getCroppedImg } from "../../../../utils/image";

const UploadDropzone = ({ media: { images }, addImageToUpload, onSelectImageForCrop, setAlert, cropImageInUpload }) => {
  const onDrop = async (acceptedFiles) => {
    if (images.length + acceptedFiles.length > 10) {
      setAlert("You can only upload up to 10 images.", "warning");
      return;
    }

    // For each file, create a URL and dispatch the addImage action
    const generateUniqueId = () => {
      let id;
      do {
        id = new ObjectId().toString();
      } while (images.some((image) => image._id === id));
      return id;
    };

    for (let index = 0; index < acceptedFiles.length; index++) {
      const file = acceptedFiles[index];
      const previewUrl = URL.createObjectURL(file);
      const imageid = generateUniqueId();
      addImageToUpload({ file, previewUrl, _id: imageid });

      // Create a new image object
      const img = new Image();
      img.src = previewUrl;
      await new Promise((resolve) => {
        img.onload = resolve;
      });

      // Get the dimensions of the original image
      const originalWidth = img.width;
      const originalHeight = img.height;

      // Calculate the size and position of the cropped image
      const cropSize = Math.min(originalWidth, originalHeight);
      const crop = {
        x: (originalWidth - cropSize) / 2,
        y: (originalHeight - cropSize) / 2,
      };

      // Use your getCroppedImage function to crop the image
      const croppedImage = await getCroppedImg(previewUrl, {
        unit: "px",
        width: cropSize,
        height: cropSize,
        x: crop.x,
        y: crop.y,
      });

      const zoom = 1;

      const cropIndex = images.length + index;
      // Dispatch the addImage action with the cropped image
      cropImageInUpload(cropIndex, {
        croppedImage,
        crop,
        zoom,
        croppedAreaPixels: { width: cropSize, height: cropSize, x: crop.x, y: crop.y },
      });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  // Render the dropzone UI
  return (
    <div
      {...getRootProps()}
      className="flex flex-col items-center text-center justify-center cursor-pointer h-full w-full min-h-[200px] min-w-[200px] border border-secondaryText-200 rounded-md"
    >
      <input {...getInputProps()} />
      <div className="flex flex-row items-center justify-center text-mainGreen mb-2">
        <ImagesIcon className="h-[3rem] fill-secondaryText-700 [&>.duo-secondary]:fill-secondaryText-300" />
      </div>
      <div className="w-[80%]">
        <Typography variant="body2" className="text-secondaryText-600">
          Upload up to 10 images
        </Typography>
      </div>
    </div>
  );
};

// Connect the UploadDropzone component to the Redux store'

const mapStateToProps = (state) => ({
  media: state.media,
});
export default connect(mapStateToProps, { addImageToUpload, setAlert, cropImageInUpload })(UploadDropzone);
