import { Portal } from "react-portal";
import { VictoryPortal, Flyout } from "victory";
const CustomTooltip = ({ active, datum, x, y }) => {
  if (!active) {
    return null;
  }

  return (
    <VictoryPortal>
      <Flyout
        x={x}
        y={y}
        dy={-20} // Adjust this value to position the tooltip above the data point
        orientation="top"
        pointerLength={10} // Adjust this value to change the size of the pointer
        width={150} // Adjust this value to change the width of the tooltip
        height={75} // Adjust this value to change the height of the tooltip
        style={{ fill: "white", stroke: "lightgray" }}
        cornerRadius={5}
        events={{}} // This is necessary to prevent Victory's event system from interfering with the tooltip
      >
        <text x={x} y={y - 30} textAnchor="middle">{`Date: ${datum.x.toLocaleDateString()}`}</text>
        <text x={x} y={y - 10} textAnchor="middle">{`Value: ${datum.y}`}</text>
      </Flyout>
    </VictoryPortal>
  );
};

export default CustomTooltip;
