import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//MUI
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";

//Img
import BGImg from "./res/paper.webp";
import Blueberries from "./res/blue.webp";

const ComingSoonModal = ({ isPlus }) => {
  return (
    <div className="relative p-4 sm:p-0 xs:aspect-square overflow-hidden sm:rounded-lg sm:min-h-[50vh] md:min-w-[600px]">
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-20 z-10"></div>
      <div className="absolute inset-0 z-20 hidden xs:block">
        <img src={Blueberries} alt="Blueberries" className="w-full h-full object-cover" />
      </div>
      <div className="relative z-30 flex flex-col justify-end h-full p-0 sm:p-6">
        <Typography
          variant="h1"
          className="font-telegraf font-medium leading-none text-mainGreen text-[4.5rem] sm:text-[5.3rem]"
        >
          Coming
        </Typography>
        <Typography
          variant="h1"
          className="font-telegraf font-medium leading-none text-mainGreen text-[4.5rem] sm:text-[5.3rem] -mt-2"
        >
          Soon
        </Typography>
        <div className="w-full sm:max-w-[37%] mt-4">
          <Typography variant="subtitle1" className="leading-tight font-telegraf font-normal ">
            We're cooking up something spicy.<br></br>Plus members will be the first to try it.
          </Typography>
          {isPlus ? (
            <div className="inline-flex flex-row items-center gap-2 mt-2 hidden">
              <Checkbox id="optin" className="p-0" />
              <label htmlFor="optin">
                <Typography variant="subtitle2" className="leading-tight font-telegraf font-normal">
                  Add me to the waitlist!
                </Typography>
              </label>
            </div>
          ) : (
            <Link to="/plus">
              <Button variant="contained" className="mt-4 " disableElevation fullWidth>
                Join thisPantry<span className="font-semibold">+</span>
              </Button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

ComingSoonModal.propTypes = {};

const mapStateToProps = (state) => ({
  isPlus: state.auth.isPlus,
});

export default connect(mapStateToProps)(ComingSoonModal);
