import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//DOM imports
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

//MUI
import { Button, IconButton, Typography, Divider } from "@mui/material";

//Icons
import { ReactComponent as ChevronLeft } from "../../../assets/icons/svg/chevron-left.svg";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as ChevronRight } from "../../../assets/icons/svg/chevron-right.svg";
import { ReactComponent as TitleIcon } from "../../../assets/icons/svg/scroll-light.svg";

const Policies = ({}) => {
  return (
    <>
      <Helmet>
        <title>thisPantry - Policies</title>
      </Helmet>

      <div className="my-[4rem] md:my-0 md:pt-6 bg-neutral-50 min-h-screen">
        <div className="w-full md:ml-[5rem]">
          <div className="px-4 py-3 md:px-8 md:py-6 md:mx-auto w-full md:max-w-[600px] border-b md:border border-separator md:rounded-[16px] bg-background">
            <div className="flex flex-col">
              <div className="my-4 md:mt-0">
                <Link className="hover:bg-transparent text-secondaryText p-0 justify-start" to="/settings">
                  <Typography variant="body2" className="body1 leading-none">{`< Go Back`}</Typography>
                </Link>
              </div>
              <div className="flex flex-row mt-">
                <div className="mr-4">
                  <TitleIcon className="h-[1.25rem] fill-primaryText"></TitleIcon>
                </div>
                <div className="mb-4">
                  <Typography variant="h6" className="font-normal leading-none mb-1">
                    thisPantry Policies
                  </Typography>
                  <Typography variant="subtitle2" className="text-secondaryText leading-tight font-normal">
                    View our Terms of Service, Privacy Policy, and Cookie Policy
                  </Typography>
                </div>
              </div>
            </div>
            <Divider />
            <Link to="/privacy">
              <div className="flex flex-row justify-between items-center py-3">
                <div>
                  <Typography variant="subtitle2" className="font-normal">
                    Privacy Policy
                  </Typography>
                  <Typography variant="body2" className="text-secondaryText">
                    View our privacy policy
                  </Typography>
                </div>
                <div>
                  <ChevronRight className="h-[1rem] text-primaryText " />
                </div>
              </div>
            </Link>
            <Divider />
            <Link to="/terms">
              <div className="flex flex-row justify-between items-center py-3">
                <div>
                  <Typography variant="subtitle2" className="font-normal">
                    Terms of Service
                  </Typography>
                  <Typography variant="body2" className="text-secondaryText">
                    View our Terms of Service
                  </Typography>
                </div>
                <div>
                  <ChevronRight className="h-[1rem] text-primaryText " />
                </div>
              </div>
            </Link>
            <Divider />
            <Link to="/cookies">
              <div className="flex flex-row justify-between items-center py-3">
                <div>
                  <Typography variant="subtitle2" className="font-normal">
                    Cookie Policy
                  </Typography>
                  <Typography variant="body2" className="text-secondaryText">
                    View our Cookie Policy
                  </Typography>
                </div>
                <div>
                  <ChevronRight className="h-[1rem] text-primaryText " />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

Policies.propTypes = {};

export default connect(null, {})(Policies);
