import api from "../api/api";

import store, { history } from "../store";

//Redux types
import {
  GET_NEW_UPDATES,
  NEW_UPDATES_ERROR,
  CLOSE_UPDATE_MODAL,
} from "./types";

//Get new updates from DB for the user
export const getNewUpdates = () => async (dispatch) => {
  try {
    const res = await api.get("/updates");

    dispatch({
      type: GET_NEW_UPDATES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: NEW_UPDATES_ERROR,
    });
  }
};

export const closeUpdateModal = () => (dispatch) => {
  dispatch({
    type: CLOSE_UPDATE_MODAL,
  });
};
