import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal, Backdrop, Fade, Paper, Typography, TextField, Button, IconButton } from "@mui/material";

//FA Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

//Actions
import { editSectionStep, removeStep } from "../../../../actions/createrecipe";
import { setAlert } from "../../../../actions/alert";

//TO DO:
//Push edits to redux state
//Remove step
//Add tip to step
const EditDirectionModal = ({
  step,
  stepIndex,
  sectionIndex,
  sections,
  handleClose,
  editSectionStep,
  removeStep,
  setAlert,
}) => {
  const [text, setText] = useState(step.text);

  const onTextChange = (e) => {
    setText(e.target.value);
  };

  const [tip, setTip] = useState(
    sections &&
      sections.length > 0 &&
      sections[sectionIndex].steps &&
      sections[sectionIndex].steps[stepIndex] &&
      sections[sectionIndex].steps[stepIndex].tips &&
      sections[sectionIndex].steps[stepIndex].tips.length > 0
      ? sections[sectionIndex].steps[stepIndex].tips[0]
      : ""
  );

  const onTipChange = (e) => {
    setTip(e.target.value);
  };

  const [showTip, setShowTip] = useState(
    sections &&
      sections.length > 0 &&
      sections[sectionIndex].steps &&
      sections[sectionIndex].steps[stepIndex] &&
      sections[sectionIndex].steps[stepIndex].tips &&
      sections[sectionIndex].steps[stepIndex].tips.length > 0
  );

  const handleRemoveTip = () => {
    setTip("");
    setShowTip(false);
  };

  const [removeAreYouSure, setRemoveAreYouSure] = useState(false);

  const handleSubmit = (e) => {
    //Stop higher forms from firing
    e.stopPropagation();
    e.preventDefault();

    editSectionStep(sectionIndex, stepIndex, { text, tip });
    handleClose();
    setAlert("Step updated", "success", 3000);
  };

  const handleRemoveStep = () => {
    removeStep(sectionIndex, stepIndex);
    handleClose();
    setAlert("Step removed", "success", 3000);
  };

  return (
    <div className="mx-4 mb-4">
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <div>
          <Typography variant="h6">Edit Step</Typography>
        </div>
        <div className="mt-4">
          <TextField
            multiline
            fullWidth
            minRows={3}
            variant="outlined"
            label="Step"
            value={text}
            onChange={(e) => onTextChange(e)}
          ></TextField>
        </div>
        <div className="mt-2 mb-4">
          {showTip ? (
            <Button onClick={() => handleRemoveTip()} disableRipple className="text-secondaryText hover:bg-transparent">
              <Typography variant="subtitle1">- Remove Tip</Typography>
            </Button>
          ) : (
            <Button onClick={() => setShowTip(true)} disableRipple className="text-secondaryText hover:bg-transparent">
              <Typography variant="body1">+ Add a tip for this step</Typography>
            </Button>
          )}
          {showTip && (
            <TextField
              multiline
              fullWidth
              variant="outlined"
              label="Tip"
              value={tip}
              onChange={(e) => onTipChange(e)}
              className="mt-2"
            ></TextField>
          )}
        </div>
        <div className="flex justify-between">
          {removeAreYouSure ? (
            <Button variant="outlined" color="danger" onClick={() => handleRemoveStep()}>
              <span className="text-red">Yes, Remove</span>
            </Button>
          ) : (
            <Button variant="outlined" color="danger" onClick={() => setRemoveAreYouSure(true)}>
              Remove Step
            </Button>
          )}

          <Button variant="contained" color="primary" disableElevation type="submit">
            Update
          </Button>
        </div>
      </form>
    </div>
  );
};

EditDirectionModal.propTypes = {
  step: PropTypes.object.isRequired,
  stepIndex: PropTypes.number.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  editSectionStep: PropTypes.func.isRequired,
  removeStep: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  sections: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  create: state.recipe.create,
  sections: state.recipe.create.sections,
});

export default connect(mapStateToProps, { editSectionStep, removeStep, setAlert })(EditDirectionModal);
