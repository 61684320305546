import React from "react";

import PostTextContent from "./components/PostTextContent";
import ImageCarousel from "./components/ImageCarousel";
const MediaContent = ({ post }) => {
  return (
    <div className="w-full aspect-square">
      <ImageCarousel data={post.data} />
      {post.text && post.text !== "" && (
        <PostTextContent text={post.text} username={post.user.username} hasImg={post.data.length > 0} />
      )}
    </div>
  );
};

export default MediaContent;
