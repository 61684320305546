import React, { useState, useEffect } from "react";

import api from "../../api/api";

//MUI Components
import { Typography, IconButton, Button } from "@mui/material";

//FA Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare, faClock } from "@fortawesome/free-regular-svg-icons";

//Other icons
import { ReactComponent as CartIcon } from "../../assets/icons/svg/shopping-cart.svg";
import { ReactComponent as CarrotIcon } from "../../assets/icons/svg/carrot.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/svg/trash.svg";

//Chart component
import AreaLineChart from "../graphs/AreaLineChart";
import VisxLineChart from "../graphs/VisxLineChart";

//Utility Functions
String.prototype.toTitleCase = function () {
  var i, j, str, lowers, uppers;
  str = this.replace(/([^\W_]+[^\s-]*) */g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

  // Certain minor words should be left lowercase unless
  // they are the first or last words in the string
  lowers = [
    "A",
    "An",
    "The",
    "And",
    "But",
    "Or",
    "For",
    "Nor",
    "As",
    "At",
    "By",
    "For",
    "From",
    "In",
    "Into",
    "Near",
    "Of",
    "On",
    "Onto",
    "To",
    "With",
  ];
  for (i = 0, j = lowers.length; i < j; i++)
    str = str.replace(new RegExp("\\s" + lowers[i] + "\\s", "g"), function (txt) {
      return txt.toLowerCase();
    });

  // Certain words such as initialisms or acronyms should be left uppercase
  uppers = ["Id", "Tv"];
  for (i = 0, j = uppers.length; i < j; i++)
    str = str.replace(new RegExp("\\b" + uppers[i] + "\\b", "g"), uppers[i].toUpperCase());

  return str;
};

/*Info to show in on hand
Amount -> Absolute and percentage remaining
Location + Sublocation
Expiry Date
Expand to show change history
+ Waste percentage, add to shopping list
*/
const PantryItemModal = ({ item, location }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);

  //Get data
  const getData = async () => {
    try {
      const res = await api.get(`/pantry/item/${item.pantryId}`);
      setData(res.data);
      setLoading(false);
      setError(false);
    } catch (err) {
      setError(true);
      setData(null);
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  console.log(item);

  return (
    <div>
      <div className="p-4 flex items-center justify-between border-b border-separator">
        <div className="flex items-center">
          <IconButton size="small">
            <FontAwesomeIcon icon={faChevronLeft} />
          </IconButton>

          <div className="ml-4">
            <Typography variant="h5">
              {item.name.toTitleCase()}
            </Typography>
            <Typography className="text-mainGreen" variant="subtitle1">
              Safeway
            </Typography>
          </div>
        </div>

        <IconButton size="small" >
          <TrashIcon className="h-4 w-full fill-primaryText" />
        </IconButton>
      </div>

      <div className="m-4 p-4 pb-0 rounded-lg border border-separator">
        <CarrotIcon className="h-4 mr-1" />
        <div className="flex flex-row items-end mt-2">
          <Typography variant="h3" className="text-mainGreen">
            500 g
          </Typography>
          <Typography variant="subtitle1" className="ml-1 text-secondaryText font-italic">
            (100%)
          </Typography>
        </div>
        <div className="text-secondaryText mb-2">
          <Typography variant="subtitle2">Added: Jan 25, 2024</Typography>
        </div>
        <div className="text-secondaryText mb-1">
          <Typography variant="subtitle2">Opened: Feb 2, 2024</Typography>
        </div>
        <div className="flex flex-row items-center justify-between py-1 border-t border-separator">
          <div>
            <Typography variant="subtitle1">Fridge - Door</Typography>
          </div>
          <div className="flex flex-row items-center">
            <FontAwesomeIcon icon={faClock} className="mr-1" />
            <Typography variant="subtitle1">Use in: 3 days</Typography>
          </div>
        </div>
        <div className="flex flex-row items-center w-full py-1 border-t border-separator">
          <Button className="py-1 hover:bg-transparent" disableRipple>
            <Typography variant="body2">
              <FontAwesomeIcon icon={faChevronDown} style={{ marginRight: "8px" }} />
              Show Item History
            </Typography>
          </Button>
        </div>
      </div>
      <div className="m-4 p-4 pb-0 rounded-lg border border-separator">
        <div className="flex flex-row items-center">
          <CartIcon className="h-4 mr-1" />
        </div>
        <div>
          <AreaLineChart />
        </div>
        <div>
          <Typography variant="h6" className="font-normal mt-1">
            <span className="font-medium">Safeway</span>
            <span> on </span>
            <span className="text-mainGreen italic">Jan 24, 2024</span>
          </Typography>
        </div>
        <div className="mt-2">
          <Typography variant="h4" className="text-mainGreen font-semibold">
            $5.47
          </Typography>
        </div>
        <div className="flex flex-row items-center">
          <FontAwesomeIcon icon={faChevronUp} className="mr-1" />
          <Typography variant="body1">
            $0.15 (+2.8%) <span className="text-secondaryText italic">from last purchase</span>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default PantryItemModal;
