import React, { useState, useEffect } from "react";

import { Typography, Button } from "@mui/material";

//Modal
import SectionTimingModal from "../modals/SectionTimingModal";
import CustomModal from "../../../../modal/CustomModal";

//Icons
import { ReactComponent as PlusIcon } from "../../../../../assets/icons/svg/leaf-solid.svg";

const RecipeStepsSection = ({ section }) => {
  const [hasAdditionalTiming, setHasAdditionalTiming] = useState(false);
  const [showTimingModal, setShowTimingModal] = useState(false);

  const handleTimingModalClose = () => {
    setShowTimingModal(false);
  };

  const checkAdditionalTiming = () => {
    if (section.timing) {
      for (const key in section.timing) {
        if (key !== "total") {
          const timing = section.timing[key];
          if ((timing.minutes && timing.minutes > 0) || (timing.hours && timing.hours > 0)) {
            setHasAdditionalTiming(true);
            return;
          }
        }
      }
    }
  };

  useEffect(() => {
    checkAdditionalTiming();
  }, [section]);
  return (
    <div>
      <div className="w-full ">
        <div className="flex flex-row items-center bg-neutral-100 py-4 px-4 md:px-8">
          <Typography variant="h6" className="font-sans font-normal text-primaryText leading-none">
            {section.title}
          </Typography>
        </div>
        <CustomModal
          open={showTimingModal}
          handleClose={handleTimingModalClose}
          type="bottom"
          parentClassNameOverride="md:max-w-[600px]"
        >
          <SectionTimingModal timing={section.timing} title={section.title} />
        </CustomModal>

        {section.timing &&
          section.timing.total &&
          (section.timing.total.hours > 0 || section.timing.total.minutes > 0) && (
            <div className="px-4 md:px-8">
              <div className="mt-2 flex flex-row items-center justify-between">
                <Typography
                  variant="subtitle2"
                  component="p"
                  className="text-secondaryText-600 font-normal leading-snug"
                >
                  {` ${section.timing.total.hours > 0 ? `${section.timing.total.hours} hrs ` : ""}${
                    section.timing.total.minutes
                  } mins`}
                </Typography>
                {hasAdditionalTiming && (
                  <Button className="p-0 hover:bg-transparent" disableRipple onClick={() => setShowTimingModal(true)}>
                    <Typography variant="subtitle2" className="text-mainGreen leading-none text-xs font-normal">
                      View Timing
                    </Typography>
                  </Button>
                )}
              </div>
            </div>
          )}
        {section.source && (
          <div className="pt-4 px-4 md:px-8">
            <Typography variant="subtitle2" className="text-secondaryText leading-none font-normal text-xs">
              {section.source.type}:
            </Typography>
            {section.source.text === "thisPantry+" ? (
              <div className="flex flex-row gap-1 items-center">
                <Typography variant="subtitle1" className="text-primaryText leading-none mt-2 text-sm">
                  thisPantry<span className="font-semibold text-mainGreen">+</span>
                </Typography>
                {section.source.text === "thisPantry+" && <PlusIcon className="w-3 fill-mainGreen" />}
              </div>
            ) : (
              <div>
                <Typography variant="subtitle1" className="text-primaryText leading-none mt-2 text-sm">
                  {section.source.text}
                </Typography>
              </div>
            )}

            {section.source.url && (
              <a
                href={section.source.url}
                target="_blank"
                rel="noopener noreferrer"
                className="block truncate leading-none text-mainGreen"
              >
                <Typography variant="caption" className="font-normal text-mainGreen leading-none text-xs">
                  {section.source.url}
                </Typography>
              </a>
            )}
          </div>
        )}
      </div>
      <ol className="ml-4 px-4 md:px-8 py-4">
        {section.steps.map((step, j) => {
          return (
            <li key={j} className="mt-2 mb-4 marker:text-mainGreen marker:font-normal">
              <Typography variant="body1" component="p" className="font-normal pl-2 leading-snug">
                {step.text}
              </Typography>

              {step.tip && (
                <div className="mt-1">
                  <Typography variant="body2" className="font-normal text-primaryText pl-2">
                    Tip: <span className="italic text-secondaryText">{step.tip}</span>
                  </Typography>
                </div>
              )}
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default RecipeStepsSection;
