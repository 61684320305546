import React, { useEffect, useState, useRef, forwardRef, Fragment } from "react";
import { styled } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { connect } from "react-redux";

//import { showRegisterModal } from '../../../actions/auth';

//Animations
import AnimationManager from "./animations/AnimationManager";
/* <AnimationManager
        srcs={activeImage}
        alts={activeAlts}
        animation={activeAnimation}
        className="h-40% max-w-full"
        shadow={shadow}
        large={large}
      />*/
import FramerAnimationManager from "./animations/FramerAnimationManager";

const PREFIX = "SplitShowcase";

const classes = {
  container: `${PREFIX}-container`,
  imageContainer: `${PREFIX}-imageContainer`,
  image: `${PREFIX}-image`,
  textContainer: `${PREFIX}-textContainer`,
  textSection: `${PREFIX}-textSection`,
  infoContainer: `${PREFIX}-infoContainer`,
  info: `${PREFIX}-info`,
  title: `${PREFIX}-title`,
  CTAbutton: `${PREFIX}-CTAbutton`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.container}`]: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    //flex flex-row w-full h-full md:flex-col
  },

  [`&.${classes.imageContainer}`]: {
    position: "sticky",
    overflow: "hidden",
    top: "2.5rem",
    width: "50%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background,
    [theme.breakpoints.down("md")]: {
      position: "relative",
      width: "100%",
      height: "50vh",
    },
    //sticky overflow-hidden top-10 w-1/2 h-screen flex items-center justify-center bg-background md:static md:w-full md:h-50vh
  },

  [`& .${classes.image}`]: {
    height: "40%",
    maxWidth: "100%",
    //h-40% max-w-full
  },

  [`& .${classes.textContainer}`]: {
    width: "50%",
    overflowY: "auto",
    zIndex: 6,
    backgroundColor: theme.palette.secondaryGrey,

    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    //w-1/2 overflow-y-auto z-6 bg-secondaryGrey md:w-full
  },

  [`& .${classes.textSection}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "calc(100vh - 5rem)",
    padding: theme.spacing(2),
    justifyContent: "center",
    margin: "0 20%",
    [theme.breakpoints.down("xl")]: {
      margin: "0 10%",
    },
    [theme.breakpoints.down("md")]: {
      margin: 0,
      height: "inherit",
      padding: theme.spacing(4),
      paddingBottom: 0,
    },
    //flex flex-col justify-center h-[calc(100vh-5rem)] p-2 justify-center mx-20% xl:mx-10% md:mx-0 md:h-inherit md:p-4 md:pb-0
  },

  [`& .${classes.infoContainer}`]: {
    padding: theme.spacing(3, 0),
    paddingBottom: theme.spacing(4),
    //py-3 pb-4
  },

  [`& .${classes.info}`]: {
    fontWeight: 400,
  },

  [`& .${classes.title}`]: {
    fontWeight: 500,
  },

  [`& .${classes.CTAbutton}`]: {
    color: "#fff",
  },
}));

//Scroll debouncing:
const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

const SplitShowcase = React.forwardRef((props, ref) => {
  //Breakpoints
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const { sections, imagesOn = "left", shadow = "true", large = false, showRegisterModal, isCharity } = props;

  const [activeImage, setActiveImage] = useState(sections[0]?.images || [""]);
  const [activeAnimation, setActiveAnimation] = useState(sections[0]?.animation || "fade");
  const [activeAlts, setActiveAlts] = useState(sections[0]?.alts || [""]);
  const sectionRefs = useRef([]);

  //TO DO: Handle animations
  const handleScroll = () => {
    const scrollPosition = window.scrollY + window.innerHeight / 2;
    const nextSection = sectionRefs.current.findIndex((section, index) => {
      //BUG: offsetTop sometimes null/undefined when scrolling?
      return section.offsetTop + section.offsetHeight > scrollPosition;
    });
    //Set new active image
    const newActiveImage = sections[nextSection]?.images || sections[sections.length - 1].images;
    setActiveImage(newActiveImage);

    //Set new active animation
    const newActiveAnimation = sections[nextSection]?.animation || sections[sections.length - 1].animation;
    setActiveAnimation(newActiveAnimation);
    //Set new active alt
    const newActiveAlts = sections[nextSection]?.alts || sections[sections.length - 1].alts;
    setActiveAlts(newActiveAlts);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const imageBox = (
    <div
      className="sticky overflow-hidden top-10 w-1/2 h-screen flex items-center justify-center bg-background md:static md:w-full md:h-50vh"
      style={{ order: imagesOn === "right" ? 2 : 1 }}
    >
      <FramerAnimationManager animation={activeAnimation} />
    </div>
  );

  const textBox = (
    <div
      className="w-1/2 h-full overflow-y-auto z-6 bg-gray-100 md:w-full"
      style={{ order: imagesOn === "right" ? 1 : 2 }}
    >
      {sections.map((section, index) => (
        <div
          key={index}
          className="flex flex-col justify-center h-[calc(100vh-5rem)] px-16 py-4 mx-20% xl:mx-10% md:mx-0 md:h-inherit"
          ref={(el) => (sectionRefs.current[index] = el)}
        >
          <div>
            <Typography variant="h4" className="font-medium">
              {section.title}
            </Typography>
          </div>
          <div className="py-3 pb-4">
            <Typography variant="h6" component="p" className="font-regular">
              {section.text}
            </Typography>
          </div>
          <div>
            {!isCharity ? (
              <Button
                variant="contained"
                size="large"
                color="primary"
                disableElevation
                style={{ textTransform: "none" }}
                className={classes.CTAbutton}
                onClick={() => showRegisterModal()}
              >
                {section.cta}
              </Button>
            ) : (
              <a href="https://pledge1percent.org/" target="_blank">
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  disableElevation
                  style={{ textTransform: "none" }}
                  className={classes.CTAbutton}
                >
                  {section.cta}
                </Button>
              </a>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="flex flex-row w-full h-full h-max[400px] " ref={ref}>
      {isSmallScreen ? (
        <Fragment>
          {sections.map((section, index) => (
            <Fragment>
              <div className="sticky overflow-hidden top-10 w-1/2 h-screen flex items-center justify-center bg-gray-300 md:static md:w-full md:h-50vh">
                <img className="h-40% max-w-full" src="logo512.png"></img>
                <FramerAnimationManager animation={activeAnimation} />
              </div>
              <div
                key={index}
                className="flex flex-col justify-center h-[calc(100vh-5rem)] p-2  mx-20% xl:mx-10% md:mx-0 md:h-inherit md:p-4 md:pb-0 bg-background"
                ref={(el) => (sectionRefs.current[index] = el)}
              >
                <div>
                  <Typography variant="h4" className="font-medium">
                    {section.title}
                  </Typography>
                </div>
                <div className="py-3 pb-4">
                  <Typography variant="h6" component="p" className="font-regular">
                    {section.text}
                  </Typography>
                </div>
                <div>
                  {!isCharity ? (
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      disableElevation
                      style={{ textTransform: "none" }}
                      className={classes.CTAbutton}
                      onClick={() => showRegisterModal()}
                    >
                      {section.cta}
                    </Button>
                  ) : (
                    <a href="https://pledge1percent.org/">
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        disableElevation
                        style={{ textTransform: "none" }}
                        className={classes.CTAbutton}
                      >
                        {section.cta}
                      </Button>
                    </a>
                  )}
                </div>
              </div>
            </Fragment>
          ))}
        </Fragment>
      ) : (
        <Fragment>
          {imagesOn === "left" ? (
            <Fragment>
              {imageBox}
              {textBox}
            </Fragment>
          ) : (
            <Fragment>
              {textBox}
              {imageBox}
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
});

/*
const mapDispatchToProps = {
  showRegisterModal
}

export default connect(null, mapDispatchToProps, null, {forwardRef: true})(SplitShowcase);
*/

export default SplitShowcase;
