import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";

import Footer from "../layout/Footer";

import { Helmet } from "react-helmet";

const Cookies = () => {
  return (
    <div className="w-full">
      <Helmet>
        <title>thisPantry - Cookies Policy</title>
      </Helmet>
      <div className="h-full overflow-y-scroll">
        <div className="px-8 my-[5rem] md:ml-[5rem] md:mt-0 md: py-8 lg:px-32 xl:px-64 min-h-[100vh]">
          <div>
            <Typography variant="h5">Cookies Policy</Typography>
          </div>
          <div className="my-4">
            <Typography variant="h6">What are cookies?</Typography>
            <div className="my-2">
              <Typography variant="body1">
                Cookies are small pieces of text that store information on web browsers. Cookies are used to store and
                receive identifiers and other information on computers, phones, and other devices. Other technologies,
                including data we store on your web browser or device, identifiers associated with your device, and
                other software are used for similar purposes. In this policy, we refer to these technologies as
                “cookies”. We use cookies if you have a thisPantry account or use thisPantry. Cookies enable thisPantry
                to offer our services to you and understand the information we receive about you.
              </Typography>
              <br></br>
              <Typography variant="body1">
                This policy explains how we use cookies and the choices you have. Except as otherwise stated in this
                policy, the Data Policy will apply to our processing of the data we collect via cookies.
              </Typography>
            </div>
          </div>
          <div className="my-4">
            <Typography variant="h6">Why do we use cookies?</Typography>
            <div className="my-2">
              <Typography variant="body1">
                thisPantry uses cookies to help us provide, improve, and protect our products, such as by personalizing
                content, tailoring and measuring ads, and providing a safer experience. While the specific cookies we
                use on thisPantry may change over time as we improve and update our service, we use cookies for the
                following purposes.
              </Typography>
              <ul className="my-4 mx-4 marker:text-secondaryText">
                <li className="mb-2">
                  <Typography variant="body1" className="font-normal">
                    Authentication:
                  </Typography>
                  <Typography variant="body1">
                    thisPantry uses cookies to detect if you are logged into our service and keep you logged in as you
                    navigate between pages on thisPantry.
                  </Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1" className="font-normal">
                    Security:
                  </Typography>
                  <Typography variant="body1">
                    We use cookies to help keep your account, data, and thisPantry safe and secure.
                  </Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1" className="font-normal">
                    Advertising:
                  </Typography>
                  <Typography variant="body1">
                    thisPantry uses Google AdSense and Facebook to deliver advertising. These services use cookies to
                    deliver personalized advertising and other services such as conversion data. You can read the{" "}
                    <a href="https://policies.google.com/technologies/cookies" target="_blank" rel="noreferrer">
                      Google Cookie Policy
                    </a>{" "}
                    and{" "}
                    <a href="https://www.facebook.com/privacy/policies/cookies/" target="_blank" rel="noreferrer">
                      Facebook Cookie Policy
                    </a>{" "}
                    to learn more.
                  </Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1" className="font-normal">
                    Analytics and Research:
                  </Typography>
                  <Typography variant="body1">
                    thisPantry uses cookies to understand how users interact and use thisPantry to help improve our
                    services. thisPantry also uses Google Analytics to help gather usage data. You can read the{" "}
                    <a href="https://policies.google.com/technologies/cookies" target="_blank" rel="noreferrer">
                      Google Cookie Policy
                    </a>{" "}
                    to learn more.
                  </Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1" className="font-normal">
                    Site features and services:
                  </Typography>
                  <Typography variant="body1">
                    We use cookies to help enable functionality that helps us provide the thisPantry service.
                  </Typography>
                </li>
              </ul>
            </div>
          </div>
          <div className="my-4">
            <Typography variant="h6">Do other parties use cookies in connection with thisPantry?</Typography>
            <div className="my-2">
              <Typography variant="body1">
                Yes, thisPantry uses services provided by both Google and Facebook that may use cookies. You can read
                the{" "}
                <a href="https://policies.google.com/technologies/cookies" target="_blank" rel="noreferrer">
                  Google Cookie Policy
                </a>{" "}
                and{" "}
                <a href="https://www.facebook.com/privacy/policies/cookies/" target="_blank" rel="noreferrer">
                  Facebook Cookie Policy
                </a>{" "}
                to learn more.
              </Typography>
            </div>
          </div>
          <div className="my-4">
            <Typography variant="h6">How can you control thisPantry’s use of cookies?</Typography>
            <div className="my-2">
              <Typography variant="body1">
                thisPantry uses cookies that are necessary for site functionality and security. To learn how to disable
                advertising-related cookies from Google and Facebook, you can read the{" "}
                <a href="https://policies.google.com/technologies/cookies" target="_blank" rel="noreferrer">
                  Google Cookie Policy
                </a>{" "}
                and{" "}
                <a href="https://www.facebook.com/privacy/policies/cookies/" target="_blank" rel="noreferrer">
                  Facebook Cookie Policy
                </a>{" "}
                to learn more.
                <br></br>
                <br></br>To learn more about how advertisers generally use cookies and the choices they offer, you can
                review the following resources:
              </Typography>
              <ul className="my-4 mx-4 marker:text-secondaryText">
                <li className="mb-2">
                  <Typography variant="body1">
                    <a href="http://www.aboutads.info/choices/" target="_blank" rel="noreferrer">
                      Digital Advertising Alliance
                    </a>
                  </Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1">
                    <a href="http://youradchoices.ca/" target="_blank" rel="noreferrer">
                      Digital Advertising Alliance of Canada
                    </a>
                  </Typography>
                </li>
                <li className="mb-2">
                  <Typography variant="body1">
                    <a href="http://www.youronlinechoices.eu/" target="_blank" rel="noreferrer">
                      European Interactive Digital Advertising Alliance
                    </a>
                  </Typography>
                </li>
              </ul>
              <Typography variant="body1">
                In addition, your browser or device may offer settings that allow you to choose whether browser cookies
                are set and to delete them. For more information about these controls, visit your browser or device's
                help material. Certain parts of thisPantry may not work properly if you have disabled browser cookie
                use.
              </Typography>
            </div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    </div>
  );
};

Cookies.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Cookies);
