//Import redux types
import {
  USER_LOADED,
  AUTH_ERROR,
  REGISTER_SUCCESS,
  LOGIN_SUCCESS,
  REGISTER_FAIL,
  LOGIN_FAIL,
  LOGOUT,
  LOGOUT_ERROR,
  TOKEN_REFRESH_FAIL,
  GET_VIEWED_PROFILE,
  MY_VIEWED_PROFILE,
  VIEWED_PROFILE_ERROR,
  RESET_VIEWED_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  GET_PROFILE_POSTDATA,
  GET_PROFILE_POST_ERROR,
  SET_POST_INTERACTIONS,
} from "../actions/types";

//Initial state object
const initialState = {
  currentProfile: null,
  viewedProfile: null,
  viewedLoading: true,
  viewedFollow: {
    loading: false,
    isFollowing: null,
  },
};

export default function (state = initialState, action) {
  //Destructure action
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
    case USER_LOADED:
      return {
        ...state,
        currentProfile: payload.profile,
      };
    case REGISTER_FAIL:
    case LOGIN_FAIL:
    case AUTH_ERROR:
    case LOGOUT:
    case LOGOUT_ERROR:
    case TOKEN_REFRESH_FAIL:
      return {
        ...state,
        currentProfile: null,
        viewedProfile: null,
      };
    case MY_VIEWED_PROFILE:
      return {
        ...state,
        viewedProfile: {
          ...state.currentProfile,
          followerCount: state.currentProfile.followers.length,
          followingCount: state.currentProfile.following.length,
        },
        viewedLoading: false,
      };
    case VIEWED_PROFILE_ERROR:
      return {
        ...state,
        viewedProfile: null,
        viewedLoading: false,
        viewedFollow: {
          loading: false,
          isFollowing: null,
        },
      };
    case RESET_VIEWED_PROFILE:
      return {
        ...state,
        viewedProfile: null,
        viewedLoading: true,
        viewedFollow: {
          loading: false,
          isFollowing: null,
        },
      };
    case GET_VIEWED_PROFILE:
      return {
        ...state,
        viewedProfile: payload,
        viewedLoading: false,
        viewedFollow: {
          loading: false,
          isFollowing: payload.isFollowing,
        },
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        currentProfile: {
          ...state.currentProfile,
          username: payload.username,
          usernameUpper: payload.username.toUpperCase(),
          name: payload.name,
          bio: payload.bio,
          link: payload.link,
          picture: payload.picture,
        },
        viewedProfile: {
          ...state.viewedProfile,
          username: payload.username,
          name: payload.name,
          bio: payload.bio,
          link: payload.link,
          picture: payload.picture,
        },
      };
    case GET_PROFILE_POSTDATA:
      return {
        ...state,
        viewedProfile: {
          ...state.viewedProfile,
          posts: {
            ...state.viewedProfile.posts,
            posts: state.viewedProfile.posts.posts.map((post, index) => {
              if (index === payload.index) {
                return { ...post, postData: payload.data, error: false, loading: false };
              }
              return post;
            }),
          },
        },
      };
    case GET_PROFILE_POST_ERROR:
      //Fix this, I don't think it should be firing. Uncaught request after navigating away from profile
      if (!state.viewedProfile || !state.viewedProfile.posts) {
        return state;
      }
      return {
        ...state,
        viewedProfile: {
          ...state.viewedProfile,
          posts: {
            ...state.viewedProfile.posts,
            posts: state.viewedProfile.posts.posts.map((post, index) => {
              if (index === payload.index) {
                return { ...post, postData: null, error: true, loading: false };
              }
              return post;
            }),
          },
        },
      };
    case SET_POST_INTERACTIONS:
      if (state.viewedProfile) {
        return {
          ...state,
          viewedProfile: {
            ...state.viewedProfile,
            posts: {
              ...state.viewedProfile.posts,
              posts: state.viewedProfile.posts.posts.map((post, index) => {
                if (post.postid === payload.postid) {
                  return {
                    ...post,
                    postData: {
                      ...post.postData,
                      interactions: { ...post.postData.interactions, ...payload.interactions },
                    },
                  };
                }
                return post;
              }),
            },
          },
        };
      } else {
        return state;
      }

    default:
      return state;
  }
}
