import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import CustomModal from "../../../modal/CustomModal";
import Searchable from "../../../layout/searchable/Searchable";
import RecipeAddMacroModal from "./RecipeAddMacroModal";

//Actions
import {
  toggleRecipeMacrosModal,
  pushSectionMacro,
  removeSectionMacro,
  setSectionMacroServingSize,
} from "../../../../actions/createrecipe";
import { setAlert } from "../../../../actions/alert";

//MUI
import { Typography, TextField, Button } from "@mui/material";

//Edit macros for a section, or compiled macros for a recipe
const RecipeSectionMacrosModal = ({
  visible,
  sectionIndex,
  sections,
  toggleRecipeMacrosModal,
  pushSectionMacro,
  removeSectionMacro,
  setSectionMacroServingSize,
  setAlert,
}) => {
  const handleClose = () => {
    if (sections[sectionIndex]?.macros?.length > 0) {
      const trimmedServingSize = servingSize.trim();
      if (trimmedServingSize.length === 0) {
        setAlert("Macronutrients must have a serving size", "warning");
        return;
      } else {
        //Update serving size
        if (trimmedServingSize !== sections[sectionIndex].servingSize) {
          setSectionMacroServingSize(sectionIndex, trimmedServingSize);
        }
      }
    } else if (sections[sectionIndex]?.servingSize && !sections[sectionIndex]?.output?.servings) {
      //Reset serving size if no macros (if serving size set)
      setSectionMacroServingSize(sectionIndex, "");
      setServingSize("");
    }
    toggleRecipeMacrosModal(false, null);
  };

  const handleAddMacroClose = () => {
    setShowAddMacroModal(false);
  };

  const handleServingSizeChange = (e) => {
    if (e.target.value.length < 48) {
      setServingSize(e.target.value);
      setSectionMacroServingSize(sectionIndex, e.target.value);
    } else {
      setAlert("Serving size cannot be longer than 48 characters", "warning");
    }
  };

  const [servingSize, setServingSize] = useState(sections[sectionIndex]?.servingSize || "");
  const [description, setDescription] = useState(null);
  const [showServingSizeDesc, setShowServingSizeDesc] = useState(false);

  const [macronutrient, setMacronutrient] = useState(null);
  const [amount, setAmount] = useState(null);
  const [showAddMacroModal, setShowAddMacroModal] = useState(false);

  useEffect(() => {
    if (sectionIndex !== null) {
      setServingSize(sections[sectionIndex].servingSize);
    }

    return () => {
      setServingSize("");
    };
  }, [sectionIndex]);

  return (
    <>
      <RecipeAddMacroModal open={showAddMacroModal} handleClose={handleAddMacroClose} sectionIndex={sectionIndex} />
      <CustomModal open={visible} handleClose={handleClose}>
        <div className="flex flex-col px-4 ">
          <div>
            <div className="w-full mb-4">
              {sectionIndex !== null && sections[sectionIndex]?.title && (
                <Typography variant="h6" className="font-normal leading-none mb-1">
                  {sections[sectionIndex].title} Macronutrients
                </Typography>
              )}
            </div>
            <div className="flex flex-col gap-2 ">
              <TextField
                label="Serving Size"
                helperText="The size of one serving (eg. 1 cup, 45 g, 27 cookies)"
                size="small"
                value={servingSize}
                onChange={(e) => handleServingSizeChange(e)}
              />
            </div>
          </div>
          <div className="py-4">
            {sections[sectionIndex] && sections[sectionIndex].macros && sections[sectionIndex].macros.length > 0 ? (
              <div>
                {sections[sectionIndex].macros.map((macro, i) => (
                  <div
                    className="flex flex-row justify-between items-center"
                    key={`${sectionIndex}-${macro.name}-${i}`}
                  >
                    <Typography variant="subtitle2" className="font-normal">
                      {macro.macronutrient?.display ? macro.macronutrient.display : macro.macronutrient.name}
                    </Typography>
                    <div className="flex-1 border-b border-separator mx-2"></div>
                    <div className="flex flex-row">
                      <Typography variant="body2">{macro.amount}</Typography>
                      {macro.macronutrient?.unit && (
                        <Typography variant="body2" className="ml-2">
                          {macro.macronutrient.unit}
                        </Typography>
                      )}
                      <Button
                        size="small"
                        color="primary"
                        className="px-2 py-0 text-red min-w-0 hover:bg-transparent"
                        onClick={() => removeSectionMacro(sectionIndex, i)}
                        disableRipple
                      >
                        <Typography variant="caption">x</Typography>
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="py-2 cursor-pointer" onClick={() => setShowAddMacroModal(true)}>
                <Typography variant="subtitle2" className="font-normal">
                  No Macros Added
                </Typography>
                <Typography variant="caption" className="text-secondaryText">
                  Add a macronutrient to get started
                </Typography>
              </div>
            )}
          </div>
          <div className="mb-4">
            <Button
              fullWidth
              disableElevation
              variant="contained"
              color="primary"
              onClick={() => setShowAddMacroModal(true)}
            >
              + Add Macronutrients
            </Button>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

RecipeSectionMacrosModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  sections: PropTypes.array.isRequired,
  setSectionMacroServingSize: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  visible: state.recipe.create.modals.editMacros.open,
  sectionIndex: state.recipe.create.modals.editMacros.sectionIndex,
  sections: state.recipe.create.sections,
});

export default connect(mapStateToProps, {
  toggleRecipeMacrosModal,
  pushSectionMacro,
  removeSectionMacro,
  setAlert,
  setSectionMacroServingSize,
})(RecipeSectionMacrosModal);
