import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

const TChart = () => {
  const options = ["Free", "thisPantry+"];
  const features = [
    "Tastes",
    "Allergies and Dietary Restrictions",
    "On-Hand Ingredients",
    "Cooking Ability",
    "Nutrition",
    "Generate Recipes with AI",
  ];
  /*
    "Recipe Pairings",
    "Drink Pairings", */
  const featureSets = [
    { name: "Free", values: [true, true, false, false, false, false] },
    { name: "thisPantry Plus", values: [true, true, true, true, true, true] },
  ];

  return (
    <div className="mt-8 rounded-[16px]">
      <Table className="mx-4 w-[calc(100%-2rem)] md:w-full md:mx-0">
        <TableHead>
          <TableRow>
            <TableCell align="left" className="border-b border-secondaryText">
              thisPantry Suggests Recipes Based On:
            </TableCell>
            {options.map((option, index) => (
              <TableCell key={index} align="center" className="border-b border-secondaryText">
                {option === "Free" ? (
                  <span>Free</span>
                ) : (
                  <div className="leading-none flex items-center justify-center text-start">
                    this<br></br>Pantry+
                  </div>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {features.map((feature, fIndex) => (
            <TableRow key={fIndex}>
              <TableCell align="left" className="border-b border-secondaryText">
                {feature}
              </TableCell>
              {featureSets.map((featureSet, fsIndex) => (
                <TableCell key={fsIndex} align="center" className="border-b border-secondaryText">
                  {featureSet.values[fIndex] ? (
                    <FontAwesomeIcon icon={faCheck} className="text-2xl text-mainGreen" />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} className="text-2xl text-rose-500" />
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default TChart;
