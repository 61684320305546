import React from "react";
import { Badge } from "../../../../ui/badge";
import { Typography } from "@mui/material";

const RecipeTag = ({ tag }) => {
  return (
    <Badge variant="primary" className="font-normal mr-[0.25rem] mb-[0.25rem] py-1 px-3 shadow-sm cursor-pointer">
      <Typography variant="body2" className="text-xs">
        {tag.text}
      </Typography>
    </Badge>
  );
};

export default RecipeTag;
