import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Button, Typography } from "@mui/material";

//Modal Pages
import ScanReceipt from "./ScanReceipt";
import ScanBarcode from "./ScanBarcode";
import AddIndividualItem from "./AddIndividualItem";
import VoiceToPantry from "./VoiceToPantry";

const Initial = ({}) => {
  const [pageToShow, setPageToShow] = useState(-1);

  const handleScanReceiptClick = () => {
    setPageToShow(0);
  };

  const handleScanBarcodeClick = () => {
    setPageToShow(1);
  };

  const handleAddIndividualItemClick = () => {
    setPageToShow(2);
  };

  const handleVoiceToPantryClick = () => {
    setPageToShow(3);
  };

  const initialHTML = (
    <div className="flex flex-col justify-between min-h-[25vh]">
      <div className="mb-4">
        <Typography variant="h5">Add Ingredients</Typography>
      </div>
      <div className="mb-2">
        <Button variant="outlined" size="large" color="secondary" fullWidth onClick={handleScanReceiptClick}>
          + Scan Receipt
        </Button>
      </div>
      <div className="mb-2">
        <Button variant="outlined" size="large" color="secondary" fullWidth onClick={handleScanBarcodeClick}>
          + Scan Barcode/UPC
        </Button>
      </div>
      <div className="mb-2">
        <Button variant="outlined" size="large" color="secondary" fullWidth onClick={handleAddIndividualItemClick}>
          + Add Individual Item
        </Button>
      </div>
      <div className="mb-2">
        <Button variant="outlined" size="large" color="secondary" fullWidth onClick={handleVoiceToPantryClick}>
          + Add Items By Voice
        </Button>
      </div>
    </div>
  );

  return (
    <div className="mx-4 max-h-[90vh]">
      {pageToShow === -1 && initialHTML}
      {pageToShow === 0 && <ScanReceipt />}
      {pageToShow === 1 && <ScanBarcode />}
      {pageToShow === 2 && <AddIndividualItem />}
      {pageToShow === 3 && <VoiceToPantry />}
    </div>
  );
};

export default Initial;
