import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Typography, Button } from "@mui/material";

//Default PFP
import { ReactComponent as DefaultPFP } from "../../../assets/default/profile_picture.svg";

//Utils
import { shortTimeSince } from "../../../utils/date";

const DefaultNotification = ({ notification, index, isLast }) => {
  return (
    <div
      className="w-full flex flex-row items-start p-4 border-b border-separator"
      style={{ borderBottom: isLast ? "none" : "inherit" }}
    >
      <div className="w-full flex flex-row justify-between">
        <div className="w-full flex flex-col justify-start">
          {notification?.from?.username && (
            <div className="flex flex-row items-center">
              <div className="mr-2">
                {notification?.from?.picture && notification.from.picture !== "" ? (
                  <Link to={`/${notification.from.username}`}>
                    <img
                      src={notification.from.picture}
                      alt="User"
                      className="w-6 h-6 rounded-full border border-separator"
                    />
                  </Link>
                ) : (
                  <Link to={`/${notification.from.username}`}>
                    <DefaultPFP alt="User" className="w-6 h-6 rounded-full border border-separator" />
                  </Link>
                )}
              </div>
              <Link to={`/${notification.from.username}`} className="flex flex-row items-center">
                <Typography
                  variant="subtitle1"
                  className="text-mainGreen text-normal leading-none"
                >{`@${notification.from.username}`}</Typography>
              </Link>
              <div className="flex flex-row items-center">
                {notification.date && (
                  <Typography variant="body2" className="text-normal text-secondaryText ml-2 text-xs">
                    {shortTimeSince(notification.date)}
                  </Typography>
                )}

                {!notification.read && <div className="h-1 w-1 rounded-full bg-mainGreen ml-2"></div>}
              </div>
            </div>
          )}

          <div className="mt-2">
            {notification.url ? (
              <Link to={notification.url} className="h-full">
                <Typography variant="body1" className="h-full leading-tight">
                  {notification.text}
                </Typography>
              </Link>
            ) : (
              <Typography variant="body1" className="h-full leading-tight">
                {notification.text}
              </Typography>
            )}
          </div>
        </div>
        {notification.content && notification.content.length > 0 && (
          <>
            {notification.url ? (
              <Link to={notification.url}>
                <div className="ml-2">
                  <img
                    src={notification.content[0].url}
                    alt="Content"
                    className="w-[5rem] aspect-square object-cover rounded"
                  />
                </div>
              </Link>
            ) : (
              <div className="ml-2">
                <img
                  src={notification.content[0].url}
                  alt="Content"
                  className="w-[5rem] aspect-square object-cover rounded"
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default DefaultNotification;
