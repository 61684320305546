import React, { useState, useEffect } from "react";
import api from "../../../api/api";

import CompoundBarChart from "../../charts/CompoundBarChart"; //<CompoundBarChart data={pastWeekExpenses} referenceLine={dailyBudget} referenceLineLabel={""} />
import { Button, Typography } from "@mui/material";
import MultiAreaChart from "../../charts/MultiAreaChart";
import Expense from "../components/Expense";
import Spinner from "../../layout/Spinner";
export const Expenses = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [expenses, setExpenses] = useState(null);

  const fetchExpenses = async () => {
    try {
      const res = await api.get("/expenses/demo");
      setExpenses(res.data);
      setLoading(false);
      setError(false);
    } catch (error) {
      setError(true);
      setLoading(false);
      setExpenses(null);
    }
  };

  useEffect(() => {
    fetchExpenses();
  }, []);

  const [currentDate, setCurrentDate] = useState(new Date());

  const monthlyBudget = 600; // $600 per month
  const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
  const dailyBudget = monthlyBudget / daysInMonth;

  const mealPlan = [
    {
      type: "Breakfast",
      recipes: [
        {
          id: "1",
          name: "Oatmeal with Berries",
          ingredients: [
            { name: "Oats", amount: 50, unit: "g" },
            { name: "Mixed Berries", amount: 100, unit: "g" },
            { name: "Almond Milk", amount: 200, unit: "ml" },
          ],
          macros: { calories: 300, protein: 10, carbs: 50, fat: 5 },
          cost: 2.5,
        },
        {
          id: "2",
          name: "Scrambled Eggs",
          ingredients: [
            { name: "Eggs", amount: 2, unit: "large" },
            { name: "Milk", amount: 30, unit: "ml" },
            { name: "Butter", amount: 5, unit: "g" },
          ],
          macros: { calories: 220, protein: 14, carbs: 2, fat: 16 },
          cost: 1.75,
        },
      ],
    },
    {
      type: "Lunch",
      recipes: [
        {
          id: "3",
          name: "Grilled Chicken Salad",
          ingredients: [
            { name: "Chicken Breast", amount: 150, unit: "g" },
            { name: "Mixed Greens", amount: 100, unit: "g" },
            { name: "Olive Oil", amount: 15, unit: "ml" },
          ],
          macros: { calories: 350, protein: 40, carbs: 10, fat: 20 },
          cost: 5.75,
        },
        {
          id: "4",
          name: "Quinoa Side",
          ingredients: [
            { name: "Quinoa", amount: 50, unit: "g" },
            { name: "Vegetable Broth", amount: 100, unit: "ml" },
          ],
          macros: { calories: 180, protein: 6, carbs: 32, fat: 3 },
          cost: 1.5,
        },
      ],
    },
    {
      type: "Dinner",
      recipes: [
        {
          id: "5",
          name: "Salmon Fillet",
          ingredients: [
            { name: "Salmon Fillet", amount: 180, unit: "g" },
            { name: "Lemon Juice", amount: 15, unit: "ml" },
            { name: "Dill", amount: 5, unit: "g" },
          ],
          macros: { calories: 330, protein: 34, carbs: 0, fat: 22 },
          cost: 7.5,
        },
        {
          id: "6",
          name: "Roasted Vegetables",
          ingredients: [
            { name: "Broccoli", amount: 100, unit: "g" },
            { name: "Carrots", amount: 100, unit: "g" },
            { name: "Olive Oil", amount: 10, unit: "ml" },
          ],
          macros: { calories: 120, protein: 3, carbs: 14, fat: 7 },
          cost: 2.0,
        },
        {
          id: "7",
          name: "Mashed Potatoes",
          ingredients: [
            { name: "Potatoes", amount: 200, unit: "g" },
            { name: "Milk", amount: 50, unit: "ml" },
            { name: "Butter", amount: 15, unit: "g" },
          ],
          macros: { calories: 220, protein: 4, carbs: 35, fat: 9 },
          cost: 1.75,
        },
      ],
    },
    {
      type: "Snacks/Other",
      recipes: [
        {
          id: "8",
          name: "Greek Yogurt with Honey",
          ingredients: [
            { name: "Greek Yogurt", amount: 150, unit: "g" },
            { name: "Honey", amount: 15, unit: "g" },
          ],
          macros: { calories: 170, protein: 15, carbs: 20, fat: 5 },
          cost: 2.25,
        },
        {
          id: "9",
          name: "Mixed Nuts",
          ingredients: [{ name: "Assorted Nuts", amount: 30, unit: "g" }],
          macros: { calories: 180, protein: 6, carbs: 5, fat: 16 },
          cost: 1.5,
        },
      ],
    },
  ];

  const totalExpenses = mealPlan.flatMap((meal) => meal.recipes).reduce((acc, recipe) => acc + recipe.cost, 0);
  const isOnTrack = totalExpenses <= dailyBudget;

  // Generate mock data for the past week's expenses with categories
  const pastWeekExpenses = Array.from({ length: 10 }, (_, i) => {
    const date = new Date(currentDate);
    date.setDate(date.getDate() - i);
    const total = Math.random() * (dailyBudget * 1.5 - dailyBudget * 0.5) + dailyBudget * 0.5;
    const groceriesProportion = 0.6 + (Math.random() * 0.2 - 0.1);
    const restaurantsProportion = 0.3 + (Math.random() * 0.2 - 0.1);
    const otherProportion = 0.1 + (Math.random() * 0.2 - 0.1);
    const totalProportion = groceriesProportion + restaurantsProportion + otherProportion;
    return {
      date: date,
      groceries: total * (groceriesProportion / totalProportion),
      restaurants: total * (restaurantsProportion / totalProportion),
      other: total * (otherProportion / totalProportion),
    };
  }).reverse();
  return (
    <div className="w-full h-full flex flex-col gap-2">
      <div className="p-4 border border-separator md:rounded-md shadow-sm bg-background">
        <div className="flex flex-row justify-between items-center">
          <Typography variant="h6" className="leading-none font-normal">
            Expenses
          </Typography>
          <Button variant="contained" color="secondary" size="small" disableElevation className="text-primaryText">
            + Add Expense
          </Button>
        </div>
      </div>
      <div className="w-full h-[300px] p-4 border border-separator md:rounded-md shadow-sm bg-background">
        <CompoundBarChart data={pastWeekExpenses} referenceLine={dailyBudget} referenceLineLabel={"Daily Budget"} />
      </div>

      <div className="flex flex-col gap-2">
        <div className="flex flex-col md:flex-row md:gap-2">
          <div className="w-full p-4 border border-separator md:rounded-md shadow-sm bg-background">
            <Typography variant="subtitle1" className="text-secondaryText">
              Groceries
            </Typography>
            <Typography variant="h5">${pastWeekExpenses[6].groceries.toFixed(2)}</Typography>
          </div>
          <div className="w-full p-4 border border-separator md:rounded-md shadow-sm bg-background">
            <Typography variant="subtitle1" className="text-secondaryText">
              Restaurants
            </Typography>
            <Typography variant="h5">${pastWeekExpenses[6].restaurants.toFixed(2)}</Typography>
          </div>
          <div className="w-full p-4 border border-separator md:rounded-md shadow-sm bg-background">
            <Typography variant="subtitle1" className="text-secondaryText">
              Other
            </Typography>
            <Typography variant="h5">${pastWeekExpenses[6].other.toFixed(2)}</Typography>
          </div>
        </div>
        <div className="w-full p-4 border border-separator md:rounded-md shadow-sm bg-background">
          {loading ? (
            <Spinner />
          ) : error ? (
            <Typography variant="h6">Error fetching expenses</Typography>
          ) : (
            expenses.map((expense) => <Expense expense={expense} />)
          )}
        </div>
        <div className="w-full border border-seprator md:rounded-md shadow-sm flex justify-center">
          <Button fullWidth className="bg-background">
            <Typography variant="subtitle2" className="font-normal">
              View Expenses
            </Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Expenses;
