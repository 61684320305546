import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import api from "../../api/api";

//Material UI Components
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as PlusIcon } from "../../assets/icons/svg/leaf-solid.svg";

//Assets
import { ReactComponent as FilledHeartIcon } from "../../assets/icons/svg/heart-solid.svg";
import { ReactComponent as HeartIcon } from "../../assets/icons/svg/heart.svg";
import { ReactComponent as CommentIcon } from "../../assets/icons/svg/comment.svg";
import { ReactComponent as ShareIcon } from "../../assets/icons/svg/new-message.svg";
import { ReactComponent as BookmarkIcon } from "../../assets/icons/svg/bookmark.svg";
import { ReactComponent as FilledBookmarkIcon } from "../../assets/icons/svg/bookmark_filled.svg";
import { ReactComponent as RepostIcon } from "../../assets/icons/svg/repost.svg";

import ProfileImg from "../../assets/default/user.jpg";

//Components
import CommentHighlight from "./components/CommentHighlight";
import ImageCarousel from "./types/components/ImageCarousel";
import RecipeContent from "./types/RecipeContent";
import MediaContent from "./types/MediaContent";
import TextContent from "./types/TextContent";
import CustomModal from "../modal/CustomModal";

//Modals
import PostCommentModal from "./modals/PostCommentModal";
import PostShareModal from "./modals/PostShareModal";
import PostOptionsModal from "./modals/PostOptionsModal";

//Utils
import { shortTimeSince } from "../../utils/date";
import { shortInteractionCount } from "../../utils/post";

//Actions
import { showRegisterModal } from "../../actions/auth";
import { setAlert } from "../../actions/alert";
const Post = ({
  post: { blockRepost, date, error, loading, postData, postid },
  profile: { currentProfile },
  fullWidth = false,
  auth: { isAuthenticated },
  showRegisterModal,
  setAlert,
}) => {
  //MUI Styling

  //Interaction State
  const [hasLiked, setHasLiked] = useState(postData && postData.interactions.hasLiked);
  const [likesCount, setLikesCount] = useState(postData && postData.interactions.likesCount);
  const [commentsCount, setCommentsCount] = useState(postData && postData.interactions.commentsCount);
  const [repostsCount, setRepostsCount] = useState(postData && postData.interactions.repostsCount);
  const [repostLoading, setRepostLoading] = useState(false);
  const [hasCommented, setHasCommented] = useState(postData && postData.interactions.hasCommented);
  const [hasReposted, setHasReposted] = useState(postData && postData.interactions.hasReposted);
  const [hasBookmarked, setHasBookmarked] = useState(postData && postData.interactions.hasBookmarked);
  const [bookmarksCount, setBookmarksCount] = useState(postData && postData.interactions.bookmarksCount);
  const [bookmarkLoading, setBookmarkLoading] = useState(false);

  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showOptionsModal, setShowOptionsModal] = useState(false);

  //Interaction functions
  const onLike = async () => {
    if (isAuthenticated) {
      setHasLiked(true);
      setLikesCount(likesCount + 1);
      try {
        const res = await api.post(`/post/like/${postData.id}`);
      } catch (error) {
        console.log(error);
        setHasLiked(false);
        setLikesCount(likesCount - 1 > 0 ? likesCount - 1 : 0);
      }
    } else {
      showRegisterModal();
    }
  };

  const onUnlike = async () => {
    if (isAuthenticated) {
      setHasLiked(false);
      setLikesCount(likesCount - 1 > 0 ? likesCount - 1 : 0);
      try {
        const res = await api.post(`/post/unlike/${postData.id}`);
      } catch (error) {
        console.log(error);
        setHasLiked(true);
        setLikesCount(likesCount + 1);
      }
    } else {
      showRegisterModal();
    }
  };

  const handleCommentsClick = () => {
    if (isAuthenticated) {
      setShowCommentModal(true);
    } else {
      showRegisterModal();
    }
  };

  const onCommentModalClose = () => {
    setShowCommentModal(false);
    //closePostComments();
  };

  const onBookmarkRecipe = async () => {
    if (isAuthenticated) {
      try {
        if (!bookmarkLoading) {
          setBookmarkLoading(true);
          setHasBookmarked(true);
          setBookmarksCount(bookmarksCount + 1);
          const res = await api.post(`/recipes/bookmark/${postData.recipe.id}`);

          setAlert(`${postData.recipe.name} added to bookmarks`, "success", 3000);
          setBookmarkLoading(false);
        }
      } catch (error) {
        setBookmarksCount(bookmarksCount - 1 > 0 ? bookmarksCount - 1 : 0);
        setBookmarkLoading(false);
        setHasBookmarked(false);
      }
    } else {
      showRegisterModal();
    }
  };

  const onUnbookmarkRecipe = async () => {
    if (isAuthenticated) {
      try {
        if (!bookmarkLoading) {
          setBookmarkLoading(true);
          setHasBookmarked(false);
          setBookmarksCount(bookmarksCount - 1 > 0 ? bookmarksCount - 1 : 0);
          const res = await api.post(`/recipes/unbookmark/${postData.recipe.id}`);

          setBookmarkLoading(false);
        }
      } catch (error) {
        console.log(error);
        if (bookmarksCount !== 0) {
          setBookmarksCount(bookmarksCount + 1);
        }

        setBookmarkLoading(false);
        setHasBookmarked(true);
      }
    } else {
      showRegisterModal();
    }
  };

  const handleRepost = async () => {
    setShowShareModal(false);
    setRepostLoading(true);
    if (isAuthenticated) {
      if (hasReposted) {
        try {
          setHasReposted(false);
          setRepostsCount(repostsCount - 1 > 0 ? repostsCount - 1 : 0);
          const res = await api.delete(`/post/unrepost/${postData.id}`);
          setAlert("Post removed from your profile", "success", 3000);
        } catch (error) {
          console.log(error);
          setAlert("Error removing post from your profile. Please refresh and try again", "error", 3000);
          setHasReposted(true);
          if (repostsCount !== 0) {
            setRepostsCount(repostsCount + 1);
          }
        }
      } else {
        try {
          setHasReposted(true);
          setRepostsCount(repostsCount + 1);
          const res = await api.post(`/post/repost/${postData.id}`);
          setAlert("Post reposted to your profile", "success", 3000);
        } catch (error) {
          console.log(error);
          setAlert("Error reposting post. Please refresh and try again", "error", 3000);
          setHasReposted(false);
          setRepostsCount(repostsCount - 1 > 0 ? repostsCount - 1 : 0);
        }
      }
    } else {
      showRegisterModal();
    }
  };

  return (
    <div>
      <CustomModal open={showCommentModal} handleClose={onCommentModalClose} type="bottom">
        <PostCommentModal
          postid={postid}
          handleClose={onCommentModalClose}
          commentCount={commentsCount}
          setCommentCount={setCommentsCount}
        />
      </CustomModal>
      <CustomModal
        open={showShareModal}
        handleClose={() => setShowShareModal(false)}
        contentStyleOverride={{ marginBottom: 0 }}
      >
        <PostShareModal
          post={postData}
          handleClose={() => setShowShareModal(false)}
          blockRepost={blockRepost}
          handleRepost={handleRepost}
          hasReposted={hasReposted}
        />
      </CustomModal>
      <CustomModal
        open={showOptionsModal}
        handleClose={() => setShowOptionsModal(false)}
        contentStyleOverride={{ marginBottom: 0 }}
      >
        <PostOptionsModal post={postData} handleClose={() => setShowOptionsModal(false)} />
      </CustomModal>
      <article
        className="w-full max-w-[768px]  text-primaryText border-y border-separator md:rounded-lg md:border md:shadow-sm bg-background"
        style={{ maxWidth: fullWidth ? "100%" : "" }}
      >
        <div className="w-full flex flex-row justify-between px-4 my-2">
          <div className="flex items-center">
            {postData.repost ? (
              <Link to={`/${postData && postData.user.username}`}>
                <div className="flex flex-row items-center">
                  <div className="relative pr-2">
                    {postData && postData.user && postData.user.picture !== "" ? (
                      <img
                        src={postData.user.picture}
                        alt={`${postData.user.username}'s profile`}
                        className="h-[2rem] w-[2rem] object-cover border border-secondaryText rounded-[50%]"
                      ></img>
                    ) : (
                      <img
                        src={ProfileImg}
                        alt={`${postData && postData.user.username}'s profile`}
                        className="h-[2rem] w-[2rem] object-cover border border-secondaryText rounded-[50%]"
                      ></img>
                    )}
                    {postData.repost && postData.repost.picture !== "" ? (
                      <img
                        src={postData.repost.picture}
                        alt={`${postData.repost.username}'s profile`}
                        className="h-[1.25rem] w-[1.25rem] object-cover border border-secondaryText rounded-[50%] absolute bottom-0 right-0"
                      ></img>
                    ) : (
                      <img
                        src={ProfileImg}
                        alt={`${postData.repost.username}'s profile`}
                        className="h-[1.25rem] w-[1.25rem] object-cover border border-secondaryText rounded-[50%] absolute bottom-0 right-0"
                      ></img>
                    )}
                  </div>
                  <div>
                    <div className="flex flex-col">
                      <div className="flex flex-row items-center">
                        <Typography variant="subtitle2" className="ml-2 font-normal leading-none">{`@${
                          postData && postData.user.username
                        }`}</Typography>
                        {postData && postData.user?.isPlus && (
                          <PlusIcon className="h-[0.75rem] w-[0.75rem] fill-mainGreen ml-1" />
                        )}

                        <Typography variant="caption" className="text-secondaryText font-normal leading-none ml-2">
                          {shortTimeSince(postData.repost.date)}
                        </Typography>
                      </div>

                      <Link to={`/${postData.repost.username}`}>
                        <div className="flex flex-row mt-1 ml-2 items-center">
                          <RepostIcon className="h-[0.75rem] fill-mainGreen" />
                          <Typography variant="caption" className="ml-1 font-normal leading-none">{`@${
                            postData && postData.repost.username
                          }`}</Typography>
                          {postData && postData.repost?.isPlus && (
                            <PlusIcon className="h-[0.66rem] w-[0.66rem] fill-mainGreen ml-1" />
                          )}
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </Link>
            ) : (
              <Link to={`/${postData && postData.user.username}`}>
                <div className="flex flex-row items-center">
                  {postData && postData.user && postData.user.picture !== "" ? (
                    <img
                      src={postData.user.picture}
                      alt={`${postData.user.username}'s profile`}
                      className="h-[2rem] w-[2rem] object-cover border border-secondaryText rounded-[50%]"
                    ></img>
                  ) : (
                    <img
                      src={ProfileImg}
                      alt={`${postData && postData.user.username}'s profile`}
                      className="h-[2rem] w-[2rem] object-cover border border-secondaryText rounded-[50%]"
                    ></img>
                  )}

                  <Typography variant="subtitle2" className="ml-2 font-normal leading-none">{`@${
                    postData && postData.user.username
                  }`}</Typography>
                  {postData && postData.user?.isPlus && (
                    <PlusIcon className="h-[0.75rem] w-[0.75rem] fill-mainGreen ml-1" />
                  )}
                  <Typography variant="caption" className="text-secondaryText font-normal ml-2 leading-none">
                    {shortTimeSince(postData.date)}
                  </Typography>
                </div>
              </Link>
            )}
          </div>
          <div>
            <div className="relative flex items-center justify-center h-full">
              <IconButton
                onClick={() => setShowOptionsModal(true)}
                size="large"
                disableRipple
                className="hover:bg-transparent px-0"
              >
                <FontAwesomeIcon icon={faEllipsis} className="w-[1rem] h-auto block text-primaryText" />
              </IconButton>
            </div>
          </div>
        </div>
        <div className="w-full h-full object-cover overflow-x-hidden ">
          {postData.type === "recipe" && <RecipeContent recipe={postData.recipe} />}
          {postData.type === "media" && <MediaContent post={postData} />}
          {postData.type === "text" && <TextContent post={postData} />}
        </div>
        <div className="w-full flex flex-row justify-between px-4 pt-4 pb-2">
          <div className="flex flex-row justify-between w-full">
            <div className="flex flex-row">
              <div className="flex flex-col items-center mr-4">
                <div className="w-full relative h-[1.75rem]">
                  {hasLiked ? (
                    <IconButton
                      className="p-0 hover:bg-transparent"
                      disableRipple
                      onClick={() => onUnlike()}
                      size="large"
                    >
                      <FilledHeartIcon className="h-[1.5rem] fill-mainGreen hover:fill-mainGreen-600" />
                    </IconButton>
                  ) : (
                    <IconButton
                      className="p-0 hover:bg-transparent"
                      disableRipple
                      onClick={() => onLike()}
                      size="large"
                    >
                      <HeartIcon className="h-[1.5rem] hover:fill-secondaryText" />
                    </IconButton>
                  )}
                </div>

                <Typography variant="body2" className="text-secondaryText">
                  {shortInteractionCount(likesCount)}
                </Typography>
              </div>
              <div className="flex flex-col items-center mr-4">
                <div className="w-full relative h-[1.75rem]">
                  <IconButton
                    className="p-0 hover:bg-transparent"
                    disableRipple
                    onClick={() => handleCommentsClick()}
                    size="large"
                  >
                    <CommentIcon className="h-[1.5rem] hover:fill-secondaryText" />
                  </IconButton>
                </div>

                <Typography variant="body2" className="text-secondaryText">
                  {shortInteractionCount(commentsCount)}
                </Typography>
              </div>
              <div className="flex flex-col items-center mr-4">
                <div className="w-full relative h-[1.75rem]">
                  <IconButton
                    className="p-0 hover:bg-transparent"
                    disableRipple
                    onClick={() => setShowShareModal(true)}
                    size="large"
                    disabled={repostLoading}
                  >
                    <ShareIcon className="h-[1.5rem] hover:fill-secondaryText" />
                  </IconButton>
                </div>

                <Typography variant="body2" className="text-secondaryText">
                  {shortInteractionCount(repostsCount)}
                </Typography>
              </div>
            </div>
            {postData.type === "recipe" && (
              <div className="flex flex-col items-center ml-4">
                <div className="w-full relative h-[1.75rem]">
                  {hasBookmarked ? (
                    <IconButton
                      className="p-0 hover:bg-transparent"
                      disableRipple
                      onClick={() => onUnbookmarkRecipe()}
                      size="large"
                    >
                      <FilledBookmarkIcon className="h-[1.5rem] fill-mainGreen hover:fill-mainGreen-600" />
                    </IconButton>
                  ) : (
                    <IconButton
                      className="p-0 hover:bg-transparent"
                      disableRipple
                      onClick={() => onBookmarkRecipe()}
                      size="large"
                    >
                      <BookmarkIcon className="h-[1.5rem] hover:fill-secondaryText" />
                    </IconButton>
                  )}
                </div>

                <Typography variant="body2" className="text-secondaryText">
                  {shortInteractionCount(bookmarksCount)}
                </Typography>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-row items-center p-4 pt-2 cursor-pointer" onClick={() => handleCommentsClick()}>
          <div>
            {currentProfile && currentProfile.picture !== "" ? (
              <img
                src={currentProfile.picture}
                alt={"My Profile"}
                className="rounded-[50%] border border-secondaryText h-[1.5rem] w-[1.5rem] object-cover mr-4"
              ></img>
            ) : (
              <img
                src={ProfileImg}
                alt={"My Profile"}
                className="rounded-[50%] border border-secondaryText h-[1.5rem] w-[1.5rem] object-cover mr-4"
              ></img>
            )}
          </div>
          <Typography variant="caption" className="text-secondaryText font-normal">
            Add a comment...
          </Typography>
        </div>
        {postData && postData.interactions && postData.interactions.topComment && (
          <div onClick={() => setShowCommentModal(true)}>
            <CommentHighlight data={postData.interactions.topComment} />
          </div>
        )}
      </article>
    </div>
  );
};

Post.propTypes = {
  // isAuthenticated: PropTypes.bool,
  post: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  showRegisterModal: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  // isAuthenticated: state.auth.isAuthenticated,
  profile: state.profile,
  auth: state.auth,
});

export default connect(mapStateToProps, { showRegisterModal, setAlert })(Post);
