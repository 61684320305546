//Import redux types
import {
  GET_FEED_POST_ERROR,
  GET_FEED_POST,
  GET_HOME_FEED,
  HOME_FEED_ERROR,
  GET_POST_COMMENTS,
  GET_POST_COMMENTS_ERROR,
  GET_POST_COMMENTS_RESET,
  SET_HOME_FEED,
  SET_POST_INTERACTIONS,
  FEED_DELETE_COMMENT,
} from "../actions/types";

//Initial state object
const initialState = {
  posts: null,
  loading: true,
  error: false,
  offset: 0,
  isEndOfFeed: false,
  numPosts: null,

  comments: [],
  commentsLoading: true,
  commentsError: false,
};

export default function (state = initialState, action) {
  //Destructure action
  const { type, payload } = action;

  switch (type) {
    case FEED_DELETE_COMMENT:
      //Remove comment from state
      //Update comment numbers for post for all posts in feed/state
      return {
        ...state,
        posts: state.posts
          ? state.posts.map((post) => {
              if (post.postid.toString() === payload.postid.toString()) {
                return {
                  ...post,
                  postData: {
                    ...post.postData,
                    interactions: {
                      ...post.postData.interactions,
                      commentCount:
                        post.postData.interactions.commentCount - 1 > 0
                          ? post.postData.interactions.commentCount - 1
                          : 0,
                    },
                  },
                };
              }
              return post;
            })
          : null,
      };
    case GET_HOME_FEED:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case SET_HOME_FEED:
      return {
        ...state,
        posts: payload.posts,
        loading: false,
        error: false,
        offset: payload.offset,
        isEndOfFeed: payload.isEndOfFeed,
        numPosts: payload.numPosts,
      };
    case GET_FEED_POST:
      return {
        ...state,
        posts: state.posts.map((post, index) => {
          if (index === payload.index) {
            return { ...post, postData: payload.data, error: false, loading: false };
          }
          return post;
        }),
      };
    case GET_FEED_POST_ERROR:
      return {
        ...state,
        posts: state.posts.map((post, index) => {
          if (index === payload.index) {
            return { ...post, postData: null, error: true, loading: false };
          }
          return post;
        }),
      };
    case HOME_FEED_ERROR:
      return {
        ...state,
        posts: null,
        loading: false,
        error: true,
      };
    case GET_POST_COMMENTS:
      return {
        ...state,
        comments: payload,
        commentsLoading: false,
        commentsError: false,
      };
    case GET_POST_COMMENTS_ERROR:
      return {
        ...state,
        comments: [],
        commentsLoading: false,
        commentsError: true,
      };
    case GET_POST_COMMENTS_RESET:
      return {
        ...state,
        comments: [],
        commentsLoading: true,
        commentsError: false,
      };
    case SET_POST_INTERACTIONS:
      if (state.posts) {
        return {
          ...state,
          posts: state.posts.map((post, index) => {
            if (post.postid === payload.postid) {
              return {
                ...post,
                postData: {
                  ...post.postData,
                  interactions: { ...post.postData.interactions, ...payload.interactions },
                },
              };
            }
            return post;
          }),
        };
      } else {
        return state;
      }

    default:
      return state;
  }
}
