import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//DOM imports
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

//UI Components
import Searchable from "../../layout/searchable/Searchable";

//MUI
import { Button, IconButton, Typography, Divider, TextField, Chip } from "@mui/material";
import { Badge } from "../../ui/badge";

//Default PFP
import { ReactComponent as DefaultPFP } from "../../../assets/default/profile_picture.svg";

//Icons
import { ReactComponent as DietaryIcon } from "../../../assets/icons/svg/stomach-light.svg";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as ChevronRight } from "../../../assets/icons/svg/chevron-right.svg";
import { ReactComponent as TitleIcon } from "../../../assets/icons/svg/stomach-light.svg";

//API
import api from "../../../api/api";

//Actions
import { setAlert } from "../../../actions/alert";

const DietarySettings = ({ profile: { currentProfile }, auth: { user }, setAlert }) => {
  const [allergens, setAllergens] = useState([]);
  const [diets, setDiets] = useState([]);
  const [dislikes, setDislikes] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [saving, setSaving] = useState(false);

  const onAllergenChange = (items) => {
    setAllergens(items);
    console.log(items);
  };

  const onDietChange = (items) => {
    setDiets(items);
  };

  const onDislikeChange = (items) => {
    setDislikes(items);
  };

  const saveDietarySettings = async () => {
    try {
      setSaving(true);

      const bodyAllergens = allergens.map((allergen) => allergen.name);
      const bodyDiets = diets.map((diet) => diet.name);
      const bodyDislikes = dislikes.map((dislike) => dislike.name);

      const res = await api.put("/settings/dietary", {
        allergens: bodyAllergens,
        diets: bodyDiets,
        dislikes: bodyDislikes,
      });

      setAlert("Dietary settings saved", "success");
      setSaving(false);
    } catch (error) {
      setAlert("Error saving dietary settings", "error");
      setSaving(false);
      console.error(error);
    }
  };

  const fetchDietarySettings = async () => {
    try {
      const res = await api.get("/settings/dietary");

      if (res.data) {
        setAllergens(res.data.allergens);
        setDiets(res.data.diets);
        setDislikes(res.data.dislikes);
        setError(false);
      } else {
        setError(true);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(true);
      setLoading(false);
    }
    //Fetch user's dietary settings
  };

  useEffect(() => {
    fetchDietarySettings();
  }, []);

  return (
    <>
      <Helmet>
        <title>Dietary Settings</title>
      </Helmet>

      <div className="my-[4rem] md:my-0 md:pt-6 bg-neutral-50 min-h-screen">
        <div className="w-full md:ml-[5rem]">
          <div className="px-4 py-3 md:px-8 md:py-6 md:mx-auto w-full md:max-w-[600px] border-b md:border border-separator md:rounded-[16px] bg-background">
            <div className="flex flex-col">
              <div className="my-4 md:mt-0">
                <Link className="hover:bg-transparent text-secondaryText p-0 justify-start" to="/settings">
                  <Typography variant="body2" className="body1 leading-none">{`< Go Back`}</Typography>
                </Link>
              </div>
              <div className="flex flex-row">
                <div className="mr-4">
                  <TitleIcon className="h-[1.25rem] fill-primaryText"></TitleIcon>
                </div>
                <div className="mb-4">
                  <Typography variant="h6" className="font-normal leading-none mb-1">
                    Dietary Settings
                  </Typography>
                  <Typography variant="subtitle2" className="text-secondaryText leading-tight font-normal">
                    Manage your allergies, special diets, and dislikes
                  </Typography>
                </div>
              </div>
            </div>
            <Divider />

            <div className="flex flex-col my-4 mt-8 gap-4">
              <div>
                <Searchable
                  label="Allergens"
                  maxItems={30}
                  multiple={true}
                  freeSolo={true}
                  apiUrl="/allergens/searchable"
                  allowAdd={true}
                  onItemChange={onAllergenChange}
                  helperText="Add any allergens you are allergic to, such as peanuts or gluten"
                  selectedItemsFromParent={allergens}
                  disabled={saving}
                />
              </div>
              <div>
                <Searchable
                  label="Special Diets"
                  multiple={true}
                  maxItems={30}
                  freeSolo={true}
                  apiUrl="/diets/searchable"
                  allowAdd={true}
                  onItemChange={onDietChange}
                  helperText="Add any special diets you follow, such as vegan or keto"
                  selectedItemsFromParent={diets}
                  disabled={saving}
                />
              </div>

              <div>
                <Searchable
                  label="Dislikes"
                  multiple={true}
                  maxItems={30}
                  freeSolo={true}
                  apiUrl="/ingredients/searchable"
                  allowAdd={true}
                  onItemChange={onDislikeChange}
                  helperText="Add any ingredients you dislike, and don't want any recipes containing them"
                  selectedItemsFromParent={dislikes}
                  disabled={saving}
                />
              </div>

              <Button
                variant="contained"
                color="primary"
                disableElevation
                disabled={saving}
                onClick={() => saveDietarySettings()}
              >
                Save Changes
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

DietarySettings.propTypes = {
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(DietarySettings);
