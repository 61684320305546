import React from "react";
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";

//MUI
import { Typography, Button } from "@mui/material";

import CreatePost from "./CreatePost";

const PREFIX = 'CreateHome';

const classes = {
  root: `${PREFIX}-root`,
  createItem: `${PREFIX}-createItem`,
  link: `${PREFIX}-link`,
  title: `${PREFIX}-title`,
  titleHighlight: `${PREFIX}-titleHighlight`,
  subtitle: `${PREFIX}-subtitle`,
  button: `${PREFIX}-button`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "center",
    marginLeft: "5rem",
    position: "absolute",
    bottom: 0,
    [theme.breakpoints.down('md')]: {
      marginLeft: "0rem",
      bottom: `calc(4rem + ${theme.spacing(2)})`,
    },
  },

  [`& .${classes.createItem}`]: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.separator}`,
    borderRadius: 16,
    width: "100%",
  },

  [`& .${classes.link}`]: {
    width: "100%",
  },

  [`& .${classes.title}`]: {
    fontWeight: 300,
  },

  [`& .${classes.titleHighlight}`]: {
    color: theme.palette.mainGreen,
    fontWeight: 600,
  },

  [`& .${classes.subtitle}`]: {
    color: theme.palette.secondaryTextOnWhite,
  },

  [`& .${classes.button}`]: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  }
}));

const CreateHome = () => {

  return (
    <Root className={classes.root}>
      <Button className={classes.button} disableRipple>
        <Link to="/create/post" className={classes.link}>
          <div className={classes.createItem}>
            <Typography variant="h4" className={classes.title}>
              Create a <span className={classes.titleHighlight}>Post</span>
            </Typography>
            <Typography variant="subtitle1" className={classes.subtitle}>
              Share the secret ingredient
            </Typography>
          </div>
        </Link>
      </Button>
      <Button className={classes.button} disableRipple>
        <Link to="/create/recipe" className={classes.link}>
          <div className={classes.createItem}>
            <Typography variant="h4" className={classes.title}>
              Create a <span className={classes.titleHighlight}>Recipe</span>
            </Typography>
            <Typography variant="subtitle1" className={classes.subtitle}>
              Share your kitchen's best kept secret
            </Typography>
          </div>
        </Link>
      </Button>
    </Root>
  );
};

export default CreateHome;
