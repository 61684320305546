import React, { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Spinner from "../layout/Spinner";

import Post from "./Post";
import PostSkeleton from "./PostSkeleton";

//Actions
import { getPostData } from "../../actions/feed";

const PostManager = ({ index, getPostData, feed: { posts, loading } }) => {
  useEffect(() => {
    if (!loading) {
      getPostData(posts[index].postid, index, posts[index]?.repostuser ? posts[index].repostuser : null);
    }
  }, []);

  const nullPost = null;

  //

  return (
    <Fragment>
      {posts[index].loading ? <PostSkeleton /> : posts[index].error ? null : <Post post={posts[index]} />}
    </Fragment>
  );
};

PostManager.propTypes = {
  getPostData: PropTypes.func.isRequired,
  feed: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  feed: state.feed,
});
export default connect(mapStateToProps, { getPostData })(PostManager);
