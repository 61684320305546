import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toggleRecipeTimingModal, setSectionTiming } from "../../../../actions/createrecipe";
import CustomModal from "../../../modal/CustomModal";
import Searchable from "../../../layout/searchable/Searchable";

//MUI
import { Typography, TextField, Button } from "@mui/material";

//Edit macros for a section, or compiled macros for a recipe
const RecipeTimingModal = ({ visible, sectionIndex, sections, toggleRecipeTimingModal, setSectionTiming }) => {
  const handleClose = () => {
    //Verify data and push to redux on close

    setSectionTiming(timing, sectionIndex);
    toggleRecipeTimingModal(false);
  };

  const [showPrepTiming, setShowPrepTiming] = useState(false);
  const [showCookTiming, setShowCookTiming] = useState(false);
  const [showRestTiming, setShowRestTiming] = useState(false);

  useEffect(() => {
    if (sections[sectionIndex]?.timing) {
      setTiming(sections[sectionIndex].timing);

      if (sections[sectionIndex].timing.prep) {
        if (sections[sectionIndex].timing.prep.hours !== "" || sections[sectionIndex].timing.prep.minutes !== "") {
          setShowPrepTiming(true);
        }
      }

      if (sections[sectionIndex].timing.cook) {
        if (sections[sectionIndex].timing.cook.hours !== "" || sections[sectionIndex].timing.cook.minutes !== "") {
          setShowCookTiming(true);
        }
      }

      if (sections[sectionIndex].timing.rest) {
        if (sections[sectionIndex].timing.rest.hours !== "" || sections[sectionIndex].timing.rest.minutes !== "") {
          setShowRestTiming(true);
        }
      }
    }

    return () => {
      setTiming({
        prep: {
          hours: "",
          minutes: "",
        },
        cook: {
          hours: "",
          minutes: "",
        },
        rest: {
          hours: "",
          minutes: "",
        },
        total: {
          hours: "",
          minutes: "",
        },
      });
      setShowPrepTiming(false);
      setShowCookTiming(false);
      setShowRestTiming(false);
    };
  }, [sectionIndex]);
  const [timing, setTiming] = useState({
    prep: {
      hours: sections[sectionIndex]?.timing?.prep?.hours || "",
      minutes: sections[sectionIndex]?.timing?.prep?.minutes || "",
    },
    cook: {
      hours: sections[sectionIndex]?.timing?.cook?.hours || "",
      minutes: sections[sectionIndex]?.timing?.cook?.minutes || "",
    },
    rest: {
      hours: sections[sectionIndex]?.timing?.rest?.hours || "",
      minutes: sections[sectionIndex]?.timing?.rest?.minutes || "",
    },
    total: {
      hours: sections[sectionIndex]?.timing?.total?.hours || "",
      minutes: sections[sectionIndex]?.timing?.total?.minutes || "",
    },
  });

  const handleTimingChange = (type, field, value) => {
    setTiming((prevTime) => {
      let newHours = prevTime[type].hours === "" ? 0 : parseInt(prevTime[type].hours);
      let newMinutes = prevTime[type].minutes === "" ? 0 : parseInt(prevTime[type].minutes);

      if (field === "hours") {
        newHours = value === "" ? 0 : parseInt(value);
      } else if (field === "minutes") {
        newMinutes = value === "" ? 0 : parseInt(value);
      }

      return { ...prevTime, [type]: { hours: newHours, minutes: newMinutes } };
    });
  };

  const handleTimingBlur = (type) => {
    setTiming((prevTime) => {
      let newHours = prevTime[type].hours === "" ? 0 : parseInt(prevTime[type].hours);
      let newMinutes = prevTime[type].minutes === "" ? 0 : parseInt(prevTime[type].minutes);

      if (newMinutes > 59) {
        newHours += Math.floor(newMinutes / 60);
        newMinutes = newMinutes % 60;
      }

      return { ...prevTime, [type]: { hours: newHours, minutes: newMinutes } };
    });
  };

  return (
    <CustomModal open={visible} handleClose={handleClose}>
      <div className="flex flex-col px-4">
        <Typography variant="h6" className="mb-4 font-normal leading-none">
          {sections[sectionIndex]?.title ? `${sections[sectionIndex]?.title} Timing` : "Section Timing"}
        </Typography>
        <div className="flex flex-col gap-4 py-4">
          <div>
            <div className="flex flex-row items-center [&>*:first-child]:mr-1 [&>*:nth-child(2)]:ml-1">
              <TextField
                variant="outlined"
                label="Hours"
                fullWidth
                helperText="Total Time"
                type="text"
                inputProps={{
                  inputMode: "numeric",
                }}
                value={timing.total.hours}
                onChange={(event) => {
                  const value = event.target.value;
                  if (value < 0 || isNaN(value) || value.includes("e")) {
                    event.target.value = 0;
                  }
                  handleTimingChange("total", "hours", event.target.value);
                }}
                onBlur={() => handleTimingBlur("total")}
              />
              <TextField
                variant="outlined"
                label="Minutes"
                fullWidth
                helperText="Total Time"
                type="text"
                inputProps={{
                  inputMode: "numeric",
                }}
                value={timing.total.minutes}
                onChange={(event) => {
                  const value = event.target.value;
                  if (value < 0 || isNaN(value) || value.includes("e")) {
                    event.target.value = 0;
                  }
                  handleTimingChange("total", "minutes", event.target.value);
                }}
                onBlur={() => handleTimingBlur("total")}
              />
            </div>
          </div>
          <div>
            {showPrepTiming ? (
              <>
                <div className="flex flex-row items-center [&>*:first-child]:mr-1 [&>*:nth-child(2)]:ml-1">
                  <TextField
                    variant="outlined"
                    label="Hours"
                    fullWidth
                    helperText="Prep Time"
                    type="text"
                    inputProps={{
                      inputMode: "numeric",
                    }}
                    value={timing.prep.hours}
                    onChange={(event) => {
                      const value = event.target.value;
                      if (value < 0 || isNaN(value) || value.includes("e")) {
                        event.target.value = 0;
                      }
                      handleTimingChange("prep", "hours", event.target.value);
                    }}
                    onBlur={() => handleTimingBlur("prep")}
                  />
                  <TextField
                    variant="outlined"
                    label="Minutes"
                    fullWidth
                    helperText="Prep Time"
                    type="text"
                    inputProps={{
                      inputMode: "numeric",
                    }}
                    value={timing.prep.minutes}
                    onChange={(event) => {
                      const value = event.target.value;
                      if (value < 0 || isNaN(value) || value.includes("e")) {
                        event.target.value = 0;
                      }
                      handleTimingChange("prep", "minutes", event.target.value);
                    }}
                    onBlur={() => handleTimingBlur("prep")}
                  />
                </div>
                <Button
                  fullWidth
                  disableElevation
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={() => setShowPrepTiming(false)}
                  className="my-2"
                >
                  <Typography variant="caption" className="text-primaryText">
                    - Remove Prep Time
                  </Typography>
                </Button>
              </>
            ) : (
              <Button
                fullWidth
                disableElevation
                size="small"
                variant="contained"
                color="secondary"
                onClick={() => setShowPrepTiming(true)}
              >
                <Typography variant="caption" className="text-primaryText">
                  + Add Prep Time
                </Typography>
              </Button>
            )}
          </div>
          <div>
            {showCookTiming ? (
              <>
                <div className="flex flex-row items-center [&>*:first-child]:mr-1 [&>*:nth-child(2)]:ml-1">
                  <TextField
                    variant="outlined"
                    label="Hours"
                    fullWidth
                    helperText="Cook Time"
                    type="text"
                    inputProps={{
                      inputMode: "numeric",
                    }}
                    value={timing.cook.hours}
                    onChange={(event) => {
                      const value = event.target.value;
                      if (value < 0 || isNaN(value) || value.includes("e")) {
                        event.target.value = 0;
                      }
                      handleTimingChange("cook", "hours", event.target.value);
                    }}
                    onBlur={() => handleTimingBlur("cook")}
                  />
                  <TextField
                    variant="outlined"
                    label="Minutes"
                    fullWidth
                    helperText="Cook Time"
                    type="text"
                    inputProps={{
                      inputMode: "numeric",
                    }}
                    value={timing.cook.minutes}
                    onChange={(event) => {
                      const value = event.target.value;
                      if (value < 0 || isNaN(value) || value.includes("e")) {
                        event.target.value = 0;
                      }
                      handleTimingChange("cook", "minutes", event.target.value);
                    }}
                    onBlur={() => handleTimingBlur("cook")}
                  />
                </div>
                <Button
                  fullWidth
                  disableElevation
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={() => setShowCookTiming(false)}
                  className="my-2"
                >
                  <Typography variant="caption" className="text-primaryText">
                    - Remove Cook Time
                  </Typography>
                </Button>
              </>
            ) : (
              <Button
                fullWidth
                disableElevation
                size="small"
                variant="contained"
                color="secondary"
                onClick={() => setShowCookTiming(true)}
              >
                <Typography variant="caption" className="text-primaryText">
                  + Add Cook Time
                </Typography>
              </Button>
            )}
          </div>
          <div>
            {showRestTiming ? (
              <>
                <div className="flex flex-row items-center [&>*:first-child]:mr-1 [&>*:nth-child(2)]:ml-1">
                  <TextField
                    variant="outlined"
                    label="Hours"
                    fullWidth
                    helperText="Rest Time"
                    type="text"
                    inputProps={{
                      inputMode: "numeric",
                    }}
                    value={timing.rest.hours}
                    onChange={(event) => {
                      const value = event.target.value;
                      if (value < 0 || isNaN(value) || value.includes("e")) {
                        event.target.value = 0;
                      }
                      handleTimingChange("rest", "hours", event.target.value);
                    }}
                    onBlur={() => handleTimingBlur("rest")}
                  />
                  <TextField
                    variant="outlined"
                    label="Minutes"
                    fullWidth
                    helperText="Rest Time"
                    type="text"
                    inputProps={{
                      inputMode: "numeric",
                    }}
                    value={timing.rest.minutes}
                    onChange={(event) => {
                      const value = event.target.value;
                      if (value < 0 || isNaN(value) || value.includes("e")) {
                        event.target.value = 0;
                      }
                      handleTimingChange("rest", "minutes", event.target.value);
                    }}
                    onBlur={() => handleTimingBlur("rest")}
                  />
                </div>
                <Button
                  fullWidth
                  disableElevation
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={() => setShowRestTiming(false)}
                  className="my-2"
                >
                  <Typography variant="caption" className="text-primaryText">
                    - Remove Rest Time
                  </Typography>
                </Button>
              </>
            ) : (
              <Button
                fullWidth
                disableElevation
                size="small"
                variant="contained"
                color="secondary"
                onClick={() => setShowRestTiming(true)}
              >
                <Typography variant="caption" className="text-primaryText">
                  + Add Rest Time
                </Typography>
              </Button>
            )}
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

RecipeTimingModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  sections: PropTypes.array.isRequired,
  setSectionTiming: PropTypes.func.isRequired,
  toggleRecipeTimingModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  visible: state.recipe.create.modals.editTiming.open,
  sectionIndex: state.recipe.create.modals.editTiming.sectionIndex,
  sections: state.recipe.create.sections,
});

export default connect(mapStateToProps, { toggleRecipeTimingModal, setSectionTiming })(RecipeTimingModal);
