import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
//MUI
import { TextField, Typography, FormControl, InputLabel, Box, OutlinedInput, CircularProgress } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

//Actions
import { searchIngredientsByName, selectSingleItemToAdd } from "../../../actions/pantry";

const PREFIX = "CustomAutocomplete";

const classes = {
  root: `${PREFIX}-root`,
  option: `${PREFIX}-option`,
  paper: `${PREFIX}-paper`,
  textField: `${PREFIX}-textField`,
  greenLabel: `${PREFIX}-greenLabel`,
  greenLabelIcon: `${PREFIX}-greenLabelIcon`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.option}`]: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },

  [`& .${classes.paper}`]: {
    backgroundColor: "white",
  },

  [`& .${classes.textField}`]: {},

  [`& .${classes.greenLabel}`]: {
    color: theme.palette.mainGreen,
  },

  [`& .${classes.greenLabelIcon}`]: {
    "& path": {
      fill: theme.palette.mainGreen,
    },
  },
}));

String.prototype.toTitleCase = function () {
  var i, j, str, lowers, uppers;
  str = this.replace(/([^\W_]+[^\s-]*) */g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

  // Certain minor words should be left lowercase unless
  // they are the first or last words in the string
  lowers = [
    "A",
    "An",
    "The",
    "And",
    "But",
    "Or",
    "For",
    "Nor",
    "As",
    "At",
    "By",
    "For",
    "From",
    "In",
    "Into",
    "Near",
    "Of",
    "On",
    "Onto",
    "To",
    "With",
  ];
  for (i = 0, j = lowers.length; i < j; i++)
    str = str.replace(new RegExp("\\s" + lowers[i] + "\\s", "g"), function (txt) {
      return txt.toLowerCase();
    });

  // Certain words such as initialisms or acronyms should be left uppercase
  uppers = ["Id", "Tv"];
  for (i = 0, j = uppers.length; i < j; i++)
    str = str.replace(new RegExp("\\b" + uppers[i] + "\\b", "g"), uppers[i].toUpperCase());

  return str;
};

const CustomAutocomplete = ({
  label,
  helperText,
  searchIngredientsByName,
  selectSingleItemToAdd,
  pantry: { addToPantry },
}) => {
  const [inputValue, setInputValue] = useState("");
  const [inputFocused, setInputFocused] = useState(false);

  useEffect(() => {
    searchIngredientsByName(inputValue);
  }, [inputValue]);

  return (
    <Root className={classes.root}>
      <Autocomplete
        options={addToPantry.searchOptions}
        getOptionLabel={(option) => option.name}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onChange={(e, value) => {
          selectSingleItemToAdd(value);
        }}
        noOptionsText={`Search for an Ingredient...`}
        classes={{
          paper: classes.paper,
        }}
        filterOptions={(options, { inputValue }) => {
          let isExactMatch = options.some((option) => option.name.toLowerCase() === inputValue.toLowerCase());
          if (inputValue !== "" && !isExactMatch) {
            options = [
              ...options,
              {
                inputValue: `Add "${inputValue}"`,
                name: `Add "${inputValue}"`,
              },
            ];
          }

          return options;
        }}
        renderOption={(option) => (
          <div className={classes.option}>
            <Typography variant="h6" style={{ fontWeight: 400 }}>{`${option.name.toTitleCase()}`}</Typography>
          </div>
        )}
        renderInput={(params) => (
          <FormControl fullWidth variant="outlined">
            <InputLabel shrink={inputFocused || inputValue !== ""} htmlFor="custom-autocomplete">
              <Box display="flex" alignItems="center">
                <FontAwesomeIcon
                  icon={faSearch}
                  className={!(inputFocused || inputValue !== "") ? "" : classes.greenLabelIcon}
                />
                <Box ml={1} className={!(inputFocused || inputValue !== "") ? "" : classes.greenLabel}>
                  {label}
                </Box>
              </Box>
            </InputLabel>
            <TextField
              {...params}
              id="custom-autocomplete"
              helperText={helperText}
              className={classes.textField}
              variant="outlined"
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
              InputProps={{
                ...params.InputProps,
                notched: inputFocused || inputValue !== "",
                labelWidth: 225, // Adjust this value to fit your label
                endAdornment: (
                  <>
                    {addToPantry.searchLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          </FormControl>
        )}
      />
    </Root>
  );
};

CustomAutocomplete.propTypes = {
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  searchIngredientsByName: PropTypes.func.isRequired,
  selectSingleItemToAdd: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  pantry: state.pantry,
});

export default connect(mapStateToProps, {
  searchIngredientsByName,
  selectSingleItemToAdd,
})(CustomAutocomplete);
