import React from "react";

import RecipeInfo from "./recipe/RecipeInfo";
import ImageCarousel from "./components/ImageCarousel";
const RecipeContent = ({ recipe }) => {
  return (
    <div className={recipe.data && recipe.data.length > 0 && "w-full aspect-square"}>
      {recipe.data && recipe.data.length > 0 && <ImageCarousel data={recipe.data} />}
      <RecipeInfo recipe={recipe} />
    </div>
  );
};

export default RecipeContent;
