import React, { useState, useEffect, useRef, useCallback } from "react";
import api from "../../api/api";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "../layout/Spinner";
import ProfileSearchObject from "../search/sections/profiles/ProfileSearchObject";
import { Typography, Button } from "@mui/material";
import { ReactComponent as FollowIcon } from "../../assets/icons/svg/user-plus-solid.svg";

const FollowSuggestions = () => {
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    fetchSuggestions();
  }, []);

  const fetchSuggestions = async () => {
    try {
      const response = await api.get(`/profile/suggested/${offset}`);
      setSuggestions([...suggestions, ...response.data.profiles]);
      setHasMore(response.data.hasMore);
      setOffset(offset + 20);
      console.log(response.data.profiles);
    } catch (error) {
      setError(error);
    }
  };

  if (error) return null;
  return (
    <div className="w-full my-8 md:my-0 md:border md:border-separator md:rounded-lg p-4 md:shadow-sm bg-background">
      <div className="flex flex-row items-center justify-between mb-6">
        <div className="flex flex-row items-center ">
          <FollowIcon className="h-4 fill-secondaryText mr-2" />
          <Typography variant="subtitle1">People to Follow</Typography>
        </div>

        {navigator.share && (
          <Button
            disableRipple
            className="hover:bg-transparent"
            onClick={() => {
              navigator
                .share({
                  title: "Join me on thisPantry",
                  text: "Bring all your recipes together with thisPantry. Generate new recipes, manage your pantry, and gain valuable insights on expenses and nutrition. Simplify your cooking and make every ingredient count with thisPantry.",
                  url: "https://www.thispantry.com",
                })
                .catch((error) => console.log("Error sharing", error));
            }}
          >
            <Typography variant="subtitle2" className="font-normal text-primaryText leading-none">
              <span className="text-mainGreen font-medium">+</span> Invite Friends
            </Typography>
          </Button>
        )}
      </div>

      <div className="overflow-x-auto">
        <InfiniteScroll
          dataLength={suggestions.length}
          next={fetchSuggestions}
          hasMore={hasMore}
          loader={<Spinner />}
          scrollableTarget="scrollableDiv"
          scrollThreshold={0.9}
          className="flex"
        >
          <div className="grid grid-flow-col auto-cols-[minmax(300px,1fr)] gap-4">
            {suggestions.map((user) => (
              <div key={user._id} className="pb-4">
                <ProfileSearchObject profile={user} border={false} padding="p-0" />
              </div>
            ))}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
};

FollowSuggestions.propTypes = {};

export default FollowSuggestions;
