import React, { useState } from "react";

import { styled } from '@mui/material/styles';

//Components
import RecipeCard from "../../recipes/card/RecipeCard";

//Icons
import { ReactComponent as HeartIcon } from "../../../assets/icons/svg/heart.svg";
import { ReactComponent as CommentIcon } from "../../../assets/icons/svg/comment.svg";

import { Typography } from "@mui/material";

const PREFIX = 'ModularManager';

const classes = {
  root: `${PREFIX}-root`,
  grid1x1: `${PREFIX}-grid1x1`,
  grid1x2: `${PREFIX}-grid1x2`,
  grid2x2: `${PREFIX}-grid2x2`,
  textRoot: `${PREFIX}-textRoot`,
  textInteraction: `${PREFIX}-textInteraction`,
  interactionIcon: `${PREFIX}-interactionIcon`,
  interactionItem: `${PREFIX}-interactionItem`,
  interactionUsername: `${PREFIX}-interactionUsername`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    width: "100%",
    height: "100%",
    border: `1px solid ${theme.palette.separator}`,
    borderRadius: 16,
    position: "relative",
    paddingBottom: "100%", // This makes the height equal to the width
    color: theme.palette.text.secondary,
    "& > *": {
      // This is to make sure the content of the grid item is positioned correctly
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },

  [`& .${classes.grid1x1}`]: {
    gridColumn: "span 1",
    gridRow: "span 1",
  },

  [`& .${classes.grid1x2}`]: {
    gridColumn: "span 1",
    gridRow: "span 2",
  },

  [`& .${classes.grid2x2}`]: {
    gridColumn: "span 2",
    gridRow: "span 2",
  },

  [`& .${classes.textRoot}`]: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    padding: theme.spacing(2),
    color: theme.palette.textOnWhite,
    justifyContent: "space-between",
  },

  [`& .${classes.textInteraction}`]: {
    display: "flex",
    flexDirection: "row",
    "& > :nth-child(2)": {
      marginLeft: theme.spacing(1),
    },
  },

  [`& .${classes.interactionIcon}`]: {
    height: "0.75rem",
    fill: theme.palette.secondaryTextOnWhite,
    marginRight: theme.spacing(1),
  },

  [`& .${classes.interactionItem}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: theme.palette.secondaryTextOnWhite,
  },

  [`& .${classes.interactionUsername}`]: {
    color: theme.palette.mainGreen,
    lineHeight: 1,
    fontWeight: 500,
  }
}));

const ModularManager = ({ post, size }) => {


  const [loading, setLoading] = useState(true);
  const selectGridStyles = () => {
    switch (size) {
      case "1x1":
        return classes.grid1x1;
      case "1x2":
        return classes.grid1x2;
      case "2x2":
        return classes.grid2x2;
      default:
        return classes.grid1x1;
    }
  };

  if (loading) {
  }

  if (post.type === "text") {
    return (
      <Root className={`${classes.root} ${selectGridStyles()}`}>
        <div className={classes.textRoot}>
          <div className={classes.textBody}>
            <Typography variant="body1">
              Holy moly that was quite the damn recipe if i do say so myself like jeez son god damn lord almighty
            </Typography>
          </div>
          <div className={classes.textInfo}>
            <Typography variant="subtitle1" className={classes.interactionUsername}>
              @username
            </Typography>
            <div className={classes.textInteraction}>
              <div className={classes.interactionItem}>
                <HeartIcon className={classes.interactionIcon} />
                <Typography variant="subtitle1">100</Typography>
              </div>
              <div className={classes.interactionItem}>
                <CommentIcon className={classes.interactionIcon} />
                <Typography variant="subtitle1">40</Typography>
              </div>
            </div>
          </div>
        </div>
      </Root>
    );
  }

  if (post.type === "media") {
    return (
      <div className={`${classes.root} ${selectGridStyles()}`}>
        <div>image</div>
      </div>
    );
  }

  if (post.type === "recipe") {
    return (
      <div className={`${classes.root} ${selectGridStyles()}`}>
        <RecipeCard recipeid={post.recipe} showUsername={true} />
      </div>
    );
  }
  return (
    <div className={`${classes.root} ${selectGridStyles()}`}>
      <div>module</div>
    </div>
  );
};

export default ModularManager;
