import api from "../api/api";

import store, { history } from "../store";

//Redux types
import {
  SEARCH_GET_RESULTS,
  SEARCH_RESULTS_ERROR,
  SEARCH_SET_QUERY,
  SEARCH_SET_TYPE,
  SEARCH_FETCH_PROFILES,
  SEARCH_PROFILES_ERROR,
  SEARCH_RESET_RESPONSE,
} from "./types";

//Set query
export const setQuery = (query) => (dispatch) => {
  dispatch({
    type: SEARCH_RESET_RESPONSE,
  });
  dispatch({
    type: SEARCH_SET_QUERY,
    payload: query,
  });
};

//Set type
export const setType = (type) => (dispatch) => {
  //TO DO: Fetch new search data on type change
  dispatch({
    type: SEARCH_SET_TYPE,
    payload: type,
  });
};

//Get for you page results
export const getResults = () => async (dispatch) => {
  try {
    const res = await api.get("/search/foryou");

    dispatch({
      type: SEARCH_GET_RESULTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SEARCH_RESULTS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Fetch profiles
export const fetchProfiles = (query, offset) => async (dispatch) => {
  try {
    dispatch({
      type: SEARCH_RESET_RESPONSE,
    });
    const res = await api.post(`/search/users/${offset}`, { query });

    dispatch({
      type: SEARCH_FETCH_PROFILES,
      payload: { profiles: res.data.profiles, endOfItems: res.data.endOfItems, offset: res.data.offset },
    });
  } catch (err) {
    dispatch({
      type: SEARCH_PROFILES_ERROR,
    });
  }
};
