import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Typography, IconButton } from "@mui/material";

import ImageCarousel from "../../feed/types/components/ImageCarousel";
import CustomModal from "../../modal/CustomModal";
import RecipeCardSkeleton from "./RecipeCardSkeleton";
import RecipeStandalone from "../standalone/components/RecipeStandalone";
import RecipeModal from "../modal/RecipeModal";

//API
import api from "../../../api/api";

//Actions
import { setAlert } from "../../../actions/alert";
import { showRegisterModal } from "../../../actions/auth";

//Icons
import { ReactComponent as BookmarkIconOutlined } from "../../../assets/icons/svg/bookmark.svg";
import { ReactComponent as BookmarkIconFilled } from "../../../assets/icons/svg/bookmark_filled.svg";
import { ReactComponent as RatingIcon } from "../../../assets/icons/svg/star-solid.svg";

//Utils
import { preventWidows } from "../../../utils/text";

const RecipeCard = ({
  recipeid,
  auth: { isAuthenticated },
  showUsername = true,
  setAlert,
  showRegisterModal,
  openType = "link",
  rounded = "rounded-lg",
  errorFallback = () => null,
  errorType = "null", //Set to "deleted" to place a fallback deleted card if 404 is returned
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [recipeData, setRecipeData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [bookmarkLoading, setBookmarkLoading] = useState(false);

  console.log(recipeData);

  const [showRecipeModal, setShowRecipeModal] = useState(false);

  //TO DO:
  //Bookmark at bottom
  //Rating and times cooked at top?

  const fetchRecipeData = async () => {
    try {
      if (openType === "link") {
        const res = await api.get(`/recipes/card/${recipeid}`);

        setError(false);
        setRecipeData(res.data);
        setUserData(res.data.user);
        setIsBookmarked(res.data.isBookmarked);
        setLoading(false);
      }

      if (openType === "modal") {
        const res = await api.get(`/recipes/id/${recipeid}`);

        setError(false);
        setRecipeData({ ...res.data.recipe, user: res.data.user });
        setUserData(res.data.user);
        setIsBookmarked(res.data.isBookmarked);
        setLoading(false);
      }
    } catch (error) {
      if (errorType === "deleted") {
        setError(false);
        setLoading(false);
        setRecipeData({ name: "Deleted Recipe", data: [] });
        setUserData({ username: "unknown" });
        errorFallback(recipeid);
      } else {
        //Null error type
        setError(true);
        setLoading(false);
        setRecipeData(null);
        errorFallback(recipeid);
      }
    }
  };

  useEffect(() => {
    fetchRecipeData();
  }, []);

  const handleBookmark = async () => {
    if (isAuthenticated) {
      try {
        if (!bookmarkLoading) {
          setBookmarkLoading(true);
          setIsBookmarked(true);
          const res = await api.post(`/recipes/bookmark/${recipeid}`);

          setAlert(`${recipeData && recipeData.name ? recipeData.name : "Recipe"} added to bookmarks`, "success", 3000);
          setBookmarkLoading(false);
        }
      } catch (error) {
        setBookmarkLoading(false);
        setIsBookmarked(false);
      }
    } else {
      showRegisterModal();
    }
  };

  const handleUnBookmark = async () => {
    if (isAuthenticated) {
      try {
        if (!bookmarkLoading) {
          setBookmarkLoading(true);
          setIsBookmarked(false);
          const res = await api.post(`/recipes/unbookmark/${recipeid}`);

          setBookmarkLoading(false);
        }
      } catch (error) {
        setIsBookmarked(true);
        setBookmarkLoading(false);
      }
    } else {
      showRegisterModal();
    }
  };

  const toggleRecipeModal = (value) => {
    setShowRecipeModal(value);
  };

  //TO DO:
  //Open modal on click on content, if not logged in, show sign up modal. Set links for SEO if not auth
  //Bookmarking recipes, depending on auth status
  //Show recipe rating
  //Get isBookmarked, display on icon
  //If only one image, whole card becomes a link (not bookmark button), if more than one, only the content is a link
  //Make current gallery pip white on card
  return (
    <div className="w-full aspect-square">
      {loading ? (
        <RecipeCardSkeleton />
      ) : error ? null : (
        <div className={`relative w-full h-full select-none shadow-sm overflow-hidden ${rounded}`}>
          <div className="absolute inset-0 border border-separator rounded-lg pointer-events-none"></div>
          {openType === "modal" && (
            <RecipeModal
              open={showRecipeModal}
              handleClose={() => toggleRecipeModal(false)}
              recipeID={recipeData._id}
            />
          )}

          <ImageCarousel data={recipeData.data} dotsTop={true} dotsAlign="left" showEmpty={true} />
          <div className="absolute bottom-0 left-0 w-full p-4 text-background flex flex-row justify-between z-[3] items-end cursor-pointer">
            {openType === "link" ? (
              <Link to={recipeData ? `/${userData.username}/${recipeData.url}/` : "/"} className="w-[80%]">
                <div className="w-full break-words">
                  <Typography
                    variant="h6"
                    className={`text-background block mb-${({ showUsername }) =>
                      showUsername ? 1 : 0} font-normal text-left break-words w-full leading-tight`}
                  >
                    <span className="card-text-clamp">
                      {preventWidows(recipeData && recipeData.name ? recipeData.name : "", 2)}
                    </span>
                  </Typography>
                  {showUsername && (
                    <Typography variant="subtitle1" className="text-secondaryText-100 leading-none mt-2">
                      {userData ? `@${userData.username}` : null}
                    </Typography>
                  )}
                </div>
              </Link>
            ) : (
              <div className="w-full break-words" onClick={() => toggleRecipeModal(true)}>
                <Typography
                  variant="h6"
                  className={`text-background block mb-${({ showUsername }) =>
                    showUsername ? 1 : 0} font-normal leading-1.2 text-left break-words w-full leading-tight mb-2`}
                >
                  <span className="display-webkit-box flex-webkit-orient-vertical line-clamp-4 overflow-hidden text-ellipsis w-full">
                    {preventWidows(recipeData && recipeData.name ? recipeData.name : "", 2)}
                  </span>
                </Typography>
                {showUsername && (
                  <Typography variant="subtitle1" className="text-background leading-none">
                    {userData ? `@${userData.username}` : null}
                  </Typography>
                )}
              </div>
            )}

            <div className="flex flex-col items-end">
              {isBookmarked ? (
                <IconButton className="p-0 hover:bg-transparent" onClick={() => handleUnBookmark()} size="large">
                  <BookmarkIconFilled className="h-8 fill-background z-[4]" />
                </IconButton>
              ) : (
                <IconButton className="p-0 hover:bg-transparent" onClick={() => handleBookmark()} size="large">
                  <BookmarkIconOutlined className="h-8 fill-background z-[4]" />
                </IconButton>
              )}
            </div>
          </div>
          <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-black/60 to-white/30 rounded-lg z-[2] pointer-events-none" />
        </div>
      )}
    </div>
  );
};

RecipeCard.propTypes = {
  recipeid: PropTypes.string.isRequired,
  showUsername: PropTypes.bool,
  setAlert: PropTypes.func.isRequired,
  showRegisterModal: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert, showRegisterModal })(RecipeCard);
