import api from "../api/api";

import store, { history } from "../store";

//Redux types
import { WALKTHROUGH_GET_RECIPE, WALKTHROUGH_RECIPE_ERROR, WALKTHROUGH_SET_SECTION } from "./types";

//Initial get recipe
export const getWalkthroughRecipe = (recipeID) => async (dispatch) => {
  try {
    //Get recipe
    const res = await api.get(`/recipes/walkthrough/${recipeID}`);

    const { recipe, user } = res.data;

    let payload = {
      recipe: recipe,
      user: user,
    };

    dispatch({
      type: WALKTHROUGH_GET_RECIPE,
      payload: payload,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: WALKTHROUGH_RECIPE_ERROR,
    });
  }
};

//Go to section
export const setWalkthroughSection = (sectionIndex) => (dispatch) => {
  dispatch({
    type: WALKTHROUGH_SET_SECTION,
    payload: sectionIndex,
  });
};

//Recipe complete

//Edit ingredient amount
export const updateSectionIngredient = (sectionIndex, ingredientIndex, ingredient) => (dispatch) => {
  let recipe = store.getState().walkthrough.recipe;

  console.log(sectionIndex, ingredientIndex, ingredient);

  console.log(recipe);
  console.log(recipe.sections);
  console.log(recipe.sections[sectionIndex]);
  recipe.sections[sectionIndex].ingredients[ingredientIndex] = ingredient;

  dispatch({
    type: WALKTHROUGH_GET_RECIPE,
    payload: recipe,
  });
};
