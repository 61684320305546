import React, { useState, Fragment } from "react";

import { styled } from '@mui/material/styles';

import { Typography, ButtonBase } from "@mui/material";

//Modal Components
import CustomModal from "../../../../modal/CustomModal";
import IngredientModal from "../modals/IngredientModal";

const PREFIX = 'Ingredient';

const classes = {
  sectionIngredients: `${PREFIX}-sectionIngredients`,
  ingredient: `${PREFIX}-ingredient`,
  titleRoot: `${PREFIX}-titleRoot`,
  title: `${PREFIX}-title`,
  state: `${PREFIX}-state`,
  amountUnit: `${PREFIX}-amountUnit`,
  item: `${PREFIX}-item`,
  note: `${PREFIX}-note`,
  buttonReset: `${PREFIX}-buttonReset`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.sectionIngredients}`]: {
    margin: theme.spacing(2, 4),
    marginBottom: 0,
  },

  [`& .${classes.ingredient}`]: {
    fontWeight: 400,
    margin: theme.spacing(2, 0),
  },

  [`& .${classes.titleRoot}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  [`& .${classes.title}`]: {
    fontWeight: 500,
  },

  [`& .${classes.state}`]: {
    fontWeight: 400,
    fontStyle: "italic",
    color: theme.palette.secondaryTextOnWhite,
  },

  [`& .${classes.amountUnit}`]: {
    fontWeight: 400,
  },

  [`& .${classes.item}`]: {
    margin: theme.spacing(2, 0),
    cursor: "pointer",
    "&::marker": {
      color: theme.palette.secondaryTextOnWhite,
    },
  },

  [`& .${classes.note}`]: {
    color: theme.palette.secondaryTextOnWhite,
  },

  [`& .${classes.buttonReset}`]: {
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  }
}));

//Utility Functions
function decimalToFraction(decimal) {
  const tolerance = 1.0e-6; // Tolerance level for approximation

  // Handle the case where decimal is 1 or very close to 1
  if (Math.abs(decimal - 1) < tolerance) {
    return "1"; // or return "exact 1" if you prefer
  }

  let bestNumerator = 1;
  let bestDenominator = 1;
  let minError = Math.abs(decimal - bestNumerator / bestDenominator);

  for (let denominator = 1; denominator <= 100; ++denominator) {
    for (let numerator = 1; numerator <= denominator; ++numerator) {
      let error = Math.abs(decimal - numerator / denominator);
      if (error < minError) {
        minError = error;
        bestNumerator = numerator;
        bestDenominator = denominator;
      }
    }
  }

  if (minError < tolerance) {
    return bestNumerator + "/" + bestDenominator;
  } else {
    // For decimals that are common repeating fractions in recipes
    const commonRepeatingFractions = {
      0.333: "1/3",
      0.666: "2/3",
      0.166: "1/6",
      0.833: "5/6",
      // Add more mappings as needed
    };

    return commonRepeatingFractions[decimal.toFixed(3)] || decimal.toString();
  }
}

String.prototype.toTitleCase = function () {
  var i, j, str, lowers, uppers;
  str = this.replace(/([^\W_]+[^\s-]*) */g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

  // Certain minor words should be left lowercase unless
  // they are the first or last words in the string
  lowers = [
    "A",
    "An",
    "The",
    "And",
    "But",
    "Or",
    "For",
    "Nor",
    "As",
    "At",
    "By",
    "For",
    "From",
    "In",
    "Into",
    "Near",
    "Of",
    "On",
    "Onto",
    "To",
    "With",
  ];
  for (i = 0, j = lowers.length; i < j; i++)
    str = str.replace(new RegExp("\\s" + lowers[i] + "\\s", "g"), function (txt) {
      return txt.toLowerCase();
    });

  // Certain words such as initialisms or acronyms should be left uppercase
  uppers = ["Id", "Tv"];
  for (i = 0, j = uppers.length; i < j; i++)
    str = str.replace(new RegExp("\\b" + uppers[i] + "\\b", "g"), uppers[i].toUpperCase());

  return str;
};

//String formatting

const formatAmountUnit = (ingredient) => {
  const unit = ingredient.unit.display;
  let formattedAmount = "";
  let formattedUnit = "";

  if (unit && unit.magnitude && unit.magnitude !== "") {
    formattedAmount = decimalToFraction(unit.magnitude);
  }

  if (unit && unit.text !== "" && unit.text !== "not_exact" && unit.text !== "self" && unit.type !== "unknown") {
    formattedUnit = unit.text;
  }

  let showSpace = false;

  if (formattedAmount !== "" && formattedUnit !== "") {
    showSpace = true;
  }

  return `${formattedAmount} ${formattedUnit}${showSpace ? " " : ""}`;
};

const formatIngredient = (ingredient) => {
  //Convert ingredient data to a string for the top line
  let formattedName = ingredient.name && ingredient.name !== "" ? ingredient.name.toTitleCase() : "Unknown Ingredient";

  return `${formattedName}`;
};

const formatState = (ingredient) => {
  let formattedState = "";

  if (ingredient.state && ingredient.state !== "") {
    formattedState = ingredient.state.toTitleCase();
  }

  if (formattedState !== "") {
    return `, ${formattedState}`;
  }
};

const Ingredient = ({ section, sectionIndex, ingredient, index }) => {


  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    //setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Root>
      <CustomModal open={showModal} handleClose={handleCloseModal}>
        <IngredientModal section={section} sectionIndex={sectionIndex} ingredient={ingredient} index={index} />
      </CustomModal>
      <li className={classes.item} onClick={() => handleOpenModal()}>
        <div>
          <Typography variant="subtitle1" className={classes.title}>
            <span className={classes.amountUnit}>{formatAmountUnit(ingredient)}</span>
            {formatIngredient(ingredient)}
            {ingredient.state !== "" && <span className={classes.state}>{formatState(ingredient)}</span>}
          </Typography>
          {ingredient.note && ingredient.note !== "" && (
            <Typography variant="body2" className={classes.note}>
              ({ingredient.note})
            </Typography>
          )}
        </div>
      </li>
    </Root>
  );
};

export default Ingredient;
