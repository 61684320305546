import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";

export const SecureStorage = {
  set: async (key, value) => {
    try {
      if (Capacitor.isNativePlatform()) {
        await Preferences.set({
          key,
          value: typeof value === "string" ? value : JSON.stringify(value),
        });
      } else {
        localStorage.setItem(key, typeof value === "string" ? value : JSON.stringify(value));
      }
    } catch (error) {
      console.error("Error setting secure storage:", error);
    }
  },

  get: async (key) => {
    try {
      if (Capacitor.isNativePlatform()) {
        const { value } = await Preferences.get({ key });
        return value ? value : null;
      } else {
        return localStorage.getItem(key);
      }
    } catch (error) {
      console.error("Error getting from secure storage:", error);
      return null;
    }
  },

  remove: async (key) => {
    try {
      if (Capacitor.isNativePlatform()) {
        await Preferences.remove({ key });
      } else {
        localStorage.removeItem(key);
      }
    } catch (error) {
      console.error("Error removing from secure storage:", error);
    }
  },
};

export default SecureStorage;
