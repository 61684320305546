import { App } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
import store from "../store";
import { setTokensFromCallback } from "../actions/auth";
import { Capacitor } from "@capacitor/core";
import history from "./navigate";

export const initializeDeepLinks = () => {
  console.log("Initializing deep links");
  const listener = App.addListener("appUrlOpen", async (data) => {
    console.log("Deep link opened", data);

    let url;
    try {
      url = new URL(data.url);
    } catch {
      console.error("Invalid URL format", data.url);
      return;
    }

    // Handle OAuth callbacks
    if (url.pathname.startsWith("/oauth/") || data.url.startsWith("thispantry://oauth/")) {
      let provider, token;

      if (data.url.startsWith("thispantry://oauth/")) {
        const parts = data.url.split("thispantry://oauth/")[1].split("/");
        provider = parts[0];
        token = parts[1];

        //Remove the trailing # from the token, if present (Sometimes happens on iOS redirect?)
        token = token.replace("#", "");
        console.log("Token #:");
        console.log(token);
      } else {
        const parts = url.pathname.split("/");
        provider = parts[2];
        token = parts[3];

        // If this is a web URL and we're on iOS, redirect to custom scheme
        if (url.protocol === "https:" && Capacitor.getPlatform() === "ios") {
          const deepLinkUrl = `thispantry://oauth/${provider}/${token}`;
          console.log("Deep link URL:", deepLinkUrl);
          try {
            try {
              const canOpen = await App.canOpenUrl({ url: deepLinkUrl });
              console.log("Can open URL:", canOpen);
            } catch (error) {
              console.error("Error checking if URL can be opened:", error);
              console.log(error);
            }
            await App.openUrl({ url: deepLinkUrl });
          } catch (error) {
            console.error("Error opening deep link:", error);
            console.log(error);
          }
        }
      }

      if (!token || token.length < 10) {
        console.error("Invalid token received");
        return;
      }

      try {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 5000);

        const response = await fetch("https://www.thispantry.com/api/auth/oauth", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ token, type: provider }),
          signal: controller.signal,
        });

        clearTimeout(timeoutId);

        if (!response.ok) {
          throw new Error("OAuth validation failed");
        }

        const data = await response.json();
        store.dispatch(setTokensFromCallback(data.token, data.refreshToken));
        await Browser.close();
        history.push("/");
      } catch (error) {
        console.error("Error handling OAuth callback:", error);
        console.log(error);
      }
      return;
    }

    //Handle delete oAuth callbacks
    if (url.pathname.startsWith("/oauth/") || data.url.startsWith("thispantry://settings/delete")) {
      let token;

      if (data.url.startsWith("thispantry://settings/delete")) {
        token = new URLSearchParams(data.url.split("?")[1]).get("token");

        console.log("DELETE TOKEN DEEPLINK:");
        console.log(token);
      } else {
        token = url.pathname.split("/")[3];
        console.log("DELETE TOKEN HTTPS:");
        console.log(token);

        if (url.protocol === "https:" && Capacitor.getPlatform() === "ios") {
          const deepLinkUrl = `thispantry://settings/delete/${token}`;
          console.log("Deep link URL:", deepLinkUrl);

          try {
            try {
              const canOpen = await App.canOpenUrl({ url: deepLinkUrl });
              console.log("Can open URL:", canOpen);
            } catch (error) {
              console.error("Error checking if URL can be opened:", error);
              console.log(error);
            }
            await App.openUrl({ url: deepLinkUrl });
          } catch (error) {
            console.error("Error opening deep link:", error);
            console.log(error);
          }
        }
      }

      console.log("DELETE TOKEN:");
      console.log(token);
      if (token) {
        try {
          await Browser.close();
        } catch (error) {
          console.error("Error closing browser:", error);
          console.log(error);
        }
        console.log("PUSHING DELETE TOKEN");
        console.log(token);
        console.log("/settings/delete/?token=" + token);
        history.push(`/settings/delete/?token=${token}`);
      } else {
        console.error("No token found in the URL");
      }

      return;
    }
    // Handle all other deep links
    const pathParts = url.pathname.split("/").filter(Boolean);
    console.log("Path parts:", pathParts);
    if (Capacitor.getPlatform() === "ios") {
      try {
        console.log("iOS: Opening deep link");
        try {
          await Browser.close();
        } catch (error) {
          console.error("Error closing browser:", error);
          console.log(error);
        }

        const deepLinkUrl = `thispantry://${pathParts.join("/")}`;
        console.log("Deep link URL:", deepLinkUrl);

        try {
          const canOpen = await App.canOpenUrl({ url: deepLinkUrl });
          console.log("Can open URL:", canOpen);

          try {
            await App.openUrl({ url: deepLinkUrl });
          } catch (error) {
            console.error("Error opening deep link:", error);
            console.log(error);
          }
        } catch (error) {
          console.error("Error checking if URL can be opened:", error);
          console.log(error);
        }
      } catch (error) {
        console.error("Error opening deep link:", error);
      }
    } else {
      // Non-iOS platforms use history.push
      if (pathParts.length === 1) {
        history.push(url.pathname); // Profile pages
      } else if (pathParts.length === 2 && !pathParts[1].startsWith("post/")) {
        history.push(url.pathname); // Recipe pages
      } else if (pathParts.length === 3 && pathParts[1] === "post") {
        history.push(url.pathname); // Post pages
      }
    }

    await Browser.close();
  });

  return () => {
    listener.remove();
  };
};
