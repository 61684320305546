import React from "react";
import MealSectionItem from "./MealSectionItem";

//MUI
import { Typography, Button, Divider } from "@mui/material";

export const MealSection = ({ course, data }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="w-full flex flex-col gap-2">
        <div className="px-4 py-2 w-full border border-separator rounded-md shadow-sm bg-background flex flex-row justify-between items-center">
          <Typography variant="h6" className="font-normal">
            {course}
          </Typography>
          <Button variant="contained" color="secondary" size="small" disableElevation className="text-primaryText">
            + Add to {course}
          </Button>
        </div>
        {data.map((item) => (
          <MealSectionItem course={course} data={item} key={item._id} />
        ))}
      </div>
      <div className="flex justify-center border border-separator rounded-md shadow-sm">
        <Button fullWidth className="bg-background">
          <Typography variant="subtitle2" className="font-normal">
            Add to {course}
          </Typography>
        </Button>
      </div>
    </div>
  );
};

export default MealSection;
