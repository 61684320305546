import React from "react";
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Typography, Button, TextField } from "@mui/material";

const PREFIX = 'IntroModal';

const classes = {
  root: `${PREFIX}-root`,
  titleContainer: `${PREFIX}-titleContainer`,
  startButton: `${PREFIX}-startButton`,
  servingsContainer: `${PREFIX}-servingsContainer`,
  servingsTitle: `${PREFIX}-servingsTitle`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    margin: theme.spacing(4),
  },

  [`& .${classes.titleContainer}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.startButton}`]: {
    textTransform: "none",
  },

  [`& .${classes.servingsContainer}`]: {
    margin: theme.spacing(4, 0),
  },

  [`& .${classes.servingsTitle}`]: {
    marginBottom: theme.spacing(1),
  }
}));

export const IntroModal = ({ walkthrough: { recipe, user } }) => {


  //Destructuring
  const { name } = recipe;
  const { username } = user;

  //TO DO:
  //Show recipe images gallery?
  //Necessary Equipment
  //Servings -> Auto scale if change value (notify user if values are gonna change) + reset button to default to the recipe base amount
  //Get started button
  return (
    <Root className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography variant="h6">{name}</Typography>
        <Typography variant="subtitle1">@{username}</Typography>
      </div>
      <div className={classes.servingsContainer}>
        <Typography variant="subtitle1" className={classes.servingsTitle}>
          Recipe Yield:
        </Typography>
        <div>
          <TextField variant="outlined"></TextField>
        </div>
      </div>
      <div className={classes.startContainer}>
        <Button
          variant="contained"
          color="primary"
          className={classes.startButton}
          disableElevation
        >
          Get Started!
        </Button>
      </div>
    </Root>
  );
};

IntroModal.propTypes = {};

const mapStateToProps = (state) => ({
  walkthrough: state.walkthrough,
});

export default connect(mapStateToProps, {})(IntroModal);
