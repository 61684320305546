import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";
import { askNotificationPerms } from "../sw";

export const initializeNotifications = async () => {
  if (Capacitor.getPlatform() === "ios") {
    console.log("iOS platform detected, initializing notifications");
    try {
      const result = await PushNotifications.requestPermissions();
      console.log("Permission request result:", result);

      if (result.receive === "granted") {
        console.log("Notifications permission granted, registering...");
        await PushNotifications.register();

        PushNotifications.addListener("registration", async (token) => {
          console.log("Received device token:", token.value);
          try {
            const response = await fetch("/api/notifications/register-device", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              body: JSON.stringify({
                token: token.value,
                environment: process.env.NODE_ENV === "production" ? "production" : "development",
              }),
            });
            const data = await response.json();
            console.log("Device registration response:", data);
          } catch (error) {
            console.error("Device registration error:", error);
          }
        });

        PushNotifications.addListener("pushNotificationReceived", (notification) => {
          console.log("Push notification received:", notification);
        });

        PushNotifications.addListener("pushNotificationActionPerformed", (notification) => {
          console.log("Push notification action performed:", notification);
          if (notification.notification.data?.url) {
            window.location.href = notification.notification.data.url;
          }
        });
      } else {
        console.log("Notification permission denied:", result);
      }
    } catch (error) {
      console.error("Notification setup error:", error);
    }
  } else {
    // Use existing web push setup
    console.log("Web push notification perms requested");
    askNotificationPerms();
  }
};
