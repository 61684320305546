import React from "react";

//Components
import Update from "./Update";

const UpdateModal = ({ updates }) => {
  return (
    <div>
      {updates.map((update, i) => {
        return <Update key={i} update={update} />;
      })}
    </div>
  );
};
export default UpdateModal;
