import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import api from "../../../../api/api";

//Components
import Spinner from "../../../layout/Spinner";
import Ingredient from "./components/Ingredient";
import ReviewModal from "../../../recipes/review/ReviewModal";
import CannotFind from "../../../static/CannotFind";

//Modal
import CustomModal from "../../../modal/CustomModal";
import IntroModal from "./modals/IntroModal";
import FinishRecipeModal from "./modals/FinishRecipeModal";

//Actions
import { getWalkthroughRecipe, setWalkthroughSection } from "../../../../actions/walkthrough";
import { showReviewModal, hideReviewModal } from "../../../../actions/review";

//FA Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

//MUI Styling
import { Typography, Button, Select, MenuItem } from "@mui/material";

const PREFIX = "Walkthrough";

const classes = {
  root: `${PREFIX}-root`,
  nav: `${PREFIX}-nav`,
  recipeTitleContainer: `${PREFIX}-recipeTitleContainer`,
  navButton: `${PREFIX}-navButton`,
  recipeTitle: `${PREFIX}-recipeTitle`,
  recipeUser: `${PREFIX}-recipeUser`,
  sectionToggle: `${PREFIX}-sectionToggle`,
  main: `${PREFIX}-main`,
  steps: `${PREFIX}-steps`,
  stepsList: `${PREFIX}-stepsList`,
  step: `${PREFIX}-step`,
  stepNumber: `${PREFIX}-stepNumber`,
  stepTip: `${PREFIX}-stepTip`,
  ingredients: `${PREFIX}-ingredients`,
  ingredientList: `${PREFIX}-ingredientList`,
  menuPaper: `${PREFIX}-menuPaper`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    marginLeft: "5rem",
    width: "calc(100% - 5rem)",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      width: "100%",
      margin: "5rem 0",
      marginBottom: "10rem",
    },
    //w-full mt-5rem mb-10rem md:m-0 w-[calc(100% - 5rem)] ml-5rem
  },

  [`& .${classes.nav}`]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.separator}`,
    padding: theme.spacing(2, 4),
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },

  [`& .${classes.recipeTitleContainer}`]: {
    padding: theme.spacing(2, 4),
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.navButton}`]: {
    color: theme.palette.background,
    backgroundColor: theme.palette.mainGreen,
    textTransform: "none",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
    },
  },

  [`& .${classes.recipeTitle}`]: {
    fontWeight: 600,
    maxWidth: "70%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
  },

  [`& .${classes.recipeUser}`]: {
    fontWeight: 400,
    color: theme.palette.mainGreen,
  },

  [`& .${classes.sectionToggle}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  [`& .${classes.main}`]: {
    margin: theme.spacing(2, 4),
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
    },
  },

  [`& .${classes.steps}`]: {
    width: "50%",
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      width: "100%",
      paddingRight: 0,
    },
  },

  [`& .${classes.stepsList}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.step}`]: {
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.stepNumber}`]: {
    width: theme.spacing(6),
  },

  [`& .${classes.stepTip}`]: {
    color: theme.palette.secondaryTextOnWhite,
    marginTop: theme.spacing(1),
  },

  [`& .${classes.ingredients}`]: {
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  [`& .${classes.ingredientList}`]: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    paddingLeft: 0,
  },

  [`& .${classes.menuPaper}`]: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    border: `1px solid ${theme.palette.separator}`,
  },
}));

String.prototype.toTitleCase = function () {
  var i, j, str, lowers, uppers;
  str = this.replace(/([^\W_]+[^\s-]*) */g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

  // Certain minor words should be left lowercase unless
  // they are the first or last words in the string
  lowers = [
    "A",
    "An",
    "The",
    "And",
    "But",
    "Or",
    "For",
    "Nor",
    "As",
    "At",
    "By",
    "For",
    "From",
    "In",
    "Into",
    "Near",
    "Of",
    "On",
    "Onto",
    "To",
    "With",
  ];
  for (i = 0, j = lowers.length; i < j; i++)
    str = str.replace(new RegExp("\\s" + lowers[i] + "\\s", "g"), function (txt) {
      return txt.toLowerCase();
    });

  // Certain words such as initialisms or acronyms should be left uppercase
  uppers = ["Id", "Tv"];
  for (i = 0, j = uppers.length; i < j; i++)
    str = str.replace(new RegExp("\\b" + uppers[i] + "\\b", "g"), uppers[i].toUpperCase());

  return str;
};

//TO DO:
//Overview screen
//Next button to go through sections
//Edit ingredient

export const Walkthrough = ({
  walkthrough: {
    recipe,
    loading,
    error,
    user,
    current: { section },
  },
  review: { recipeReviewModal },
  getWalkthroughRecipe,
  setWalkthroughSection,
  showReviewModal,
  hideReviewModal,
}) => {
  const { recipeID } = useParams();

  const [showIntroModal, setShowIntroModal] = useState(false);
  const [showFinishRecipeModal, setShowFinishRecipeModal] = useState(false);

  const onCloseIntroModal = () => {};

  const getRecipe = async (id) => {
    getWalkthroughRecipe(id);
  };

  const handleFinishRecipe = () => {
    setShowFinishRecipeModal(true);
  };

  useEffect(() => {
    getRecipe(recipeID);
  }, [recipeID]);

  //Sections
  //Intro screen
  const navSection = (
    <Root className={classes.navContainer}>
      <div className={classes.recipeTitleContainer}>
        <Typography variant="h5" className={classes.recipeTitle}>
          {recipe && recipe.name}
        </Typography>
        <Typography variant="subtitle1" className={classes.recipeUser}>
          {user && "@" + user.username}
        </Typography>
      </div>
      <div className={classes.nav}>
        <Select
          value={recipe && recipe.sections ? recipe.sections[section.index].title : ""}
          size="small"
          MenuProps={{
            classes: { paper: classes.menuPaper },
          }}
          onChange={(e) => {
            // Handle onChange event here
            // Update the visible section based on the selected value
            // You can access the selected value using e.target.value
            const selectedSection = e.target.value;
            console.log(e.target.value);
            const selectedIndex = recipe.sections.findIndex((section) => section.title === selectedSection);

            setWalkthroughSection(selectedIndex);
          }}
        >
          {recipe && recipe.sections ? (
            recipe.sections.map((section) => (
              <MenuItem key={section.id} value={section.title}>
                {section.title}
              </MenuItem>
            ))
          ) : (
            <MenuItem value={null}>No sections</MenuItem>
          )}
        </Select>
        <div>
          <Button
            variant="contained"
            className={classes.navButton}
            disableElevation
            size="small"
            onClick={() => handleFinishRecipe()}
          >
            <Typography variant="subtitle1">Finish Recipe</Typography>
          </Button>
        </div>
      </div>
    </Root>
  );
  //Walkthrough
  const steps = (
    <Root className={classes.steps}>
      <Typography variant="h6">Steps</Typography>
      <div className={classes.stepsList}>
        {section && section.steps ? (
          section.steps.map((step, i) => {
            return (
              <div className={classes.step}>
                <div>
                  <Typography variant="h6" className={classes.stepNumber}>
                    {i + 1}.
                  </Typography>
                </div>
                <div>
                  <div className={classes.stepText}>
                    <Typography variant="subtitle1">{step.text}</Typography>
                  </div>
                  {step && step.tip && (
                    <div className={classes.stepTip}>
                      <Typography variant="subtitle1">Tip: {step.tip}</Typography>
                    </div>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <Typography>No steps</Typography>
        )}
      </div>
    </Root>
  );

  const ingredients = (
    <Root className={classes.ingredients}>
      <Typography variant="h6">Ingredients</Typography>
      <ul className={classes.ingredientList}>
        {section && section.ingredients && section.ingredients.length > 0 ? (
          section.ingredients.map((ingredient, i) => {
            return (
              <Ingredient section={section} sectionIndex={section.index} ingredient={ingredient} key={i} index={i} />
            );
          })
        ) : (
          <li>
            <Typography>No ingredients</Typography>
          </li>
        )}
      </ul>
    </Root>
  );
  //Just have a title above nav bar, no intro section
  const walkthroughHTML = (
    <Root className={classes.container}>
      {navSection}
      {recipe ? (
        <div className={classes.main}>
          {steps}
          {ingredients}
        </div>
      ) : (
        <Spinner />
      )}
    </Root>
  );
  return (
    <Root className="w-full mt-[5rem] mb-[10rem] md:m-0 md:w-[calc(100%-5rem)] md:ml-[5rem]">
      {!loading && recipe && user && (
        <CustomModal open={showIntroModal} handleClose={onCloseIntroModal} showExit={false}>
          <IntroModal recipe={recipe} user={user} handleClose={onCloseIntroModal} />
        </CustomModal>
      )}
      <CustomModal open={showFinishRecipeModal} handleClose={() => setShowFinishRecipeModal(false)} showExit={true}>
        <FinishRecipeModal handleClose={() => setShowFinishRecipeModal(false)} />
      </CustomModal>

      {loading ? <Spinner /> : error ? <CannotFind /> : walkthroughHTML}
    </Root>
  );
};

Walkthrough.propTypes = {
  walkthrough: PropTypes.object.isRequired,
  getWalkthroughRecipe: PropTypes.func.isRequired,
  setWalkthroughSection: PropTypes.func.isRequired,
  showReviewModal: PropTypes.func.isRequired,
  review: PropTypes.object.isRequired,
  hideReviewModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  walkthrough: state.walkthrough,
  review: state.review,
});

export default connect(mapStateToProps, {
  getWalkthroughRecipe,
  setWalkthroughSection,
  showReviewModal,
  hideReviewModal,
})(Walkthrough);
