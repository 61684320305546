import api from "../api/api";

import store, { history } from "../store";

//Notifications
import { askNotificationPerms } from "../sw";

//Register
import { setRegisterErrors, setSignupStage } from "./register";
//Redux types
import {
  GET_VIEWED_PROFILE,
  MY_VIEWED_PROFILE,
  VIEWED_PROFILE_ERROR,
  RESET_VIEWED_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  GET_PROFILE_POSTDATA,
  GET_PROFILE_POST_ERROR,
  GET_PROFILE_RECIPEDATA,
  GET_PROFILE_RECIPE_ERROR,
} from "./types";

export const getViewedProfile = (username) => async (dispatch) => {
  try {
    const state = store.getState().profile;

    //Fetch viewed profile
    const res = await api.get(`/profile/full/${username}`);

    let viewedMine = false;
    if (state.currentProfile) {
      viewedMine = username.toUpperCase() === state.currentProfile.usernameUpper;
    }

    const payloadData = {
      ...res.data,
      viewedMine,
    };

    dispatch({
      type: GET_VIEWED_PROFILE,
      payload: payloadData,
    });

    return { followers: res.data.followerCount, following: res.data.followingCount };
  } catch (error) {
    console.log(error);
    dispatch({
      type: VIEWED_PROFILE_ERROR,
    });

    return { followers: 0, following: 0 };
  }
};

export const resetViewedProfile = () => (dispatch) => {
  dispatch({
    type: RESET_VIEWED_PROFILE,
  });
};

export const updateProfileSuccess = (newValues) => (dispatch) => {
  dispatch({
    type: UPDATE_PROFILE_SUCCESS,
    payload: newValues,
  });
};

export const fetchProfilePosts = () => async (dispatch) => {
  //Fetch additional posts for profile
  const state = store.getState();
};

export const fetchProfileRecipes = () => async (dispatch) => {};

export const getRecipePostData = (recipeid, index) => async (dispatch) => {
  try {
    //TO DO: Check if post already loaded earlier, avoid an extra call
    const res = await api.get(`/recipe/feed/${recipeid}`);

    dispatch({
      type: GET_PROFILE_RECIPEDATA,
      payload: {
        index: index,
        data: res.data,
      },
    });
  } catch (error) {
    dispatch({
      type: GET_PROFILE_RECIPE_ERROR,
      payload: {
        index,
      },
    });
  }
};
export const getProfilePostData =
  (postid, index, repostuser = null) =>
  async (dispatch) => {
    try {
      //TO DO: Check if post already loaded earlier, avoid an extra call
      const res = await api.post(`/post/feed/`, { postid, repostuser }, { timeout: 30000 });

      dispatch({
        type: GET_PROFILE_POSTDATA,
        payload: {
          index: index,
          data: res.data,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_PROFILE_POST_ERROR,
        payload: {
          index,
        },
      });
    }
  };

export const followUser = (id) => async (dispatch) => {
  try {
    //Set state to following

    const res = await api.post(`/profile/follow/${id}`);

    dispatch({
      type: UPDATE_PROFILE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};
