import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//Actions
import { hideRegisterModal } from "../../../actions/auth";
import { setSignupStage } from "../../../actions/register";
//MUI Styling
import { useMediaQuery } from "@mui/material";

//MUI Components
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

//Logo
import { ReactComponent as Logo } from "../../../assets/logos/full_logo.svg";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as GoogleIcon } from "../res/google.svg";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { ReactComponent as AppleIcon } from "../../../assets/icons/svg/apple-brands-solid.svg";

//Capacitor
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";

const Initial = ({ hideRegisterModal, setSignupStage }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [capacitorPlatform, setCapacitorPlatform] = useState(null);

  const getCapacitorPlatform = () => {
    const platform = Capacitor.getPlatform();

    console.log(platform);

    if (platform) {
      console.log(platform);
      setCapacitorPlatform(platform);
    } else {
      console.log("No platform");
      setCapacitorPlatform("web");
    }
  };
  useEffect(() => {
    getCapacitorPlatform();
  }, []);

  const handleGoogleClick = async () => {
    console.log("handleGoogleClick");

    if (capacitorPlatform === "web") {
      console.log("web open google");
      await Browser.open({ url: "https://www.thispantry.com/api/auth/google" });
    } else if (capacitorPlatform === "ios") {
      console.log("ios open google");
      await Browser.open({
        url: "https://www.thispantry.com/api/auth/google/ios",
      });
    } else if (capacitorPlatform === "android") {
      console.log("android open google");
      await Browser.open({
        url: "https://www.thispantry.com/api/auth/google",
        presentationStyle: "popover",
        windowName: "_self",
        toolbarColor: "#598a56",
      });
    }
  };

  const handleAppleClick = async () => {
    if (capacitorPlatform === "web") {
      await Browser.open({
        url: "https://www.thispantry.com/api/auth/apple",
      });
    } else if (capacitorPlatform === "ios") {
      await Browser.open({
        url: "https://www.thispantry.com/api/auth/apple/ios",
      });
    } else if (capacitorPlatform === "android") {
      await Browser.open({
        url: "https://www.thispantry.com/api/auth/apple",
        presentationStyle: "popover",
        windowName: "_self",
        toolbarColor: "#598a56",
      });
    }
  };
  return (
    <div className="h-full w-full flex items-center">
      <div className="w-full flex flex-col overflow-y-auto text-center ">
        <Logo className="h-[4rem] md:h-[5rem] select-none fill-mainGreen" />
        <Typography variant={isSmallScreen ? "subtitle1" : "h6"} className="mt-2 mb-8">
          Get in early.<br></br>Sign Up for the Public Beta Test.
        </Typography>
        <div className="w-full flex flex-col gap-2">
          <Button
            variant="outlined"
            fullWidth
            startIcon={<GoogleIcon className="h-[1.5rem]" />}
            classes={{
              root: "relative pl-[1rem] border-secondaryText-300 hover:border-secondaryText",
              startIcon: "absolute left-[1rem]",
            }}
            size={isSmallScreen ? "medium" : "large"}
            onClick={handleGoogleClick}
          >
            Sign Up with Google
          </Button>
          <Button
            variant="outlined"
            fullWidth
            startIcon={<AppleIcon className="h-[1.5rem] fill-black" />}
            classes={{
              root: "relative pl-[1rem] border-secondaryText-300 hover:border-secondaryText",
              startIcon: "absolute left-[1rem]",
            }}
            size={isSmallScreen ? "medium" : "large"}
            onClick={handleAppleClick}
          >
            Sign Up with Apple
          </Button>

          <Button
            variant="outlined"
            fullWidth
            startIcon={<FontAwesomeIcon icon={faEnvelope} />}
            classes={{
              root: "relative pl-[1rem] border-secondaryText-300 hover:border-secondaryText",
              startIcon: "absolute left-[1rem]",
            }}
            size={isSmallScreen ? "medium" : "large"}
            onClick={() => setSignupStage(0)}
          >
            Sign Up with Email
          </Button>
          <Typography variant="subtitle2" className="mt-1 md:mt-4">
            Already have an account?{" "}
            <Link to="/login" className="text-mainGreen" onClick={() => hideRegisterModal()}>
              Sign In
            </Link>
          </Typography>
          <Typography variant={isSmallScreen ? "caption" : "body2"} className="text-secondaryText md:mt-4">
            Click "Sign Up" to agree to thisPantry's{" "}
            <Link to="/terms" className="text-mainGreen">
              Terms of Service
            </Link>{" "}
            and acknowledge that thisPantry's{" "}
            <Link to="/privacy" className="text-mainGreen">
              Privacy Policy
            </Link>{" "}
            applies to you.
          </Typography>
        </div>
      </div>
    </div>
  );
};

Initial.propTypes = {
  hideRegisterModal: PropTypes.func.isRequired,
  setSignupStage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, { hideRegisterModal, setSignupStage })(Initial);
