import api from "../api/api";

//Redux types
import {
  SHOW_RECIPE_REVIEW_MODAL,
  HIDE_RECIPE_REVIEW_MODAL,
  CREATE_RECIPE_REVIEW,
  CREATE_RECIPE_REVIEW_ERROR,
} from "./types";

//Show review modal
export const showReviewModal = (allowDelay, recipeData) => (dispatch) => {
  dispatch({
    type: SHOW_RECIPE_REVIEW_MODAL,
    payload: { allowDelay, recipeData },
  });
};

//Hide review modal
export const hideReviewModal = () => (dispatch) => {
  dispatch({
    type: HIDE_RECIPE_REVIEW_MODAL,
  });
};

export const createRecipeReview = (reviewData) => async (dispatch) => {
  try {
    //Send data to backend
    const res = await api.post(`/review/recipe/${reviewData.id}`, reviewData);

    dispatch({
      type: CREATE_RECIPE_REVIEW,
      payload: res.data,
    });

    return true;
  } catch (error) {
    dispatch({
      type: CREATE_RECIPE_REVIEW_ERROR,
    });
    return false;
  }
};
