import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Typography, TextField, Button, Checkbox } from "@mui/material";

//Actions
import { editIngredient, removeIngredient } from "../../../../actions/createrecipe";

//to do:
//Add stylized formatted above the quantity and unit text boxes to quickly grasp the ingredient
//Add helper text to text boxes
//Change units to be an autofill textbox with current unit names
//Set errors if name is empty
//If missing key values, display in ingredient list too
//Show if ingredient is verified (from database), if name changes show it is unverified but re-check onSave or after name input is inactive?
//Handle not_exact and to taste amounts/units -> Checkbox underneath "Measured ingredient", if disabled, disable quantity and unit text boxes
//Remove ingredient functionality
//Ingredient search functionality? (Add ingredient from database)

const EditIngredientModal = ({
  ingredient,
  sectionIndex,
  ingredientIndex,
  editIngredient,
  handleModalClose,
  removeIngredient,
}) => {
  const [ingredientState, setIngredientState] = useState({
    id: ingredient && ingredient.id ? ingredient.id : "",
    name: ingredient && ingredient.name ? ingredient.name : "",
    quantity: ingredient && ingredient.quantity ? ingredient.quantity : "",
    unit: ingredient && ingredient.unit ? ingredient.unit : "",
    state: ingredient && ingredient.state ? ingredient.state : "",
    note: ingredient && ingredient.note ? ingredient.note : "",
    measured:
      ingredient && ingredient.measured
        ? ingredient.measured
        : ingredient.measured === false
        ? false
        : (ingredient.unit !== "not_exact" || ingredient.unit !== "to_taste") && ingredient.quantity !== ""
        ? true
        : false,
  });

  const [ingredientErrors, setIngredientErrors] = useState({
    name: "",
  });

  const [showOptionalFields, setShowOptionalFields] = useState(false);

  const handleHideOptionals = () => {
    //Clear optionals state
    setIngredientState({ ...ingredientState, state: "", note: "" });

    setShowOptionalFields(false);
  };

  //Remove state TO DO: Reset to false if clicking away from button
  const [removeAreYouSure, setRemoveAreYouSure] = useState(false);

  //Submit
  const handleSubmit = (e) => {
    //Stop higher forms from firing
    e.stopPropagation();
    e.preventDefault();

    const editSuccess = editIngredient(sectionIndex, ingredientIndex, ingredientState);

    if (!editSuccess.error) {
      handleModalClose();
    } else {
      switch (editSuccess.error) {
        case "empty_name": {
          setIngredientErrors({ ...ingredientErrors, name: "Name cannot be empty" });
          return;
        }
      }
    }
  };

  const handleRemove = () => {
    //Remove ingredient
    removeIngredient(sectionIndex, ingredientIndex);
    handleModalClose();
  };

  //Input handlers
  const handleQuantityChange = (e) => {
    setIngredientState({ ...ingredientState, quantity: e.target.value });
  };

  const handleUnitChange = (e) => {
    setIngredientState({ ...ingredientState, unit: e.target.value });
  };

  const handleNameChange = (e) => {
    setIngredientState({ ...ingredientState, name: e.target.value });
  };

  const handleStateChange = (e) => {
    setIngredientState({ ...ingredientState, state: e.target.value });
  };

  const handleNoteChange = (e) => {
    setIngredientState({ ...ingredientState, note: e.target.value });
  };

  const generateHelperTextForUnit = () => {
    if (ingredientState.unit === "not_exact") {
      return "This ingredient is not measured in exact quantities";
    } else if (ingredientState.unit === "to_taste") {
      return "This ingredient is measured to taste";
    } else if (ingredientState.unit === "self") {
      return "This ingredient is measured by itself. 1 egg, 2 apples";
    } else if (ingredientState.unit === "count") {
      return "This ingredient is measured by count. 2 eggs, 3 apples";
    } else {
      return "";
    }
  };

  //Check to display optional fields on modal open
  useEffect(() => {
    if (ingredient.state !== "" || ingredient.note !== "") {
      setShowOptionalFields(true);
    }
  }, []);

  return (
    <div className="mx-4 mb-4">
      <form onSubmit={(e) => handleSubmit(e)}>
        <div>
          <Typography variant="h6">Edit Ingredient</Typography>
        </div>
        <div>
          <div>
            <div className="flex flex-row">
              <TextField
                label="Amount"
                variant="outlined"
                fullWidth
                margin="normal"
                value={ingredientState.quantity}
                onChange={(e) => handleQuantityChange(e)}
                className="mr-1"
              />
              <TextField
                label="Unit"
                variant="outlined"
                fullWidth
                margin="normal"
                value={ingredientState.unit}
                onChange={(e) => handleUnitChange(e)}
                className="ml-1"
                helperText={generateHelperTextForUnit()}
              />
            </div>
          </div>
          <TextField
            label="Ingredient Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={ingredientState.name}
            onChange={(e) => handleNameChange(e)}
            error={ingredientErrors.name !== ""}
            helperText={ingredientErrors.name !== "" ? ingredientErrors.name : null}
          />
          <div className="flex flex-row items-center mt-2">
            <Checkbox
              className="p-0 mr-1 hover:bg-transparent"
              color="primary"
              checked={ingredientState.measured}
              disableRipple
              onClick={() => setIngredientState({ ...ingredientState, measured: !ingredientState.measured })}
            />
            <div>
              <Typography variant="subtitle2" className="text-secondaryText font-normal">
                Measured Ingredient
              </Typography>
            </div>
          </div>
          <div className="mt-2">
            {showOptionalFields ? (
              <Button onClick={() => handleHideOptionals()} disableRipple className="hover:bg-transparent">
                <Typography variant="subtitle1" className="text-secondaryText">
                  - Hide Notes/Ingredient State
                </Typography>
              </Button>
            ) : (
              <Button onClick={() => setShowOptionalFields(true)} disableRipple className="hover:bg-transparent">
                <Typography variant="subtitle1" className="text-secondaryText">
                  + Add Notes/Ingredient State
                </Typography>
              </Button>
            )}
            {showOptionalFields && (
              <div className="mt-2">
                <TextField
                  label="State"
                  variant="outlined"
                  fullWidth
                  helperText="Fresh, Diced, Minced, etc."
                  value={ingredientState.state}
                  onChange={(e) => handleStateChange(e)}
                />
                <TextField
                  label="Notes"
                  variant="outlined"
                  className="mt-2"
                  fullWidth
                  value={ingredientState.note}
                  onChange={(e) => handleNoteChange(e)}
                />
              </div>
            )}
          </div>

          <div className="flex flex-row justify-between mt-4">
            {removeAreYouSure ? (
              <Button variant="outlined" color="danger" onClick={() => handleRemove()}>
                <span className="text-red">Yes, Remove</span>
              </Button>
            ) : (
              <Button variant="outlined" color="danger" onClick={() => setRemoveAreYouSure(true)}>
                Remove
              </Button>
            )}

            <Button variant="contained" color="primary" disableElevation type="submit">
              Save
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

EditIngredientModal.propTypes = {
  editIngredient: PropTypes.func.isRequired,
  ingredient: PropTypes.object.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  ingredientIndex: PropTypes.number.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  removeIngredient: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {};

export default connect(mapStateToProps, { editIngredient, removeIngredient })(EditIngredientModal);
