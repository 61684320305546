import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Link } from "react-router-dom";

//UI Components
import Searchable from "../../../layout/searchable/Searchable";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

//Actions
import {
  resetCookbookFilters,
  setAllergenFilters,
  setDietFilters,
  setExcludeIngredientFilters,
  setIncludeIngredientFilters,
} from "../../../../actions/cookbook";

const FiltersModal = ({
  handleClose,
  cookbook: { search },
  resetCookbookFilters,
  setAllergenFilters,
  setDietFilters,
  setExcludeIngredientFilters,
  setIncludeIngredientFilters,
}) => {
  const onAllergenChange = (items) => {
    setAllergenFilters(items);
  };

  const onDietChange = (items) => {
    setDietFilters(items);
  };

  const onIncludeChange = (items) => {
    setIncludeIngredientFilters(items);
  };

  const onExcludeChange = (items) => {
    setExcludeIngredientFilters(items);
  };

  const handleResetFilters = () => {
    resetCookbookFilters();
  };

  return (
    <div className="flex flex-col p-4 pt-0">
      <div className="mb-8">
        <Typography variant="h6" className="font-normal leading-none mb-1">
          Search Filters
        </Typography>
        <Typography variant="subtitle2" className="text-secondaryText leading-tight font-normal">
          Refine your search for recipes by adding filters
        </Typography>
      </div>
      <div className="flex flex-col gap-3">
        <div>
          <Searchable
            label="Allergens"
            labelWidth={85}
            maxItems={30}
            multiple={true}
            freeSolo={true}
            apiUrl="/allergens/searchable"
            allowAdd={true}
            onItemChange={onAllergenChange}
            selectedItemsFromParent={search.filters.allergies}
            helperText="Avoid recipes with these allergens listed"
          />
        </div>
        <div>
          <Searchable
            label="Special Diets"
            labelWidth={115}
            multiple={true}
            maxItems={30}
            freeSolo={true}
            apiUrl="/diets/searchable"
            allowAdd={true}
            onItemChange={onDietChange}
            selectedItemsFromParent={search.filters.diets}
            helperText="Find recipes for these diets"
          />
        </div>
      </div>
      <div className="flex flex-col gap-3 mt-4">
        <Typography variant="subtitle1" className="font-normal">
          Ingredients
        </Typography>
        <div>
          <Searchable
            label="Include"
            labelWidth={115}
            multiple={true}
            maxItems={30}
            freeSolo={true}
            apiUrl="/ingredients/searchable"
            allowAdd={true}
            onItemChange={onIncludeChange}
            selectedItemsFromParent={search.filters.include}
            helperText="Recipe must include these ingredients"
          />
        </div>
        <div>
          <Searchable
            label="Exclude"
            labelWidth={115}
            multiple={true}
            maxItems={30}
            freeSolo={true}
            apiUrl="/ingredients/searchable"
            allowAdd={true}
            onItemChange={onExcludeChange}
            selectedItemsFromParent={search.filters.exclude}
            helperText="Recipe must not include these ingredients"
          />
        </div>
      </div>
      <div className="my-4">
        <Divider />
      </div>
      <div className="flex flex-col">
        <Typography variant="caption" className="text-secondaryText">
          * Your dietary preferences are added by default
        </Typography>
        <Link to="/settings/dietary" onClick={() => handleClose()}>
          <Typography variant="caption" className="text-mainGreen">
            Edit preferences
          </Typography>
        </Link>
      </div>

      <div className="flex flex-row justify-between items-center">
        <div>
          <Button
            disableRipple
            className="hover:bg-transparent px-0 text-rose-700"
            onClick={() => handleResetFilters()}
          >
            Remove All Filters
          </Button>
        </div>
        <div>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            className="bg-mainGreen text-background normal-case"
            onClick={handleClose}
            size="large"
          >
            Search
          </Button>
        </div>
      </div>
    </div>
  );
};

FiltersModal.propTypes = {
  handleClose: PropTypes.func,
  cookbook: PropTypes.object.isRequired,
  resetCookbookFilters: PropTypes.func.isRequired,
  setAllergenFilters: PropTypes.func.isRequired,
  setDietFilters: PropTypes.func.isRequired,
  setIncludeIngredientFilters: PropTypes.func.isRequired,
  setExcludeIngredientFilters: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  cookbook: state.cookbook,
});

export default connect(mapStateToProps, {
  resetCookbookFilters,
  setAllergenFilters,
  setDietFilters,
  setIncludeIngredientFilters,
  setExcludeIngredientFilters,
})(FiltersModal);
