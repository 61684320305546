import React, { useState } from "react";
import { Typography, Button } from "@mui/material";
import { ReactComponent as ChevronDown } from "../../../assets/icons/svg/chevron-down.svg";
import { ReactComponent as ChevronUp } from "../../../assets/icons/svg/chevron-up.svg";

const Expense = ({ expense }) => {
  const [showItems, setShowItems] = useState(false);
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row justify-between">
        <div className="w-full">
          <div className="flex flex-row gap-2 items-center">
            <Typography variant="h6" className="font-normal leading-none">
              {expense.name}
            </Typography>
            <Typography variant="body2" className="font-normal leading-none text-secondaryText italic">
              - {expense.location}
            </Typography>
          </div>
        </div>

        <div>
          <Typography variant="h6" className="font-normal leading-none">
            ${(expense.total / 100).toFixed(2)}
          </Typography>
        </div>
      </div>
      <div>
        {expense.items && expense.items.length > 0 && (
          <div className="flex flex-row justify-between gap-4 mt-2">
            <div className="flex flex-row gap-2 items-center">
              {expense.date && (
                <Typography variant="body2" className="font-normal leading-none">
                  {new Date(expense.date).toLocaleDateString("en-US", { month: "short", day: "numeric" })}
                </Typography>
              )}
              <Typography variant="caption" className="font-normal leading-none text-secondaryText">
                {expense.items.length} items
              </Typography>
            </div>

            <Button
              onClick={() => setShowItems(!showItems)}
              disableRipple
              className="p-0 hover:bg-transparent justify-end"
            >
              <div className="flex flex-row gap-2">
                {showItems ? <ChevronUp className="w-3" /> : <ChevronDown className="w-3" />}
                <Typography variant="caption" className="font-normal leading-none text-secondaryText-600">
                  {showItems ? "Hide" : "Show Items"}
                </Typography>
              </div>
            </Button>
          </div>
        )}
        {expense.items && expense.items.length > 0 && showItems && (
          <div className="flex flex-col gap-2 mt-4">
            {expense.items.map((item, i) => (
              <div key={`${item.name}-${i}`} className="w-full flex flex-row justify-between items-center">
                <Typography variant="subtitle2" className="font-normal leading-none text-secondaryText-600">
                  {item.name}
                </Typography>
                <div className="flex-1 border-b border-separator mx-4"></div>
                <Typography variant="subtitle2" className="font-normal leading-none text-secondaryText-600">
                  ${(item.amount / 100).toFixed(2)}
                </Typography>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Expense;
