import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import api from "../../../api/api";

//Components
import CustomModal from "../../modal/CustomModal";
import { Typography } from "@mui/material";

const RecipeModal = ({ open, handleClose, recipeID }) => {
  const handleModalClose = () => {
    //TODO: Reset state (recipe data)
    handleClose();
  };

  const [recipe, setRecipe] = useState(null);

  const getRecipeByID = async () => {
    try {
      const res = await api.get(`/recipes/id/${recipeID}`);

      setRecipe(res.data.recipe);
    } catch (error) {
      setRecipe({ name: "F U C K" });
    }
  };

  useEffect(() => {
    if (open) {
      getRecipeByID();
    }
  }, [open]);

  console.log(recipeID);

  return (
    <CustomModal open={open} handleClose={() => handleModalClose()} type="bottom">
      <div className="w-full min-h-[90vh]">{recipe && recipe.name && <Typography>{recipe.name}</Typography>}</div>
    </CustomModal>
  );
};

export default RecipeModal;
