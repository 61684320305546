import React, { useState, Fragment, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  ButtonGroup,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  Divider,
  MenuItem,
} from "@mui/material";

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../ui/dropdown-menu";
import { Button as ShadcnButton } from "../ui/button";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import Helmet from "react-helmet";

//Components
import RecipeSectionManager from "./components/recipe/RecipeSectionManager";
import ImageDropzone from "./components/images/ImageDropzone";
import ImageReorderPreview from "./components/images/ImageReorderPreview";
import RecipeSectionTags from "./components/recipe/RecipeSectionTags";
import Searchable from "../layout/searchable/Searchable";

//Icons
import { ReactComponent as ImagesIcon } from "../../assets/icons/svg/images.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/svg/question-circle.svg";
import { ReactComponent as TitleIcon } from "../../assets/icons/svg/chef.svg";
import { ReactComponent as ChevronDown } from "../../assets/icons/svg/chevron-down.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faCropSimple, faPlus } from "@fortawesome/free-solid-svg-icons";

//Actions
import { processIngredients, fetchRecipeToEdit, resetCreateRecipe } from "../../actions/createrecipe";
import { setAlert } from "../../actions/alert";
import { imageUploadReset, setOriginalImages } from "../../actions/media";
import { createRecipe, editRecipe } from "../../actions/recipe";
import { togglePlusUpgradeModal } from "../../actions/plus";

//Modals
import CustomModal from "../modal/CustomModal";
import AddRecipeSectionModal from "./components/recipe/AddRecipeSectionModal";
import { Tabs, TabsList, TabsTrigger } from "../ui/tabs";
import PlusModal from "../plus/PlusModal";
import RecipeMacrosModal from "./components/recipe/RecipeMacrosModal";
import RecipeOutputModal from "./components/recipe/RecipeOutputModal";
import RecipeTimingModal from "./components/recipe/RecipeTimingModal";
//TO DO: Make sure if ounce unit and first time for ingredient that the ounce type is logged\

//TO DO:
//Horizontal scrollable carousel for uploaded images with delete button on top right, click to edit crop?

const CreateRecipe = ({
  processIngredients,
  recipe: { create, edit },
  user,
  setAlert,
  setOriginalImages,
  imageUploadReset,
  createRecipe,
  editRecipe,
  fetchRecipeToEdit,
  resetCreateRecipe,
  media: { images, originalImages },
  plus,
  togglePlusUpgradeModal,
}) => {
  //Limits

  const { recipeid } = useParams();

  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const startType = queryParams.get("type");
  const startPrompt = queryParams.get("prompt");
  const [initialAddType, setInitialAddType] = useState("add");
  const [initialAddPrompt, setInitialAddPrompt] = useState("");

  const handleAddSectionModalClose = () => {
    setInitialAddType("add");
    setShowAddSection(false);
  };

  const [formView, setFormView] = useState("info");

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [tags, setTags] = useState([]);
  const [isPublic, setIsPublic] = useState(true);

  const [recipeSource, setRecipeSource] = useState("");
  const [recipeSourceType, setRecipeSourceType] = useState("Add a source");
  const [recipeSourceURL, setRecipeSourceURL] = useState("");
  const [recipeSourceRefs, setRecipeSourceRefs] = useState(null);

  const [submitLoading, setSubmitLoading] = useState(false);

  const onRecipeSourceChange = (e) => {
    const value = e.target.value;

    if (value.length > 256) {
      setAlert("Recipe source must be less than 256 characters", "warning");
    } else {
      setRecipeSource(e.target.value);
    }
  };

  const onRecipeSourceURLChange = (e) => {
    const value = e.target.value;

    if (value.length > 256) {
      setAlert("Recipe source must be less than 256 characters", "warning");
    } else {
      setRecipeSourceURL(e.target.value);
    }
  };

  //Cuisine State
  const [cuisine, setCuisine] = useState([]);
  const onCuisineChange = (items) => {
    setCuisine(items);
  };

  //Course state
  const [course, setCourse] = useState([]);
  const onCourseChange = (items) => {
    setCourse(items);
  };

  //Allergen state
  //TO DO: Detect from ingredients in sections
  const [allergens, setAllergens] = useState([]);
  const [allergenMayContain, setAllergenMayContain] = useState([]);

  const [showAllergens, setShowAllergens] = useState(false);
  const onAllergenChange = (items) => {
    setAllergens(items);
  };

  const onAllergenMayContainChange = (items) => {
    setAllergenMayContain(items);
  };

  const handleRemoveAllergens = () => {
    //Reset allergen state
    setAllergens([]);
    setAllergenMayContain([]);
    setShowAllergens(false);
  };

  //Friendly Diet State
  //TO DO: Detect from ingredients in sections
  const [friendlyDiets, setFriendlyDiets] = useState([]);
  const [showFriendlyDiets, setShowFriendlyDiets] = useState(false);
  const onFriendlyDietChange = (items) => {
    setFriendlyDiets(items);
  };

  const handleRemoveFriendlyDiets = () => {
    setFriendlyDiets([]);
    setShowFriendlyDiets(false);
  };

  //Modal Handling
  const [showAddSection, setShowAddSection] = useState(false);

  const handleNameChange = (e) => {
    const name = e.target.value;

    if (name.length < 100) {
      setName(name);
    } else {
      setAlert("Name must be less than 100 characters", "warning");
    }
  };

  const handleDescriptionChange = (e) => {
    const desc = e.target.value;

    if (desc.length < 2048) {
      setDescription(desc);
    } else {
      setAlert("Description must be less than 2048 characters", "warning");
    }
  };

  const handleAddSections = () => {
    if (create.sections.length < 10) {
      setInitialAddType("add");
      setShowAddSection(true);
    } else {
      setAlert("A recipe cannot have more than 10 sections", "warning");
    }
  };

  const handleFormSubmit = async (event) => {
    //TO DO: Add edit handling
    event.preventDefault();

    setSubmitLoading(true);

    const sectionsObj = JSON.parse(JSON.stringify(create.sections));

    //Create recipe object
    let recipe = {
      name,
      description,
      tags,
      isPublic,
      sections: sectionsObj,
      cuisine,
      course,
      allergens: {
        contains: allergens,
        mayContain: allergenMayContain,
      },
      friendlyDiets,
      source:
        recipeSourceType !== "Add a source"
          ? {
              type: recipeSourceType,
              url: recipeSourceURL,
              text: recipeSource.trim(),
              ref: recipeSourceRefs,
            }
          : null,
    };

    //===Data Verification===
    //Name

    let isError = false;
    if (name.length < 3) {
      setAlert("Name must be at least 3 characters", "warning");
      isError = true;
    }

    if (name.length > 128) {
      setAlert("Name must be less than 128 characters", "warning");
      isError = true;
    }

    //Description
    if (!description) {
      recipe.description = "";
    } else {
      recipe.description = description;
    }

    recipe.description = recipe.description.trim();
    if (recipe.description.length > 2048) {
      setAlert("Description must be less than 2048 characters", "warning");
      isError = true;
    }

    //Tags
    if (tags.length < 3) {
      setAlert("Add at least 3 tags", "warning");
      isError = true;
    }

    if (tags.length > 20) {
      setAlert("Maximum of 20 tags allowed", "warning");
      isError = true;
    }

    //Recipe source
    if (recipe.source) {
      if (recipe.source.url.length > 1024) {
        setAlert("Source link must be less than 1024 characters in length");
      }

      if (recipe.source.text.length > 128) {
        setAlert("Source title must be less than 128 characters in length");
      }
    }

    let tagLengthLimit = false;
    for (let i = 0; i < tags.length; i++) {
      if (tags[i].length > 30) {
        tagLengthLimit = true;
        break;
      }
    }

    if (tagLengthLimit) {
      setAlert("Tags must be less than 30 characters", "warning");
      isError = true;
    }

    if (recipe.source) {
      if (recipe.source.length > 256) {
        setAlert("Recipe source must be less than 256 characters", "warning");
        isError = true;
      }
    }

    //Course
    //Strip values from searchables
    recipe.course && recipe.course.value ? (recipe.course = recipe.course.value) : (recipe.course = "");

    if (recipe.course && recipe.course.length > 32) {
      setAlert("Course must be less than 32 characters", "warning");
      isError = true;
    }

    //Cuisine
    //Strip values from searchables
    recipe.cuisine && recipe.cuisine.value ? (recipe.cuisine = recipe.cuisine.value) : (recipe.cuisine = "");

    if (recipe.cuisine && recipe.cuisine.length > 32) {
      setAlert("Cuisine must be less than 32 characters", "warning");
      isError = true;
    }

    //Allergens
    //Strip values from searchables
    if (recipe.allergens) {
      recipe.allergens.mayContain = recipe.allergens.mayContain.map((item) => item.value);
      recipe.allergens.contains = recipe.allergens.contains.map((item) => item.value);

      if (recipe.allergens.length > 20) {
        setAlert("Maximum of 20 allergens allowed", "warning");
        isError = true;
      }

      if (recipe.allergens.mayContain.length > 20) {
        setAlert("Maximum of 20 potential allergens allowed", "warning");
        isError = true;
      }

      for (let i = 0; i < recipe.allergens.contains.length; i++) {
        if (recipe.allergens.contains[i].length > 32) {
          setAlert("Allergen must be less than 32 characters", "warning");
          isError = true;
          break;
        }

        if (recipe.allergens.contains[i].length < 3) {
          setAlert("Allergen must be at least 3 characters", "warning");
          isError = true;
          break;
        }
      }

      for (let i = 0; i < recipe.allergens.mayContain.length; i++) {
        if (recipe.allergens.mayContain[i].length > 32) {
          setAlert("Potential allergen must be less than 32 characters", "warning");
          isError = true;
          break;
        }

        if (recipe.allergens.mayContain[i].length < 3) {
          setAlert("Potential allergen must be at least 3 characters", "warning");
          isError = true;
          break;
        }
      }
    }

    //Friendly Diets
    //Strip values from searchables
    if (recipe.friendlyDiets) {
      recipe.friendlyDiets = recipe.friendlyDiets.map((item) => item.value);

      for (let i = 0; i < recipe.friendlyDiets.length; i++) {
        if (recipe.friendlyDiets[i].length > 32) {
          setAlert("Friendly Diet must be less than 32 characters", "warning");
          isError = true;
          break;
        }

        if (recipe.friendlyDiets[i].length < 3) {
          setAlert("Friendly Diet must be at least 3 characters", "warning");
          isError = true;
          break;
        }
      }
    }

    //Loop over sections, verify each section has a title and *some* content
    let hasIngredients = false;
    let hasInstructions = false;
    let hasTiming = false;
    let hasServings = false;

    if (recipe.sections.length === 0) {
      setAlert("Recipes must have at least one section", "warning");
      isError = true;
    }

    if (recipe.sections.length > 10) {
      setAlert("A recipe cannot have more than 10 sections", "warning");
      isError = true;
    }

    for (let i = 0; i < recipe.sections.length; i++) {
      let section = recipe.sections[i];

      if (section.title.length === 0) {
        setAlert("Sections must have a title", "warning");
        isError = true;
      }

      if (section.title.length > 50) {
        setAlert("Section title must be less than 50 characters", "warning");
        isError = true;
      }

      if (section.ingredients && section.ingredients.length > 0) {
        hasIngredients = true;
        if (section.ingredients.length > 100) {
          setAlert("A section cannot have more than 100 ingredients", "warning");
          isError = true;
        } else {
          for (let ing = 0; ing < section.ingredients.length; ing++) {
            let sectionIngredient = section.ingredients[ing];

            //Ingredient name
            if (sectionIngredient.name.length === 0) {
              setAlert("Ingredients must have a name", "warning");
              isError = true;
            }

            if (sectionIngredient.name.length < 3) {
              setAlert("Ingredient name must be at least 3 characters", "warning");
              isError = true;
            }

            if (sectionIngredient.name.length > 64) {
              setAlert("Ingredient name must be less than 64 characters", "warning");
              isError = true;
            }

            //Ingredient quantity
            // Check if the amount is a valid fraction
            if (sectionIngredient.quantity !== "") {
              if (sectionIngredient.measured) {
                const fractionRegex = /^\d{1,3}\/\d{1,3}$/;
                if (fractionRegex.test(sectionIngredient.quantity)) {
                  const [numerator, denominator] = sectionIngredient.quantity.split("/");
                  if (!isNaN(numerator) && !isNaN(denominator)) {
                    const decimalValue = parseFloat(numerator) / parseFloat(denominator);
                    if (decimalValue <= 0 && sectionIngredient.quantity !== "") {
                      setAlert("Ingredient amount must be greater than 0", "warning");
                      isError = true;
                    } else {
                      sectionIngredient.quantity = decimalValue.toFixed(3);
                    }
                  }
                } else if (isNaN(sectionIngredient.quantity) || sectionIngredient.quantity <= 0) {
                  setAlert("Ingredient amount must be a number or a fraction greater than 0", "warning");
                  isError = true;
                }
              }
            }

            //Ingredient unit
            if (sectionIngredient.unit) {
              if (sectionIngredient.unit.length > 32) {
                setAlert("Ingredient unit must be less than 32 characters", "warning");
                isError = true;
              }
            }

            //Ingredient state
            if (sectionIngredient.state) {
              if (sectionIngredient.state.length > 32) {
                setAlert("Ingredient state must be less than 32 characters", "warning");
                isError = true;
              }
            }

            //Note
            if (sectionIngredient.note) {
              if (sectionIngredient.note.length > 128) {
                setAlert("Ingredient note must be less than 128 characters", "warning");
                isError = true;
              }
            }
          }
        }
      }

      if (section.steps && section.steps.length > 0) {
        hasInstructions = true;

        if (section.steps.length > 100) {
          setAlert("A section cannot have more than 100 steps", "warning");
          isError = true;
        } else {
          for (let s = 0; s < section.steps.length; s++) {
            let step = section.steps[s];

            //Step text
            if (step.text.length === 0) {
              setAlert("Steps must have text", "warning");
              isError = true;
            }

            if (step.text.length > 1024) {
              setAlert("A step's text must be less than 1024 characters", "warning");
              isError = true;
            }
            //Step tip
            if (step.tips && step.tips.length > 0) {
              if (step.tips.length > 1) {
                setAlert("A step can only have one tip", "warning");
                isError = true;
              } else {
                if (step.tips[0].length > 256) {
                  setAlert("A step's tip must be less than 256 characters", "warning");
                  isError = true;
                }
              }
            }
          }
        }
      }

      //Timing
      if (section.timing) {
        const validateTime = (type) => {
          if (section.timing[type]) {
            let hours = section.timing[type].hours;
            let minutes = section.timing[type].minutes;

            if (hours && minutes) {
              hours = parseInt(section.timing[type].hours);
              minutes = parseInt(section.timing[type].minutes);

              if ((isNaN(hours) || hours < 0) && (isNaN(minutes) || minutes < 0)) {
                setAlert(`Section ${i + 1}, ${type} time must have valid hours or minutes`, "warning");
                isError = true;
              } else {
                if (type === "total") {
                  hasTiming = true;
                }
              }
            }
          }
        };
        validateTime("prep");
        validateTime("cook");
        validateTime("rest");
        validateTime("total");
      }

      //Output

      if (section.output) {
        //Servings + serving size
        if (section.output.servings) {
          const servingsNumber = parseInt(section.output.servings);
          if (isNaN(servingsNumber) || servingsNumber < 1 || servingsNumber > 100) {
            setAlert(`Section ${i + 1}, servings must be a number between 1 and 100`, "warning");
            isError = true;
          } else {
            hasServings = true;
          }
        }

        if (section.servingSize) {
          if (section.servingSize.length > 48) {
            setAlert(`Section ${i + 1}, serving size must be less than 48 characters`, "warning");
            isError = true;
          }
        }

        //Yield
        if (section.output.yield) {
          //Map searchable objects to just values for ingredient and unit
          let yieldUnit = "";
          let yieldIngredient = "";
          let hasUnitOrIngredient = false;

          //Map from searchable objects to strings
          if (section.output.yield.unit) {
            if (typeof section.output.yield.unit === "string") {
              yieldUnit = section.output.yield.unit;
            } else if (typeof section.output.yield.unit === "object") {
              if (section.output.yield.unit?.value || section.output.yield.unit?.value === "") {
                yieldUnit = section.output.yield.unit.value;
                section.output.yield.unit = yieldUnit;
              }
            }
          }

          if (section.output.yield.ingredient) {
            if (typeof section.output.yield.ingredient === "string") {
              yieldIngredient = section.output.yield.ingredient;
            } else if (typeof section.output.yield.ingredient === "object") {
              if (section.output.yield.ingredient?.value || section.output.yield.ingredient?.value === "") {
                yieldIngredient = section.output.yield.ingredient.value;
                section.output.yield.ingredient = yieldIngredient;
              }
            }
          }

          if (!(section.output.yield.amount === "" && yieldUnit === "" && yieldIngredient === "")) {
            //Must have either amount and unit or amount and ingredient
            //Amount
            if (!section.output.yield.amount || section.output.yield.amount === "") {
              setAlert("Yield amount is required", "warning");
              isError = true;
            }

            // Check if the amount is a valid fraction
            const fractionRegex = /^\d{1,3}\/\d{1,3}$/;
            if (fractionRegex.test(section.output.yield.amount)) {
              const [numerator, denominator] = section.output.yield.amount.split("/");
              if (!isNaN(numerator) && !isNaN(denominator)) {
                const decimalValue = parseFloat(numerator) / parseFloat(denominator);
                if (decimalValue <= 0) {
                  setAlert("Yield amount must be greater than 0", "warning");
                  isError = true;
                } else {
                  section.output.yield.amount = decimalValue.toFixed(3);
                }
              }
            } else if (isNaN(section.output.yield.amount) || section.output.yield.amount <= 0) {
              setAlert("Yield amount must be a number or a fraction greater than 0", "warning");
              isError = true;
            }

            if (section.output.yield.unit) {
              if (yieldUnit !== "") {
                hasUnitOrIngredient = true;
                if (yieldUnit.length > 32) {
                  setAlert("Yield unit must be less than 32 characters", "warning");
                  isError = true;
                }
              }

              //Set formatted unit string for backend
              section.output.yield.unit = yieldUnit;
            }

            if (section.output.yield.ingredient) {
              if (yieldIngredient !== "") {
                hasUnitOrIngredient = true;
                if (yieldIngredient.length > 64) {
                  setAlert("Yield ingredient must be less than 64 characters", "warning");
                  isError = true;
                }
              }

              //Set formatted ingredient string for backend
              section.output.yield.ingredient = yieldIngredient;
            }

            if (!hasUnitOrIngredient) {
              setAlert("Yield must have a unit or ingredient", "warning");
              isError = true;
            }
          }
        }
      }

      //Nutrition
      //Macronutrients
      if (recipe.macros) {
        for (let m = 0; m < recipe.macros.length; m++) {
          let macro = recipe.macros[m];

          if (isNaN(macro.amount) || macro.amount < 0 || macro.amount > 10000) {
            setAlert(
              `Macronutrient amount must be a number between 0 and 10,000 (Section ${i + 1}, ${macro.value})`,
              "warning"
            );
            isError = true;
          }

          //Verify macro value < 48 characters
          if (macro.value.length > 48) {
            setAlert(
              `Macronutrient value must be less than 48 characters (Section ${i + 1}, ${macro.value})`,
              "warning"
            );
            isError = true;
          }
        }
      }
    }

    if (!hasIngredients || !hasInstructions) {
      setAlert("Recipes must have at least one ingredient and step", "warning");
      isError = true;
    }

    if (isError) {
      setSubmitLoading(false);
      return;
    }

    if (recipeid) {
      try {
        const create = await editRecipe(recipe, recipeid, originalImages);

        if (create.url) {
          setAlert(`${create.name ? create.name : "Recipe"} updated`, "success");
          navigate(`/${user.username}/${create.url}`);
        }
      } catch (error) {
        console.log(error);
        setAlert("Error editing recipe", "error");
      }
      setSubmitLoading(false);
    } else {
      try {
        const create = await createRecipe(recipe);

        if (create.url) {
          setAlert(`${create.name ? create.name : "Recipe"} created`, "success");
          navigate(`/${user.username}/${create.url}`);
        }
      } catch (error) {
        console.log(error);
        setAlert("Error creating recipe", "error");
      }
      setSubmitLoading(false);
    }
  };

  const prefillRecipeFields = (recipe) => {
    if (recipe.name && recipe.name !== "") {
      if (name === "") {
        setName(recipe.name);
      }
    }

    if (recipe.description && recipe.description !== "") {
      if (description === "") {
        setDescription(recipe.description);
      }
    }

    if (recipe.cuisine) {
      if (cuisine && cuisine.length === 0) {
        setCuisine(recipe.cuisine);
      }
    }

    if (recipe.course) {
      if (course && course.length === 0) {
        setCourse(recipe.course);
      }
    }

    if (recipe.tags) {
      if (tags.length + recipe.tags.length < 20) {
        setTags([...tags, ...recipe.tags]);
      }
    }

    if (recipe.source) {
      setRecipeSourceType(recipe.source.type);
      setRecipeSourceURL(recipe.source.url ? recipe.source.url : "");
      setRecipeSource(recipe.source.text ? recipe.source.text : "");
      setRecipeSourceRefs(recipe.source.ref ? recipe.source.ref : null);
    }

    if (recipe.nutrition) {
      //If macros not all null, show macros
      //If allergens not empty, show
      if (recipe.nutrition?.allergens?.contains.length > 0) {
        setAllergens(recipe.nutrition.allergens.contains);
      }

      if (recipe.nutrition?.allergens?.mayContain.length > 0) {
        setAllergenMayContain(recipe.nutrition.allergens.mayContain);
      }
      //If diets not empty, show

      if (recipe.nutrition) {
        if (recipe.nutrition.friendlyDiets) {
          if (recipe.nutrition.friendlyDiets.length > 0) {
            setFriendlyDiets(recipe.nutrition.friendlyDiets);
          }
        }
      }
    }
  };

  const getEditRecipe = async (id) => {
    const editRecipe = await fetchRecipeToEdit(id);

    const recipe = editRecipe.recipe;

    if (recipe) {
      //Prefill non-state values
      //TODO: Expand sections for data if necessary
      prefillRecipeFields(recipe);
      setOriginalImages(recipe.data);
    }
  };

  useEffect(() => {
    //Check if is editing a recipe
    if (recipeid) {
      getEditRecipe(recipeid);
      //TO DO:
      //Check if can edit
    }

    if (startType) {
      if (startType === "generate") {
        if (plus.isPlus) {
          setFormView("sections");
          setInitialAddType("generate");
          if (startPrompt) {
            setInitialAddPrompt(startPrompt);
          }
          setShowAddSection(true);
        } else {
          togglePlusUpgradeModal(true);
        }
      }

      if (startType === "import") {
        setFormView("sections");
        setInitialAddType("import");
        setShowAddSection(true);
      }
    }
  }, [startType]);

  //Cleanup
  useEffect(() => {
    return () => {
      imageUploadReset();
      resetCreateRecipe();
    };
  }, []);
  const infoView = (
    <div className="flex flex-col gap-4">
      <div className="px-4 py-8 md:px-8 border-y md:border border-separator md:rounded-md shadow-sm bg-background">
        <div className="flex flex-col h-full md:flex-row">
          <div className="w-full">
            <TextField
              label="Name"
              variant="outlined"
              value={name}
              onChange={handleNameChange}
              fullWidth
              className="mb-2"
            />
            <TextField
              label="Description"
              multiline
              minRows={7}
              maxRows={10}
              variant="outlined"
              value={description}
              onChange={handleDescriptionChange}
              fullWidth
            />
          </div>
          <div className="ml-0 mt-2 md:ml-2 md:mt-0">
            <ImageDropzone />
          </div>
        </div>

        <ImageReorderPreview className={images.length > 0 ? "my-2" : "my-1"} />
        <div className="flex flex-row justify-between w-full [&>*:first-child]:mr-1 [&>*:nth-child(2)]:ml-1">
          <div className="w-1/2 space-y-2">
            <Searchable
              label="Cuisine"
              helperText="The recipe's cuisine, such as Italian or Mexican"
              labelWidth={70}
              multiple={false}
              freeSolo={true}
              apiUrl="/cuisines/searchable"
              allowAdd={true}
              onItemChange={onCuisineChange}
              selectedItemsFromParent={cuisine}
            />
          </div>

          <div className="w-1/2 space-y-2">
            <Searchable
              label="Course"
              helperText="The recipe's course, such as main or dessert"
              labelWidth={70}
              multiple={false}
              freeSolo={true}
              apiUrl="/recipe-courses/searchable"
              allowAdd={true}
              onItemChange={onCourseChange}
              selectedItemsFromParent={course}
            />
          </div>
        </div>

        <div className="my-4">
          <RecipeSectionTags tags={tags} setTags={setTags} />
        </div>
        <div className="flex flex-col gap-2">
          <div className="mb-2">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <ShadcnButton
                  variant="outline"
                  className="w-full justify-between font-normal text-secondaryText-600/90 rounded-lg border-secondaryText-200 hover:border-primaryText font-sans text-md"
                >
                  {recipeSourceType}
                  <ChevronDown className="w-3 opacity-50" />
                </ShadcnButton>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-[var(--radix-dropdown-menu-trigger-width)] rounded-[8px]">
                <DropdownMenuItem onSelect={() => setRecipeSourceType("Add a source")}>
                  {recipeSourceType === "Add a source" ? "Remove Source" : "Add a Source"}
                </DropdownMenuItem>
                <DropdownMenuItem onSelect={() => setRecipeSourceType("Inspired by")}>Inspired by</DropdownMenuItem>
                <DropdownMenuItem onSelect={() => setRecipeSourceType("Adapted from")}>Adapted from</DropdownMenuItem>
                <DropdownMenuItem onSelect={() => setRecipeSourceType("Based on")}>Based on</DropdownMenuItem>
                <DropdownMenuItem onSelect={() => setRecipeSourceType("A take on")}>A take on</DropdownMenuItem>
                <DropdownMenuItem onSelect={() => setRecipeSourceType("Found in")}>Found in</DropdownMenuItem>
                <DropdownMenuItem onSelect={() => setRecipeSourceType("Handed down from")}>
                  Handed down from
                </DropdownMenuItem>
                <DropdownMenuItem onSelect={() => setRecipeSourceType("Influenced by")}>Influenced by</DropdownMenuItem>
                <DropdownMenuItem onSelect={() => setRecipeSourceType("Generated with")}>
                  Generated with
                </DropdownMenuItem>
                <DropdownMenuItem onSelect={() => setRecipeSourceType("Imported from")}>Imported from</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>

          {recipeSourceType !== "Add a source" && (
            <>
              <div className="">
                <TextField
                  label="Source"
                  helperText="Name of the source of the recipe"
                  variant="outlined"
                  fullWidth
                  value={recipeSource}
                  onChange={onRecipeSourceChange}
                  size="small"
                />
              </div>
              <div className="">
                <TextField
                  label="Link"
                  helperText="Add a link to the recipe source"
                  variant="outlined"
                  fullWidth
                  value={recipeSourceURL}
                  onChange={onRecipeSourceURLChange}
                  size="small"
                />
              </div>
            </>
          )}
        </div>
      </div>

      <div className="flex w-full items-center justify-between">
        {edit.recipe ? (
          <Button
            onClick={(e) => handleFormSubmit(e)}
            variant="contained"
            color="primary"
            disableElevation
            fullWidth
            className="rounded-none md:rounded-[0.375rem]"
            disabled={submitLoading}
          >
            Save Recipe
          </Button>
        ) : (
          <Button
            onClick={(e) => handleFormSubmit(e)}
            variant="contained"
            color="primary"
            disableElevation
            fullWidth
            disabled={submitLoading}
          >
            +&nbsp;Create Recipe
          </Button>
        )}
      </div>
    </div>
  );

  const sectionsView = (
    <div className="flex flex-col gap-4">
      <div className="w-full md:mx-0">
        <RecipeSectionManager setShowAddSection={setShowAddSection} setInitialAddType={setInitialAddType} />
        {create?.sections && create.sections.length > 0 && (
          <div className="p-4 border border-separator rounded-md shadow-sm bg-background">
            <Button variant="contained" disableElevation disableRipple fullWidth>
              <Typography className="font-normal" variant="subtitle2" onClick={() => handleAddSections()}>
                + Add a New Section
              </Typography>
            </Button>
          </div>
        )}
      </div>
    </div>
  );

  const dietaryView = (
    <div className="w-full md:m-4 md:mx-0">
      <div className="w-full flex flex-col gap-4">
        <div className="px-4 md:px-8 py-6 w-full border border-separator rounded-md shadow-sm bg-background">
          <div>
            <Typography variant="h6" className="font-normal leading-none">
              Allergens:
            </Typography>
          </div>
          <div className="flex flex-col gap-4 mt-4">
            <Searchable
              label="Contains"
              labelWidth={85}
              multiple={true}
              freeSolo={true}
              apiUrl="/allergens/searchable"
              allowAdd={true}
              onItemChange={onAllergenChange}
              helperText="Add any allergens this recipe contains, such as peanuts or soy"
              selectedItemsFromParent={allergens}
            />
            <Searchable
              label="May Contain"
              labelWidth={110}
              multiple={true}
              freeSolo={true}
              apiUrl="/allergens/searchable"
              allowAdd={true}
              onItemChange={onAllergenMayContainChange}
              helperText="Add any allergens this recipe may contain"
              selectedItemsFromParent={allergenMayContain}
            />
          </div>
        </div>
        <div className="px-4 md:px-8 py-6 w-full border border-separator rounded-md shadow-sm bg-background">
          <div>
            <Typography variant="h6" className="font-normal leading-none">
              Friendly Diets:
            </Typography>
          </div>
          <div className="mt-4">
            <Searchable
              label="Friendly Diets"
              labelWidth={115}
              multiple={true}
              freeSolo={true}
              apiUrl="/diets/searchable"
              allowAdd={true}
              onItemChange={onFriendlyDietChange}
              helperText="Add any friendly diets this recipe adheres to, such as vegan or gluten-free"
              selectedItemsFromParent={friendlyDiets}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <Helmet>
        <title>{edit.recipe ? `Edit ${edit.recipe.name}` : "Create Recipe"}</title>
      </Helmet>
      <CustomModal
        open={showAddSection}
        handleClose={() => setShowAddSection(false)}
        contentClassNameOverride="md:w-[70vw] md:max-w-[700px] lg:max-w-[600px]"
      >
        <AddRecipeSectionModal
          handleModalClose={() => handleAddSectionModalClose()}
          setRecipeFields={prefillRecipeFields}
          initialAddType={initialAddType}
          initialAddPrompt={initialAddPrompt}
        />
      </CustomModal>
      <CustomModal open={plus.modals.upgrade} handleClose={() => togglePlusUpgradeModal(false)} border={false}>
        <PlusModal handleClose={() => togglePlusUpgradeModal(false)} />
      </CustomModal>

      <RecipeMacrosModal />
      <RecipeOutputModal />
      <RecipeTimingModal />
      <div className="flex flex-col items-center  md:pb-[5rem] mt-[4rem] md:px-4 md:py-4 md:ml-[5rem] md:mt-0 min-h-screen bg-neutral-50">
        <div className="max-w-[800px] w-full">
          <div className="flex flex-col items-center">
            <div className="w-full m-4 space-y-4 md:m-4 md:mx-0">
              <div className="w-full">
                <Tabs value={formView} onValueChange={(value) => setFormView(value)} className="w-full">
                  <TabsList className="w-full border border-separator p-0">
                    <TabsTrigger value="info" className="w-full h-full">
                      Info
                    </TabsTrigger>
                    <TabsTrigger value="sections" className="w-full h-full">
                      Sections
                    </TabsTrigger>
                    <TabsTrigger value="dietary" className="w-full h-full">
                      Dietary
                    </TabsTrigger>
                  </TabsList>
                </Tabs>
              </div>
              {formView === "info" && infoView}
              {formView === "sections" && sectionsView}
              {formView === "dietary" && dietaryView}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CreateRecipe.propTypes = {
  processIngredients: PropTypes.func.isRequired,
  recipe: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  imageUploadReset: PropTypes.func.isRequired,
  createRecipe: PropTypes.func.isRequired,
  editRecipe: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  fetchRecipeToEdit: PropTypes.func.isRequired,
  media: PropTypes.object.isRequired,
  resetCreateRecipe: PropTypes.func.isRequired,
  plus: PropTypes.object.isRequired,
  togglePlusUpgradeModal: PropTypes.func.isRequired,
  setOriginalImages: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  recipe: state.recipe,
  user: state.auth.user,
  media: state.media,
  plus: state.plus,
});
export default connect(mapStateToProps, {
  processIngredients,
  setAlert,
  imageUploadReset,
  createRecipe,
  editRecipe,
  fetchRecipeToEdit,
  resetCreateRecipe,
  togglePlusUpgradeModal,
  setOriginalImages,
})(CreateRecipe);
