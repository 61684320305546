import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toggleRecipeOutputModal, setSectionOutput } from "../../../../actions/createrecipe";
import CustomModal from "../../../modal/CustomModal";
import Searchable from "../../../layout/searchable/Searchable";

import { setAlert } from "../../../../actions/alert";

//MUI
import { Typography, TextField, Button } from "@mui/material";

//Edit macros for a section, or compiled macros for a recipe
const RecipeOutputModal = ({
  visible,
  sectionIndex,
  sections,
  toggleRecipeOutputModal,
  setAlert,
  setSectionOutput,
}) => {
  const handleClose = () => {
    //Verify values are valid
    handleSave();
  };

  const handleSave = () => {
    //TODO: Validate values FIRST THING
    setSectionOutput(
      { servings, yield: { amount: yieldAmount, unit: yieldUnit, ingredient: yieldIngredient } },
      servingSize,
      sectionIndex
    );
    toggleRecipeOutputModal(false);
  };

  const [servings, setServings] = useState("");
  const [servingSize, setServingSize] = useState("");
  const [yieldAmount, setYieldAmount] = useState([]);
  const [yieldUnit, setYieldUnit] = useState([]);
  const [yieldIngredient, setYieldIngredient] = useState([]);

  //Local state handling
  useEffect(() => {
    if (sectionIndex !== null) {
      setServings(sections[sectionIndex]?.output?.servings || "");
      setServingSize(sections[sectionIndex]?.servingSize || "");
      setYieldAmount(sections[sectionIndex]?.output?.yield?.amount || []);
      setYieldUnit(sections[sectionIndex]?.output?.yield?.unit || []);
      setYieldIngredient(sections[sectionIndex]?.output?.yield?.ingredient || []);
    }

    return () => {
      setServings("");
      setServingSize("");
      setYieldAmount([]);
      setYieldUnit([]);
      setYieldIngredient([]);
    };
  }, [sectionIndex]);

  const onYieldAmountChange = (e) => {
    const value = e.target.value;

    // Regular expression to check if the value is a valid fraction or ends with a slash
    const fractionRegex = /^(\d{1,3}\/\d{1,3})$|^(\d{1,3}\/)$/;

    if (value === "" || fractionRegex.test(value)) {
      setYieldAmount(value);
      return;
    }

    if (value === "" || fractionRegex.test(value)) {
      setYieldAmount(value);
      return;
    }
    if (!isNaN(value) && value >= 1 && value <= 10000) {
      setYieldAmount(value);
    } else {
      if (isNaN(value)) {
        setAlert("Amount must be a number", "warning");
      } else if (value < 1) {
        setAlert("Amount must be greater than or equal to 1", "warning");
      } else if (value > 10000) {
        setAlert("Amount must be less than or equal to 10,000", "warning");
      }
    }
  };

  const onYieldUnitChange = (newValue) => {
    if (newValue === null) {
      setYieldUnit([]);
      return;
    }
    if (newValue.value.length > 32) {
      setAlert("Yield Unit name must be less than 32 characters", "warning");
      return;
    }

    if (newValue.value.length === 0) {
      setYieldUnit([]);
      return;
    }

    if (newValue.value.length < 3 && newValue.value.length > 0) {
      setAlert("Yield Unit name must be at least 3 characters", "warning");
      return;
    }

    setYieldUnit(newValue);
  };

  const onYieldIngredientChange = (newValue) => {
    console.log(newValue);
    if (newValue === null) {
      setYieldIngredient([]);
      return;
    }
    if (newValue.value.length > 128) {
      setAlert("Yield Ingredient name must be less than 128 characters", "warning");
      return;
    }

    if (newValue.value.length === 0) {
      setYieldIngredient([]);
      return;
    }

    if (newValue.value.length < 3 && newValue.value.length > 0) {
      setAlert("Yield Ingredient name must be at least 3 characters", "warning");
      return;
    }

    setYieldIngredient(newValue);
  };

  const onServingsChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && value >= 0 && value <= 100) {
      setServings(value);
    } else {
      if (isNaN(value)) {
        setAlert("Servings must be a number", "warning");
      } else if (value < 0) {
        setAlert("Servings must be greater than or equal to 0", "warning");
      } else if (value > 100) {
        setAlert("Servings must be less than or equal to 100", "warning");
      }
    }
  };

  const onServingSizeChange = (e) => {
    const value = e.target.value;

    if (value.length < 48) {
      setServingSize(value);
    } else {
      setAlert("Serving size must be less than 48 characters", "warning");
    }
  };
  return (
    <CustomModal open={visible} handleClose={handleClose}>
      <div className="flex flex-col px-4">
        <div>
          <Typography variant="h6" className="mb-1 font-normal leading-none">
            {sections[sectionIndex]?.title ? `${sections[sectionIndex]?.title} Output` : "Section Output"}
          </Typography>
          <Typography variant="caption" className="text-secondaryText">
            Add an output to track leftovers for this section
          </Typography>
        </div>

        <div className="flex flex-col gap-4 py-4">
          <div className="flex flex-row gap-2">
            <TextField
              label="Servings"
              variant="outlined"
              helperText="How many people does this section serve?"
              fullWidth
              value={servings}
              onChange={onServingsChange}
              inputProps={{
                inputMode: "numeric",
              }}
            />
            <TextField
              label="Serving Size"
              variant="outlined"
              helperText="eg. 1 cup (100g)"
              fullWidth
              value={servingSize}
              onChange={onServingSizeChange}
            />
          </div>

          <div className="space-y-2">
            <div>
              <Typography variant="subtitle1" className="mb-2 font-normal">
                Total Yield
              </Typography>
            </div>
            <div className="flex flex-row w-full [&>*:first-child]:mr-1 [&>*:first-child]:w-1/2 [&>*:nth-child(2)]:ml-1 [&>*:nth-child(2)]:w-1/2">
              <div>
                <TextField
                  label="Yield Amount"
                  variant="outlined"
                  helperText="Eg. 12, 1/2"
                  fullWidth
                  inputProps={{
                    inputMode: "numeric",
                  }}
                  onChange={(e) => onYieldAmountChange(e)}
                  value={yieldAmount}
                />
              </div>
              <div>
                <Searchable
                  label="Yield Type/Unit"
                  helperText="Eg. cookies, ml, cups"
                  labelWidth={125}
                  multiple={false}
                  freeSolo={true}
                  apiUrl="/units/searchable"
                  allowAdd={true}
                  onItemChange={onYieldUnitChange}
                  fullWidth
                  selectedItemsFromParent={yieldUnit}
                />
              </div>
            </div>

            <div>
              <Searchable
                label="Yield Ingredient"
                helperText="What does the section make?"
                labelWidth={130}
                multiple={false}
                freeSolo={true}
                apiUrl="/ingredients/searchable"
                allowAdd={true}
                onItemChange={onYieldIngredientChange}
                selectedItemsFromParent={yieldIngredient}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-end mb-4">
          <Button variant="contained" color="primary" onClick={handleSave} disableElevation>
            Save
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

RecipeOutputModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  sections: PropTypes.array.isRequired,
  setAlert: PropTypes.func.isRequired,
  setSectionOutput: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  visible: state.recipe.create.modals.editOutput.open,
  sectionIndex: state.recipe.create.modals.editOutput.sectionIndex,
  sections: state.recipe.create.sections,
});

export default connect(mapStateToProps, { toggleRecipeOutputModal, setAlert, setSectionOutput })(RecipeOutputModal);
