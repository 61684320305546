import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Typography, IconButton } from "@mui/material";

//Components
import RecipeMacrosItem from "./RecipeMacrosItem";

//Utils
import { preventWidows } from "../../../../../utils/text";

//Icons
import { ReactComponent as ChevronLeft } from "../../../../../assets/icons/svg/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../../../../assets/icons/svg/chevron-right.svg";

const RecipeMacros = ({ macros, username }) => {
  const [viewedIndex, setViewedIndex] = useState(0);

  const viewNextSection = () => {
    const current = viewedIndex;
    if (current + 1 > macros.length - 1) {
      setViewedIndex(0);
    } else {
      setViewedIndex(current + 1);
    }
  };

  return (
    <div className="">
      <div className="py-4 px-4 md:px-8">
        <Typography variant="h6" className="font-sans font-normal mt-2 text-primaryText leading-none">
          Macronutrients:
        </Typography>
      </div>

      <div className="p-4 md:px-8 bg-neutral-100">
        <div className="flex flex-row justify-between">
          <div className="flex-1">
            <Typography variant="h6" className="font-normal leading-snug">
              {preventWidows(macros[viewedIndex].title)}
            </Typography>
          </div>
          <div>
            <IconButton onClick={viewNextSection} className="hover:bg-transparent" disableRipple>
              <ChevronRight className="h-3"></ChevronRight>
            </IconButton>
          </div>
        </div>
      </div>
      {viewedIndex === 0 ? (
        <div className="px-4 py-2 md:px-8">
          <Typography variant="subtitle2" className="font-normal  leading-tight">
            1 Serving (All Sections)
          </Typography>
        </div>
      ) : (
        macros[viewedIndex]?.servingSize && (
          <div className="px-4 py-2 md:px-8">
            <Typography variant="subtitle2" className="font-normal leading-snug">
              1 Serving
            </Typography>
            <Typography variant="caption" className="font-normal text-secondaryText-600 italic leading-tight">
              Serving Size: {macros[viewedIndex]?.servingSize}
            </Typography>
          </div>
        )
      )}
      <div className="p-4 md:px-8">
        {macros[viewedIndex].data.map((macro) => {
          return (
            <div>
              <RecipeMacrosItem data={macro} type="primary" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RecipeMacros;
