import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const PREFIX = 'SpendSourcesModal';

const classes = {
  content: `${PREFIX}-content`,
  item: `${PREFIX}-item`,
  title: `${PREFIX}-title`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.content}`]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      overflowY: 'auto',
      textAlign: 'center',
      marginTop: theme.spacing(2),
      padding: theme.spacing(4),
      paddingBottom: 0,
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0, 2),
        paddingTop: theme.spacing(6)
      },
      overflowWrap: 'break-word'
    },

  [`& .${classes.item}`]: {
    marginBottom: theme.spacing(4)
  },

  [`& .${classes.title}`]: {
    marginBottom: theme.spacing(1),
    fontWeight: 500
  }
}));

const SpendSourcesModal = () => {

    
    return (
      <Root className={classes.content}>
      <div className={classes.item}>
          <Typography variant='subtitle1' className={classes.title}>Household Annual Food Spending:</Typography>
                  <Typography variant='subtitle1'>
                  Detailed Food Spending, Canada, Regions and Provinces, Government of Canada, Statistics Canada, <a href='https://www150.statcan.gc.ca/t1/tbl1/en/tv.action?pid=1110012501' target='_blank'>https://www150.statcan.gc.ca/t1/tbl1/en/tv.action?pid=1110012501</a>.
                  </Typography>
          </div>
          <div className={classes.item}>
          <Typography variant='subtitle1' className={classes.title}>Household Food Waste as a Percentage:</Typography>
                  <Typography variant='subtitle1'>
                  Yu, Yang, and Edward C. Jaenicke. “Estimating household food waste using food acquisition data.” SSRN Electronic Journal, 2018, <a href='https://doi.org/10.2139/ssrn.3257535' target='_blank'>https://doi.org/10.2139/ssrn.3257535</a>.
                  </Typography>
          </div>
              
             </Root>
    );
};

export default SpendSourcesModal;