import React from 'react';
import { useMediaQuery } from '@mui/material';

//Versions
import Desktop from './Desktop';
import Mobile from './Mobile';
const GeneralAuth = () => {

//Breakpoints
const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
    return isSmallScreen ? <Mobile /> : <Desktop />;
}

export default GeneralAuth;