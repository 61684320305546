//Alert types
import { SHOW_RECIPE_REVIEW_MODAL, HIDE_RECIPE_REVIEW_MODAL } from "../actions/types";

const initialState = {
  recipeReviewModal: {
    open: false,
    allowDelay: false,
    recipeData: null,
  },
};

export default function (state = initialState, action) {
  //Destructure action
  const { type, payload } = action;

  switch (type) {
    case SHOW_RECIPE_REVIEW_MODAL:
      return {
        ...state,
        recipeReviewModal: {
          open: true,
          allowDelay: payload.allowDelay,
          recipeData: payload.recipeData,
        },
      };
    case HIDE_RECIPE_REVIEW_MODAL:
      return {
        ...state,
        recipeReviewModal: {
          open: false,
          allowDelay: false,
          recipeData: null,
        },
      };
    default:
      return state;
  }
}
