import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import InfiniteScroll from "react-infinite-scroll-component";
import ProfileSearchObject from "./ProfileSearchObject";

import Spinner from "../../../layout/Spinner";

//Actions
import { fetchProfiles } from "../../../../actions/search";

const SearchProfiles = ({ query, response, fetchProfiles }) => {
  useEffect(() => {
    fetchProfiles(query.value, response.offset);
  }, [query.value]);

  return (
    <InfiniteScroll
      dataLength={response?.data ? response.data.length : 0}
      next={fetchProfiles}
      hasMore={response?.hasMore}
      loader={<h4>Loading...</h4>}
    >
      {response?.error ? (
        <div>error</div>
      ) : response?.loading ? (
        <Spinner />
      ) : response?.data && response.data.length > 0 ? (
        <div className="grid auto-fill-minmax-250 md:auto-fill-minmax-300 gap-4 gap-y-8 mt-4 pt-[2.5rem]">
          {response.data.map((profile) => (
            <ProfileSearchObject key={profile.id} profile={profile} />
          ))}
        </div>
      ) : (
        <div>no profiles</div>
      )}
    </InfiniteScroll>
  );
};

SearchProfiles.propTypes = {
  query: PropTypes.object.isRequired,
  fetchProfiles: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  query: state.search.query,
  response: state.search.response,
});

export default connect(mapStateToProps, { fetchProfiles })(SearchProfiles);
