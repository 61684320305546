export const shortInteractionCount = (value) => {
  let result = value;
  let suffix = "";

  if (value === "NaN" || value < 0) return 0;

  if (value >= 1000000) {
    result = (value / 1000000).toFixed(1);
    suffix = "m";
  } else if (value >= 1000) {
    result = (value / 1000).toFixed(1);
    suffix = "k";
  }

  return result + suffix;
};
