import React, { useState, useEffect, Fragment } from "react";
import {
  Typography,
  TextField,
  Button,
  IconButton,
  Paper,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import CustomAutocomplete from "../../../layout/searchable/CustomAutocomplete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import PropTypes from "prop-types";
import { connect } from "react-redux";

//FA Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCircleCheck } from "@fortawesome/free-solid-svg-icons";

//Utility Function
String.prototype.toTitleCase = function () {
  var i, j, str, lowers, uppers;
  str = this.replace(/([^\W_]+[^\s-]*) */g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

  // Certain minor words should be left lowercase unless
  // they are the first or last words in the string
  lowers = [
    "A",
    "An",
    "The",
    "And",
    "But",
    "Or",
    "For",
    "Nor",
    "As",
    "At",
    "By",
    "For",
    "From",
    "In",
    "Into",
    "Near",
    "Of",
    "On",
    "Onto",
    "To",
    "With",
  ];
  for (i = 0, j = lowers.length; i < j; i++)
    str = str.replace(new RegExp("\\s" + lowers[i] + "\\s", "g"), function (txt) {
      return txt.toLowerCase();
    });

  // Certain words such as initialisms or acronyms should be left uppercase
  uppers = ["Id", "Tv"];
  for (i = 0, j = uppers.length; i < j; i++)
    str = str.replace(new RegExp("\\b" + uppers[i] + "\\b", "g"), uppers[i].toUpperCase());

  return str;
};

//TO DO: Split into 3 parts -> Item search -> Required Fields -> Optional Fields
//TO DO: After submitting, allow user to choose to add another item, reset values, and go again (for easy mass import)
const IndividualItem = ({ pantry: { addToPantry } }) => {
  //Local State
  //General
  const [section, setSection] = useState(0);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle the form submission here
    console.log(ingredient);
  };
  //Search Section
  const [ingredient, setIngredient] = useState("");

  //Amount Section
  const [selectedUnit, setSelectedUnit] = useState("");

  const [quantity, setQuantity] = useState(1);
  const [quantityError, setQuantityError] = useState({
    error: false,
    message: "",
  });

  const handleQuantityChange = (value) => {
    //Ensure the value is a number between 1 and 100
    if (value < 0 || value > 100) {
      setQuantityError({
        error: true,
        message: "Quantity must be between 1 and 100",
      });
      return;
    }
    setQuantityError({ error: false, message: "" });
    setQuantity(value);
  };

  const [size, setSize] = useState("");
  const [sizeError, setSizeError] = useState({
    error: false,
    message: "",
  });

  const handleSizeChange = (value) => {
    if (value.length < 50) {
      setSizeError({
        error: false,
        message: "",
      });
      setSize(value);
    } else {
      setSizeError({
        error: true,
        message: "Size must be less than 50 characters",
      });
    }
  };

  const handleAmountNext = () => {};
  //Info Section
  const [storageLocation, setStorageLocation] = useState("");
  const [showSublocation, setShowSublocation] = useState(false);
  const [expiryDate, setExpiryDate] = useState(new Date());
  const [noExpiryDate, setNoExpiryDate] = useState(false);
  //Final Section
  const [showPurchaseInfo, setShowPurchaseInfo] = useState(false);
  const [showNotes, setShowNotes] = useState(false);

  //Show optional fields

  const onHidePurchaseInfo = () => {
    //TODO: Clear purchase info fields
    setShowPurchaseInfo(false);
  };

  //Fields to add:
  //Date added to location
  //Expiry Date
  //State?

  const [itemType, setItemType] = useState(0);
  const searchSection = (
    <div className="w-full mt-2">
      <div className="h-full flex items-stretch mb-2">
        <Button className="p-0" onClick={() => setItemType(0)}>
          <Typography variant="h6" className={itemType === 0 ? "text-primaryText" : "text-secondaryText"}>
            Ingredient
          </Typography>
        </Button>
      </div>
      <div className="m-0">
        {itemType === 0 && (
          <CustomAutocomplete label={"Search for an Ingredient"} onChange={(e, v) => setIngredient(v)} />
        )}
        {itemType === 1 && <CustomAutocomplete label={"Search for a Product"} onChange={(e, v) => setIngredient(v)} />}
      </div>
      {addToPantry.selected && (
        <div className="mb-2">
          <div>
            <Typography className="italic">Adding:</Typography>
          </div>
          <div className="flex flex-row mx-4 mt-1">
            <Typography variant="h6" className="font-normal">
              {addToPantry.selected.name.toTitleCase()}
            </Typography>
            <IconButton className="p-0 text-red ml-2 hover:bg-transparent" size="large">
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </div>
          <div className="flex flex-row items-center italic text-secondaryText mx-4">
            {addToPantry.selected.verified ? (
              <Fragment>
                <FontAwesomeIcon icon={faCircleCheck} className="text-mainGreen mr-1" />
                <Typography variant="body2">Verified Ingredient</Typography>
              </Fragment>
            ) : (
              <Typography variant="body2">Unverified</Typography>
            )}
          </div>
        </div>
      )}
    </div>
  );

  const itemInfoSection = (
    <Fragment>
      <div className="w-full mt-2">
        {addToPantry.selected && (
          <div className="flex flex-col mb-2">
            <Typography variant="h6" className="font-normal">
              {addToPantry.selected.name.toTitleCase()}
            </Typography>
            <div className="flex flex-row items-center italic text-secondaryText">
              {addToPantry.selected.verified ? (
                <Fragment>
                  <FontAwesomeIcon icon={faCircleCheck} className="text-mainGreen mr-1" />
                  <Typography variant="body2">Verified Ingredient</Typography>
                </Fragment>
              ) : (
                <Typography variant="body2">Unverified</Typography>
              )}
            </div>
          </div>
        )}

        <div className="flex flex-col">
          <Typography variant="subtitle1" className="mb-1">
            Use By:
          </Typography>
          <DatePicker
            value={expiryDate}
            onChange={(date) => setExpiryDate(date)}
            format="MM/dd/yyyy"
            disablePast
            animateYearScrolling
            disabled={noExpiryDate}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={noExpiryDate}
                onChange={(event) => setNoExpiryDate(event.target.checked)}
                color="primary"
              />
            }
            label="No Use By Date"
            className="mt-1"
          />
        </div>
      </div>

      <div className="w-full mt-2">
        <FormControl variant="outlined" className="w-full">
          <InputLabel id="storage-location-label">Storage Location</InputLabel>
          <Select
            labelId="storage-location-label"
            id="storage-location"
            label="Storage Location"
            value={storageLocation}
            onChange={(e) => setStorageLocation(e.target.value)}
            fullWidth
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: "white",
                },
              },
            }}
          >
            <MenuItem value={"refrigerator"}>Refrigerator</MenuItem>
            <MenuItem value={"freezer"}>Freezer</MenuItem>
            <MenuItem value={"pantry"}>Pantry</MenuItem>
            <MenuItem value={"spicerack"}>Spice Rack</MenuItem>
          </Select>
        </FormControl>
        <Button fullWidth variant="outlined" className="mt-2 mb-8 text-secondaryText">
          Add a Sub-location
        </Button>
      </div>
    </Fragment>
  );

  const amountSection = (
    <div className="w-full mt-2">
      <div className="my-2 w-full">
        <div className="flex flex-row items-center mb-8">
          <TextField
            variant="outlined"
            label="Quantity"
            error={quantityError.error}
            type="number"
            value={quantity}
            onChange={(e) => handleQuantityChange(e.target.value)}
            inputProps={{
              min: 0,
              max: 100,
            }}
            InputProps={{
              endAdornment: <FontAwesomeIcon icon={faTimes} />,
            }}
          />
          {addToPantry.selected && (
            <div className="flex flex-col ml-4">
              <Typography variant="h6" className="font-normal">
                {addToPantry.selected.name.toTitleCase()}
              </Typography>
              <div className="flex flex-row items-center italic text-secondaryText">
                {addToPantry.selected.verified ? (
                  <Fragment>
                    <FontAwesomeIcon icon={faCircleCheck} className="text-mainGreen mr-1" />
                    <Typography variant="body2">Verified Ingredient</Typography>
                  </Fragment>
                ) : (
                  <Typography variant="body2">Unverified</Typography>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="[&>*]:mb-1">
          <div className="mb-1">
            <Typography variant="subtitle1">Each item:</Typography>
          </div>
          <TextField
            variant="outlined"
            label="Amount"
            fullWidth
            helperText="Specify the size of one unit (e.g., 500 for a 500 ml bottle of oil)"
          />
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="unit-label">Unit</InputLabel>
            <Select
              labelId="unit-label"
              id="unit"
              value={selectedUnit}
              onChange={(e) => setSelectedUnit(e.target.value)}
              label="Unit"
            >
              <MenuItem value="cup">Cup</MenuItem>
              <MenuItem value="grams">Grams</MenuItem>
              <MenuItem value="pieces">Pieces</MenuItem>
            </Select>
            <FormHelperText>
              Choose the unit that corresponds to the Amount (e.g., 'g' for grams, 'fl oz' for fluid ounces).
            </FormHelperText>
          </FormControl>
        </div>
      </div>
    </div>
  );

  const finalSection = (
    <Fragment>
      <div>
        <Typography variant="subtitle1" className="mb-1">
          Optional:
        </Typography>
      </div>
      <div>
        {showPurchaseInfo ? (
          <div>
            <Typography>Purchase Info</Typography>
            <IconButton onClick={() => setShowPurchaseInfo(false)} size="large">
              X
            </IconButton>
          </div>
        ) : (
          <Button
            fullWidth
            variant="contained"
            disableElevation
            className="text-secondaryText"
            onClick={() => setShowPurchaseInfo(true)}
          >
            Add Purchase Info
          </Button>
        )}
      </div>

      <div className="w-full mt-2">
        <Button fullWidth variant="contained" disableElevation className="text-secondaryText">
          Add Notes
        </Button>
      </div>
    </Fragment>
  );

  return (
    <div>
      <form className="flex flex-col" onSubmit={handleSubmit}>
        {section === 0 && searchSection}
        {section === 1 && amountSection}
        {section === 2 && itemInfoSection}

        {section === 3 && finalSection}

        <div className="flex justify-between">
          {section !== 0 && (
            <Button onClick={() => setSection(section - 1)} variant="outlined" disableElevation>
              Back
            </Button>
          )}
          {section === 0 && (
            <Fragment>
              <div></div>
              <Button
                onClick={() => setSection(section + 1)}
                variant="contained"
                color="primary"
                disableElevation
                disabled={!addToPantry.selected}
              >
                Next
              </Button>
            </Fragment>
          )}

          {(section === 1 || section === 2) && (
            <Button onClick={() => setSection(section + 1)} variant="contained" color="primary" disableElevation>
              Next
            </Button>
          )}
          {section === 3 && (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disableElevation
              className="mt-4"
              onClick={() => handleSubmit()}
            >
              Add to Pantry
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

IndividualItem.propTypes = {
  pantry: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  pantry: state.pantry,
});

export default connect(mapStateToProps, {})(IndividualItem);
