import React from "react";
import { useMediaQuery } from "@mui/material";
import { Typography, Button } from "@mui/material";

import { ReactComponent as ChefIcon } from "../img/chef.svg";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";

const VideoComponent = ({ videoSrc, quote, showRegisterModal }) => {
  //Breakpoints
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <div className="relative w-full h-[600px] md:h-[700px] overflow-hidden">
      <video className="w-full h-full block object-cover" autoPlay muted loop playsInline preload="auto">
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="absolute top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.2)] flex items-center justify-center flex-col">
        <Typography variant={isSmallScreen ? "h4" : "h3"} className="text-background text-center p-4">
          {quote}
        </Typography>
        <Button
          className="mt-8"
          variant="contained"
          color="primary"
          size={isSmallScreen ? "medium" : "large"}
          disableElevation
          endIcon={<FontAwesomeIcon icon={faUserPlus} />}
          onClick={() => showRegisterModal()}
        >
          <Typography variant="h6">Get in early</Typography>
        </Button>
      </div>
    </div>
  );
};

export default VideoComponent;
