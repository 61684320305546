import * as React from "react";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Grow,
  InputAdornment,
  Typography,
} from "@mui/material";
import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
/* Pass values as options 
eg: 

const options = [
  { label: 'Option 1', onClick: () => console.log('Option 1 selected') },
  { label: 'Option 2', onClick: () => console.log('Option 2 selected') },
  { label: 'Option 3', onClick: () => console.log('Option 3 selected') },


];


Option props:
label -> string
onClick -> func
onSelect -> func
icon -> component
dropdownComponent -> component
*/

const SelectableButtonGroup = ({
  options,
  variant = "contained",
  color = "primary",
  buttonSize = "medium",
  buttonTextClasses = "",
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClick = (e) => {
    options[selectedIndex].onClick(e);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);

    //Fire on select
    if (options[index].onSelect) {
      options[index].onSelect(event);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <div className="flex flex-col items-center relative">
      <ButtonGroup
        variant={variant}
        color={color}
        ref={anchorRef}
        aria-label="split button"
        disableElevation
        size={buttonSize}
      >
        <Button onClick={handleClick}>
          {options[selectedIndex].icon && (
            <InputAdornment position="start">{options[selectedIndex].icon}</InputAdornment>
          )}
          <Typography className={buttonTextClasses}>{options[selectedIndex].label}</Typography>
        </Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select action"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal={false}
        placement="bottom-start"
        className="z-[9000]"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-start" ? "center top" : "center bottom",
            }}
          >
            <Paper elevation={0} className="border border-separator w-full">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.label}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option.dropdownComponent ? (
                        React.createElement(option.dropdownComponent, { selected: index === selectedIndex })
                      ) : (
                        <>
                          {option.icon && <InputAdornment position="start">{option.icon}</InputAdornment>}
                          {option.label}
                        </>
                      )}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default SelectableButtonGroup;
