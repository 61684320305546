import React, { useState, useRef, useEffect, useCallback } from "react";
import { styled } from "@mui/material/styles";
import { AreaClosed, Line, Bar } from "@visx/shape";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { scaleTime, scaleLinear } from "@visx/scale";
import { extent, max } from "d3-array";
import { timeFormat } from "d3-time-format";

const PREFIX = "VisxLineChart";

const classes = {
  root: `${PREFIX}-root`,
  svg: `${PREFIX}-svg`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    position: "relative",
    paddingTop: "66.66%", // 2:3 aspect ratio
  },

  [`& .${classes.svg}`]: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
}));

const VisxAreaLineChart = ({ data, parentWidth, parentHeight, margin }) => {
  //MUI styles

  //Set defaults for props
  const width = parentWidth;
  const height = parentHeight;
  margin = margin || { top: 20, right: 80, bottom: 20, left: 80 };
  data = data || [
    { date: new Date("2022-01-02"), value: 2.99 },
    { date: new Date("2022-01-05"), value: 3.05 },
    { date: new Date("2022-01-10"), value: 2.95 },
    { date: new Date("2022-01-15"), value: 3.1 },
    { date: new Date("2022-01-20"), value: 3.02 },
    { date: new Date("2022-01-25"), value: 3.08 },
    { date: new Date("2022-01-30"), value: 3.15 },
  ];

  // Get the min and max dates
  const dates = data.map((d) => d.date);
  const minDate = Math.min(...dates);
  const maxDate = Math.max(...dates);

  // Get the min and max Y values
  const values = data.map((d) => d.value);
  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);

  //Format date for X Axis Labels
  // Check if all dates are from the same year
  const allSameYear = data.every((d) => d.date.getFullYear() === data[0].date.getFullYear());
  const formatDate = (timestamp) => {
    const date = new Date(Number(timestamp));

    if (!(date instanceof Date)) {
      console.error("Non-Date object passed to formatDate:", date);
      return "";
    }
    const format = allSameYear ? "%b %d" : "%b %d, %Y";
    return timeFormat(format)(date);
  };

  // Define the scales
  const xScale = scaleTime({
    range: [margin.left, width - margin.right],
    domain: extent(data, (d) => d.date),
  });

  const yScale = scaleLinear({
    range: [height - margin.bottom, margin.top],
    domain: [minValue - (maxValue - minValue) / 2, maxValue],
    nice: true,
  });

  function TickComponent({ x, y, formattedValue }) {
    return (
      <g>
        <Line from={{ x: 0, y }} to={{ x: width, y }} stroke="#ddd" />
        <text x={x} y={y} dy=".32em" fill="#666" fontFamily="Poppins, sans-serif" fontSize={10}>
          {formattedValue}
        </text>
      </g>
    );
  }

  return (
    <Root className={classes.root}>
      <svg width={width} height={height} className={classes.svg}>
        <AxisBottom
          top={height - margin.bottom}
          scale={xScale}
          tickValues={[minDate, maxDate]}
          tickFormat={formatDate}
        />
        <AxisLeft
          left={margin.left}
          scale={yScale}
          tickValues={[minValue - (maxValue - minValue) / 2, minValue, maxValue]}
          tickComponent={TickComponent}
        />
        <AreaClosed
          data={data}
          x={(d) => xScale(d.date)}
          y={(d) => yScale(d.value)}
          yScale={yScale}
          fill="#aeeef8"
          stroke="#598a56"
          strokeWidth={1}
        />
      </svg>
    </Root>
  );
};

export default VisxAreaLineChart;
