import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
//Sections
import ForYou from "./sections/foryou/ForYou";
import ProfileSearch from "./sections/profiles/ProfileSearch";
//Actions
import { setType } from "../../actions/search";

//TO DO:
//Maintain bottom/desktop nav bar when in search to allow for easy navigation away from search
//If typing, switch to users search by default
//Clicking on recipes takes to cookbook and prefills that search
const Search = ({ search: { query, response }, setType }) => {
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabParam = searchParams.get("type");
    if (tabParam === "trending" || tabParam === "profiles") {
      setType(tabParam);
    }
    if (tabParam === "recipes") {
      window.location.href = "/cookbook";
    }
  }, [location]);

  return (
    <div className="w-full min-h-screen bg-neutral-50">
      <div className="p-4">{query.type === "trending" && <ForYou />}</div>
      <div className="p-4">{query.type === "profiles" && <ProfileSearch query={query} />}</div>
    </div>
  );
};

Search.propTypes = {
  search: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  search: state.search,
  setType: PropTypes.func.isRequired,
});

export default connect(mapStateToProps, { setType })(Search);
