import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

import api from "../../../api/api";

//Components
import FollowButton from "../../profile/FollowButton";

import { Typography, Button } from "@mui/material";

//Default PFP
import { ReactComponent as DefaultPFP } from "../../../assets/default/profile_picture.svg";

//Utils
import { shortTimeSince } from "../../../utils/date";

const FollowedYou = ({ notification, index, isLast }) => {
  const [isFollowing, setIsFollowing] = useState(notification?.from?.following ? notification.from.following : false);
  const [followLoading, setFollowLoading] = useState(false);

  const handleFollow = async () => {
    setIsFollowing(true);
  };

  const handleUnfollow = async () => {
    setIsFollowing(false);
  };

  return (
    <div
      className="w-full flex flex-row items-start p-4 border-b border-separator"
      style={{ borderBottom: isLast ? "none" : "inherit" }}
    >
      <div className="w-full flex flex-row justify-between">
        <div className="w-full mr-4">
          {notification?.from?.username && (
            <div className="flex flex-row items-center mb-2">
              <div className="mr-2">
                {notification?.from?.picture && notification.from.picture !== "" ? (
                  <Link to={`/${notification.from.username}`}>
                    <img
                      src={notification.from.picture}
                      alt="User"
                      className="w-6 h-6 rounded-full border border-separator"
                    />
                  </Link>
                ) : (
                  <Link to={`/${notification.from.username}`}>
                    <DefaultPFP alt="User" className="w-6 h-6 rounded-full border border-separator" />
                  </Link>
                )}
              </div>
              <Link to={`/${notification.from.username}`} className="flex flex-row items-center">
                <Typography
                  variant="subtitle1"
                  className="text-normal text-mainGreen leading-none"
                >{`@${notification.from.username}`}</Typography>
              </Link>
              <div className="flex flex-row items-center">
                {notification.date && (
                  <Typography variant="body2" className="mx-2 text-normal text-secondaryText text-xs">
                    {shortTimeSince(notification.date)}
                  </Typography>
                )}

                {!notification.read && <div className="h-1 w-1 rounded-full bg-mainGreen mb-1"></div>}
              </div>
            </div>
          )}

          <Typography variant="body1">{notification.text}</Typography>
        </div>
        <div>
          <FollowButton
            isFollowing={isFollowing}
            handleFollow={handleFollow}
            handleUnfollow={handleUnfollow}
            size={"small"}
            userid={notification.from.id}
          />
        </div>
      </div>
    </div>
  );
};

export default FollowedYou;
