import React from "react";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const CannotFind = () => {
  return (
    <div className="flex flex-col justify-center items-center h-[calc(100vh-5rem)] px-4 md:px[4rem] text-center">
      <Typography variant="h2" className="text-mainGreen font-semibold">
        Uh oh.
      </Typography>
      <Typography variant="h6">The link you followed may be broken, or the page may have been removed.</Typography>
      <Link to="/" className="flex items-center mt-4">
        <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: "5px" }} />
        <Typography variant="h6" className="text-mainGreen">
          Go back to Home
        </Typography>
      </Link>
    </div>
  );
};

export default CannotFind;
