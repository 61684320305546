import React from "react";

const BarcodeScan = () => {
  return (
    <div>
      <h1>Hello, BarcodeScan!</h1>
      <p>This is a basic React component with some text.</p>
    </div>
  );
};

export default BarcodeScan;
