import api from "../api/api";

import store, { history } from "../store";

//Redux types
import {
  GET_UNREAD_NOTIFICATIONS,
  GET_ALL_NOTIFICATIONS,
  GET_UNREAD_NOTIFICATIONS_ERROR,
  GET_ALL_NOTIFICATIONS_ERROR,
  RESET_ALL_NOTIFICATIONS,
} from "./types";

//Set query
export const getUnreadNotifications = () => async (dispatch) => {
  try {
    const res = await api.get("/notifications");
    dispatch({
      type: GET_UNREAD_NOTIFICATIONS,
      payload: {
        unread: res.data.unread,
      },
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_UNREAD_NOTIFICATIONS_ERROR });
  }
};

export const getAllNotifications = () => async (dispatch) => {
  try {
    const res = await api.get("/notifications/open");

    dispatch({
      type: GET_ALL_NOTIFICATIONS,
      payload: {
        notifications: res.data,
      },
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_ALL_NOTIFICATIONS_ERROR });
  }
};

export const resetAllNotifications = () => (dispatch) => {
  dispatch({
    type: RESET_ALL_NOTIFICATIONS,
  });
};
