// src/components/graphs/AreaLineChart.js
import React from "react";
import {
  VictoryChart,
  VictoryArea,
  VictoryAxis,
  VictoryScatter,
  VictoryTheme,
  VictoryLine,
  VictoryLabel,
  VictoryContainer,
  VictoryVoronoiContainer,
  VictoryTooltip,
} from "victory";

import CustomTooltip from "./CustomTooltip";

const startDate = new Date("2022-01-02");
const endDate = new Date("2022-01-30");
const numberOfPoints = 30;

const data = Array.from({ length: numberOfPoints }, (_, index) => {
  const progress = index / (numberOfPoints - 1);
  const x = new Date(startDate.getTime() + progress * (endDate.getTime() - startDate.getTime()));
  const trend = 2.99 + progress * (3.15 - 2.99); // Adjust the range based on your desired trend
  const deviation = Math.random() * 0.1; // Adjust the deviation range based on your desired randomness
  const y = trend + deviation;
  return { x, y };
});

const CustomLabel = ({ text, ...props }) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.font = "16px Poppins"; // Set this to the font you're using for the labels
  const textWidth = context.measureText(text).width;
  return <VictoryLabel dx={-textWidth} text={text} {...props} />;
};

const AreaChart = () => {
  const maxYValue = Math.max(...data.map((d) => d.y));
  const minYValue = Math.min(...data.map((d) => d.y));
  const minDomain = Math.max(0, minYValue - (maxYValue - minYValue) / 2);
  console.log(minDomain);
  return (
    <div
      style={{
        width: "100%",
        position: "relative",
      }}
    >
      <VictoryChart
        theme={VictoryTheme.material}
        padding={{ top: 40, bottom: 40, left: 0, right: 60 }}
        containerComponent={
          <VictoryVoronoiContainer
            voronoiDimension="x"
            labels={({ datum }) => `y: ${datum.y}`}
            labelComponent={<CustomTooltip />}
          />
        }
        width={600}
        height={400}
        scale={{ x: "time", y: "linear" }}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
        domain={{
          x: [new Date(data[0].x), new Date(data[data.length - 1].x)], // Add this line
          y: [minDomain, maxYValue],
        }}
      >
        {/* Y Axis */}

        <VictoryAxis
          dependentAxis
          crossAxis
          domain={[0, maxYValue]}
          standalone={false}
          tickValues={[minYValue, maxYValue]}
          tickFormat={(t) => `${t.toFixed(2)}`}
          style={{
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
            grid: {
              strokeDasharray: "0", // Make the grid lines solid
            },
            tickLabels: { fontSize: 16, padding: 5, fontFamily: "'Poppins', sans-serif" },
          }}
          orientation="right" // Move the labels to the right
          labelComponent={<CustomLabel />} // Use the custom label component
        />

        {/* X Axis */}
        <VictoryAxis
          scale="time"
          tickValues={[new Date(data[0].x), new Date(data[data.length - 1].x)]} // Only include labels for the min and max date
          tickFormat={(x) => {
            const date = new Date(x);
            const minYear = new Date(data[0].x).getFullYear();
            const maxYear = new Date(data[data.length - 1].x).getFullYear();
            if (minYear === maxYear) {
              // If the years are the same, format the date as 'MMM DD'
              return `${date.toLocaleString("default", { month: "short" })} ${date.getDate()}`;
            } else {
              // If the years are different, format the date as 'MMM DD, YYYY'
              return `${date.toLocaleString("default", { month: "short" })} ${date.getDate()}, ${date.getFullYear()}`;
            }
          }}
          style={{
            tickLabels: {
              textAnchor: (tick) => (tick === new Date(data[data.length - 1].x) ? "end" : "start"),
              fontSize: 16,
              fontFamily: "'Poppins', sans-serif",
            },
          }}
        />

        {/* Line for min Y value */}
        <VictoryLine
          data={[
            { x: 1, y: minYValue },
            { x: data.length, y: minYValue },
          ]}
          style={{ data: { stroke: "#362e2d26" } }}
        />

        {/* Line for max Y value */}
        <VictoryLine
          data={[
            { x: 1, y: maxYValue },
            { x: data.length, y: maxYValue },
          ]}
          style={{ data: { stroke: "#362e2d26" } }}
        />

        {/* Area chart 
        <VictoryArea
          data={data}
          //interpolation="cardinal"
          style={{
            data: {
              fill: "url(#gradient)",
              fillOpacity: 0.7,
              stroke: "url(#line-gradient)",
              strokeWidth: 4,
            },
          }}
          animate={{
            onLoad: { duration: 1000 },
          }}
        />*/}

        <defs>
          <linearGradient id="fade" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#598a56" stopOpacity="0" />
            <stop offset="100%" stopColor="#598a56" stopOpacity="1" />
          </linearGradient>
        </defs>
        <VictoryLine
          data={data.slice(0, 2)}
          style={{
            data: { stroke: "url(#fade)" },
          }}
        />
        <VictoryLine
          data={data.slice(1)}
          style={{
            data: { stroke: "#598a56" },
          }}
        />
        {/* Scatter plot for data points 
        <VictoryScatter
          data={data}
          size={4} // Customize the size of the data point
          style={{
            data: {
              fill: "#598a56", // Customize the fill color of data points
            },
          }}
          animate={{
            onLoad: { duration: 500 },
          }}
        />
        */}

        {/* Gradient definition */}
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#598a56" />
            <stop offset="60%" stopColor="#598a56" stopOpacity="0.6" />
          </linearGradient>
          <linearGradient id="line-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#598a56" stopOpacity="0" />
            <stop offset="50%" stopColor="#598a56" stopOpacity="1" />
            <stop offset="100%" stopColor="#598a56" stopOpacity="0" />
          </linearGradient>
        </defs>
      </VictoryChart>
    </div>
  );
};

export default AreaChart;
