import React from 'react';

export const AddProduct = () => {
    return (
        <div>
            <h1>Add</h1>
        </div>
    )
}

export default AddProduct;