import React, { useState, useEffect } from "react";

//API
import api from "../../../api/api";

//Components
import GridSkeleton from "./types/GridSkeleton";
import StandardGridRecipe from "./types/StandardGridRecipe";
import StandardGridMedia from "./types/StandardGridMedia";
import StandardGridText from "./types/StandardGridText";
//TO DO:
// -> Display skeleton loader when loading
// -> Fetch post data
// -> Display post data

const StandardPostGridManager = ({ post }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [postData, setPostData] = useState(null);

  const fetchPost = async () => {
    try {
      const res = await api.post(
        `/post/feed/`,
        { postid: post.postid, repostuser: post.repostuser ? post.repostuser : null },
        { timeout: 30000 }
      );
      setPostData(res.data);
      setError(false);
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
      setPostData(null);
    }
  };

  useEffect(() => {
    fetchPost();
  }, []);

  return (
    <div>
      {error ? null : loading ? (
        <GridSkeleton />
      ) : (
        <>
          {postData.type === "recipe" && (
            <StandardGridRecipe recipeid={postData && postData.recipe && postData.recipe.id} />
          )}
          {postData.type === "media" && <StandardGridMedia post={postData} />}
          {postData.type === "text" && <StandardGridText post={postData} />}
        </>
      )}
    </div>
  );
};

export default StandardPostGridManager;
