import { TrendingUpOutlined } from "@mui/icons-material";
import api from "../api/api";
import viper from "../api/viper";
import store, { history } from "../store";
import { setAlert } from "./alert";

// Redux types
import {
  RECIPE_CREATE_INGRED_ERROR,
  RECIPE_CREATE_UPDATE,
  RECIPE_CREATE_FORMAT_INGRED,
  GET_EDIT_RECIPE,
  GET_EXTERNAL_RECIPE,
  GET_GENERATED_RECIPE,
  RECIPE_CREATE_RESET,
  TOGGLE_RECIPE_MACROS_MODAL,
  TOGGLE_RECIPE_OUTPUT_MODAL,
  TOGGLE_RECIPE_TIMING_MODAL,
} from "./types";

export const resetCreateRecipe = () => (dispatch) => {
  dispatch({
    type: RECIPE_CREATE_RESET,
  });
};

export const processIngredients = (ingredients, sectionIndex) => async (dispatch) => {
  try {
    if (ingredients.length === 0) {
      dispatch(setAlert("Ingredient cannot be empty", "warning"));
      return false;
    }

    dispatch({
      type: RECIPE_CREATE_FORMAT_INGRED,
    });

    const res = await api.post("/ingredients/process", { ingredients });

    const state = store.getState();
    const newSections = state.recipe.create.sections.map((section) => ({
      ...section,
      ingredients: [...section.ingredients],
    }));

    // Push the processed ingredients to the section
    if (res.data.ingredients.length > 0) {
      for (let i = 0; i < res.data.ingredients.length; i++) {
        let newIngredient = { ...res.data.ingredients[i] };

        if (!newIngredient.measured) {
          newIngredient.measured = !(
            newIngredient.quantity === 0 ||
            newIngredient.unit === "not_exact" ||
            newIngredient.unit === "to_taste"
          );
        }
        newSections[sectionIndex].ingredients.push(newIngredient);
      }
    } else {
      dispatch(setAlert("No ingredients detected", "warning"));
      return false;
    }

    dispatch({
      type: RECIPE_CREATE_UPDATE,
      payload: newSections,
    });
    return true;
  } catch (error) {
    const state = store.getState();
    const newSections = state.recipe.create.sections.map((section) => ({
      ...section,
      ingredients: [...section.ingredients],
    }));

    newSections[sectionIndex].ingredients.push({
      name: "Unknown Ingredient",
      quantity: "",
      unit: "",
      state: "",
      note: "",
      id: "",
      measured: true,
    });

    dispatch({
      type: RECIPE_CREATE_INGRED_ERROR,
      payload: newSections,
    });
    dispatch(setAlert("Error processing ingredient. Click the ingredient to add the info", "error"));
    return false;
  }
};

// Create section by title
export const createSection = (title) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section) => ({
    ...section,
    ingredients: [...section.ingredients],
    steps: [...section.steps],
  }));

  // Check for title uniqueness
  for (let section of newSections) {
    if (section.title === title) {
      dispatch(setAlert("Section title must be unique", "warning"));
      return false;
    }
  }

  newSections.push({
    title,
    ingredients: [],
    steps: [],
    macros: [],
    servingSize: "",
    timing: {
      rest: {
        hours: "",
        minutes: "",
      },
      prep: {
        hours: "",
        minutes: "",
      },
      cook: {
        hours: "",
        minutes: "",
      },
      total: {
        hours: "",
        minutes: "",
      },
    },
    output: {
      servings: "",
      yield: {
        amount: "",
        unit: "",
        ingredient: "",
      },
    },
  });

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });

  return true;
};

// Add ingredient to section
export const addIngredient = (sectionIndex, ingredient) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section) => ({
    ...section,
    ingredients: [...section.ingredients],
  }));

  newSections[sectionIndex].ingredients.push(ingredient);

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });
};

// Move ingredient in section to new index
export const moveIngredient = (sourceSectionIndex, destSectionIndex, oldIndex, newIndex) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section) => ({
    ...section,
    ingredients: [...section.ingredients],
  }));

  const [removed] = newSections[sourceSectionIndex].ingredients.splice(oldIndex, 1);
  newSections[destSectionIndex].ingredients.splice(newIndex, 0, removed);

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });
};

// Remove ingredient from section
export const removeIngredient = (sectionIndex, ingredientIndex) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section) => ({
    ...section,
    ingredients: [...section.ingredients],
  }));

  newSections[sectionIndex].ingredients.splice(ingredientIndex, 1);

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });
};

// Edit ingredient in section
export const editIngredient = (sectionIndex, ingredientIndex, ingredient) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section) => ({
    ...section,
    ingredients: [...section.ingredients],
  }));

  let trimmedIngredient = { ...ingredient };
  // Trim strings for all keys in ingredient
  for (let key in trimmedIngredient) {
    if (typeof trimmedIngredient[key] === "string") {
      trimmedIngredient[key] = trimmedIngredient[key].trim();
    }
  }

  // Set alert if invalid fields
  if (ingredient.name === "") {
    dispatch(setAlert("Name cannot be empty", "warning"));
    return {
      error: "empty_name",
    };
  }

  newSections[sectionIndex].ingredients[ingredientIndex] = trimmedIngredient;
  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });
  return {
    error: false,
  };
};

// Add step to section
export const addStep = (sectionIndex, stepText) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section) => ({
    ...section,
    steps: [...section.steps],
  }));

  newSections[sectionIndex].steps.push({ text: stepText, tips: [] });

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });
};

// Edit step in section (including adding tip)
export const editSectionStep = (sectionIndex, stepIndex, data) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section) => ({
    ...section,
    steps: [...section.steps],
  }));

  newSections[sectionIndex].steps[stepIndex] = {
    text: data.text,
    tips: data.tip && data.tip !== "" ? [data.tip] : [],
  };

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });
};

// Remove step from section
export const removeStep = (sectionIndex, stepIndex) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section) => ({
    ...section,
    steps: [...section.steps],
  }));

  newSections[sectionIndex].steps.splice(stepIndex, 1);

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });
};

// Edit section title
export const editSectionInfo = (sectionIndex, title, source) => (dispatch) => {
  const state = store.getState();

  // Check if the trimmed title already exists in another section
  const trimmedTitle = title.trim();
  const isTitleDuplicate = state.recipe.create.sections.some(
    (section, index) => index !== sectionIndex && section.title === trimmedTitle
  );

  if (isTitleDuplicate) {
    // If the title is a duplicate, dispatch an alert action
    dispatch(setAlert("Section title already exists. Please choose a unique title.", "error"));
    return null; // Exit the function early
  }

  let verifiedSource = source;

  //If source is automatically generated, do not allow editing
  if (state.recipe.create.sections[sectionIndex]?.source?.ref) {
    verifiedSource = state.recipe.create.sections[sectionIndex].source;
  } else {
    let allowedTypes = [
      "Inspired by",
      "Adapted from",
      "Based on",
      "A take on",
      "Found in",
      "Handed down from",
      "Influenced by",
      "Generated",
      "Generated with",
      "Imported from",
    ];

    if (source) {
      if (source.type === "Add a source") {
        verifiedSource = null;
      } else {
        //Verify source type is one of the allowed types
        if (!allowedTypes.includes(source.type)) {
          verifiedSource = null;
          dispatch(setAlert("Invalid source type", "warning"));
          return null;
        }

        //Verify source at least source OR url
        if (source.text === "" && source.url === "") {
          verifiedSource = null;
          dispatch(setAlert("Source must have a source or URL", "warning"));
          return null;
        } else {
          //Verify text and url are both under char limits
          source.text = source.text.trim();
          source.url = source.url.trim();

          if (source.text.length > 128) {
            dispatch(setAlert("Source text cannot be longer than 128 characters", "warning"));
            return null;
          }
          if (source.url.length > 256) {
            dispatch(setAlert("Source URL cannot be longer than 256 characters", "warning"));
            return null;
          }
        }
      }
    }
  }

  const newSections = state.recipe.create.sections.map((section, index) => {
    if (index !== sectionIndex) return section;

    return {
      ...section,
      title: trimmedTitle,
      source: verifiedSource,
    };
  });

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });

  return true;
};

// Remove section
export const deleteSection = (sectionIndex) => (dispatch) => {
  const state = store.getState();

  const sectionName = state.recipe.create.sections[sectionIndex].title;

  const newSections = state.recipe.create.sections.map((section) => ({
    ...section,
    ingredients: [...section.ingredients],
    steps: [...section.steps],
  }));

  newSections.splice(sectionIndex, 1);

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });

  dispatch(setAlert(`Removed: ${sectionName}`, "success"));
};

// Get recipe to edit, prep for editing
export const fetchRecipeToEdit = (recipeid) => async (dispatch) => {
  try {
    const res = await api.get(`/recipes/edit/${recipeid}`);

    dispatch({
      type: GET_EDIT_RECIPE,
      payload: res.data,
    });

    console.log(res.data);

    return {
      canEdit: true,
      recipe: res.data,
    };
  } catch (error) {
    return {
      canEdit: false,
    };
  }
};

//Search for recipes to insert as a section

//Get external recipe for section
export const fetchExternalRecipe = (url) => async (dispatch) => {
  try {
    const res = await api.post(
      "/recipes/find",
      { url },
      {
        validateStatus: (status) => {
          return (status >= 200 && status < 300) || status === 429 || status === 404;
        },
      }
    );

    if (res.status === 429) {
      //Throw response error
      return {
        error: {
          code: 429,
          msg: "Rate limit. Try again soon.",
        },
      };
    } else if (res.status === 404) {
      return {
        error: 404,
        msg: "Recipe not found",
      };
    }

    //Insert ingredients into each section
    let recipe = res.data.recipe;

    console.log(recipe);

    if (!recipe) {
      return {
        error: 404,
        msg: "Recipe not found.",
      };
    }

    dispatch({
      type: GET_EXTERNAL_RECIPE,
      payload: recipe,
    });

    return recipe;
  } catch (error) {
    return {
      error: {
        code: 500,
        msg: "Server Error",
      },
    };
  }
};

export const fetchGeneratedRecipe = (query) => async (dispatch) => {
  try {
    /* Route through express server now, DNS fix
    const res = await viper.post(
      "/recipes/generate",
      { text: query },
      {
        validateStatus: (status) => {
          return (status >= 200 && status < 300) || status === 429 ;
        },
      }
    );
    */

    const res = await api.post(
      "/recipes/generate",
      { text: query },
      {
        validateStatus: (status) => {
          return (status >= 200 && status < 300) || status === 429;
        },
      }
    );

    if (res.status === 429) {
      //Throw response error
      return {
        error: {
          code: 429,
          msg: "Rate limit. Try again soon.",
        },
      };
    }

    if (res.status === 402) {
      return {
        error: {
          code: 402,
          msg: "Plus is required for this feature.",
        },
      };
    }
    //Insert ingredients into each section
    let recipe = res.data.recipe;

    dispatch({
      type: GET_GENERATED_RECIPE,
      payload: recipe,
    });

    return recipe;
  } catch (error) {
    return {
      error: {
        code: 500,
        msg: "Server Error",
      },
    };
  }
};

export const toggleRecipeMacrosModal = (visible, sectionIndex) => (dispatch) => {
  if (!visible) {
    dispatch({
      type: TOGGLE_RECIPE_MACROS_MODAL,
      payload: {
        visible: false,
        sectionIndex: null,
      },
    });
  } else {
    dispatch({
      type: TOGGLE_RECIPE_MACROS_MODAL,
      payload: {
        visible,
        sectionIndex,
      },
    });
  }
};

export const pushSectionMacro = (sectionIndex, macro) => (dispatch) => {
  const state = store.getState();

  // Validate macro amount
  const amount = parseFloat(macro.amount);
  if (isNaN(amount) || !isFinite(amount) || amount.toString().length > 5) {
    dispatch(setAlert("Macro amount must be a valid number with at most 5 digits", "error"));
    return;
  }

  // Validate macronutrient name
  if (!macro.macronutrient || typeof macro.macronutrient.name !== "string" || macro.macronutrient.name.length > 32) {
    dispatch(setAlert("Invalid macronutrient selected", "error"));
    return;
  }

  const newSections = state.recipe.create.sections.map((section, index) => {
    if (index !== sectionIndex) return section;

    const newMacros = section.macros ? [...section.macros] : [];
    const existingMacroIndex = newMacros.findIndex((m) => m.macronutrient.name === macro.macronutrient.name);

    if (existingMacroIndex !== -1) {
      newMacros[existingMacroIndex] = {
        ...newMacros[existingMacroIndex],
        amount: amount,
      };
    } else {
      newMacros.push({ ...macro, amount: amount });
    }

    return {
      ...section,
      macros: newMacros,
    };
  });

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });
};

export const removeSectionMacro = (sectionIndex, macroIndex) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section) => ({
    ...section,
    macros: section.macros ? [...section.macros] : [],
  }));

  newSections[sectionIndex].macros.splice(macroIndex, 1);

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });
};

export const setSectionMacroServingSize = (sectionIndex, servingSize) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section, i) => {
    if (i !== sectionIndex) return section;

    return {
      ...section,
      servingSize: servingSize.trim(),
    };
  });

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });
};

export const toggleRecipeOutputModal = (visible, sectionIndex) => (dispatch) => {
  if (!visible) {
    dispatch({
      type: TOGGLE_RECIPE_OUTPUT_MODAL,
      payload: {
        visible: false,
        sectionIndex: null,
      },
    });
  } else {
    dispatch({
      type: TOGGLE_RECIPE_OUTPUT_MODAL,
      payload: {
        visible,
        sectionIndex,
      },
    });
  }
};

export const setSectionOutput = (outputData, servingSize, sectionIndex) => (dispatch) => {
  const state = store.getState();

  //outputData: servings, yield: {amount, unit, ingredient}
  const newSections = state.recipe.create.sections.map((section, i) => {
    if (i !== sectionIndex) return section;

    const returnObj = {
      ...section,
      output: outputData,
    };

    if (servingSize && servingSize !== "") {
      returnObj.servingSize = servingSize;
    } else {
      returnObj.servingSize = "";
    }
    return returnObj;
  });

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });
};

export const toggleRecipeTimingModal = (visible, sectionIndex) => (dispatch) => {
  if (!visible) {
    dispatch({
      type: TOGGLE_RECIPE_TIMING_MODAL,
      payload: {
        visible: false,
        sectionIndex: null,
      },
    });
  } else {
    dispatch({
      type: TOGGLE_RECIPE_TIMING_MODAL,
      payload: {
        visible,
        sectionIndex,
      },
    });
  }
};

export const setSectionTiming = (timingData, sectionIndex) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section, i) => {
    if (i !== sectionIndex) return section;

    return {
      ...section,
      timing: timingData,
    };
  });

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });
};
