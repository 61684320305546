//Import redux types
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  LOGOUT_ERROR,
  SHOW_REGISTER_MODAL,
  HIDE_REGISTER_MODAL,
  SHOW_LOGIN_MODAL,
  HIDE_LOGIN_MODAL,
  TOKEN_REFRESH_SUCCESS,
  TOKEN_REFRESH_FAIL,
  SET_TOKENS_FROM_CALLBACK,
  DELETE_ACCOUNT,
} from "../actions/types";

//Initial state object
const initialState = {
  hasFailed: false,
  token: localStorage.getItem("token"),
  refreshToken: localStorage.getItem("refreshToken"),
  isAuthenticated: false,
  loading: true,
  user: {},
  updates: 0,
  modals: {
    login: false,
    register: false,
  },
  errors: {
    loginErrors: null,
  },
};

export default function (state = initialState, action) {
  //Destructure action
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload.user,
        isPlus: payload.isPlus,
      };
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      localStorage.setItem("token", payload.token);
      localStorage.setItem("refreshToken", payload.refreshToken);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
        token: payload.token,
        refreshToken: payload.refreshToken,
        user: payload.user,
        modals: { ...state.modals, login: false },
      };
    case TOKEN_REFRESH_SUCCESS:
    case SET_TOKENS_FROM_CALLBACK:
      localStorage.setItem("token", payload.token);
      localStorage.setItem("refreshToken", payload.refreshToken);
      return {
        ...state,
        token: payload.token,
        refreshToken: payload.refreshToken,
      };

    case REGISTER_FAIL:
    case LOGIN_FAIL:
    case AUTH_ERROR:
    case LOGOUT:
    case LOGOUT_ERROR:
    case TOKEN_REFRESH_FAIL:
    case DELETE_ACCOUNT:
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      return {
        ...state,
        token: null,
        refreshToken: null,
        isAuthenticated: false,
        loading: false,
        user: null,
        errors: {
          ...state.errors,
          loginErrors: payload,
        },
      };
    case SHOW_LOGIN_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          login: true,
        },
      };
    case HIDE_LOGIN_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          login: false,
        },
      };
    case SHOW_REGISTER_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          register: true,
        },
      };
    case HIDE_REGISTER_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          register: false,
        },
      };
    default:
      return state;
  }
}
