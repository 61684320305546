//Import redux types
import {
  GET_ALL_NOTIFICATIONS,
  GET_ALL_NOTIFICATIONS_ERROR,
  GET_UNREAD_NOTIFICATIONS,
  GET_UNREAD_NOTIFICATIONS_ERROR,
  RESET_ALL_NOTIFICATIONS,
} from "../actions/types";

//Initial state object
const initialState = {
  unread: 0,
  notifications: null,
  loading: true,
  error: false,
};

export default function (state = initialState, action) {
  //Destructure action
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_NOTIFICATIONS:
      return {
        ...state,
        notifications: payload.notifications,
        loading: false,
        error: false,
      };
    case GET_ALL_NOTIFICATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        notifications: null,
      };

    case GET_UNREAD_NOTIFICATIONS:
      return {
        ...state,
        unread: payload.unread,
      };
    case GET_UNREAD_NOTIFICATIONS_ERROR:
      return {
        ...state,
      };
    case RESET_ALL_NOTIFICATIONS:
      return {
        ...state,
        unread: 0,
        notifications: null,
        error: false,
        loading: true,
      };

    default:
      return state;
  }
}
