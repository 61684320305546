import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import api from "../../api/api";
import { useNavigate } from "react-router-dom";

//Actions
import { setAlert } from "../../actions/alert";
import { createPost } from "../../actions/feed";
import { imageUploadReset } from "../../actions/media";

//Image Handling
import ImageDropzone from "./components/images/ImageDropzone";
import ImageReorderPreview from "./components/images/ImageReorderPreview";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";

//MUI
import { Typography, Button, TextField, CircularProgress } from "@mui/material";
import { NavigateBeforeRounded } from "@mui/icons-material";

const PREFIX = "CreatePost";

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  create_action: `${PREFIX}-create_action`,
  create_button: `${PREFIX}-create_button`,
  textRoot: `${PREFIX}-textRoot`,
  charCount: `${PREFIX}-charCount`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: theme.spacing(4),
    marginLeft: "5rem",
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(8, 2),
    },
  },

  [`& .${classes.content}`]: {
    width: "100%",
    maxWidth: "800px",
  },

  [`& .${classes.create_action}`]: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: theme.spacing(1),
  },

  [`& .${classes.create_button}`]: {
    textTransform: "none",
    color: theme.palette.mainGreen,
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  [`& .${classes.textRoot}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.charCount}`]: {
    color: theme.palette.secondaryTextOnWhite,
  },
}));

//TO DO: Post flow, api hit, clear state, redirect to feed

const CreatePost = ({ createPost, imageUploadReset }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [postText, setPostText] = useState("");

  const handleTextChange = (e) => {
    if (e.target.value.length <= 300) {
      setPostText(e.target.value);
    }
  };

  const handlePostSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      setError(false);

      const res = await createPost({ postText });

      //Reset image state
      imageUploadReset();

      //Redirect to feed
      navigate("/");
    } catch (error) {
      console.error(error);
      setError(true);
      setLoading(false);
    }
  };

  return (
    <Root className={classes.root}>
      <form onSubmit={handlePostSubmit} className={classes.content}>
        <ImageDropzone />
        <ImageReorderPreview />
        <div className={classes.textRoot}>
          <TextField
            label="Tell us about it!"
            multiline
            minRows={4}
            variant="outlined"
            value={postText}
            onChange={handleTextChange}
            fullWidth
          />
          <div className={classes.create_action}>
            <Typography variant="subtitle2" className={classes.charCount}>
              {postText.length}/300
            </Typography>
            <Button
              type="submit"
              className={classes.create_button}
              startIcon={loading ? <CircularProgress size={20} /> : <FontAwesomeIcon icon={faShare} />}
              disableRipple
              disabled={loading}
            >
              Create Post
            </Button>
          </div>
        </div>
      </form>
    </Root>
  );
};

CreatePost.propTypes = {
  createPost: PropTypes.func.isRequired,
  imageUploadReset: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { createPost, imageUploadReset })(CreatePost);
