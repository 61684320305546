import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import { connect } from "react-redux";

import EditIcon from "@mui/icons-material/Edit";

//Actions
import { openImageCropModal } from "../../../../actions/media";

const PREFIX = 'ReorderImage';

const classes = {
  container: `${PREFIX}-container`,
  image: `${PREFIX}-image`,
  overlay: `${PREFIX}-overlay`,
  overlayHover: `${PREFIX}-overlayHover`,
  icon: `${PREFIX}-icon`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.container}`]: {
    width: "150px",
    height: "150px",
    position: "relative",
  },

  [`& .${classes.image}`]: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
  },

  [`& .${classes.overlay}`]: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0,
    transition: "opacity 0.3s",
  },

  [`& .${classes.overlayHover}`]: {
    opacity: 1,
  },

  [`& .${classes.icon}`]: {
    color: "white",
  }
}));

const ReorderImage = ({ image, index, openImageCropModal }) => {


  const [hover, setHover] = useState(false);

  return (
    <Root className={classes.container} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <img
        src={image && image.cropped ? image.cropped.croppedImage : image.previewUrl}
        alt={`preview ${index}`}
        className={classes.image}
        onClick={() => openImageCropModal(index)}
      />
      <div
        className={`${classes.overlay} ${hover ? classes.overlayHover : ""}`}
        onClick={() => openImageCropModal(index)}
      >
        <EditIcon className={classes.icon} />
      </div>
    </Root>
  );
};

ReorderImage.propTypes = {
  image: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  openImageCropModal: PropTypes.func.isRequired,
};

export default connect(null, { openImageCropModal })(ReorderImage);
